import { parseDateToLocal } from "../../reuse/Dates";
import { BadgeColors } from "../../components/ReusableBadge/ReusableBadge";
import { getBadgeColorForQPInvoiceStatus } from "../../reuse/Styles";
import {
  QuickPayInvoiceStatus,
  QuickPayRequest,
  QuickPayRequestStatus,
  QuickPayToFilter,
  QuickPayToFilterMultiSelect,
  TimeProps,
} from "../../types/QuickPayRequest";
import { QUICKPAY_REQUEST_DEADLINE } from "../../reuse/Constants";
import { IQuickPayView } from "../../api-platform/quickpay";

export const getAllIds = (quickpays: IQuickPayView[]): string[] => {
  return quickpays?.map((element) => element.id) ?? [];
};

export const getAmountTotalSelected = (
  quickpays: IQuickPayView[],
  selectedQuickPayIds: string[],
): number => {
  const amountsArray = quickpays.map(({ id, amountToPayCarrier }) => {
    return selectedQuickPayIds.includes(id) ? amountToPayCarrier : 0;
  });
  const totalAmount = amountsArray.reduce(
    (acc, amount) => acc + Number(amount),
    0,
  );
  return totalAmount;
};

const sortQuickPaysByDate = (requests: QuickPayRequest[]): QuickPayRequest[] =>
  requests?.sort((requestA, requestB) =>
    new Date(requestA.createDate) < new Date(requestB.createDate) ? 1 : -1,
  );

export const getFilteredTableData = (
  requests: QuickPayRequest[],
  status: QuickPayRequestStatus,
): QuickPayRequest[] =>
  requests.filter((request) => status.includes(request.status)) ?? [];

export const getRequests = (
  requests: QuickPayRequest[],
  status: QuickPayRequestStatus,
): QuickPayRequest[] =>
  sortQuickPaysByDate(getFilteredTableData(requests, status));

const includeQuickPay = (request: QuickPayRequest, term: string): boolean => {
  const invoiceId = request.invoiceId.toString().toLowerCase();
  const broker = request.invoice.broker.contactInformation.companyName
    .toString()
    .toLowerCase();
  const carrier = request.carrier?.companyName.toString().toLowerCase() ?? "";
  const searchTerm = term.toLowerCase();
  const options = [invoiceId, broker, carrier];
  return options.some((option) => option.includes(searchTerm));
};

export const filterRowsByTerm = (
  requests: QuickPayRequest[],
  term: string,
): QuickPayRequest[] => requests.filter((row) => includeQuickPay(row, term));

export const generateData = (request: IQuickPayView): TimeProps => {
  const createDate = new Date(
    request.decisionDate ?? request.createDate,
  ).getTime();
  const elapsedTime = Math.round((Date.now() - createDate) / 1000 / 60 / 60);
  const timeRemaining = QUICKPAY_REQUEST_DEADLINE - elapsedTime;
  return { elapsedTime, timeRemaining };
};

export const getQuickPayToFilter = (
  quickPay: QuickPayRequest,
): QuickPayToFilter => {
  return {
    load_id: quickPay.invoice.mvmntInvoiceId?.toString() ?? "",
    broker: quickPay.invoice.broker.contactInformation.companyName,
    carrier: quickPay.carrier?.companyName ?? "",
    decisionAdmin: quickPay.decidingAdmin ?? "",
    decisionDate: parseDateToLocal(quickPay.decisionDate!),
    status: quickPay.status,
  };
};

export const filterRowsByMultiSelect = (
  quickPays: QuickPayRequest[],
  multiselectFilters: QuickPayToFilterMultiSelect,
): QuickPayRequest[] => {
  return quickPays.filter((row) => {
    const parsedInvoice = getQuickPayToFilter(row);
    const filterValues = Object.values(multiselectFilters).filter(
      (v) => v.length > 0,
    );

    const invoiceWithParsedDate: QuickPayToFilter = {
      ...parsedInvoice,
    };
    return filterValues.some((v) =>
      v.some((item) =>
        Object.values(invoiceWithParsedDate).join(",").includes(item),
      ),
    );
  });
};

export const getStatusWheterIsOverdueOrNot = (
  status: QuickPayInvoiceStatus,
  dueDate: Date,
): QuickPayInvoiceStatus =>
  new Date(dueDate) < new Date() ? QuickPayInvoiceStatus.OVERDUE : status;

export const getBadgeColorWheterIsOverdueOrNot = (
  status: QuickPayInvoiceStatus,
  dueDate: Date,
): BadgeColors =>
  new Date(dueDate) < new Date()
    ? BadgeColors.Red
    : getBadgeColorForQPInvoiceStatus(status);
