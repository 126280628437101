import { HStack, Avatar, Text } from "@chakra-ui/react";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { getUserName } from "../../reuse/UserData";
import TopBarDropdown from "../TopBarDropdown";

export const ShellTopBar = ({ height }: { height: string }): JSX.Element => {
  const data = useAppSelector(selectUserData);

  return (
    <HStack w="100%" h="3vh" minH={height} pl="15px" justifyContent="right">
      <HStack pe="0.5rem">
        <Avatar name={getUserName(data.user)} size="xs" />
        <Text>{getUserName(data.user)}</Text>
        <TopBarDropdown />
      </HStack>
    </HStack>
  );
};

export default ShellTopBar;
