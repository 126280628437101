import { mvmntApi, OrgInvitesKey } from "./mvmnt.api";

export interface OrgInvite {
  email: string;
  orgId: string;
  brokerId: string | null;
  roleId: string;
}

export const orgApi = mvmntApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrgInviteEmail: builder.query<string, string>({
      query: (invId) => ({
        url: `/orgs/invite/${invId}/email`,
        method: "GET",
      }),
    }),
    getUnacceptedOrgInvites: builder.query<OrgInvite[], string>({
      query: (orgId) => ({
        url: `/orgs/${orgId}/invites`,
        method: "GET",
      }),
      providesTags: [OrgInvitesKey],
    }),
  }),
});

export const { useGetOrgInviteEmailQuery, useGetUnacceptedOrgInvitesQuery } =
  orgApi;
