import { DeleteIcon } from "@chakra-ui/icons";
import { Spinner, HStack, Text } from "@chakra-ui/react";
import { useDeleteExtLoadDraftDocumentMutation } from "../../../../api-platform/documents";
import { IDraftDoc } from "./UploadDoc";

interface UploadDocUploadedProps {
  extLoadDraftDoc: IDraftDoc;
}

export const UploadDocUploaded = ({
  extLoadDraftDoc,
}: UploadDocUploadedProps): JSX.Element => {
  const [deleteDraftDocument, { isLoading: isDeleteLoading, isSuccess }] =
    useDeleteExtLoadDraftDocumentMutation();

  const splitDocName = extLoadDraftDoc.documentName.split("/");

  return (
    <HStack
      justifyContent="space-between"
      minH="32px"
      mb={6}
      opacity={isDeleteLoading ? "0.5" : undefined}
    >
      <HStack>
        <Text color="gray.600">{extLoadDraftDoc.documentType}</Text>
      </HStack>
      <HStack>
        <Text fontSize={18}>
          {splitDocName[splitDocName.length >= 1 ? splitDocName.length - 1 : 0]}
        </Text>
      </HStack>
      <HStack>
        {isDeleteLoading ? (
          <Spinner size="xs" color="red.500" />
        ) : (
          <DeleteIcon
            color="gray.600"
            cursor="pointer"
            visibility={isSuccess ? "hidden" : undefined}
            onClick={async () =>
              await deleteDraftDocument({
                id: extLoadDraftDoc.id,
                externalLoadDraftId: extLoadDraftDoc.externalDraftId,
              })
            }
          />
        )}
      </HStack>
    </HStack>
  );
};
