import { Icon, IconProps } from "@chakra-ui/react";

export const CloseIcon = (props: IconProps): JSX.Element => (
  <Icon width="14px" height="14px" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M1 13L13 1M13 13L1 1"
      stroke="#7B7B7B"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
