import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBrokerAccountSummary: build.query<
      GetBrokerAccountSummaryApiResponse,
      GetBrokerAccountSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/broker-account-summary/${queryArg.id}`,
      }),
    }),
    getBrokerAccounts: build.query<
      GetBrokerAccountsApiResponse,
      GetBrokerAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/broker-accounts`,
        params: {
          name: queryArg.name,
          admin: queryArg.admin,
          createDate: queryArg.createDate,
          status: queryArg.status,
          quickpayEnabled: queryArg.quickpayEnabled,
          ordering: queryArg.ordering,
          search: queryArg.search,
        },
      }),
    }),
    getBroker: build.query<GetBrokerApiResponse, GetBrokerApiArg>({
      query: (queryArg) => ({ url: `/internal/brokers/${queryArg.id}` }),
    }),
    updateBroker: build.mutation<UpdateBrokerApiResponse, UpdateBrokerApiArg>({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getBrokerCreditInfo: build.query<
      GetBrokerCreditInfoApiResponse,
      GetBrokerCreditInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/credit-info`,
      }),
    }),
    upsertBrokerCreditInfo: build.mutation<
      UpsertBrokerCreditInfoApiResponse,
      UpsertBrokerCreditInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/credit-info`,
        method: "PUT",
        body: queryArg.iUpsertBrokerFinancials,
      }),
    }),
    getPastBrokerages: build.query<
      GetPastBrokeragesApiResponse,
      GetPastBrokeragesApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/past-brokerages`,
      }),
    }),
    addPastBrokerages: build.mutation<
      AddPastBrokeragesApiResponse,
      AddPastBrokeragesApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/past-brokerages`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getBrokerAccountRequest: build.query<
      GetBrokerAccountRequestApiResponse,
      GetBrokerAccountRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/broker-account-requests/${queryArg.id}`,
      }),
    }),
    inviteBroker: build.mutation<InviteBrokerApiResponse, InviteBrokerApiArg>({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/invite`,
        method: "POST",
      }),
    }),
    rejectBrokerAccountRequest: build.mutation<
      RejectBrokerAccountRequestApiResponse,
      RejectBrokerAccountRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/broker-account-requests/${queryArg.id}/reject`,
        method: "PUT",
      }),
    }),
    updateBrokerAdmin: build.mutation<
      UpdateBrokerAdminApiResponse,
      UpdateBrokerAdminApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/admin`,
        method: "PUT",
        body: queryArg.updateBrokerAdminParams,
      }),
    }),
    getBrokerBillingContact: build.query<
      GetBrokerBillingContactApiResponse,
      GetBrokerBillingContactApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.brokerId}/billing-contact`,
      }),
    }),
    upsertBrokerBillingContact: build.mutation<
      UpsertBrokerBillingContactApiResponse,
      UpsertBrokerBillingContactApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.brokerId}/billing-contact`,
        method: "PUT",
        body: queryArg.iUpsertBrokerBillingContactInfo,
      }),
    }),
    sendForCreditCheck: build.mutation<
      SendForCreditCheckApiResponse,
      SendForCreditCheckApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/credit-check`,
        method: "POST",
      }),
    }),
    updateBrokerQuickPayEnable: build.mutation<
      UpdateBrokerQuickPayEnableApiResponse,
      UpdateBrokerQuickPayEnableApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/quickpay-enabled`,
        method: "PUT",
      }),
    }),
    updateBrokerPaymentControls: build.mutation<
      UpdateBrokerPaymentControlsApiResponse,
      UpdateBrokerPaymentControlsApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.id}/payment-controls`,
        method: "PUT",
        body: queryArg.iUpdateBrokerPaymentControls,
      }),
    }),
    getCarriersForBrokerAccount: build.query<
      GetCarriersForBrokerAccountApiResponse,
      GetCarriersForBrokerAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/internal/brokers/${queryArg.brokerId}/carriers`,
        params: { page: queryArg.page, limit: queryArg.limit },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type GetBrokerAccountSummaryApiResponse = /** status 200 Returned */ {
  type: Type;
  alloyApplicationToken: any | null;
  status: BrokersAndBrokerAccountRequestsStatus;
  email: string | null;
  lastName: string | null;
  firstName: string | null;
  name: string;
  id: string;
};
export type GetBrokerAccountSummaryApiArg = {
  id: string;
};
export type GetBrokerAccountsApiResponse = /** status 200 Returned */ {
  results: {
    type: Type;
    status: BrokersAndBrokerAccountRequestsStatus;
    quickpayEnabled: boolean;
    admin: string | null;
    lastName: string | null;
    firstName: string | null;
    name: string;
    createDate: string;
    id: string;
  }[];
};
export type GetBrokerAccountsApiArg = {
  name?: string;
  admin?: string;
  createDate?: string;
  status?: BrokersAndBrokerAccountRequestsStatus;
  quickpayEnabled?: boolean;
  ordering?: OrderingNameOrCreateDateOrAdminOrStatusOrQuickpayEnabled;
  search?: string;
};
export type GetBrokerApiResponse = /** status 200 Returned */ IBroker;
export type GetBrokerApiArg = {
  id: string;
};
export type UpdateBrokerApiResponse = /** status 200 Updated */ {
  taxIdentificationNumber: string;
  quickpayEnabled: boolean;
  phone?: string;
  addressPostal: string;
  addressCountry: string;
  addressState: string;
  addressCity: string;
  addressOne: string;
  address: string;
  salesforceId?: string;
  mcNumber: string;
  website?: string;
  name: string;
  id: string;
};
export type UpdateBrokerApiArg = {
  id: string;
  body: IBrokerToUpdate & {
    taxIdentificationNumber: string | null;
  };
};
export type GetBrokerCreditInfoApiResponse =
  /** status 200 Returned */ IBrokerFinancials;
export type GetBrokerCreditInfoApiArg = {
  id: string;
};
export type UpsertBrokerCreditInfoApiResponse =
  /** status 200 Updated */ IBrokerFinancials;
export type UpsertBrokerCreditInfoApiArg = {
  id: string;
  iUpsertBrokerFinancials: IUpsertBrokerFinancials;
};
export type GetPastBrokeragesApiResponse =
  /** status 200 Returned */ IBrokerBrokerage[];
export type GetPastBrokeragesApiArg = {
  id: string;
};
export type AddPastBrokeragesApiResponse = unknown;
export type AddPastBrokeragesApiArg = {
  id: string;
  body: IAddPastBrokerage[];
};
export type GetBrokerAccountRequestApiResponse = /** status 200 Returned */ {
  phone: string;
  email: string;
  lastName: string;
  firstName: string;
  brokerName: string;
  id: string;
};
export type GetBrokerAccountRequestApiArg = {
  id: string;
};
export type InviteBrokerApiResponse =
  /** status 201 Created */ IInviteUserResponse;
export type InviteBrokerApiArg = {
  id: string;
};
export type RejectBrokerAccountRequestApiResponse = unknown;
export type RejectBrokerAccountRequestApiArg = {
  id: string;
};
export type UpdateBrokerAdminApiResponse = /** status 200 Updated */ {
  phone?: string;
  email: string;
  lastName: string;
  firstName: string;
  id: string;
};
export type UpdateBrokerAdminApiArg = {
  id: string;
  updateBrokerAdminParams: UpdateBrokerAdminParams;
};
export type GetBrokerBillingContactApiResponse =
  /** status 200 Returned */ IBrokerBillingContact;
export type GetBrokerBillingContactApiArg = {
  brokerId: string;
};
export type UpsertBrokerBillingContactApiResponse =
  /** status 200 Updated */ IBrokerBillingContact;
export type UpsertBrokerBillingContactApiArg = {
  brokerId: string;
  iUpsertBrokerBillingContactInfo: IUpsertBrokerBillingContactInfo;
};
export type SendForCreditCheckApiResponse = /** status 201 Created */ string;
export type SendForCreditCheckApiArg = {
  id: string;
};
export type UpdateBrokerQuickPayEnableApiResponse =
  /** status 200 Updated */ IBroker;
export type UpdateBrokerQuickPayEnableApiArg = {
  id: string;
};
export type UpdateBrokerPaymentControlsApiResponse =
  /** status 200 Updated */ IBroker;
export type UpdateBrokerPaymentControlsApiArg = {
  id: string;
  iUpdateBrokerPaymentControls: IUpdateBrokerPaymentControls;
};
export type GetCarriersForBrokerAccountApiResponse =
  /** status 200 Returned */ {
    results: BrokerAccountCarriers[];
    totalSize: number;
    limit: number;
    page: number;
  };
export type GetCarriersForBrokerAccountApiArg = {
  brokerId: string;
  page?: number;
  limit?: number;
};
export type Type = "BROKER" | "BROKER_ACCOUNT_REQUEST";
export type BrokersAndBrokerAccountRequestsStatus =
  | "APPROVED"
  | "PENDING"
  | "REJECTED";
export type OrderingNameOrCreateDateOrAdminOrStatusOrQuickpayEnabled =
  | "name"
  | "admin"
  | "createDate"
  | "status"
  | "quickpayEnabled"
  | "-name"
  | "-createDate"
  | "-admin"
  | "-status"
  | "-quickpayEnabled";
export type IBrokerAdminUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};
export type IBroker = {
  id: string;
  name: string;
  address: string;
  addressCity: string;
  addressCountry: string;
  addressOne: string;
  addressPostal: string;
  addressState: string;
  addressTwo: string;
  adminUserId: string | null;
  counterpartyId: string | null;
  createDate: string;
  ledgerAccountId: string | null;
  mcNumber: string;
  phone: string;
  carrierQpPercentageFeeMax: number;
  carrierQpPercentageFeeMin: number;
  brokerQpPercentageFee: number;
  quickpayEnabled: boolean;
  salesforceId: string | null;
  updateDate: string;
  virtualAccountId: string | null;
  website: string | null;
  alloyApplicationToken: string | null;
  adminUser?: IBrokerAdminUser;
  svbSubAccount: string | null;
};
export type IBrokerToUpdate = {
  address?: string;
  addressOne?: string;
  addressTwo?: string;
  addressCity?: string;
  addressCountry?: string;
  addressPostal?: string;
  addressState?: string;
  mcNumber?: string;
  name?: string;
  phone?: string;
  quickpayEnabled?: boolean;
  salesforceId?: string;
  website?: string;
};
export type IBrokerFinancials = {
  brokerId: string;
  createDate: string;
  equityCapital: number | null;
  id: string;
  lastYearBankruptcy: boolean | null;
  lastYearFelonyCharges: boolean | null;
  lastYearInterestExpense: number | null;
  lastYearNetIncome: number | null;
  monthlyIncome: number | null;
  monthlyRevenue: number | null;
  taxIdentificationNumber: string | null;
  taxLiens: boolean | null;
  totalAssets: number | null;
  totalDebt: number | null;
  unrestrictedCash: number | null;
  unsatisfiedJudgements: boolean | null;
  updateDate: string;
  yearOneEbitda: number | null;
  yearOneRevenue: number | null;
  yearThreeEbitda: number | null;
  yearThreeRevenue: number | null;
  yearTwoEbitda: number | null;
  yearTwoRevenue: number | null;
  creditLimit: number | null;
};
export type IUpsertBrokerFinancials = {
  equityCapital: number;
  lastYearBankruptcy: boolean;
  lastYearFelonyCharges: boolean;
  lastYearInterestExpense: number;
  lastYearNetIncome: number;
  monthlyIncome: number;
  monthlyRevenue: number;
  taxLiens: boolean;
  totalAssets: number;
  totalDebt: number;
  unrestrictedCash: number;
  unsatisfiedJudgements: boolean;
  yearOneEbitda: number;
  yearOneRevenue: number;
  yearThreeEbitda: number;
  yearThreeRevenue: number;
  yearTwoEbitda: number;
  yearTwoRevenue: number;
  creditLimit: number | null;
};
export type IBrokerBrokerage = {
  brokerId: string;
  createDate: string;
  id: string;
  updateDate: string;
  pastBrokerageStillInOperation: boolean;
  pastBrokerageName: string | null;
};
export type IAddPastBrokerage = {
  pastBrokerageName: string;
  pastBrokerageStillInOperation: boolean;
};
export type IInviteUserResponse = {
  congitoUserCreated: boolean;
  userAuthTokenSet: boolean;
};
export type UpdateBrokerAdminParams = {
  phone?: string;
  lastName?: string;
  firstName?: string;
};
export type IBrokerBillingContact = {
  brokerId: string;
  createDate: string;
  id: string;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  updateDate: string;
};
export type IUpsertBrokerBillingContactInfo = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
};
export type IUpdateBrokerPaymentControls = {
  carrierQpPercentageFeeMax: number;
  carrierQpPercentageFeeMin: number;
  brokerQpPercentageFee: number;
};
export type CarrierTypeIdEnum = "DOT number" | "Motor Carrier number" | "Other";
export type BrokerAccountCarriers = {
  company: {
    carrierId: string;
    typeId: CarrierTypeIdEnum;
    carrierName: string;
  };
  corporateAddress: string | null;
  phone: string | null;
  contact: {
    primaryContactPhone: string | null;
    primaryContactEmail: string | null;
    primaryContactLastName: string | null;
    primaryContactFirstName: string | null;
  };
  paymentAccount: {
    bank: string;
    routing: string;
    accountLastFourDigits: string;
  };
  lastPaidDate: string | null;
  dateAdded: string | null;
};
export const {
  useGetBrokerAccountSummaryQuery,
  useLazyGetBrokerAccountSummaryQuery,
  useGetBrokerAccountsQuery,
  useLazyGetBrokerAccountsQuery,
  useGetBrokerQuery,
  useLazyGetBrokerQuery,
  useUpdateBrokerMutation,
  useGetBrokerCreditInfoQuery,
  useLazyGetBrokerCreditInfoQuery,
  useUpsertBrokerCreditInfoMutation,
  useGetPastBrokeragesQuery,
  useLazyGetPastBrokeragesQuery,
  useAddPastBrokeragesMutation,
  useGetBrokerAccountRequestQuery,
  useLazyGetBrokerAccountRequestQuery,
  useInviteBrokerMutation,
  useRejectBrokerAccountRequestMutation,
  useUpdateBrokerAdminMutation,
  useGetBrokerBillingContactQuery,
  useLazyGetBrokerBillingContactQuery,
  useUpsertBrokerBillingContactMutation,
  useSendForCreditCheckMutation,
  useUpdateBrokerQuickPayEnableMutation,
  useUpdateBrokerPaymentControlsMutation,
  useGetCarriersForBrokerAccountQuery,
  useLazyGetCarriersForBrokerAccountQuery,
} = injectedRtkApi;
