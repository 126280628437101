import { HStack, Select, Spinner, Text, VStack } from "@chakra-ui/react";
import { PDFIcon, DeleteIcon } from "../../ui/icons";
import { useDeleteExtLoadDocumentMutation } from "../../api-platform/documents";
import { DocumentType } from "../../types/QuickpayDocuments";
import { ExternalLoadDraftDocument } from "../../types/ExternalLoadDraft";

interface DocumentProps {
  doc: ExternalLoadDraftDocument;
  onChangeDocType: React.ChangeEventHandler<HTMLSelectElement> | undefined;
}

export const Document = ({
  doc,
  onChangeDocType,
}: DocumentProps): JSX.Element => {
  const [deleteDocument, { isLoading: isDeleteLoading, isSuccess }] =
    useDeleteExtLoadDocumentMutation();

  const getDocFileName = (fullName: string): string => {
    return fullName.split("/").pop() ?? "";
  };

  return (
    <HStack
      key={`ext load doc: ${doc.id}`}
      justifyContent="space-between"
      minH="32px"
      mb={6}
      opacity={isDeleteLoading ? "0.5" : undefined}
      pointerEvents={isDeleteLoading ? "none" : undefined}
    >
      <HStack maxW="50%">
        <PDFIcon color="gray.400" />
        <Text wordBreak={"break-all"} textColor="gray.700">
          {getDocFileName(doc.documentName)}
        </Text>
      </HStack>
      <HStack w="250px">
        <Select
          value={doc.documentType}
          onChange={onChangeDocType}
          textColor="gray.500"
        >
          <option value={DocumentType.INVOICE}>Carrier invoice</option>
          <option value={DocumentType.RATECON}>Rate confirmation</option>
          <option value={DocumentType.ProofOfDelivery}>
            Proof of delivery
          </option>
          <option value={DocumentType.BillOfLading}>Bill of lading</option>
          <option value={DocumentType.OTHER}>Other</option>
        </Select>
        <VStack width="50px">
          {isDeleteLoading ? (
            <Spinner size="xs" color="red.500" />
          ) : (
            <DeleteIcon
              color="gray.600"
              cursor="pointer"
              visibility={isSuccess ? "hidden" : undefined}
              onClick={() =>
                deleteDocument({
                  id: doc.id,
                  externalLoadId: doc.externalDraftId,
                })
              }
            />
          )}
        </VStack>
      </HStack>
    </HStack>
  );
};
