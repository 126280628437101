import { createContext, useContext } from "react";
import { GetQuickPayCountsByStatusApiResponse } from "../../api-platform/quickpay/generated";
import { QuickPayStatusEnum } from "../../types/QuickPayRequest";

interface QuickPay {
  currentStatus: QuickPayStatusEnum;
  setCurrentStatus: React.Dispatch<React.SetStateAction<QuickPayStatusEnum>>;
  quickPayCounts: GetQuickPayCountsByStatusApiResponse | undefined;
}

const QuickPayContext = createContext<QuickPay>({
  currentStatus: QuickPayStatusEnum.Pending,
  setCurrentStatus: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  quickPayCounts: undefined,
});

const useQuickPayContext = (): QuickPay => useContext(QuickPayContext);

export { QuickPayContext, useQuickPayContext };
