import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ManageUserBtns from "../../components/SharedTable/ManageUserBtns";
import { useManageUserContext } from "./ManageUserContext";
import { toTitleCase } from "../../reuse/Strings";
import { BrokerStatus, BrokerUser } from "../../types/Broker";
import { parseDateToLocal } from "../../reuse/Dates";
import { sortDate } from "../../reuse/Sorting";
import SortButton from "../../components/SortButton";
import ReusableBadge from "../../components/ReusableBadge";
import { getBadgeColorForBrokerStatus } from "../../reuse/Styles";

export interface AdminManageBrokersTableProps {
  brokers: BrokerUser[];
}

export const AdminManageBrokersTable = ({
  brokers,
}: AdminManageBrokersTableProps): JSX.Element => {
  const context = useManageUserContext();
  const { term, setSelectedBroker, currentStatus } = context!;

  const [filteredData, setFilteredData] = useState<BrokerUser[]>(brokers);
  const [descOrder, setDescOrder] = useState<boolean>(true);

  const sortByDate = (): void => {
    const sort = (a: BrokerUser, b: BrokerUser): number =>
      sortDate(a.createDate!, b.createDate!, descOrder);
    setDescOrder((currentOrder) => !currentOrder);
    setFilteredData([...filteredData].sort(sort));
  };

  useEffect(() => {
    setFilteredData(
      brokers.filter(
        (row) =>
          row.contactInformation.firstName
            .toLowerCase()
            .includes(term.toLowerCase()) ||
          row.contactInformation.lastName
            .toLowerCase()
            .includes(term.toLowerCase()),
      ),
    );
  }, [term]);

  const renderTableRow = (
    tabStatus: BrokerStatus,
    broker: BrokerUser,
    idx: number,
  ): JSX.Element => (
    <Tr key={`broker user table row:${idx}`} border="0.8px solid grey">
      <Td
        color="mvmntBlue"
        onClick={() => setSelectedBroker(broker)}
        cursor="pointer"
      >
        {broker.contactInformation.firstName}{" "}
        {broker.contactInformation.lastName}
      </Td>
      <Td>{broker.contactInformation.email}</Td>
      <Td>{parseDateToLocal(broker.createDate!)}</Td>
      <Td>{toTitleCase(broker.userType)}</Td>
      <Td>
        <ReusableBadge<BrokerStatus>
          currentStatus={broker.status}
          badgeColor={getBadgeColorForBrokerStatus(broker.status)}
        />
      </Td>
      <Td>
        {BrokerStatus.PENDING === broker.status &&
        BrokerStatus.PENDING === tabStatus ? (
          <ManageUserBtns
            id={broker.id}
            email={broker.contactInformation.email}
            userType={broker.userType}
          />
        ) : (
          <></> // TODO: There are three dots here in the design, not sure what they do yet
        )}
      </Td>
    </Tr>
  );

  return (
    <Table
      variant="unstyled"
      border="0.8px solid grey"
      borderRadius="4"
      overflow="hidden"
      paddingInline={0}
    >
      <Thead bgColor="lightgray" border="0.8px solid grey">
        <Tr border="0.8px solid grey">
          <Th textTransform="uppercase">Name</Th>
          <Th textTransform="uppercase">Email Address</Th>
          <Th textTransform="uppercase" cursor="pointer" onClick={sortByDate}>
            Date Submitted
            <SortButton asc={!descOrder} />
          </Th>
          <Th textTransform="uppercase">Role</Th>
          <Th textTransform="uppercase">Status</Th>
          <Th></Th>
        </Tr>
      </Thead>
      <Tbody>
        {filteredData.map((broker, idx) =>
          renderTableRow(currentStatus, broker, idx),
        )}
      </Tbody>
    </Table>
  );
};

export default AdminManageBrokersTable;
