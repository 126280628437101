import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import { mvmntApi } from "./mvmnt.api";

interface S3Upload {
  file: File;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

export const documentsApi = mvmntApi.injectEndpoints({
  endpoints: (builder) => ({
    getDocumentUrl: builder.query<string, string>({
      query: (id) => `/documents/${id}`,
    }),
    getExternalLoadTemplate: builder.query<string, void>({
      query: () => "/documents/external-load-template",
    }),
    getLoadUploadTemplate: builder.query<string, void>({
      query: () => "/documents/load_template",
    }),
    uploadLoadDocument: builder.mutation<
      boolean,
      S3Upload & { loadId: string }
    >({
      async queryFn(
        { file, loadId, onUploadProgress },
        _queryApi,
        _extraOptions,
        fetchWithBQ,
      ) {
        const result = await fetchWithBQ({
          url: `/load/${loadId}`,
          method: "POST",
          body: {
            fileName: file.name,
            contentType: file.type,
          },
        });
        if (result.error) return { error: result.error as FetchBaseQueryError };
        const upload = await axios.put(result.data as string, file, {
          headers: { "Content-Type": file.type },
          onUploadProgress: (progressEvent) => {
            onUploadProgress?.(progressEvent);
          },
        });
        return { data: upload.status === 200 };
      },
      invalidatesTags: [],
    }),
  }),
});

export const {
  useGetDocumentUrlQuery,
  useLazyGetDocumentUrlQuery,
  useGetLoadUploadTemplateQuery,
  useUploadLoadDocumentMutation,
  useGetExternalLoadTemplateQuery,
  useLazyGetExternalLoadTemplateQuery,
} = documentsApi;
