import {
  Box,
  Stack,
  HStack,
  Image,
  Link,
  VStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Outlet, useLocation, useOutletContext } from "react-router-dom";
import MvmntLogo from "../../../resources/svg/new-logo.svg";
import LoginBackground from "../../../resources/png/login-background.png";
import { PeopleIcon, QuickPayIcon } from "../../../ui/icons";
import { routes } from "../../../routes";
import { useState } from "react";

interface ContextType {
  username: string | undefined;
  setUsername: (username: string) => void;
}

export function useUsername(): ContextType {
  return useOutletContext<ContextType>();
}

export function Layout(): JSX.Element {
  const location = useLocation();
  const [username, setUsername] = useState<string>();

  return (
    <>
      <Stack direction={{ base: "column", md: "row" }} spacing="0">
        <Box
          w={{ md: "50%" }}
          minH={{ base: "100px", md: "100vh" }}
          bg="gray.800"
          backgroundImage={LoginBackground}
          backgroundSize="cover"
          backgroundPosition="right"
        >
          <Image
            src={MvmntLogo}
            position="absolute"
            top={{ base: "20px", md: "60px" }}
            left={{ base: "20px", md: "60px" }}
          />
          {location.pathname.includes(routes.REQUEST_ACCOUNT) && (
            <Flex
              justifyContent={{ md: "center" }}
              minH={{ md: "100vh" }}
              alignItems={{ base: "start", md: "center" }}
              marginTop={{ base: "60px", md: 0 }}
              padding="20px"
            >
              <VStack spacing="48px" color="white">
                <Text
                  fontSize="44px"
                  fontFamily="Saira Heading"
                  color="gray.100"
                  textTransform="uppercase"
                  alignSelf="baseline"
                >
                  Join the MVMNT
                </Text>
                <HStack spacing="24px" alignSelf="baseline">
                  <Box
                    borderRadius="full"
                    bg="gray.900"
                    w="56px"
                    h="56px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <PeopleIcon />
                  </Box>
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">
                      Quick and easy setup
                    </Text>
                    <Text>
                      Submit a request and our team will reach <br /> out to
                      schedule an onboarding session.
                    </Text>
                  </Box>
                </HStack>
                <HStack spacing="24px" alignSelf="baseline">
                  <Box
                    borderRadius="full"
                    bg="gray.900"
                    w="56px"
                    h="56px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <QuickPayIcon width="32px" />
                  </Box>
                  <Box>
                    <Text fontSize="lg" fontWeight="bold">
                      Start using Quickpay
                    </Text>
                    <Text>Make faster payments with QuickPay.</Text>
                  </Box>
                </HStack>
              </VStack>
            </Flex>
          )}
        </Box>
        <VStack
          w={{ md: "50%" }}
          justifyContent="center"
          padding="20px"
          alignItems={{ base: "start", md: "center" }}
        >
          <Box w="340px">
            <Outlet context={{ username, setUsername }} />
          </Box>
          <HStack
            position={{ md: "absolute" }}
            paddingTop={{ base: "20px", md: 0 }}
            bottom="20px"
            fontSize="12px"
            spacing="12px"
          >
            <Box>&copy; {new Date().getFullYear()} MVMNT</Box>{" "}
            <Box as="span" color="gray.200">
              &#x25cf;
            </Box>
            <Link>Privacy & terms</Link>{" "}
            <Box as="span" color="gray.200">
              &#x25cf;
            </Box>
            <Link>Contact us</Link>
          </HStack>
        </VStack>
      </Stack>
    </>
  );
}
