import { EmailIcon, PhoneIcon, QuestionIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Stack,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Spacer,
  VStack,
  Tooltip,
  Button,
  Divider,
  Box,
  HStack,
} from "@chakra-ui/react";
import { Header } from "../components/Header";
import { OptionalIndicator } from "../../../ui/components/OptionalIndicator";

export function Add(): JSX.Element {
  return (
    <>
      <Header>
        <HStack pb="20px">
          <Heading>Add account</Heading>
          <Spacer />
          <Button layerStyle="black">Cancel</Button>
          <Button layerStyle="yellow">Create account</Button>
        </HStack>
      </Header>
      <Box maxW="1130px" padding="40px 60px">
        <Stack direction={{ base: "column", md: "row" }} spacing="20px">
          <VStack spacing="20px" align="content-start" minWidth="315px">
            <Heading size="md">Company information</Heading>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>Account type</FormLabel>
              <Select placeholder="Select">
                <option value="option2">Broker</option>
              </Select>
            </FormControl>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>Company name</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>
                MC Number
                <Tooltip label="?">
                  <QuestionIcon w="18px" h="18px" marginLeft="5px" />
                </Tooltip>
              </FormLabel>
              <Input placeholder="6 digits" htmlSize={12} w="auto" />
            </FormControl>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>
                Salesforce ID{" "}
                <Tooltip label="?">
                  <QuestionIcon w="18px" h="18px" marginLeft="5px" />
                </Tooltip>
              </FormLabel>
              <Input htmlSize={12} w="auto" placeholder="-" />
            </FormControl>
            <FormControl>
              <FormLabel optionalIndicator={<OptionalIndicator />}>
                Company address
              </FormLabel>
              <Stack>
                <Input />
                <Input placeholder="Address 2" />
              </Stack>
            </FormControl>
            <FormControl>
              <FormLabel optionalIndicator={<OptionalIndicator />}>
                Phone
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="gray.400" />
                </InputLeftElement>
                <Input
                  htmlSize={10}
                  w="auto"
                  placeholder="(___) - ___ - ____"
                />
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel
                optionalIndicator={
                  <>
                    <OptionalIndicator />
                    <Tooltip label="?">
                      <QuestionIcon w="18px" h="18px" marginLeft="5px" />
                    </Tooltip>
                  </>
                }
              >
                Tax Identification Number
              </FormLabel>
              <Input htmlSize={12} w="auto" placeholder="___-__-____" />
            </FormControl>
          </VStack>

          <Spacer />

          <VStack spacing="20px" align="content-start" minWidth="315px">
            <Heading size="md">Admin user</Heading>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>First name</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>Last name</FormLabel>
              <Input />
            </FormControl>
            <FormControl isRequired>
              <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <EmailIcon color="gray.400" />
                </InputLeftElement>
                <Input type="email" />
              </InputGroup>
            </FormControl>
          </VStack>
        </Stack>
        <Divider margin="36px 0" />
        <HStack>
          <Button layerStyle="gray">Cancel</Button>
          <Button layerStyle="yellow">Create account</Button>
        </HStack>
      </Box>
    </>
  );
}
