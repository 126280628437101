import { useState } from "react";
import { ICarrierPayeeAccount } from "../../api-platform/carrierPayeeAccounts";
import AddExternalLoadPage from "../BrokerCarrierAccounts/AddExternalLoadPage/AddExternalLoadPage";
import { CarrierAccountsPageContext } from "../BrokerCarrierAccounts/CarrierAccountsPageContext";

export const DraftUpdateFormWrapper = (): JSX.Element => {
  const [carrierSelectedForDefaultLoad, setCarrierSelectedForDefaultLoad] =
    useState<ICarrierPayeeAccount | undefined>();

  return (
    <CarrierAccountsPageContext.Provider
      value={{
        carrierSelectedForDefaultLoad,
        setCarrierSelectedForDefaultLoad,
      }}
    >
      <AddExternalLoadPage />
    </CarrierAccountsPageContext.Provider>
  );
};
