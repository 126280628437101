import { EmailIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Divider,
  Button,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validateEmail } from "../../reuse/UserData";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { useResetPasswordMutation, UserResetPassword } from "../../api/auth";
import { useUsername } from "./components/Layout";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { PasswordInput } from "./components/PasswordInput";
import routes from "../../routes";
import { useNavigate } from "react-router-dom";

export function ResetPassword(): JSX.Element {
  const notificationBanner = useNotificationBanner();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const { username } = useUsername();
  const form = useForm<UserResetPassword>({
    defaultValues: {
      username,
    },
  });
  const navigate = useNavigate();

  async function onSubmit(data: UserResetPassword): Promise<void> {
    await resetPassword(data)
      .unwrap()
      .then(() => {
        notificationBanner({
          status: "success",
          description: "Password reset successfully.",
        });
        navigate(routes.LOGIN);
      })
      .catch((e) => {
        notificationBanner({
          status: "error",
          description: e.message,
        });
      });
  }

  return (
    <FormProvider {...form}>
      <VStack
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Heading fontSize="24px">Reset password</Heading>
        <Text>
          Enter the code you received in your email and choose a new password.
        </Text>
        {!username && (
          <FormControl isRequired isInvalid={!!form.formState.errors?.username}>
            <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="gray.400" />
              </InputLeftElement>
              <Input
                {...form.register("username", {
                  validate: validateEmail,
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="username" />
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl isRequired isInvalid={!!form.formState.errors?.code}>
          <FormLabel requiredIndicator={<></>}>Code</FormLabel>
          <Input
            type="number"
            {...form.register("code", {
              required: DEFAULT_MESSAGE_REQUIRED,
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="code" />
          </FormErrorMessage>
        </FormControl>
        <PasswordInput />
        <Divider />
        <Button
          isLoading={isLoading}
          type="submit"
          size="lg"
          layerStyle="yellow"
        >
          Reset password
        </Button>
      </VStack>
    </FormProvider>
  );
}
