import { ReactNode } from "react";
import { To, NavLink } from "react-router-dom";
import mvmntTheme from "../../../styles/theme";

export function StyledNavLink(props: {
  to: To;
  children: ReactNode;
  end?: boolean;
  disabled?: boolean;
}): JSX.Element {
  return (
    <NavLink
      end={props.end}
      to={props.to}
      style={({ isActive }) => ({
        borderBottom: "2px solid",
        paddingBottom: "5px",
        borderColor: isActive ? mvmntTheme.colors.mvmntBlue : "transparent",
        fontFamily: isActive ? "Montserrat Bold" : "Montserrat Regular",
      })}
    >
      {props.children}
    </NavLink>
  );
}
