import SharedButton from "../../components/SharedButton";
import { DownloadIcon } from "@chakra-ui/icons";

interface DownloadOptionProps {
  downloadTable: () => void;
}

export const DownloadOption = ({
  downloadTable,
}: DownloadOptionProps): JSX.Element => {
  return (
    <SharedButton
      title="Download"
      leftIcon={<DownloadIcon />}
      layerStyle="yellow"
      onClick={downloadTable}
    />
  );
};
