import { VStack, Flex, HStack } from "@chakra-ui/layout";
import Header from "../../components/Header";
import TabsContainer from "../../components/TabsContainer";
import { useEffect, useState, useRef } from "react";
import AppRoutes from "../../routes";
import { Button, useDisclosure, Link } from "@chakra-ui/react";
import { useLazyGetExternalLoadTemplateQuery } from "../../api/documents";
import { FileUploadButton } from "../../ui/components/FileUploadButton";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { ExternalLoadQPStatus } from "../../types/ExternalLoad";
import CSVErrorsModal from "../../components/CSVErrorsModal/CSVErrorsModal";
import ExternalLoadTable from "./ExternalLoadTable";
import {
  useGetPaymentsHistoryQuery,
  useGetQuickPayCountsByStatusQuery,
} from "../../api-platform/quickpay";
import { PaymentsDrawer } from "./PaymentsDrawer";
import { QPBalances } from "./QPBalances";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BrokerQPPageContext } from "./BrokerQuickpayPageContext";
import { CsvError } from "../../api-platform/externalLoadsDraft";
import { useUploadExternalTemplateLoadsNotGeneratedMutation } from "../../api/externalLoadDraft";

export const BrokerQuickpayPage = (): JSX.Element => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { data } = useGetPaymentsHistoryQuery();

  const notificationBanner = useNotificationBanner();
  const [tabs, setTabs] = useState<string[]>([]);
  const [, { data: templateHref }] = useLazyGetExternalLoadTemplateQuery();
  const [uploadExtLoadCSV, { isLoading }] =
    useUploadExternalTemplateLoadsNotGeneratedMutation();
  const [csvToUpload, setCsvToUpload] = useState<File | null>(null);
  const [csvErrors, setCsvErrors] = useState<CsvError[]>([]);
  const { data: loadCounts } = useGetQuickPayCountsByStatusQuery();
  const downloadTemplateRef = useRef<HTMLAnchorElement>(null);
  const [showBalances, setShowBalances] = useState(false);
  const [onClickReference, setOnClickReference] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>();

  const getNumberForStatus = (status: string | null): number => {
    switch (status) {
      case ExternalLoadQPStatus.Draft: {
        return 0;
      }
      case ExternalLoadQPStatus.Pending: {
        return 1;
      }
      case ExternalLoadQPStatus.Approved: {
        return 2;
      }
      case ExternalLoadQPStatus.Rejected: {
        return 3;
      }
      default: {
        searchParams.set("status", ExternalLoadQPStatus.Draft);
        setSearchParams(searchParams, { replace: true });
        return 1;
      }
    }
  };
  useEffect(() => {
    if (loadCounts) {
      setTabs([
        `Draft (${loadCounts.DRAFT})`,
        `Processing (${loadCounts.PENDING})`,
        `Approved (${loadCounts.APPROVED})`,
        `Rejected (${loadCounts.REJECTED})`,
      ]);
    }
  }, [loadCounts]);

  useEffect(() => {
    void uploadDocument(csvToUpload);
  }, [csvToUpload]);

  useEffect(() => {
    if (templateHref) {
      downloadTemplateRef.current?.click();
    }
  }, [templateHref]);

  const uploadDocument = async (file: File | null): Promise<void> => {
    if (!file) return;
    const response = await uploadExtLoadCSV(file);
    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem uploading your file. Please try again.</p>
        ),
      });
    } else if (response.data.externalLoads.length) {
      notificationBanner({
        status: "success",
        description: <p>Load upload success</p>,
      });
    } else if (response.data.errors.length) {
      setCsvErrors(response.data.errors);
    }
    setCsvToUpload(null);
  };

  const onClickTab = (tabName: string): void => {
    if (tabName.toUpperCase().includes(ExternalLoadQPStatus.Approved))
      setShowBalances(true);
    else setShowBalances(false);
  };

  const renderBalancesCTAs = (): JSX.Element => (
    <Flex>
      <HStack spacing={4}>
        <QPBalances data={data} />
        <Button
          ref={btnRef as React.LegacyRef<HTMLButtonElement>}
          layerStyle="yellow"
          onClick={onOpen}
        >
          View Payments
        </Button>
      </HStack>
    </Flex>
  );

  const renderExtLoadsCTAs = (): JSX.Element => (
    <Flex>
      <Link hidden ref={downloadTemplateRef} href={templateHref} />
      <FileUploadButton
        layerStyle="black"
        onClickReference={onClickReference}
        setOnClickReference={setOnClickReference}
        accept={".csv"}
        disabled={isLoading}
        onChange={setCsvToUpload}
        value={csvToUpload}
      >
        Bulk upload
      </FileUploadButton>
      <Button
        layerStyle="yellow"
        ml="1rem"
        onClick={() => navigate(AppRoutes.DRAFT)}
      >
        Send payment
      </Button>
    </Flex>
  );

  return (
    <BrokerQPPageContext.Provider
      value={{
        searchParams,
        setSearchParams,
      }}
    >
      <VStack backgroundColor="grey6" w="100%">
        <Flex
          w="100%"
          padding="2rem 1.2rem 0rem"
          justifyContent="space-between"
        >
          <Header headingText="Payments" />
          {!!csvErrors.length && (
            <CSVErrorsModal
              errors={csvErrors}
              onClose={() => setCsvErrors([])}
              isOpen={true}
              templateHref={templateHref}
            />
          )}
          {showBalances && process.env.REACT_APP_SHOW_QP === "true"
            ? renderBalancesCTAs()
            : renderExtLoadsCTAs()}
        </Flex>
        <TabsContainer
          defaultIndex={getNumberForStatus(searchParams.get("status"))}
          onClickTab={onClickTab}
          padding={0}
          tabListProps={{ backgroundColor: "grey6" }}
          tabProps={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginLeft: "20px",
            _selected: {
              color: "gray90",
              fontWeight: "bold",
              borderBottom: "2px solid var(--chakra-colors-mvmntBlue)",
            },
          }}
          tabPanelProps={{ backgroundColor: "white" }}
          tabList={tabs}
          tabPanels={[
            <ExternalLoadTable
              onClickReference={() => setOnClickReference(true)}
              key={`Draft Broker QP Loads`}
            />,
            <ExternalLoadTable key={`Pending Broker QP Loads`} />,
            <ExternalLoadTable key={`Appoved Broker QP Loads`} />,
            <ExternalLoadTable key={`Rejected Broker QP Loads`} />,
          ]}
          onTabChange={(index) => {
            switch (index) {
              case 0:
                searchParams.set("status", ExternalLoadQPStatus.Draft);
                break;
              case 1:
                searchParams.set("status", ExternalLoadQPStatus.Pending);
                break;
              case 2:
                searchParams.set("status", ExternalLoadQPStatus.Approved);
                break;
              case 3:
                searchParams.set("status", ExternalLoadQPStatus.Rejected);
                break;
              default:
                searchParams.set("status", ExternalLoadQPStatus.Draft);
                break;
            }
            setSearchParams(searchParams, { replace: true });
          }}
        />
        <PaymentsDrawer
          btnRef={btnRef}
          isOpen={isOpen}
          onClose={onClose}
          data={data}
        />
      </VStack>
    </BrokerQPPageContext.Provider>
  );
};

export default BrokerQuickpayPage;
