import { createContext, useContext } from "react";

interface CarrierTable {
  sortOption: "createDate";
  setSortOption: React.Dispatch<React.SetStateAction<"createDate">>;
  sortDirection: "asc" | "desc";
  setSortDirection: React.Dispatch<React.SetStateAction<"asc" | "desc">>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const CarrierTableContext = createContext<CarrierTable>({
  sortOption: "createDate",
  setSortOption: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  sortDirection: "desc",
  setSortDirection: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  searchTerm: "",
  setSearchTerm: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

const useCarrierTableContext = (): CarrierTable =>
  useContext(CarrierTableContext);

export { CarrierTableContext, useCarrierTableContext };
