import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { DefaultModalProps } from "../../types/DefaultModal";
import SharedButton from "../SharedButton";

interface AppearanceBtnProps {
  textColor: string;
  border: string;
}

interface ReusableConfirmModalProps extends DefaultModalProps {
  title: string;
  content: string;
  onClickCTA: () => void;
  cancelText: string;
  confirmText: string;
  loading?: boolean;
  appearanceConfirmBtn: AppearanceBtnProps;
}

export const ReusableConfirmModal = ({
  isOpen,
  onClose,
  onClickCTA,
  title,
  content,
  cancelText,
  confirmText,
  loading,
  appearanceConfirmBtn,
}: ReusableConfirmModalProps): JSX.Element => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Divider color={"#C0C0C0"} />
        <ModalBody py="2rem">
          <Text>{content}</Text>
        </ModalBody>
        <Divider color={"#C0C0C0"} />
        <ModalFooter>
          <HStack py="0.5rem">
            <SharedButton
              title={cancelText}
              textTransform="none"
              textColor="#C0C0C0"
              border="1px solid gray"
              fontSize="15px"
              px="1.5rem"
              h="35px"
              isDisabled={loading}
              onClick={onClose}
            />
            <SharedButton
              title={confirmText}
              textTransform="none"
              textColor={appearanceConfirmBtn.textColor}
              border={appearanceConfirmBtn.border}
              fontSize="15px"
              px="1.5rem"
              h="35px"
              isDisabled={loading}
              onClick={onClickCTA}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReusableConfirmModal;
