import { useRef } from "react";
import { useToast, Box } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import mvmntTheme from "../../styles/theme";
import type { UseToastOptions, ToastId, AlertStatus } from "@chakra-ui/react";

type UseToastReturn = ReturnType<typeof useToast>;
type Colors = keyof typeof mvmntTheme.colors;

export const bgColors: { [key in AlertStatus]: Colors } = {
  success: "mvmntGreen",
  info: "mvmntBlue",
  error: "mvmntRed",
  warning: "yellow",
};

export const useNotificationBanner = (): UseToastReturn => {
  const toast = useToast();
  const toastIdRef = useRef<ToastId>();

  function createNotificationBanner(
    options: UseToastOptions = {},
  ): ToastId | undefined {
    options.position = options.position ?? "top";

    if (options.status === "error") {
      if (!options.duration) {
        options.duration = null;
      }
      options.isClosable = true;
    }

    if (options.status === "success") {
      if (!options.duration) {
        options.duration = 5000;
      }
      options.isClosable = true;
    }

    options.containerStyle = {
      width: "100%",
      maxWidth: "none",
      margin: 0,
      textAlign: "center",
    };
    options.render = (props) => {
      return (
        <Box color="white" p={3} bg={bgColors[options.status ?? "success"]}>
          {options.title && options.title}
          {options.description}
          <CloseIcon
            onClick={props.onClose}
            position="absolute"
            right="20px"
            top={3}
            marginTop="3px"
            cursor="pointer"
          />
        </Box>
      );
    };

    if (toastIdRef.current) toast.close(toastIdRef.current);
    toastIdRef.current = toast(options);

    return toastIdRef.current;
  }
  return Object.assign(createNotificationBanner, toast);
};
