export enum UserType {
  BROKER = "BROKER",
  ADMIN = "ADMIN",
}

export interface UserName {
  firstName: string;
  lastName: string;
}

export interface User extends UserName {
  id: string;
  type: UserType;
  email: string;
  authToken: string;
}
