import { SkeletonText } from "@chakra-ui/react";
import {
  useGetBrokerAccountSummaryQuery,
  useGetBrokerQuery,
  useGetBrokerCreditInfoQuery,
} from "../../../../../api-platform/internal";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useParams } from "react-router-dom";
import { UpdateAccount } from "./UpdateAccount";
import { CreateAccount } from "./CreateAccount";
import { useGetBrokerAccountRequestLeadQuery } from "../../../../../api-platform/brokerAccountRequestLeads";

export function AccountInfo(): JSX.Element {
  const { brokerId } = useParams();
  const { data: brokerAccountSummary, isLoading: isLoadingBrokerSummary } =
    useGetBrokerAccountSummaryQuery(brokerId ? { id: brokerId } : skipToken);

  if (isLoadingBrokerSummary)
    return (
      <SkeletonText maxW="400px" mt="8px" noOfLines={20} spacing="4" mb="8px" />
    );

  if (brokerAccountSummary?.type === "BROKER") {
    return <UpdateAccountWrapper brokerId={brokerId} />;
  }
  return <CreateAccountWrapper brokerId={brokerId} />;
}

function CreateAccountWrapper({
  brokerId,
}: {
  brokerId?: string;
}): JSX.Element {
  const { data, isLoading } = useGetBrokerAccountRequestLeadQuery(
    brokerId ? { id: brokerId } : skipToken,
  );
  if (isLoading || !data) {
    return (
      <SkeletonText maxW="400px" mt="8px" noOfLines={20} spacing="4" mb="8px" />
    );
  }
  return <CreateAccount brokerAccountRequest={data} />;
}

function UpdateAccountWrapper({
  brokerId,
}: {
  brokerId?: string;
}): JSX.Element {
  const { data: broker, isLoading: isLoadingBroker } = useGetBrokerQuery(
    brokerId ? { id: brokerId } : skipToken,
  );

  const { data: financials, isLoading: isLoadingFinancials } =
    useGetBrokerCreditInfoQuery(brokerId ? { id: brokerId } : skipToken);

  if (isLoadingBroker || !broker || isLoadingFinancials) {
    return (
      <SkeletonText maxW="400px" mt="8px" noOfLines={20} spacing="4" mb="8px" />
    );
  }
  return <UpdateAccount broker={broker} financials={financials} />;
}
