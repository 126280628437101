import { Box, Tag, Text, VStack } from "@chakra-ui/react";
import { useGetExtLoadDocumentsQuery } from "../../api-platform/documents";
import { IExternalLoad } from "../../api-platform/externalLoads";
import { IExtLoadDraft } from "../../api-platform/externalLoadsDraft";
import { parseNumberToZeroDecimalCurrency } from "../../reuse/Money";

interface QPUploadListLoadProps {
  externalLoad: IExternalLoad | IExtLoadDraft;
  selectedLoad: IExternalLoad | IExtLoadDraft;
  setSelectedLoad: (load: IExternalLoad | IExtLoadDraft) => void;
}

export const QPUploadListLoad = ({
  externalLoad,
  selectedLoad,
  setSelectedLoad,
}: QPUploadListLoadProps): JSX.Element => {
  const { data: extLoadDocuments, isLoading } = useGetExtLoadDocumentsQuery({
    externalLoadId: externalLoad.id,
  });

  return (
    <Box
      as="button"
      borderRadius="4px"
      padding="12px"
      width="100%"
      textAlign="left"
      bg={externalLoad.id === selectedLoad.id ? "blue.100" : ""}
      borderColor={externalLoad.id === selectedLoad.id ? "blue.500" : ""}
      borderWidth={externalLoad.id === selectedLoad.id ? "1px" : ""}
      onClick={() => setSelectedLoad(externalLoad)}
    >
      <VStack alignItems="left" spacing="0">
        <Text fontSize="11px" color="gray.500">
          {externalLoad.originCity} {" > "}
          {externalLoad.destinationCity}
        </Text>
        <Text fontSize="16px" color="gray.700">
          {externalLoad.customerName}
        </Text>
        <Text fontSize="16px" color="gray.700" as="b">
          {parseNumberToZeroDecimalCurrency(
            externalLoad.carrierInvoiceAmount
              ? +externalLoad.carrierInvoiceAmount
              : +0,
          )}
          {" | "}
          {parseNumberToZeroDecimalCurrency(
            externalLoad.amountToPayCarrier
              ? +externalLoad.amountToPayCarrier
              : +0,
          )}
        </Text>
      </VStack>
      {!isLoading && (
        <Tag
          size="sm"
          borderRadius="full"
          variant="outline"
          colorScheme="red"
          hidden={
            extLoadDocuments?.some((doc) => doc.documentType === "Invoice") &&
            extLoadDocuments?.some((doc) => doc.documentType === "RateCon")
          }
        >
          Missing Documents
        </Tag>
      )}
    </Box>
  );
};
