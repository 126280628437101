import {
  VStack,
  IconButton,
  Image,
  Button,
  ButtonProps,
  Tooltip,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { useLocation, useNavigate, Location } from "react-router-dom";
import AppRoutes from "../../routes";
import InvoicesLogo from "../../resources/svg/new-invoices.svg";
import ManageUsers from "../../resources/svg/new-users.svg";
import MvmntSmallLogo from "../../resources/svg/new-logo-small.svg";
import MvmntLogo from "../../resources/svg/new-logo.svg";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { UserType } from "../../types/User";
import Trailer from "../../resources/svg/trailer.svg";

interface SidebarButtonProps extends ButtonProps {
  isCollapsed: boolean;
  isActiveLink?: (location: Location) => boolean | undefined;
  icon: JSX.Element;
  title: string;
  route?: string[];
  options?: Array<{ route: string; title: string }>;
}

const CarriersIcon = (): JSX.Element => {
  return <Image src={Trailer} />;
};

const ManageUsersIcon = (): JSX.Element => {
  return <Image src={ManageUsers} />;
};

const InvoicesIcon = (): JSX.Element => {
  return <Image src={InvoicesLogo} />;
};

const MvmntSmallIcon = (): JSX.Element => {
  return <Image src={MvmntSmallLogo} />;
};

const MvmntIcon = (): JSX.Element => {
  return <Image src={MvmntLogo} />;
};

const ExpandButton = ({
  title,
  icon,
  isCollapsed,
  route,
  ...props
}: SidebarButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const onClick = route ? () => navigate(route[0]) : undefined;

  return (
    <IconButton
      icon={icon}
      aria-label={title}
      bgColor="grey2"
      onClick={onClick}
      isRound={true}
      size="xs"
      position="absolute"
      left={isCollapsed ? "48px" : "188px"}
      top=".25vh"
      transition={isCollapsed ? "none" : "left .5s"}
      {...props}
    />
  );
};

const SidebarButton = ({
  title,
  icon,
  isCollapsed,
  isActiveLink,
  route,
  ...props
}: SidebarButtonProps): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const onClick = route ? () => navigate(route[0]) : undefined;
  const isActive =
    isActiveLink?.(location) ?? route?.includes(location.pathname);
  const bgColor = isActive ? "grey2" : "transparent";
  const borderLeft = bgColor === "grey2" ? "3px solid yellow" : "transparent";
  const color = bgColor === "grey2" ? "white" : "grey5";

  return isCollapsed ? (
    <Tooltip
      hasArrow
      arrowSize={6}
      placement="right"
      label={title}
      bg={"grey1"}
      color="white"
      isDisabled={!isCollapsed}
    >
      <IconButton
        icon={icon}
        aria-label={title}
        bgColor={bgColor}
        w="100%"
        borderRadius="0px"
        textTransform="capitalize"
        borderLeft={borderLeft}
        onClick={onClick}
        {...props}
      />
    </Tooltip>
  ) : (
    <Button
      leftIcon={icon}
      color={color}
      w="100%"
      borderRadius="0px"
      textTransform="capitalize"
      justifyContent="start"
      bgColor={bgColor}
      borderLeft={borderLeft}
      onClick={onClick}
      {...props}
    >
      {title}
    </Button>
  );
};

export const ShellSideBar = (): JSX.Element => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const data = useAppSelector(selectUserData);
  const { type } = data.user;

  return (
    <VStack
      h="100%"
      flexShrink={0}
      flexBasis={isCollapsed ? "60px" : "200px"}
      bgColor="black"
      spacing="1vh"
      alignItems="center"
      overflowX="hidden"
      transition={isCollapsed ? "none" : "flex-basis .5s"}
    >
      <ExpandButton
        isCollapsed={isCollapsed}
        icon={
          isCollapsed ? (
            <ChevronRightIcon h={8} w={4} color="white" />
          ) : (
            <ChevronLeftIcon h={8} w={4} color="white" />
          )
        }
        title=""
        onClick={() => setIsCollapsed(!isCollapsed)}
      />

      <SidebarButton
        isCollapsed={isCollapsed}
        icon={isCollapsed ? <MvmntSmallIcon /> : <MvmntIcon />}
        title=""
        mt="1vh"
        bgColor="transparent"
      />

      {type === UserType.ADMIN && (
        <>
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<ManageUsersIcon />}
            title="Manage Users"
            route={[AppRoutes.INTERNAL_ACCOUNTS]}
          />
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<InvoicesIcon />}
            title="QuickPay Requests"
            route={[AppRoutes.QUICKPAY]}
          />
        </>
      )}

      {type !== UserType.ADMIN && (
        <>
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<InvoicesIcon />}
            title="QuickPay"
            route={[`${AppRoutes.QUICKPAY}?status=DRAFT`]}
          />
          <SidebarButton
            isCollapsed={isCollapsed}
            icon={<CarriersIcon />}
            title="Carriers"
            route={[AppRoutes.CARRIER_ACCOUNTS]}
          />
        </>
      )}
    </VStack>
  );
};

export default ShellSideBar;
