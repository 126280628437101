import { useMemo, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import type { Table, ColumnDef } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import "../../../styles/multiselect.scss";

interface IDataTableMultiselectFilter<T, C extends keyof T> {
  table: Table<T>;
  columnId: C;
  selectSomeItemsLabel?: string;
  labelFormatter?: (label: T[C] | string) => string;
  syncSearchParams?: boolean;
}

export function DataTableMultiselectFilter<T, C extends keyof T>({
  table,
  columnId,
  selectSomeItemsLabel: label,
  labelFormatter,
  syncSearchParams = false,
}: IDataTableMultiselectFilter<T, C>): JSX.Element {
  const column = table.getColumn(String(columnId));
  const sortedUniqueValues = useMemo(() => {
    const facetedUniqueValues = table
      .getColumn(String(columnId))
      .getFacetedUniqueValues();
    const keys = Array.from(facetedUniqueValues.keys());
    // A column could have multiple values, so we need to flatten and
    return [...new Set(keys.flat())]
      .sort((a, b) => {
        return `${a}`.localeCompare(`${b}`, undefined, { numeric: true });
      })
      .map((value) => ({
        value: `${value}`,
        label: labelFormatter ? labelFormatter(value) : value,
      }));
  }, [column.getFacetedUniqueValues()]);
  // we'll use either localParams or searchParams depending on syncSearchParams
  const [localParams, setLocalParams] = useState<typeof sortedUniqueValues>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  function setSelected(options: typeof sortedUniqueValues): void {
    if (!syncSearchParams) {
      setLocalParams(options);
      return;
    }
    /**
     * It's easiest to reconstruct the entire parameter on each change because
     * searchParams.append("id", 1) && searchParams.append("id", 2) results in ?id=1&id=2,
     * but there's no method on URLSearchParams to remove a single value of a parameter.
     */
    searchParams.delete(column.id);
    setSearchParams(searchParams, { replace: true });

    options.forEach((option) => {
      searchParams.append(column.id, option.value);
      setSearchParams(searchParams, { replace: true });
    });
  }

  function parseSelected(): typeof sortedUniqueValues {
    if (!syncSearchParams) return localParams;
    return searchParams.getAll(column.id).map((value) => ({
      label: labelFormatter ? labelFormatter(value) : value,
      value: value,
    }));
  }

  function getHeader(columnDef: ColumnDef<T>): string {
    return typeof columnDef.header === "string" ? columnDef.header : column.id;
  }

  return (
    <>
      <MultiSelect
        value={parseSelected()}
        labelledBy="Select"
        onChange={(options: typeof sortedUniqueValues) => {
          setSelected(options);
        }}
        options={sortedUniqueValues}
        overrideStrings={{
          selectSomeItems: label ?? getHeader(column.columnDef),
        }}
      />
    </>
  );
}
