import { AccountsReceivable } from "./BrokerAccountsReceivable";
import { ContactInformation } from "./BrokerContactInformation";
import { Employee } from "./BrokerEmployees";
import { Insurance } from "./BrokerInsurance";
import { UserType } from "./User";

export enum BrokerType {
  Broker = "BROKER",
}

export type Broker = Exclude<UserType, UserType.ADMIN>;

export interface BrokerAccountRequestData {
  // ContactInformation
  companyName: string;
  adminFirst: string;
  adminLast: string;
  adminEmail: string;
  companyEmail: string;
  companyWebsite: string;
  companyPhone: string;
  dunsCode: string;
  naicsCode: string;
  // Insurance
  insurance: {
    autoLiability: string;
    generalLiability: string;
    cargoInsurance: string;
  };
  // AccountsReceivable + Address
  accountsReceivable: AccountsReceivable;
  userType: Broker;
}
export interface BrokerUserData {
  contactInformation: ContactInformation;
  employees: Employee[];
  accountsReceivable: AccountsReceivable;
  insuranceRequirements: Insurance;
}

export interface BrokerUser extends BrokerUserData {
  id: string;
  brokerCode: string;
  status: BrokerStatus;
  userType: BrokerType;
  createDate?: Date;
}

export enum BrokerStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  REVOKED = "REVOKED",
}

export enum BrokerStatusLabel {
  PENDING = "Pending",
  APPROVED = "Active",
  REJECTED = "Rejected",
  REVOKED = "Inactive",
}
