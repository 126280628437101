import { ArrowDownIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from "@chakra-ui/react";
import { useLogoutUserMutation } from "../../api/auth";
import AppRoutes from "../../routes";

export const TopBarDropdown = (): JSX.Element => {
  const [signOut] = useLogoutUserMutation();

  const onClickLogout = async (): Promise<void> => {
    await signOut();
    window.location.href = AppRoutes.LOGIN;
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Account Options"
        icon={<ArrowDownIcon />}
        size="sm"
      />
      <MenuList>
        <MenuItem icon={<ArrowForwardIcon />} onClick={onClickLogout}>
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default TopBarDropdown;
