import { mvmntApiPlatform } from "./generated";
export * from "./generated";

mvmntApiPlatform.enhanceEndpoints({
  addTagTypes: [
    "Broker",
    "BrokerCreditInfo",
    "BrokerBillingContact",
    "PastBrokerages",
  ],
  endpoints: {
    getBroker: {
      providesTags: ["Broker"],
    },
    getBrokerAccountSummary: {
      providesTags: ["BrokerAccountSummary"],
    },
    getBrokerAccounts: {
      providesTags: ["BrokerAccounts"],
    },
    updateBroker: {
      invalidatesTags: [
        "BrokerAccountSummary",
        "Broker",
        "BrokerAccounts",
        "BrokerCreditInfo",
      ],
    },
    updateBrokerAdmin: {
      invalidatesTags: ["BrokerAccountSummary", "Broker", "BrokerAccounts"],
    },
    rejectBrokerAccountRequest: {
      invalidatesTags: ["BrokerAccountSummary", "BrokerAccounts"],
    },
    getBrokerCreditInfo: {
      providesTags: ["BrokerCreditInfo"],
    },
    upsertBrokerCreditInfo: {
      invalidatesTags: (response) => {
        // Do not invalidate if endpoint fails
        if (response) {
          return ["BrokerCreditInfo"];
        }
        return [];
      },
    },
    getBrokerBillingContact: {
      providesTags: ["BrokerBillingContact"],
    },
    upsertBrokerBillingContact: {
      invalidatesTags: ["BrokerBillingContact"],
    },
    sendForCreditCheck: {
      invalidatesTags: ["BrokerAccountSummary"],
    },
    updateBrokerQuickPayEnable: {
      invalidatesTags: ["Broker"],
    },
    getPastBrokerages: {
      providesTags: ["PastBrokerages"],
    },
    addPastBrokerages: {
      invalidatesTags: ["PastBrokerages"],
    },
    updateBrokerPaymentControls: {
      invalidatesTags: ["Broker"],
    },
  },
});
