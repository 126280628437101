import { Skeleton, HStack, VStack } from "@chakra-ui/react";
import { useGetUserQuery } from "../../api/user";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import AuthenticatedPage from "../../components/AuthenticatedPageHOC";
import ShellSideBar from "../../components/ShellSideBar";
import ShellTopBar from "../../components/ShellTopBar";
import { UserType } from "../../types/User";
import BrokerShell from "../BrokerShell";
import AdminShell from "../AdminShell";

export const TopBarHeight = "42px";

const ShellContent = (): JSX.Element => {
  const { isFetching } = useGetUserQuery();
  const data = useAppSelector(selectUserData);
  const { id, type } = data.user;

  return (
    <Skeleton
      isLoaded={!isFetching}
      w="100%"
      h={`calc(100vh - ${TopBarHeight})`}
    >
      {id && type === UserType.BROKER ? (
        <BrokerShell />
      ) : id && type === UserType.ADMIN ? (
        <AdminShell />
      ) : null}
    </Skeleton>
  );
};

export const Shell = (): JSX.Element => {
  return (
    <HStack h="100vh" w="100vw" alignItems="start" spacing={0}>
      <ShellSideBar />
      <VStack w="100%" h="100vh" overflowY="auto" spacing={0}>
        <ShellTopBar height={TopBarHeight} />
        <ShellContent />
      </VStack>
    </HStack>
  );
};

export default AuthenticatedPage(Shell);
