import { QuestionIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  HStack,
  Divider,
  FormControl,
  FormLabel,
  Tooltip,
  InputGroup,
  Input,
  FormErrorMessage,
  Spinner,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { DefaultModalProps } from "../../types/DefaultModal";
import SharedButton from "../../components/SharedButton";

interface IAddExternalAccount {
  accountNumber: string;
  confirmAccountNumber?: string;
  achRoutingNumber: string;
}

interface AddPaymentModalProps extends DefaultModalProps {
  title: string;
  cancelText: string;
  confirmText: string;
  layerConfirmButton: string;
  loading?: boolean;
  onClickCTA: (accountNumber: string, achRoutingNumber: string) => void;
}

export const AddPaymentModal = ({
  isOpen,
  onClose,
  title,
  cancelText,
  confirmText,
  layerConfirmButton,
  loading,
  onClickCTA,
}: AddPaymentModalProps): JSX.Element => {
  const {
    register,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = useForm<IAddExternalAccount>();

  const onCloseModal = (): void => {
    reset();
    onClose();
  };

  const { accountNumber, confirmAccountNumber, achRoutingNumber } = getValues();

  return (
    <Modal isOpen={isOpen} onClose={onCloseModal} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Divider color="#C0C0C0" />
        <ModalBody>
          <HStack w="100%" alignItems="flex-start">
            <FormControl
              isRequired
              isInvalid={!!errors?.accountNumber}
              w="50%"
              mb="2rem"
            >
              <FormLabel requiredIndicator={<></>}>Account Number</FormLabel>
              <InputGroup>
                <Input
                  w="100%"
                  value={watch("accountNumber") ?? ""}
                  {...register("accountNumber", {
                    pattern: /^\d{9}$/,
                    required: {
                      value: true,
                      message: DEFAULT_MESSAGE_REQUIRED,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>
                <ErrorMessage errors={errors} name="accountNumber" />
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!errors?.confirmAccountNumber}
              w="50%"
              mb="2rem"
            >
              <FormLabel requiredIndicator={<></>}>
                Confirm Account Number
              </FormLabel>
              <InputGroup>
                <Input
                  w="100%"
                  value={watch("confirmAccountNumber") ?? ""}
                  {...register("confirmAccountNumber", {
                    pattern: /^\d{9}$/,
                    required: {
                      value: false,
                      message: DEFAULT_MESSAGE_REQUIRED,
                    },
                  })}
                />
              </InputGroup>
              <FormErrorMessage>
                <ErrorMessage errors={errors} name="confirmAccountNumber" />
              </FormErrorMessage>
            </FormControl>
          </HStack>
          <FormControl
            w="100%"
            isRequired
            isInvalid={!!errors?.achRoutingNumber}
          >
            <FormLabel requiredIndicator={<></>}>
              ACH Routing Number
              <Tooltip label="9 digit number that identifies the bank or credit union holding the account. This is the first set of numbers listed on the bottom of a check">
                <QuestionIcon w="15px" h="15px" margin="0px 5px" />
              </Tooltip>
            </FormLabel>
            <InputGroup>
              <Input
                w="50%"
                value={watch("achRoutingNumber") ?? ""}
                {...register("achRoutingNumber", {
                  pattern: /^\d{9}$/,
                  required: {
                    value: true,
                    message: DEFAULT_MESSAGE_REQUIRED,
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage errors={errors} name="achRoutingNumber" />
            </FormErrorMessage>
          </FormControl>
        </ModalBody>
        <Divider color="#C0C0C0" />
        <ModalFooter>
          <HStack
            w="100%"
            py="0.5rem"
            alignItems="center"
            justifyContent={loading ? "center" : "flex-end"}
          >
            {loading ? (
              <Spinner size="lg" color="red.500" />
            ) : (
              <>
                <SharedButton
                  title={cancelText}
                  textTransform="none"
                  textColor="#C0C0C0"
                  border="1px solid gray"
                  fontSize="15px"
                  px="1.5rem"
                  h="35px"
                  isDisabled={loading}
                  onClick={onCloseModal}
                />
                <SharedButton
                  title={confirmText}
                  textTransform="none"
                  fontSize="15px"
                  px="1.5rem"
                  h="35px"
                  layerStyle={layerConfirmButton}
                  isDisabled={
                    !accountNumber ||
                    !confirmAccountNumber ||
                    !achRoutingNumber ||
                    accountNumber !== confirmAccountNumber ||
                    loading
                  }
                  onClick={() =>
                    accountNumber === confirmAccountNumber &&
                    onClickCTA(accountNumber, achRoutingNumber)
                  }
                />
              </>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
