import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  HStack,
  Image,
} from "@chakra-ui/react";
import { Option } from "../../pages/BrokerCarrierAccounts/types";
import { MenuIcon } from "../../ui/icons";

interface PopoverOptionsProps {
  options: Option[];
}

export const PopoverOptions = ({
  options,
}: PopoverOptionsProps): JSX.Element => (
  <Popover>
    <PopoverTrigger>
      <IconButton icon={<MenuIcon />} bgColor="white" aria-label={""} />
    </PopoverTrigger>
    <PopoverContent>
      <PopoverBody>
        {options.map(({ title, icon, disabled, onClick }) => (
          <HStack key={title} p="0.5rem 0">
            {icon && <Image src={icon} />}
            <Text
              onClick={onClick}
              my="0.5rem"
              pointerEvents={disabled ? "none" : undefined}
              cursor={disabled ? "default" : "pointer"}
              color={disabled ? "gray" : "#232323"}
              fontSize={13}
              fontWeight={600}
            >
              {title}
            </Text>
          </HStack>
        ))}
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

export default PopoverOptions;
