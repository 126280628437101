import { SearchIcon } from "@chakra-ui/icons";
import {
  HStack,
  Text,
  Select,
  InputLeftElement,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { useContext } from "react";
import { getLabelForQuickPaySortOption } from "../../reuse/Enum";
import { QuickPayTableContext } from "./QuickPayTableContext";
import { QuickPaySortOptions } from "../../api-platform/quickpay";
import { QuickPaySortOptionsEnum } from "../../types/QuickPayRequest";
import "../../styles/QuickPayRequestSubhead.scss";

interface QuickPayTableSubheadProps {
  totalDisplayedQuickPays: number | undefined;
}

export const QuickPayTableSubhead = ({
  totalDisplayedQuickPays,
}: QuickPayTableSubheadProps): JSX.Element => {
  const context = useContext(QuickPayTableContext);
  const { sortBy, setSortBy, searchTerm, setSearchTerm } = context;

  return (
    <HStack
      h="60px"
      backgroundColor="grey6"
      padding="1rem"
      justify="space-between"
      minW="740px"
      w="100%"
    >
      <div className="subHeadLeft">
        <div className="totalDisplayedQuickPaysContainer">
          <Text color="gray70" fontSize={13}>
            Showing {totalDisplayedQuickPays} QuickPay
            {totalDisplayedQuickPays !== 1 && "s"}
          </Text>
        </div>
        <div className="sortContainer">
          <Text color="gray70" fontSize={15} mr={4}>
            {" "}
            Sort By:{" "}
          </Text>
          <Select
            variant="unstyled"
            value={sortBy}
            onChange={(e) => {
              if (e.target.value) {
                setSortBy(e.target.value as QuickPaySortOptions);
              }
            }}
          >
            {Object.values(QuickPaySortOptionsEnum).map((option) => (
              <option key={`quickPay sort select:${option}`} value={option}>
                {getLabelForQuickPaySortOption(option)}
              </option>
            ))}
          </Select>
        </div>
      </div>

      <div className="searchContainer">
        <InputGroup w="100%" borderRadius="8px">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search by QuickPay ID, Brokerage or Carrier"
            fontSize={13}
            borderRadius="4px"
            borderColor="grey5"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target?.value);
            }}
          />
        </InputGroup>
      </div>
    </HStack>
  );
};

export default QuickPayTableSubhead;
