import { Skeleton } from "@chakra-ui/react";

export function DataTableSkeleton(): JSX.Element {
  return (
    <>
      {Array(10)
        .fill(true)
        .map((item, i) => (
          <Skeleton
            key={i}
            height="30px"
            isLoaded={false}
            marginTop="10px"
            marginBottom="10px"
          />
        ))}
    </>
  );
}
