import { createContext, useContext } from "react";
import { IExternalLoad } from "../../api-platform/externalLoads";
import { IExtLoadDraft } from "../../api-platform/externalLoadsDraft";

interface ExtLoadTable {
  quickpays: IExternalLoad[] | IExtLoadDraft[];
  selectedExtLoads: IExternalLoad[] | IExtLoadDraft[];
  setSelectedExtLoads: React.Dispatch<
    React.SetStateAction<IExternalLoad[] | IExtLoadDraft[]>
  >;
}

const ExtLoadTableContext = createContext<ExtLoadTable>({
  quickpays: [],
  selectedExtLoads: [],
  setSelectedExtLoads: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

const useExtLoadTableContext = (): ExtLoadTable =>
  useContext(ExtLoadTableContext);

export { ExtLoadTableContext, useExtLoadTableContext };
