import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  HStack,
  Divider,
  Select,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Spacer,
  Text,
  Box,
  Tag,
} from "@chakra-ui/react";
import {
  CellContext,
  ColumnDefTemplate,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";
import { ReactNode, useMemo, useState } from "react";
import { OrgAccount, OrgAccountStatus } from "../../../api/admin";
import { OrgInvite } from "../../../api/org";
import { getDateDiffInMinutes } from "../../../reuse/Dates";
import { pluralize, toTitleCase } from "../../../reuse/Strings";
import {
  useDataTable,
  DataTable,
  DataTableMultiselectFilter,
} from "../../../ui/components/DataTable";

const columnHelper = createColumnHelper<OrgAccount>();
enum COLUMN_IDS {
  lastLogin = "lastLogin",
  role = "role",
  status = "status",
}
type Cell = ColumnDefTemplate<CellContext<OrgAccount, string>>;

export function UsersTable(props: {
  orgAccounts: OrgAccount[];
  orgInvites: OrgInvite[];
  afterFilters?: ReactNode;
  menuCell: Cell;
}): JSX.Element {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([
    { id: "lastLogin", desc: false },
  ]);
  const table = useDataTable({
    data: props.orgAccounts,
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    columns: useMemo(
      () => [
        columnHelper.accessor("user.firstName", {
          header: "Name",
          cell: (cell) => {
            return (
              <>
                <Text>
                  {cell.row.original.user.firstName}{" "}
                  {cell.row.original.user.lastName}
                </Text>
                <Text fontSize="13px" color="gray.600">
                  {cell.row.original.user.email}
                </Text>
              </>
            );
          },
        }),
        columnHelper.accessor("lastLoginDate", {
          header: "Last login",
          id: COLUMN_IDS.lastLogin,
          sortingFn: "datetime",
          cell: (cell) => {
            const dateString = cell.getValue();
            if (!dateString) return "Never";
            const prettyDate = getDateDiffInMinutes(new Date(dateString));
            return prettyDate
              ? `${getDateDiffInMinutes(new Date(dateString))} ago`
              : "Just now";
          },
        }),
        columnHelper.accessor(
          (cell) => cell.orgAccountRoles.map(({ role }) => role.name),
          {
            header: "Role(s)",
            id: COLUMN_IDS.role,
            filterFn: "arrIncludesSome",
            cell: (cell) => {
              return cell.getValue<string[]>().map((role) => {
                return <div key={role}>{role}</div>;
              });
            },
          },
        ),
        columnHelper.accessor("status", {
          header: "Status",
          id: COLUMN_IDS.status,
          filterFn: "arrIncludesSome",
          cell: (cell) => {
            const status = cell.getValue<`${OrgAccountStatus}`>();

            return (
              <Tag
                size="md"
                color={status === "ACTIVE" ? "green.600" : "pink"}
                bgColor={status === "ACTIVE" ? "green.100" : "pinkOpacity15"}
                fontWeight="bold"
                borderRadius="12px"
                padding="6px 12px"
              >
                {toTitleCase(status)}
              </Tag>
            );
          },
        }),
        columnHelper.accessor("id", {
          id: "menu",
          header: "",
          enableSorting: false,
          cell: props.menuCell,
        }),
      ],
      [],
    ),
  });

  return (
    <>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        bgColor="grey6"
        marginTop="20px"
        marginBottom="20px"
        padding="8px 8px 8px 20px"
        fontFamily="Montserrat Regular"
        fontSize="13px"
      >
        <HStack spacing="13px">
          <Text>
            Showing{" "}
            {pluralize("user", table.getPrePaginationRowModel().rows.length)}
          </Text>
          <Divider orientation="vertical" color="gray30" />
          <Text color="gray70" fontSize={15} mr={4}>
            Sort by:{" "}
          </Text>
          <Select
            value={table.getState().sorting[0]?.id}
            onChange={(event) =>
              table.setSorting([{ id: event.currentTarget.value, desc: false }])
            }
            w="inherit"
            variant="unstyled"
          >
            {table
              .getAllColumns()
              .filter((col) => col.getCanSort())
              .map((col) => {
                return (
                  <option value={col.id} key={col.id}>
                    {col.columnDef.header}
                  </option>
                );
              })}
          </Select>
        </HStack>

        <InputGroup marginLeft="auto" width="auto">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray30" />
          </InputLeftElement>
          {table.getState().globalFilter && (
            <InputRightElement
              cursor="pointer"
              onClick={() => table.resetGlobalFilter()}
            >
              <CloseIcon color="gray30" />
            </InputRightElement>
          )}
          <Input
            onChange={(event) => setGlobalFilter(event.target.value)}
            value={table.getState().globalFilter || ""}
            placeholder="Search"
            width="480px"
            borderRadius="4px"
            borderColor="grey5"
            bgColor="#fff"
          />
        </InputGroup>
      </Flex>
      <HStack marginBottom="20px">
        <DataTableMultiselectFilter
          table={table}
          columnId="orgAccountRoles"
          selectSomeItemsLabel="Role"
        />
        <DataTableMultiselectFilter
          table={table}
          columnId={COLUMN_IDS.status}
          labelFormatter={(label) => {
            return toTitleCase(label ?? "");
          }}
        />
        <Spacer />
        {props.afterFilters && <Box>{props.afterFilters}</Box>}
      </HStack>
      <DataTable table={table} />
    </>
  );
}
