import { VStack, Flex } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import Header from "../../components/Header";
import TabsContainer from "../../components/TabsContainer";
import QuickPayTable from "./QuickPayTable";
import { QuickPayStatusEnum } from "../../types/QuickPayRequest";
import { DownloadOption } from "./DownloadOption";
import {
  createCsvFromObjectsArray,
  createQuickPayRequestedToExport,
  createQuickPayDecisionToExport,
  exportFileResults,
} from "../../reuse/Files";
import { QuickPayContext } from "./QuickPayContext";
import {
  useGetQuickPayCountsByStatusQuery,
  useGetQuickPayRequestsViewQuery,
} from "../../api-platform/quickpay";

export const QuickPay = (): JSX.Element => {
  const [tabs, setTabs] = useState<string[]>([]);
  const { data: quickPayCounts } = useGetQuickPayCountsByStatusQuery();

  const [currentStatus, setCurrentStatus] = useState<QuickPayStatusEnum>(
    QuickPayStatusEnum.Pending,
  );

  const { data } = useGetQuickPayRequestsViewQuery({
    status: currentStatus.toString().toUpperCase() as QuickPayStatusEnum,
    sortOption: undefined,
    sortDirection: undefined,
    searchTerm: undefined,
  });

  useEffect(() => {
    if (quickPayCounts) {
      setTabs([
        `Open Requests (${quickPayCounts.PENDING})`,
        `Approved (${quickPayCounts.APPROVED})`,
        `Rejected (${quickPayCounts.REJECTED})`,
      ]);
    }
  }, [quickPayCounts]);

  const Pending = useMemo(
    () => <QuickPayTable status={QuickPayStatusEnum.Pending} />,
    [quickPayCounts],
  );

  const Approved = useMemo(
    () => <QuickPayTable status={QuickPayStatusEnum.Approved} />,
    [quickPayCounts],
  );

  const Rejected = useMemo(
    () => <QuickPayTable status={QuickPayStatusEnum.Rejected} />,
    [quickPayCounts],
  );

  const tabPanels = [Pending, Approved, Rejected];

  const handleClickTab = (title: string): void => {
    const status = title.includes("Open Requests")
      ? QuickPayStatusEnum.Pending
      : (title.split(" ")[0] as QuickPayStatusEnum);
    setCurrentStatus(status);
  };

  const downloadTable = (): void => {
    let mappedExportData;
    if (data && data.length !== 0) {
      if (currentStatus.includes(QuickPayStatusEnum.Pending)) {
        mappedExportData = createQuickPayRequestedToExport(data);
      } else {
        mappedExportData = createQuickPayDecisionToExport(data, currentStatus);
      }
      const csvFileUrl = createCsvFromObjectsArray(mappedExportData);
      exportFileResults(csvFileUrl, "quickpay.csv");
    }
  };

  return (
    <QuickPayContext.Provider
      value={{
        currentStatus: currentStatus,
        setCurrentStatus: setCurrentStatus,
        quickPayCounts: quickPayCounts,
      }}
    >
      <VStack backgroundColor="grey6" w="100%">
        <Flex
          w="100%"
          padding="2rem 1.2rem 0rem"
          justifyContent="space-between"
        >
          <Header headingText="QuickPay Requests" fontSize={32} />
          <DownloadOption downloadTable={downloadTable} />
        </Flex>
        <TabsContainer
          padding={0}
          tabListProps={{ backgroundColor: "grey6" }}
          tabProps={{
            paddingLeft: "0px",
            paddingRight: "0px",
            marginLeft: "20px",
            _selected: {
              color: "gray90",
              fontWeight: "bold",
              borderBottom: "2px solid var(--chakra-colors-mvmntBlue)",
            },
          }}
          tabPanelProps={{ backgroundColor: "white" }}
          tabList={tabs}
          tabPanels={tabPanels}
          onClickTab={handleClickTab}
        />
      </VStack>
    </QuickPayContext.Provider>
  );
};

export default QuickPay;
