import { CarrierUser } from "./Carrier";
import { Invoice } from "./Invoice";

// a duplicate of a generated type (generated.ts) because <OpenAPI 3.0 doesn't include enum key values for RTK to translate
// PR on RTK dependent code generator that MAY fix https://github.com/cellular/oazapfts/pull/308
export enum QuickPaySortOptionsEnum {
  Id = "id",
  Brokerage = "brokerage",
  Carrier = "carrier",
  ElapsedTime = "elapsedTime",
  Amount = "amount",
  TimeRemaining = "timeRemaining",
}

export enum QuickPayStatusEnum {
  NotRequested = "NOTREQUESTED",
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
}

export enum QuickPayRequestStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export interface QuickPayRequest {
  id: string;
  createDate: Date;
  invoiceId: string;
  invoice: Invoice;
  status: QuickPayRequestStatus;
  decisionDate: Date | null;
  reason: string | null;
  decidingAdmin: string | null;
  carrier?: CarrierUser;
}

export interface TimeProps {
  elapsedTime: number;
  timeRemaining: number;
}

export enum QuickPayToDropdownEnum {
  load_id = "load_id",
  broker = "broker",
  carrier = "carrier",
}

export type QuickPayToFilterDropdown = {
  [key in QuickPayToDropdownEnum]: string[];
};

export enum QuickPayToFilterEnum {
  load_id = "load_id",
  broker = "broker",
  carrier = "carrier",
  decisionAdmin = "decisionAdmin",
  decisionDate = "decisionDate",
  status = "status",
}

export type QuickPayToFilter = {
  [key in QuickPayToFilterEnum]: string;
};

export type QuickPayToFilterMultiSelect = {
  [key in QuickPayToFilterEnum]: string[];
};

export enum CommomColumns {
  Load_Id = "ID",
  Broker = "Brokerage",
  Carrier = "Carrier",
  Amount = "Amount",
}

export enum ApprovedColumns {
  Approved_By = "Approved By",
  Approval_Date = "Approval Date",
  Action = "",
}

export enum RejectedColumns {
  Rejected_By = "Rejected By",
  Rejection_Date = "Rejection Date",
  // Reason = "Reason",
  Action = "",
}

export interface ColumnProps {
  columns: CommomColumns[] | ApprovedColumns[] | RejectedColumns[];
}

export interface QuickPayRequestUpdate {
  id: string;
  status: QuickPayInvoiceStatus;
}

export enum QuickPayExtraActions {
  Mark_Not_Paid = "Mark as NOT paid",
  Contact_Broker = "Contact Broker",
}

export enum QuickPayInvoiceStatus {
  DELIVERED = "DELIVERED",
  OVERDUE = "OVERDUE",
  NOT_PAID = "NOT PAID",
  SHIPPER_PAID = "PAID",
}

export interface BrokerQuickPayInvoice {
  id: string;
  invoice: Invoice;
  status: QuickPayInvoiceStatus;
}

export interface QuickPayRequestPost {
  ids: string[];
}

export enum QPResponseEnum {
  rejected = "rejected",
  approved = "approved",
}
