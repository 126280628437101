import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from "@chakra-ui/react";
import { GetPaymentsHistoryApiResponse } from "../../api-platform/quickpay";
import { PaymentsTable } from "./PaymentsTable";
import { QPBalances } from "./QPBalances";

interface PaymentsDrawerProps {
  btnRef: React.MutableRefObject<HTMLButtonElement | undefined>;
  isOpen: boolean;
  onClose: () => void;
  data: GetPaymentsHistoryApiResponse | undefined;
}

export const PaymentsDrawer = ({
  btnRef,
  isOpen,
  onClose,
  data,
}: PaymentsDrawerProps): JSX.Element => {
  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      size="md"
      finalFocusRef={btnRef as React.RefObject<HTMLButtonElement>}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader
          fontSize="24px"
          height="92px"
          backgroundColor="gray.100"
          mb={4}
        >
          Payments
        </DrawerHeader>

        <DrawerBody>
          <QPBalances data={data} layerStyle="sidebar" />
          <PaymentsTable payments={data} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
