import {
  DataTable,
  useDataTable,
} from "../../ui/components/DataTable/DataTable";
import { Checkbox, HStack, Text, VStack } from "@chakra-ui/react";
import { ExternalLoadQPStatus } from "../../types/ExternalLoad";
import { parseStopDateToLocal } from "../../reuse/Dates";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { ExtLoadTableContext } from "./ExtLoadTableContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { TableSelection } from "./FooterSelection";
import { TableFilters } from "./TableFilters";
import ExternalLoadTableSubhead from "./ExternalLoadTableSubhead";
import { Empty } from "./Empty";
import EmptyQP from "../../resources/png/EmptyQP.png";
import {
  GetExternalLoadBoardViewApiResponse,
  IExternalLoad,
  PaymentOrderStatusEnum,
  QuickPayStatusEnum,
  useGetExternalLoadBoardViewQuery,
} from "../../api-platform/externalLoads";
import ReusableBadge from "../../components/ReusableBadge";
import { PaymentOrderStatusEnum as PaymentOrderStatusEnumOptions } from "../../types/Quickpay";
import { BadgeColors } from "../../components/ReusableBadge/ReusableBadge";
import { createColumnHelper } from "@tanstack/react-table";
import { parseNumberToTwoDecimalCurrency } from "../../reuse/Money";
import { Option, QPOperation } from "../BrokerCarrierAccounts/types";
import { PopoverOptions } from "../../components/PopoverOptions";
import { requiredUploadedDocsMissing } from "./reuse";
import editQp from "../../resources/svg/edit.svg";
import duplicateSVG from "../../resources/svg/duplicate.svg";
import requestQp from "../../resources/svg/request-qp.svg";
import attachDocs from "../../resources/svg/attach-docs.svg";
import deleteQp from "../../resources/svg/delete.svg";
import { Paginator } from "../../components/Paginator/Paginator";
import {
  IDuplicateExternalLoad,
  IExtLoadDraft,
  useDuplicateExternalLoadOrDraftMutation,
  useGetAllExtLoadDraftsViewQuery,
} from "../../api-platform/externalLoadsDraft";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { BrokerQPPageContext } from "./BrokerQuickpayPageContext";
import AppRoutes from "../../routes";
import { useNavigate } from "react-router-dom";
import { useNotificationBanner } from "../../components/useNotificationBanner";

interface IExternalLoadTableProps {
  onClickReference?: () => void;
  onClickDownloadTemplate?: () => void;
}

const PAGE_SIZE = 25;

const columnHelper = createColumnHelper<IExternalLoad[][0]>();

const showFileStatuses: ExternalLoadQPStatus[] = [
  ExternalLoadQPStatus.Draft,
  ExternalLoadQPStatus.Pending,
];

export const ExternalLoadTable = ({
  onClickReference,
  onClickDownloadTemplate,
}: IExternalLoadTableProps): JSX.Element => {
  const navigate = useNavigate();
  const notificationBanner = useNotificationBanner();
  const pageContext = useContext(BrokerQPPageContext);
  const { searchParams } = pageContext;
  const [selectedLoads, setSelectedLoads] = useState<
    IExternalLoad[] | IExtLoadDraft[]
  >([]);
  const [duplicate] = useDuplicateExternalLoadOrDraftMutation();

  async function onDuplicate(
    id: string,
    type: IDuplicateExternalLoad["type"],
  ): Promise<void> {
    if (window.confirm("Create a draft duplicate of this row?")) {
      const response = await duplicate({
        iDuplicateExternalLoad: { id, type },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: <p>Failed to duplicate, please try again.</p>,
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Duplicate saved to drafts.</p>,
        });
        navigate(`${AppRoutes.DRAFT}?extLoadDraftId=${response.data.id}`);
      }
    }
  }

  const [isOpenFlagModal, setIsOpenFlagModal] = useState(false);
  const [qpOperation, setQpOperation] = useState<QPOperation | undefined>();

  const onSelectExtLoad = (extLoad: IExternalLoad): void => {
    const selectedIds = selectedLoads.map((load) => load.id);

    if (selectedIds.includes(extLoad.id)) {
      setSelectedLoads(
        selectedLoads.filter((element) => element.id !== extLoad.id),
      );
    } else {
      setSelectedLoads([...selectedLoads, extLoad]);
    }
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [searchParams]);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    data: loadDrafts,
    refetch: draftRefetch,
    isLoading: isLoadingDrafts,
  } = useGetAllExtLoadDraftsViewQuery(
    searchParams.get("status") === ExternalLoadQPStatus.Draft
      ? {
          search: searchParams.get("search") ?? undefined,
          customer: searchParams.get("customer") ?? undefined,
          lastStatusChangeDate:
            searchParams.getAll("lastStatusChangeDate").join(",") ?? undefined,
          carrier: searchParams.get("carrier") ?? undefined,
          missingDocuments: searchParams.get("missingDocuments") ?? undefined,
          origin: searchParams.get("origin") ?? undefined,
          page: currentPage,
          limit: PAGE_SIZE,
        }
      : skipToken,
  );

  const {
    data: extLoads,
    refetch,
    isLoading,
  } = useGetExternalLoadBoardViewQuery(
    searchParams.get("status") !== ExternalLoadQPStatus.Draft &&
      searchParams.get("status") !== null
      ? {
          status:
            (searchParams.get("status") as QuickPayStatusEnum) ?? undefined,
          search: searchParams.get("search") ?? undefined,
          customer: searchParams.get("customer") ?? undefined,
          carrier: searchParams.get("carrier") ?? undefined,
          lastStatusChangeDate:
            searchParams.getAll("lastStatusChangeDate").join(",") ?? undefined,
          missingDocuments: searchParams.get("missingDocuments") ?? undefined,
          origin: searchParams.get("origin") ?? undefined,
          paymentOrderStatus:
            searchParams.getAll("paymentOrderStatus").join("-") ?? undefined,
          page: currentPage,
          limit: PAGE_SIZE,
        }
      : skipToken,
  );

  const getDateColumnLabel = (): string => {
    switch (searchParams.get("status")) {
      case ExternalLoadQPStatus.Pending:
        return "Date requested";
      case ExternalLoadQPStatus.Approved:
        return "Date approved";
      case ExternalLoadQPStatus.Rejected:
        return "Date rejected";
      default:
        return "Date added";
    }
  };

  const getPopoverOptions = (load: IExternalLoad): Option[] => {
    const options: Option[] = [
      {
        title: "Duplicate",
        icon: duplicateSVG,
        onClick: () => {
          if (searchParams.get("status")) {
            void onDuplicate(
              load.id,
              searchParams.get("status") === ExternalLoadQPStatus.Draft
                ? "draft"
                : "load",
            );
          }
        },
      },
    ];
    if (!searchParams.get("status")) {
      return [];
    } else if (searchParams.get("status") === ExternalLoadQPStatus.Draft) {
      options.push(
        {
          title: "Edit",
          icon: editQp,
          disabled: false,
          onClick: () =>
            navigate(`${AppRoutes.DRAFT}?extLoadDraftId=${load.id}`),
        },
        {
          title: "Send payment",
          icon: requestQp,
          disabled: requiredUploadedDocsMissing(load.uploadedDocs),
          onClick: () => {
            onSelectExtLoad(load);
            setQpOperation(QPOperation.REQUEST);
          },
        },
        {
          title: "Attach documents",
          icon: attachDocs,
          onClick: () => {
            onSelectExtLoad(load);
            setIsOpenFlagModal(true);
          },
        },
        {
          title: "Delete",
          icon: deleteQp,
          onClick: () => {
            onSelectExtLoad(load);
            setQpOperation(QPOperation.DELETE);
          },
        },
      );
    }
    return options;
  };

  const shouldShowOnStatus = (
    validStatuses: ExternalLoadQPStatus[],
  ): boolean => {
    if (searchParams.get("status") !== null) {
      return validStatuses.includes(
        searchParams.get("status")! as ExternalLoadQPStatus,
      );
    }

    return false;
  };

  const table = useDataTable<IExternalLoad>({
    data:
      searchParams.get("status") !== ExternalLoadQPStatus.Draft
        ? extLoads?.results ?? []
        : (loadDrafts as unknown as GetExternalLoadBoardViewApiResponse)
            ?.results ?? [],
    enableSorting: false,
    state: {
      columnVisibility: {
        origin: false,
        missingDocuments: false,
        paymentOrderStatus: false,
        invoice: shouldShowOnStatus(showFileStatuses),
        rateCon: shouldShowOnStatus(showFileStatuses),
        bol: shouldShowOnStatus(showFileStatuses),
        pod: shouldShowOnStatus(showFileStatuses),
        fee: shouldShowOnStatus([
          ExternalLoadQPStatus.Approved,
          ExternalLoadQPStatus.Rejected,
        ]),
      },
    },
    columns: useMemo(
      () => [
        {
          header: "",
          accessorKey: "id",
          cell: (cellInfo) => {
            return (
              <>
                {shouldShowOnStatus([ExternalLoadQPStatus.Draft]) && (
                  <Checkbox
                    borderColor="grey"
                    colorScheme="blue"
                    isInvalid={false}
                    isChecked={selectedLoads.includes(cellInfo.row.original)}
                    onChange={() => onSelectExtLoad(cellInfo.row.original)}
                  />
                )}
              </>
            );
          },
        },
        columnHelper.accessor("brokerShipmentId", {
          header: "Load",
          cell(cellInfo) {
            return (
              <VStack alignItems="baseline">
                <Text>{cellInfo.row.original.brokerShipmentId}</Text>
                <Text color="gray.600">
                  {(cellInfo.row.original.originCity +
                    ", " +
                    cellInfo.row.original.originState ?? "") +
                    " > " +
                    (cellInfo.row.original.destinationCity +
                      ", " +
                      cellInfo.row.original.destinationState ?? "")}
                </Text>
              </VStack>
            );
          },
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("carrierPayeeAccount.name", {
          header: "Carrier",
          enableSorting: false,
          cell: (cellInfo) => {
            return (
              <VStack alignItems="baseline">
                <Text>{cellInfo.row.original.carrierPayeeAccount?.name}</Text>
                <Text color="gray.600">
                  Invoice #{cellInfo.row.original.carrierInvoiceId}
                </Text>
              </VStack>
            );
          },
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("customerName", {
          header: "Customer",
          cell: (cellInfo) => {
            return <Text>{cellInfo.row.original.customerName}</Text>;
          },
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("carrierInvoiceAmount", {
          header: "Invoice",
          cell: (cellInfo) => {
            return (
              <Text>
                {parseNumberToTwoDecimalCurrency(
                  +cellInfo.row.original.carrierInvoiceAmount,
                )}
              </Text>
            );
          },
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("amountToPayCarrier", {
          header: "Quickpay",
          filterFn: "arrIncludesSome",
          cell: (cellInfo) => {
            return (
              <HStack alignItems="baseline">
                <Text>
                  {parseNumberToTwoDecimalCurrency(
                    +cellInfo.row.original.amountToPayCarrier,
                  )}
                </Text>
                {searchParams.get("status") === ExternalLoadQPStatus.Approved &&
                cellInfo.row.original.paymentOrderStatus ? (
                  <ReusableBadge<PaymentOrderStatusEnum>
                    currentStatus={cellInfo.row.original.paymentOrderStatus}
                    badgeColor={
                      cellInfo.row.original.paymentOrderStatus ===
                      PaymentOrderStatusEnumOptions.Failed
                        ? BadgeColors.Red
                        : cellInfo.row.original.paymentOrderStatus ===
                          PaymentOrderStatusEnumOptions.Posted
                        ? BadgeColors.Green
                        : BadgeColors.Grey
                    }
                  />
                ) : (
                  <></>
                )}
              </HStack>
            );
          },
        }),
        columnHelper.accessor("lastStatusChangeDate", {
          header: getDateColumnLabel(),
          cell: (cellInfo) => {
            return (
              <Text>
                {cellInfo.row.original.lastStatusChangeDate
                  ? parseStopDateToLocal(
                      new Date(cellInfo.row.original.lastStatusChangeDate),
                    )
                  : ""}
              </Text>
            );
          },
          filterFn: "arrIncludesSome",
        }),
        {
          header: "Fee",
          enableSorting: false,
          accessorKey: "fee",
          cell: (cellInfo) => {
            return (
              <Text>
                {parseNumberToTwoDecimalCurrency(
                  +cellInfo.row.original.carrierInvoiceAmount -
                    +cellInfo.row.original.amountToPayCarrier,
                )}
              </Text>
            );
          },
          filterFn: "arrIncludesSome",
        },
        {
          header: "Invoice",
          enableSorting: false,
          accessorKey: "invoice",
          cell: (cellInfo) => {
            return cellInfo.row.original.uploadedDocs?.invoiceId !== null ? (
              <CheckIcon color="green" />
            ) : (
              <CloseIcon color="red" />
            );
          },
          filterFn: "arrIncludesSome",
        },
        {
          header: "RateCon",
          accessorKey: "rateCon",
          enableSorting: false,
          cell: (cellInfo) => {
            return cellInfo.row.original.uploadedDocs?.rateconId !== null ? (
              <CheckIcon color="green" />
            ) : (
              <CloseIcon color="red" />
            );
          },
          filterFn: "arrIncludesSome",
        },
        {
          header: "Bol",
          accessorKey: "bol",
          enableSorting: false,
          cell: (cellInfo) => {
            return cellInfo.row.original.uploadedDocs?.bolId !== null ? (
              <CheckIcon color="green" />
            ) : (
              <CloseIcon color="red" />
            );
          },
          filterFn: "arrIncludesSome",
        },
        {
          header: "Pod",
          accessorKey: "pod",
          enableSorting: false,
          cell: (cellInfo) => {
            const { PODRequired, uploadedDocs } = cellInfo.row.original;
            return uploadedDocs?.podId !== null ? (
              <CheckIcon color="green" />
            ) : PODRequired && uploadedDocs?.podId === null ? (
              <CloseIcon color="red" />
            ) : (
              <></>
            );
          },
          filterFn: "arrIncludesSome",
        },
        columnHelper.accessor("paymentOrderStatus", {
          header: "QuickPay Payment",
          filterFn: "arrIncludesSome",
          cell: (cellInfo) => {
            return <Text>{cellInfo.row.original.paymentOrderStatus}</Text>;
          },
        }),
        {
          header: " ",
          cell: (cellInfo) => {
            const popoverOptions: Option[] = getPopoverOptions(
              cellInfo.row.original,
            );

            return (
              <>
                {shouldShowOnStatus([
                  ExternalLoadQPStatus.Draft,
                  ExternalLoadQPStatus.Approved,
                  ExternalLoadQPStatus.Pending,
                ]) && <PopoverOptions options={popoverOptions} />}
              </>
            );
          },
        },
      ],
      [selectedLoads, extLoads?.results],
    ),
  });

  return (
    <ExtLoadTableContext.Provider
      value={{
        quickpays:
          searchParams.get("status") === ExternalLoadQPStatus.Draft
            ? loadDrafts?.results ?? []
            : extLoads?.results ?? [],
        selectedExtLoads: selectedLoads,
        setSelectedExtLoads: setSelectedLoads,
      }}
    >
      <VStack height="calc(100vh - 300px)" overflow="auto" mb="30px">
        <TableFilters setCurrentPage={setCurrentPage} />
        <ExternalLoadTableSubhead
          totalPossibleLoads={
            searchParams.get("status") !== ExternalLoadQPStatus.Draft
              ? extLoads?.totalSize
              : loadDrafts?.totalSize
          }
          totalDisplayedLoads={
            searchParams.get("status") !== ExternalLoadQPStatus.Draft
              ? extLoads?.results.length ?? 0
              : loadDrafts?.results.length ?? 0
          }
          page={currentPage}
          offset={PAGE_SIZE}
        />
        {((extLoads?.results.length !== 0 &&
          searchParams.get("status") !== ExternalLoadQPStatus.Draft) ||
          (loadDrafts?.results.length !== 0 &&
            searchParams.get("status") === ExternalLoadQPStatus.Draft)) &&
        !isLoading &&
        !isLoadingDrafts ? (
          <>
            <VStack w="100%">
              <DataTable table={table} />
              {selectedLoads.length !== 0 &&
                (searchParams.get("status") === ExternalLoadQPStatus.Rejected ||
                  searchParams.get("status") ===
                    ExternalLoadQPStatus.Draft) && (
                  <TableSelection
                    isOpenFlagModal={isOpenFlagModal}
                    setIsOpenFlagModal={setIsOpenFlagModal}
                    qpOperation={qpOperation}
                    setQpOperation={setQpOperation}
                    refetch={
                      searchParams.get("status") === ExternalLoadQPStatus.Draft
                        ? draftRefetch
                        : refetch
                    }
                  />
                )}
            </VStack>
          </>
        ) : (
          <Empty
            source={EmptyQP}
            onClickReference={onClickReference}
            onClickDownloadTemplate={onClickDownloadTemplate}
          />
        )}
      </VStack>
      {searchParams.get("status") === ExternalLoadQPStatus.Draft ? (
        <>
          {loadDrafts?.results && selectedLoads.length === 0 && (
            <Paginator
              onPageChange={setCurrentPage}
              totalCount={loadDrafts.totalSize}
              currentPage={currentPage}
              pageSize={loadDrafts.limit}
            />
          )}
        </>
      ) : (
        <>
          {extLoads?.results && selectedLoads.length === 0 && (
            <Paginator
              onPageChange={setCurrentPage}
              totalCount={extLoads.totalSize}
              currentPage={currentPage}
              pageSize={extLoads.limit}
            />
          )}
        </>
      )}
    </ExtLoadTableContext.Provider>
  );
};

export default ExternalLoadTable;
