import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppRoutes from "./routes";
import Shell from "./pages/Shell";
import SignUpByInvitation from "./pages/SignUpByInvitation/SignUpByInvitation";
import { Login } from "./pages/Auth/Login";
import { RequestAccount } from "./pages/Auth/RequestAccount";
import { Layout } from "./pages/Auth/components/Layout";
import { UpdatePassword } from "./pages/Auth/UpdatePassword";
import { ForgotPassword } from "./pages/Auth/ForgotPassword";
import { ResetPassword } from "./pages/Auth/ResetPassword";
import AccountRequest from "./pages/AccountRequest";
import { UserType } from "./types/User";

function App(): JSX.Element {
  document.addEventListener("wheel", function (event) {
    if (
      document?.activeElement &&
      "type" in document.activeElement &&
      (document.activeElement as any).type === "number" // eslint-disable-line @typescript-eslint/no-explicit-any
    ) {
      if ("blur" in document.activeElement)
        (document.activeElement as any).blur(); // eslint-disable-line @typescript-eslint/no-explicit-any
    }
  });
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path={AppRoutes.LOGIN} element={<Login />} />
          <Route
            path={AppRoutes.UPDATE_PASSWORD}
            element={<UpdatePassword />}
          />
          <Route
            path={AppRoutes.FORGOT_PASSWORD}
            element={<ForgotPassword />}
          />
          <Route path={AppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={AppRoutes.REQUEST_ACCOUNT}
            element={<RequestAccount />}
          />
        </Route>
        <Route
          path={AppRoutes.INVITED_USER_SIGN_UP}
          element={<SignUpByInvitation />}
        />
        <Route
          path="requestaccount"
          element={<AccountRequest userType={UserType.BROKER} />}
        />
        <Route path="/*" element={<Shell />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
