import { VStack, Flex } from "@chakra-ui/layout";
import { Button, Link, Heading } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CarrierPayeeAccountPageContext } from "./CarrierPayeeAccountPageContext";
import {
  ICarrierPayeeAccount,
  IModernTreasuryAccountInfo,
  useCreateCarriersAccountStatusMutation,
  useGetCarrierPayeeAccountByIdQuery,
  useGetExternalAccountsQuery,
} from "../../api-platform/carrierPayeeAccounts";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import TabsContainer from "../../components/TabsContainer";
import { ChevronLeftIcon } from "@chakra-ui/icons";
import { routes } from "../../routes";
import CarrierCompanyDetailsForm from "./CarrierCompanyDetailsForm";
import CarrierPrimaryContactForm from "./CarrierPrimaryContactForm";
import ReusableConfirmModal from "../../components/ReusableConfirmModal";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { CarrierPaymentDetails } from "./CarrierPaymentDetails";

export const CarrierPayeeAccountPage = (): JSX.Element => {
  const { carrierId } = useParams();
  const navigate = useNavigate();
  const notificationBanner = useNotificationBanner();
  const [removeCarrier, { isLoading: deactivateLoading }] =
    useCreateCarriersAccountStatusMutation();

  const { data: carrierInfo, isLoading: isLoadingCarrier } =
    useGetCarrierPayeeAccountByIdQuery(
      carrierId
        ? {
            id: carrierId,
          }
        : skipToken,
    );

  const { data: accounts } = useGetExternalAccountsQuery(
    carrierId
      ? {
          id: carrierId,
        }
      : skipToken,
  );

  const [carrier, setCarrier] = useState<ICarrierPayeeAccount | undefined>();
  const [carrierToDeactivate, setCarrierToDeactivate] = useState<
    ICarrierPayeeAccount | undefined
  >();
  const [externalAccounts, setExternalAccounts] = useState<
    IModernTreasuryAccountInfo[]
  >([]);

  useEffect(() => {
    setCarrier(carrierInfo);
  }, [carrierInfo]);

  useEffect(() => {
    setExternalAccounts(accounts!);
  }, [accounts]);

  const confirmRemoveCarrier = async (): Promise<void> => {
    if (carrierToDeactivate) {
      const response = await removeCarrier({
        id: carrierToDeactivate.id,
        body: {
          status: "INACTIVE",
        },
      });
      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: (
            <p>
              There was a problem deactivating this carrier. Please try again.
            </p>
          ),
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Successfully deactivated carrier.</p>,
        });
      }
      setCarrierToDeactivate(undefined);
    }
  };

  return (
    <CarrierPayeeAccountPageContext.Provider
      value={{
        carrier,
        setCarrier,
        externalAccounts,
        setExternalAccounts,
      }}
    >
      <VStack w="100%" spacing={0}>
        <Flex backgroundColor="grey6" w="100%" padding="20px 40px 0px 40px">
          <Button
            onClick={() => navigate(routes.CARRIER_ACCOUNTS)}
            layerStyle="gray"
            leftIcon={<ChevronLeftIcon />}
            p={0}
            disabled={isLoadingCarrier}
          >
            <Link>Back</Link>
          </Button>
        </Flex>
        <Flex
          backgroundColor="grey6"
          w="100%"
          padding="0px 40px 0px 40px"
          justifyContent="space-between"
        >
          <Heading as="h1">{carrier ? carrier.name : "Carriers"}</Heading>
          <Flex>
            <Button
              layerStyle="inverseRed"
              mr="5px"
              onClick={() => {
                if (carrierInfo) {
                  setCarrierToDeactivate(carrierInfo);
                }
              }}
            >
              Deactivate carrier
            </Button>
            {carrierToDeactivate && (
              <ReusableConfirmModal
                isOpen={true}
                onClose={() => setCarrierToDeactivate(undefined)}
                onClickCTA={confirmRemoveCarrier}
                title="Deactivate carrier"
                content={`Are you sure you want to deactivate carrier ${carrierToDeactivate.name} ?`}
                cancelText="Close"
                confirmText="Deactivate carrier"
                loading={deactivateLoading}
                appearanceConfirmBtn={{
                  textColor: "red",
                  border: "1px solid red",
                }}
              />
            )}
          </Flex>
        </Flex>
        {!isLoadingCarrier && (
          <>
            <TabsContainer
              p={0}
              bg="realWhite"
              tabListProps={{ bg: "grey6", padding: "0px 40px" }}
              tabList={[
                "Company details",
                "Primary contact",
                "Payment details",
              ]}
              tabProps={{
                marginRight: "20px",
                padding: "8px 0px",
                _selected: {
                  color: "gray90",
                  fontWeight: "bold",
                  borderBottom: "2px solid var(--chakra-colors-mvmntBlue)",
                },
              }}
              tabPanelProps={{ padding: "40px" }}
              tabPanels={[
                <CarrierCompanyDetailsForm
                  key={`carrier account tab: company details`}
                />,
                <CarrierPrimaryContactForm
                  key={`carrier account tab: contact`}
                />,
                <CarrierPaymentDetails
                  key={`carrier account tab: Payment details`}
                />,
              ]}
            />
          </>
        )}
      </VStack>
    </CarrierPayeeAccountPageContext.Provider>
  );
};

export default CarrierPayeeAccountPage;
