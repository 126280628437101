import { EditIcon } from "@chakra-ui/icons";
import {
  Text,
  HStack,
  VStack,
  Heading,
  SkeletonText,
  Button,
  Spacer,
  Switch,
  FormLabel,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  IUpsertBrokerFinancials,
  useGetBrokerAccountSummaryQuery,
  useGetBrokerCreditInfoQuery,
  useGetBrokerQuery,
  useUpsertBrokerCreditInfoMutation,
  useUpdateBrokerQuickPayEnableMutation,
} from "../../../../../api-platform/internal";
import { useNotificationBanner } from "../../../../../components/useNotificationBanner";
import { DEFAULT_ERROR_MESSAGE } from "../../../../../reuse/Constants";
import { BillingContact } from "./BillingContact";
import { PastBrokerage } from "./PastBrokerage";
import { EditFinances } from "./EditFinances";
import { ViewFinances } from "./ViewFinances";
import {
  useGetBrokerAccountRequestLeadQuery,
  useUpdateRequestLeadFinancialsMutation,
} from "../../../../../api-platform/brokerAccountRequestLeads";
import { ViewAccountLeadFinances } from "./ViewAccountLeadFinances";

const BrokerAccountRequestType = "BROKER_ACCOUNT_REQUEST";

export function Finances(): JSX.Element {
  const { brokerId } = useParams();
  const notificationBanner = useNotificationBanner();

  const [
    updateBrokerCreditInfoParams,
    { isLoading: isUpdatingBrokerCreditInfo },
  ] = useUpsertBrokerCreditInfoMutation();

  const [
    updateAccountLeadFinancials,
    { isLoading: isUpdatingAccountLeadFinancials },
  ] = useUpdateRequestLeadFinancialsMutation();

  const [toggleBrokerQuickPayEnable] = useUpdateBrokerQuickPayEnableMutation();

  const { data: brokerSummary } = useGetBrokerAccountSummaryQuery(
    brokerId ? { id: brokerId } : skipToken,
  );
  const { data: financials, isLoading: loadingFinancials } =
    useGetBrokerCreditInfoQuery(
      brokerId &&
        brokerSummary &&
        brokerSummary.type !== BrokerAccountRequestType
        ? { id: brokerId }
        : skipToken,
    );

  const { data: broker } = useGetBrokerQuery(
    brokerId && brokerSummary && brokerSummary.type !== BrokerAccountRequestType
      ? { id: brokerId }
      : skipToken,
  );

  const { data: brokerRequestLead, isLoading: requestLeadLoading } =
    useGetBrokerAccountRequestLeadQuery(
      brokerId &&
        brokerSummary &&
        brokerSummary.type === BrokerAccountRequestType
        ? { id: brokerId }
        : skipToken,
    );

  const { reset, handleSubmit, register, formState, watch } =
    useForm<IUpsertBrokerFinancials>();

  const [isEditMode, setIsEditMode] = useState(false);
  const [checkedOnHoldQP, setCheckedOnHoldQP] = useState<boolean | undefined>();

  const onToggleHoldQP = async (): Promise<void> => {
    const response = toggleBrokerQuickPayEnable({
      id: brokerId!,
    });
    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>
            There was a problem requesting On hold Broker QuickPays. Please try
            again.
          </p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Updated On/Off Hold Quickpay.</p>,
      });
      setCheckedOnHoldQP(!checkedOnHoldQP);
    }
  };

  const resetToDefaultFinancials = (): void => {
    if (financials) {
      reset({
        equityCapital: financials.equityCapital ?? undefined,
        lastYearBankruptcy: financials.lastYearBankruptcy ?? undefined,
        lastYearFelonyCharges: financials.lastYearFelonyCharges ?? undefined,
        lastYearInterestExpense:
          financials.lastYearInterestExpense ?? undefined,
        lastYearNetIncome: financials.lastYearNetIncome ?? undefined,
        monthlyIncome: financials.monthlyIncome ?? 0, // default to 0 for now
        monthlyRevenue: financials.monthlyRevenue ?? 0, // default to 0 for now
        taxLiens: financials.taxLiens ?? undefined,
        totalAssets: financials.totalAssets ?? 0, // default to 0 for now,
        totalDebt: financials.totalDebt ?? 0, // default to 0 for now
        unrestrictedCash: financials.unrestrictedCash ?? undefined,
        unsatisfiedJudgements: financials.unsatisfiedJudgements ?? undefined,
        yearOneEbitda: financials.yearOneEbitda ?? undefined,
        yearOneRevenue: financials.yearOneRevenue ?? undefined,
        yearThreeEbitda: financials.yearThreeEbitda ?? undefined,
        yearThreeRevenue: financials.yearThreeRevenue ?? undefined,
        yearTwoEbitda: financials.yearTwoEbitda ?? undefined,
        yearTwoRevenue: financials.yearTwoRevenue ?? undefined,
        creditLimit: financials.creditLimit ?? undefined,
      });
    }
  };

  const resetToDefaultRequestLead = (): void => {
    if (brokerRequestLead) {
      reset({
        equityCapital: brokerRequestLead.equityCapital ?? undefined,
        lastYearBankruptcy: brokerRequestLead.lastYearBankruptcy ?? undefined,
        lastYearFelonyCharges:
          brokerRequestLead.lastYearFelonyCharges ?? undefined,
        lastYearInterestExpense:
          brokerRequestLead.lastYearInterestExpense ?? undefined,
        lastYearNetIncome: brokerRequestLead.lastYearNetIncome ?? undefined,
        monthlyIncome: brokerRequestLead.monthlyIncome ?? undefined,
        monthlyRevenue: brokerRequestLead.monthlyRevenue ?? undefined,
        taxLiens: brokerRequestLead.taxLiens ?? undefined,
        totalAssets: brokerRequestLead.totalAssets ?? undefined,
        totalDebt: brokerRequestLead.totalDebt ?? undefined,
        unrestrictedCash: brokerRequestLead.unrestrictedCash ?? undefined,
        unsatisfiedJudgements:
          brokerRequestLead.unsatisfiedJudgements ?? undefined,
        yearOneEbitda: brokerRequestLead.yearOneEbitda ?? undefined,
        yearOneRevenue: brokerRequestLead.yearOneRevenue ?? undefined,
        yearThreeEbitda: brokerRequestLead.yearThreeEbitda ?? undefined,
        yearThreeRevenue: brokerRequestLead.yearThreeRevenue ?? undefined,
        yearTwoEbitda: brokerRequestLead.yearTwoEbitda ?? undefined,
        yearTwoRevenue: brokerRequestLead.yearTwoRevenue ?? undefined,
        creditLimit: brokerRequestLead.creditLimit ?? undefined,
      });
    }
  };

  useEffect(() => {
    resetToDefaultFinancials();
  }, [loadingFinancials]);

  useEffect(() => {
    resetToDefaultRequestLead();
  }, [requestLeadLoading]);

  async function handleUpsertBrokerCreditInfoFormSubmit(
    data: IUpsertBrokerFinancials,
  ): Promise<void> {
    if (brokerId) {
      await updateBrokerCreditInfoParams({
        id: brokerId,
        iUpsertBrokerFinancials: data,
      })
        .unwrap()
        .then(() => {
          setIsEditMode(false);
          notificationBanner({
            status: "success",
            description: "Broker Credit Info updated successfully.",
          });
        })
        .catch((e) => {
          notificationBanner({
            status: "error",
            description: DEFAULT_ERROR_MESSAGE,
          });
          throw e;
        });
    } else {
      notificationBanner({
        status: "error",
        description: "Missing Broker Id",
      });
    }
  }

  async function handleUpsertAccountLeadCreditInfoFormSubmit(
    data: IUpsertBrokerFinancials,
  ): Promise<void> {
    if (brokerRequestLead) {
      await updateAccountLeadFinancials({
        id: brokerRequestLead.id,
        iEditBrokerAccountRequestLeadFinancials: {
          equityCapital: data.equityCapital ?? null,
          lastYearBankruptcy: data.lastYearBankruptcy ?? null,
          lastYearFelonyCharges: data.lastYearFelonyCharges ?? null,
          lastYearInterestExpense: data.lastYearInterestExpense ?? null,
          lastYearNetIncome: data.lastYearNetIncome ?? null,
          monthlyIncome: data.monthlyIncome ?? null,
          monthlyRevenue: data.monthlyRevenue ?? null,
          taxLiens: data.taxLiens ?? null,
          totalAssets: data.totalAssets ?? null,
          totalDebt: data.totalDebt ?? null,
          unrestrictedCash: data.unrestrictedCash ?? null,
          unsatisfiedJudgements: data.unsatisfiedJudgements ?? null,
          yearOneEbitda: data.yearOneEbitda ?? null,
          yearOneRevenue: data.yearOneRevenue ?? null,
          yearThreeEbitda: data.yearThreeEbitda ?? null,
          yearThreeRevenue: data.yearThreeRevenue ?? null,
          yearTwoEbitda: data.yearTwoEbitda ?? null,
          yearTwoRevenue: data.yearTwoRevenue ?? null,
          creditLimit: data.creditLimit ?? null,
        },
      })
        .unwrap()
        .then(() => {
          setIsEditMode(false);
          notificationBanner({
            status: "success",
            description: "Broker Credit Info updated successfully.",
          });
        })
        .catch((e) => {
          notificationBanner({
            status: "error",
            description: DEFAULT_ERROR_MESSAGE,
          });
          throw e;
        });
    } else {
      notificationBanner({
        status: "error",
        description: "Missing Account Request Id",
      });
    }
  }

  if (loadingFinancials || requestLeadLoading) {
    return (
      <SkeletonText maxW="400px" mt="8px" noOfLines={20} spacing="4" mb="8px" />
    );
  }

  const onSave = (): void => {
    if (brokerSummary?.type === BrokerAccountRequestType) {
      void handleSubmit(handleUpsertAccountLeadCreditInfoFormSubmit)();
    } else {
      void handleSubmit(handleUpsertBrokerCreditInfoFormSubmit)();
    }
  };

  const onCancel = (): void => {
    setIsEditMode(false);
    if (brokerSummary?.type === BrokerAccountRequestType) {
      resetToDefaultRequestLead();
    } else {
      resetToDefaultFinancials();
    }
  };

  return (
    <VStack spacing="20px" maxW="640px" align="start" pb="40px">
      <HStack w="100%">
        <Heading size="md">Credit info</Heading>
        <Spacer />
        <Button
          leftIcon={<EditIcon />}
          visibility={isEditMode ? "hidden" : "visible"}
          onClick={() => setIsEditMode(true)}
        >
          <Text pt="0.1rem">Edit</Text>
        </Button>
      </HStack>
      {broker && (
        <HStack w="100%">
          <Switch
            colorScheme="teal"
            size="lg"
            isChecked={broker?.quickpayEnabled}
            onChange={onToggleHoldQP}
          />
          <FormLabel m="0 1rem" requiredIndicator={<></>}>
            This brokerage can request QuickPay
          </FormLabel>
        </HStack>
      )}
      {isEditMode ? (
        <>
          <EditFinances
            watch={watch}
            formState={formState}
            register={register}
            requestLeadMode={brokerSummary?.type === BrokerAccountRequestType}
          />
          <HStack>
            <Button
              layerStyle="yellow"
              onClick={onSave}
              isLoading={
                isUpdatingBrokerCreditInfo || isUpdatingAccountLeadFinancials
              }
            >
              Save changes
            </Button>
            <Button
              onClick={onCancel}
              isLoading={
                isUpdatingBrokerCreditInfo || isUpdatingAccountLeadFinancials
              }
            >
              Cancel
            </Button>
          </HStack>
        </>
      ) : (
        <>
          {broker && <ViewFinances financials={financials} broker={broker} />}
          {brokerRequestLead && (
            <ViewAccountLeadFinances accountLead={brokerRequestLead} />
          )}
        </>
      )}
      {broker && <PastBrokerage />}
      {broker && <BillingContact />}
    </VStack>
  );
}
