import { Tag } from "@chakra-ui/react";
import { toTitleCase } from "../../../reuse/Strings";
import type { GetBrokerAccountsApiResponse } from "../../../api-platform/internal";

type Status = GetBrokerAccountsApiResponse["results"][0]["status"];

const statusTagProps: Record<
  Status,
  Record<"colorScheme" | "bgColor", string>
> = {
  APPROVED: { colorScheme: "green", bgColor: "green.100" },
  REJECTED: { colorScheme: "red", bgColor: "pinkOpacity15" },
  PENDING: { colorScheme: "blue", bgColor: "blue.100" },
};

export function AccountStatusTag({ status }: { status: Status }): JSX.Element {
  return (
    <Tag
      colorScheme={statusTagProps[status].colorScheme}
      bgColor={statusTagProps[status].bgColor}
      variant="outline"
      size="sm"
    >
      {toTitleCase(status)}
    </Tag>
  );
}
