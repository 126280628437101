import { EditIcon, SmallAddIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  SkeletonText,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  // useGetBrokerBrokerageQuery,
  useGetPastBrokeragesQuery,
  useAddPastBrokeragesMutation,
} from "../../../../../api-platform/internal";
import { useForm, useFieldArray } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNotificationBanner } from "../../../../../components/useNotificationBanner";
import { DEFAULT_ERROR_MESSAGE } from "../../../../../reuse/Constants";

interface IAddBrokerage {
  pastBrokerageName: string;
  pastBrokerageStillInOperation: boolean;
}

interface BrokerageFormValues {
  brokerage: IAddBrokerage[];
}

enum PastBroker {
  NO = "NO",
  YES = "YES",
}

export const PastBrokerage = (): JSX.Element => {
  const { brokerId } = useParams();
  const notificationBanner = useNotificationBanner();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [pastBroker, setPastBroker] = useState<PastBroker>(PastBroker.NO);
  const { data: brokerage, isLoading } = useGetPastBrokeragesQuery(
    brokerId ? { id: brokerId } : skipToken,
  );

  const [addBrokerBrokerage] = useAddPastBrokeragesMutation();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<BrokerageFormValues>();

  const { fields, append, remove } = useFieldArray({
    name: "brokerage",
    control,
  });

  const onSubmit = async (data: BrokerageFormValues): Promise<void> => {
    if (brokerId) {
      await addBrokerBrokerage({
        id: brokerId,
        body: data.brokerage,
      })
        .unwrap()
        .catch((e) => {
          notificationBanner({
            status: "error",
            description: DEFAULT_ERROR_MESSAGE,
          });
          throw e;
        });
      setIsEditMode(false);
      notificationBanner({
        status: "success",
        description: "Finances Brokerage added successfully.",
      });
      reset();
    } else {
      notificationBanner({
        status: "error",
        description: "Missing Broker Id",
      });
    }
  };

  if (isLoading) {
    return (
      <SkeletonText maxW="400px" mt="8px" noOfLines={20} spacing="4" mb="8px" />
    );
  }

  return (
    <VStack spacing="20px" w="100%" maxW="640px" align="start">
      <VStack w="100%" alignItems="start">
        <HStack w="100%" justifyContent="space-between">
          <Heading size="md">Past Brokerage</Heading>
          <Button
            leftIcon={<EditIcon />}
            visibility={isEditMode ? "hidden" : "visible"}
            onClick={() => setIsEditMode(true)}
          >
            <Text pt="0.1rem">Edit</Text>
          </Button>
        </HStack>
        <FormLabel requiredIndicator={<></>}>
          Has the company`s owner or management operated another brokerage in
          the past?
        </FormLabel>
        {isEditMode ? (
          <Select
            w="100%"
            value={pastBroker}
            onChange={(event) => {
              setPastBroker(event.target.value as PastBroker);
              if (event.target.value === PastBroker.NO) {
                setIsEditMode(false);
                reset();
              }
            }}
          >
            <option value={PastBroker.NO}>No</option>
            <option value={PastBroker.YES}>Yes</option>
          </Select>
        ) : (
          <Text>{brokerage && brokerage.length > 0 ? "Yes" : "No"}</Text>
        )}
      </VStack>
      <VStack spacing="24px" align="start" mt="20px">
        {brokerage?.map((element) => (
          <Box key={element.id}>
            <FormLabel>What is the name of the brokerage?</FormLabel>
            <Text mb="1rem">{element.pastBrokerageName}</Text>
            <FormLabel>Is that brokerage still in Operation?</FormLabel>
            <Text>{element.pastBrokerageStillInOperation ? "Yes" : "No"}</Text>
          </Box>
        ))}
      </VStack>
      <Spacer />
      {isEditMode && pastBroker === PastBroker.YES && (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {fields.map((field, index) => (
              <VStack key={field.id} mb="1rem" alignItems="flex-start">
                <FormControl isRequired isInvalid={!!errors}>
                  <FormLabel requiredIndicator={<></>}>
                    What is the name of the brokerage?
                  </FormLabel>
                  <Input
                    w="auto"
                    mb="1rem"
                    {...register(
                      `brokerage.${index}.pastBrokerageName` as const,
                      {
                        required: {
                          value: true,
                          message: DEFAULT_ERROR_MESSAGE,
                        },
                      },
                    )}
                  />
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      message={DEFAULT_ERROR_MESSAGE}
                      name="pastBrokerageName"
                    />
                  </FormErrorMessage>
                </FormControl>
                <FormControl isRequired isInvalid={!!errors}>
                  <FormLabel requiredIndicator={<></>}>
                    Is that brokerage still in Operation?
                  </FormLabel>
                  <Select
                    mb="1rem"
                    {...register(
                      `brokerage.${index}.pastBrokerageStillInOperation` as const,
                      {
                        required: {
                          value: true,
                          message: DEFAULT_ERROR_MESSAGE,
                        },
                      },
                    )}
                  >
                    <option value="False">No</option>
                    <option value="True">Yes</option>
                  </Select>
                  <FormErrorMessage>
                    <ErrorMessage
                      errors={errors}
                      name="pastBrokerageStillInOperation"
                    />
                  </FormErrorMessage>
                </FormControl>
                <Button
                  variant="outline"
                  colorScheme="red"
                  leftIcon={<SmallCloseIcon />}
                  onClick={() => remove(index)}
                >
                  Remove Brokerage
                </Button>
              </VStack>
            ))}

            <Button
              layerStyle="black"
              mb="1rem"
              rightIcon={<SmallAddIcon />}
              onClick={() =>
                append({
                  pastBrokerageName: "",
                  pastBrokerageStillInOperation: false,
                })
              }
            >
              Add Brokerage
            </Button>
            <HStack>
              <Button
                layerStyle="yellow"
                type="submit"
                isDisabled={fields.length === 0}
              >
                Save changes
              </Button>
              <Button
                onClick={() => {
                  setIsEditMode(false);
                  setPastBroker(PastBroker.NO);
                  reset();
                }}
              >
                Cancel
              </Button>
            </HStack>
          </form>
        </>
      )}
    </VStack>
  );
};
