import { createContext, useContext } from "react";
import { BrokerStatus, BrokerUser } from "../../types/Broker";

interface ManageUser {
  statusCellStyle: React.CSSProperties | undefined;
  setStatusCellStyle: React.Dispatch<React.SetStateAction<React.CSSProperties>>;
  currentStatus: BrokerStatus;
  setCurrentStatus: React.Dispatch<React.SetStateAction<BrokerStatus>>;
  setStylingBasedOnStatus: () => void;
  term: string;
  selectedBroker: BrokerUser | undefined;
  setSelectedBroker: React.Dispatch<
    React.SetStateAction<BrokerUser | undefined>
  >;
}

const ManageUserContext = createContext<ManageUser | null>(null);

const useManageUserContext = (): ManageUser | null =>
  useContext(ManageUserContext);

export { ManageUserContext, useManageUserContext };
