import { useContext, useEffect, useState } from "react";
import { HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import {
  PaymentOrderStatusEnum,
  useGetBrokerExternalLoadCustomersQuery,
  useGetBrokerExternalLoadOriginsQuery,
} from "../../api-platform/externalLoads";
import { ExternalLoadQPStatus } from "../../types/ExternalLoad";
import "../../styles/datepicker.scss";
import { useGetCarriersForBrokerQuery } from "../../api-platform/carrierPayeeAccounts";
import { MultiSelect } from "react-multi-select-component";
import { ExtLoadDocTypeEnum } from "../../api-platform/documents";
import { BrokerQPPageContext } from "./BrokerQuickpayPageContext";
import {
  useGetDraftBrokerExternalLoadCustomersQuery,
  useGetDraftBrokerExternalLoadOriginsQuery,
} from "../../api-platform/externalLoadsDraft";

// pulled from react-multi-select-component
interface Option<T> {
  value: T;
  label: string;
  key?: string;
  disabled?: boolean;
}

const NoneOption = { value: "none" as const, label: "None" };

const MissingDocOptions: Array<Option<ExtLoadDocTypeEnum | "none">> = [
  NoneOption,
  { value: "Invoice", label: "Carrier invoice" },
  { value: "RateCon", label: "Rate confirmation" },
  { value: "BillOfLading", label: "Bill of Lading (BOL)" },
  { value: "ProofOfDelivery", label: "Proof of delivery (POD)" },
];

const PaymentStatusOptions: Array<Option<PaymentOrderStatusEnum>> = [
  { value: "FAILED", label: "Failed" },
  { value: "PENDING", label: "Pending" },
  { value: "POSTED", label: "Posted" },
];

interface FilterProps {
  setCurrentPage: (newPage: number) => void;
}

export const TableFilters = ({ setCurrentPage }: FilterProps): JSX.Element => {
  const pageContext = useContext(BrokerQPPageContext);
  const { searchParams, setSearchParams } = pageContext;
  const { data: carriers } = useGetCarriersForBrokerQuery({});
  const { data: customers } = useGetBrokerExternalLoadCustomersQuery();
  const { data: draftCustomers } =
    useGetDraftBrokerExternalLoadCustomersQuery();
  const { data: origins } = useGetBrokerExternalLoadOriginsQuery();
  const { data: draftOrigins } = useGetDraftBrokerExternalLoadOriginsQuery();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [selectedMissingDocs, setSelectedMissingDocs] = useState<
    Array<Option<ExtLoadDocTypeEnum | "none">>
  >([]);
  const [selectedCarriers, setSelectedCarriers] = useState<
    Array<Option<string>>
  >([]);
  const [selectedCustomerNames, setSelectedCustomerNames] = useState<
    Array<Option<string>>
  >([]);
  const [selectedOrigins, setSelectedOrigins] = useState<Array<Option<string>>>(
    [],
  );
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState<
    Array<Option<PaymentOrderStatusEnum>>
  >([]);

  const onClearAllFilters = (): void => {
    setCurrentPage(0);
    setStartDate(null);
    setEndDate(null);
    setSelectedCarriers([]);
    setSelectedCustomerNames([]);
    setSelectedMissingDocs([]);
    setSelectedOrigins([]);
    setSelectedPaymentStatuses([]);
  };

  const onChangeDateRange = (dates: [Date | null, Date | null]): void => {
    setCurrentPage(0);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // set date range filter
  useEffect(() => {
    if (startDate && endDate) {
      searchParams.set(
        "lastStatusChangeDate",
        `${startDate.toISOString().split("T")[0]},${
          endDate.toISOString().split("T")[0]
        }`,
      );
      setSearchParams(searchParams, { replace: true });
    } else if (startDate) {
      searchParams.set(
        "lastStatusChangeDate",
        startDate.toISOString().split("T")[0],
      );
      setSearchParams(searchParams, { replace: true });
    } else {
      searchParams.delete("lastStatusChangeDate");
      setSearchParams(searchParams, { replace: true });
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedCarriers.length === 0) {
      searchParams.delete("carrier");
    } else {
      searchParams.set(
        "carrier",
        selectedCarriers.map((option) => option.value).join("-"),
      );
    }
    setSearchParams(searchParams, { replace: true });
  }, [selectedCarriers]);

  useEffect(() => {
    if (selectedCustomerNames.length === 0) {
      searchParams.delete("customer");
    } else {
      searchParams.set(
        "customer",
        selectedCustomerNames.map((option) => option.value).join("-"),
      );
    }
    setSearchParams(searchParams, { replace: true });
  }, [selectedCustomerNames]);

  useEffect(() => {
    if (selectedMissingDocs.length === 0) {
      searchParams.delete("missingDocuments");
    } else {
      searchParams.set(
        "missingDocuments",
        selectedMissingDocs.map((option) => option.value).join("-"),
      );
    }
    setSearchParams(searchParams, { replace: true });
  }, [selectedMissingDocs]);

  useEffect(() => {
    if (selectedOrigins.length === 0) {
      searchParams.delete("origin");
    } else {
      searchParams.set(
        "origin",
        selectedOrigins.map((option) => option.value).join("-"),
      );
    }
    setSearchParams(searchParams, { replace: true });
  }, [selectedOrigins]);

  useEffect(() => {
    if (selectedPaymentStatuses.length === 0) {
      searchParams.delete("paymentOrderStatus");
    } else {
      searchParams.set(
        "paymentOrderStatus",
        selectedPaymentStatuses.map((option) => option.value).join("-"),
      );
    }
    setSearchParams(searchParams, { replace: true });
  }, [selectedPaymentStatuses]);

  const getDateFilterLabel = (): string => {
    switch (searchParams.get("status")) {
      case ExternalLoadQPStatus.Pending:
        return "Date requested";
      case ExternalLoadQPStatus.Approved:
        return "Date approved";
      case ExternalLoadQPStatus.Rejected:
        return "Date rejected";
      default:
        return "Date added";
    }
  };

  return (
    <HStack w="100%" display="flex" m="5px 0" p="0 0.7rem">
      <HStack marginBottom="10px">
        <MultiSelect
          value={selectedOrigins}
          labelledBy="Select Origin"
          overrideStrings={{
            selectSomeItems: "Origin",
          }}
          onChange={setSelectedOrigins}
          hasSelectAll={false}
          options={
            searchParams.get("status") === "DRAFT"
              ? draftOrigins?.map((origin) => {
                  return { label: origin, value: origin };
                }) ?? []
              : origins?.map((origin) => {
                  return { label: origin, value: origin };
                }) ?? []
          }
        />

        <MultiSelect
          value={selectedCarriers}
          labelledBy="Select Carrier"
          overrideStrings={{
            selectSomeItems: "Carrier",
          }}
          onChange={setSelectedCarriers}
          hasSelectAll={false}
          options={
            carriers?.map((carrier) => {
              return { label: carrier.name, value: carrier.name };
            }) ?? []
          }
        />

        <MultiSelect
          value={selectedCustomerNames}
          labelledBy="Select Customer"
          overrideStrings={{
            selectSomeItems: "Customer",
          }}
          onChange={setSelectedCustomerNames}
          hasSelectAll={false}
          options={
            searchParams.get("status") === "DRAFT"
              ? (draftCustomers
                  ?.map((customer) => {
                    return { label: customer, value: customer };
                  })
                  .filter(
                    (customer) =>
                      customer.value !== "" && customer.label !== null,
                  ) as Array<Option<string>>) ?? []
              : customers?.map((customer) => {
                  return { label: customer, value: customer };
                }) ?? []
          }
        />

        <DatePicker
          placeholderText={getDateFilterLabel()}
          isClearable
          onChange={onChangeDateRange}
          peekNextMonth
          showMonthDropdown
          useShortMonthInDropdown
          showYearDropdown
          dropdownMode="scroll"
          startDate={startDate}
          endDate={endDate}
          selectsRange
        />

        <MultiSelect
          value={selectedMissingDocs}
          disableSearch
          labelledBy="Select missing documents"
          overrideStrings={{
            selectSomeItems: "Missing documents",
          }}
          onChange={(options: Array<Option<ExtLoadDocTypeEnum | "none">>) => {
            if (options.some((el) => el.value === "none")) {
              setSelectedMissingDocs([NoneOption]);
            } else {
              setSelectedMissingDocs(options);
            }
          }}
          hasSelectAll={false}
          options={MissingDocOptions}
        />

        {searchParams
          .get("status")
          ?.includes(ExternalLoadQPStatus.Approved) && (
          <MultiSelect
            value={selectedPaymentStatuses}
            disableSearch
            labelledBy="Select payment status"
            overrideStrings={{
              selectSomeItems: "Payment status",
            }}
            onChange={setSelectedPaymentStatuses}
            hasSelectAll={false}
            options={PaymentStatusOptions}
          />
        )}

        <HStack paddingInlineStart={4}>
          <Button
            size="md"
            color="mvmntBlue"
            variant="link"
            textDecorationLine="underline"
            onClick={() => onClearAllFilters()}
          >
            Clear all
          </Button>
        </HStack>
      </HStack>
    </HStack>
  );
};
