import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { authApi } from "../api/auth";
import { documentsApi } from "../api/documents";
import { brokerApi } from "../api/broker";
import { invoiceApi } from "../api/invoices";
import { userApi } from "../api/user";
import authReducer, { authSlice } from "./authSlice";
import userReducer, { userSlice } from "./userSlice";
import { mvmntApi } from "../api/mvmnt.api";
import { mvmntApiPlatform } from "../api-platform";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [authSlice.name]: authReducer,
    [userApi.reducerPath]: userApi.reducer,
    [userSlice.name]: userReducer,
    [brokerApi.reducerPath]: brokerApi.reducer,
    [invoiceApi.reducerPath]: invoiceApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [mvmntApi.reducerPath]: mvmntApi.reducer,
    [mvmntApiPlatform.reducerPath]: mvmntApiPlatform.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(userApi.middleware)
      .concat(brokerApi.middleware)
      .concat(invoiceApi.middleware)
      .concat(documentsApi.middleware)
      .concat(mvmntApi.middleware)
      .concat(mvmntApiPlatform.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
