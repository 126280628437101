import { isPossiblePhoneNumber } from "react-phone-number-input";
import { regexPasswordPolices } from "../components/RegexPolicesList/RegexPasswordPolices";
import { UserName } from "../types/User";
import { RegexPoliceProp } from "../types/Regex";

export const getUserName = (user: UserName): string => {
  const fullName = `${user.firstName ?? ""} ${user.lastName ?? ""}`;
  return fullName.replace(/\s+/g, " ").trim();
};

export const validateEmail = (email: string): true | string => {
  if (!email) return true;
  const regex =
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (regex.test(email)) {
    return true;
  } else {
    return "Please enter a valid email address.";
  }
};

export const validateUSDollarAmount = (dollarAmount: string): true | string => {
  if (!dollarAmount) return true;
  if (+dollarAmount <= 0) return "Payment amount must be greater than $0";

  const regex = /^\d+(\.\d\d)?$/;
  if (regex.test(dollarAmount)) {
    return true;
  } else {
    return "Please enter a valid dollar amount.";
  }
};

export const validateMinimumPercent = (percent: number): true | string => {
  if (!percent) return true;
  if (percent <= 0) {
    return "Must be more than 0%";
  } else {
    return true;
  }
};

export const validateMaximumPercent = (percent: number): true | string => {
  if (!percent) return true;
  if (percent > 11) {
    return "Must be less than 11%";
  } else {
    return true;
  }
};

export const validateCarrierPercent = (
  carrierMaxPercent: number,
  carrierMinPercent: number,
  carrierPercent: number,
): true | string => {
  if (!carrierPercent && !carrierMaxPercent && !carrierMinPercent) return true;
  if (
    carrierPercent >= carrierMinPercent &&
    carrierPercent <= carrierMaxPercent
  ) {
    return true;
  } else {
    return `Rate must be between ${carrierMinPercent}% and ${carrierMaxPercent}%`;
  }
};

export const validatePhoneNumber = (
  phoneNumber: string | undefined,
): true | string => {
  if (!phoneNumber) return true;
  if (isPossiblePhoneNumber(phoneNumber, "US")) {
    return true;
  } else {
    return "Phone number incorrectly formatted. Please use a US based phone number.";
  }
};

export const validateZipCode = (zipCode: string): true | string => {
  if (!zipCode) return true;
  const regex = /^[0-9]{3,5}$|^[A-Z][0-9][A-Z] ?[0-9][A-Z][0-9]$/;
  if (regex.test(zipCode)) {
    return true;
  } else {
    return "Zip code incorrectly formatted";
  }
};

export const parseDate = (date: Date): string => {
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
    weekday: "short",
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
  });
};

export const validatePassword = (password: string): RegexPoliceProp[] | [] => {
  if (password === "") {
    return [];
  }
  return regexPasswordPolices.filter((regex) =>
    regex.expression.test(password),
  );
};

export const noContent = (password: string | undefined): boolean => {
  return password === "" || password === undefined;
};
