import {
  Box,
  HStack,
  SkeletonCircle,
  SkeletonText,
  Link,
  Heading,
  Button,
  Spacer,
  Tooltip,
} from "@chakra-ui/react";
import {
  generatePath,
  Outlet,
  useParams,
  Link as RouterLink,
  useNavigate,
} from "react-router-dom";
import { Header } from "./Header";
import { StyledNavLink } from "./StyledNavLink";
import {
  useGetBrokerAccountSummaryQuery,
  useRejectBrokerAccountRequestMutation,
} from "../../../api-platform/internal";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { EmailIcon, PersonIcon } from "../../../ui/icons";
import { routes } from "../../../routes";
import { ChevronLeftIcon, CloseIcon } from "@chakra-ui/icons";
import { AccountStatusTag } from "./AccountStatusTag";
import { useNotificationBanner } from "../../../components/useNotificationBanner";
import { DEFAULT_ERROR_MESSAGE } from "../../../reuse/Constants";
import {
  useApproveBrokerAccountRequestMutation,
  useGetBrokerAccountRequestLeadQuery,
} from "../../../api-platform/brokerAccountRequestLeads";

export function AccountLayout(): JSX.Element {
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const { data, isLoading } = useGetBrokerAccountSummaryQuery(
    brokerId ? { id: brokerId } : skipToken,
  );

  const { data: requestLead } = useGetBrokerAccountRequestLeadQuery(
    brokerId && data && data.type === "BROKER_ACCOUNT_REQUEST"
      ? { id: brokerId }
      : skipToken,
  );

  const [approveLead, { isLoading: approveLoading }] =
    useApproveBrokerAccountRequestMutation();
  const [rejectBrokerAccountRequestMutation, { isLoading: isRejecting }] =
    useRejectBrokerAccountRequestMutation();

  const notificationBanner = useNotificationBanner();

  const approveAndInviteLead = async (brokerId: string): Promise<void> => {
    const response = await approveLead({
      id: brokerId,
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: DEFAULT_ERROR_MESSAGE,
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Broker approved and invitation sent.</p>,
      });
      if (!response.data.userAuthTokenSet) {
        notificationBanner({
          status: "error",
          description:
            "Invite suceeded, but user not connected to approved broker, please contact support.",
        });
      }
      navigate(
        generatePath(routes.INTERNAL_ACCOUNT_INFO, {
          brokerId: response.data.brokerId,
        }),
      );
    }
  };

  async function rejectBrokerAccountRequest(id: string): Promise<void> {
    try {
      await rejectBrokerAccountRequestMutation({ id });
      notificationBanner({
        status: "success",
        description: "Broker account request rejected.",
      });
    } catch (error) {
      notificationBanner({
        status: "error",
        description: DEFAULT_ERROR_MESSAGE,
      });
    }
  }

  return (
    <>
      <Header>
        <HStack>
          <Box>
            <Box mb="10px">
              <Link
                as={RouterLink}
                to={routes.INTERNAL_ACCOUNTS}
                fontWeight="bold"
              >
                <ChevronLeftIcon fontSize="16px" />
                Back
              </Link>
            </Box>
            {isLoading || !data ? (
              <>
                <SkeletonCircle size="10" />
                <SkeletonText mt="8px" noOfLines={2} spacing="4" mb="8px" />
              </>
            ) : (
              <>
                <Heading mb="5px">{data.name}</Heading>
                <HStack spacing="20px" mb="20px">
                  <Box display="flex" alignItems="baseline">
                    <PersonIcon color="gray.500" mr="5px" /> {data.firstName}{" "}
                    {data.lastName}
                  </Box>
                  <Box display="flex" alignItems="center">
                    <EmailIcon color="gray.500" mr="5px" /> {data.email}
                  </Box>
                  <AccountStatusTag status={data.status} />
                </HStack>
              </>
            )}
            <HStack spacing="20px">
              <StyledNavLink
                end
                to={generatePath(routes.INTERNAL_ACCOUNT_INFO, { brokerId })}
              >
                Account info
              </StyledNavLink>
              <StyledNavLink
                to={generatePath(routes.INTERNAL_ACCOUNT_FINANCES, {
                  brokerId,
                })}
              >
                Finances
              </StyledNavLink>
              <StyledNavLink
                to={generatePath(routes.INTERNAL_ACCOUNT_CARRIERS, {
                  brokerId,
                })}
              >
                Carriers
              </StyledNavLink>
            </HStack>
          </Box>
          <Spacer />
          <>
            {data && data.status === "PENDING" && (
              <Button
                variant="outline"
                colorScheme="red"
                leftIcon={<CloseIcon />}
                onClick={async () => await rejectBrokerAccountRequest(data.id)}
                isLoading={isRejecting}
              >
                Reject
              </Button>
            )}
            {data && data.status === "PENDING" && (
              <Tooltip
                hasArrow
                placement="auto"
                label={
                  !requestLead?.approvalDate
                    ? `Account info and finances forms must be complete before you can invite ${data.name}.`
                    : "Account already approved, please return to the table and refresh the page"
                }
              >
                <span>
                  <Button
                    variant="outline"
                    disabled={
                      !requestLead?.readyForApproval ||
                      requestLead.approvalDate !== null
                    }
                    colorScheme="green"
                    leftIcon={<EmailIcon alignSelf="center" />}
                    onClick={async () => await approveAndInviteLead(data.id)}
                    isLoading={approveLoading}
                  >
                    Invite to MVMNT
                  </Button>
                </span>
              </Tooltip>
            )}
          </>
        </HStack>
      </Header>
      <Box padding="20px 40px 0px 40px">
        <Outlet />
      </Box>
    </>
  );
}
