import { PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Text,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useNotificationBanner } from "../../../../../components/useNotificationBanner";
import { DEFAULT_MESSAGE_REQUIRED } from "../../../../../reuse/Constants";
import { validatePhoneNumber } from "../../../../../reuse/UserData";
import { OptionalIndicator } from "../../../../../ui/components/OptionalIndicator";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { getAddressFieldsFromGooglePlacePrediction } from "../../../../../reuse/Addressess";
import {
  IBrokerAccountRequestLead,
  IEditBrokerAccountRequestLeadInfo,
  useUpdateRequestLeadMutation,
} from "../../../../../api-platform/brokerAccountRequestLeads";

export function CreateAccount({
  brokerAccountRequest,
}: {
  brokerAccountRequest: IBrokerAccountRequestLead;
}): JSX.Element {
  const form = useForm<IEditBrokerAccountRequestLeadInfo>({
    defaultValues: {
      brokerName: brokerAccountRequest.brokerName,
      firstName: brokerAccountRequest.firstName,
      lastName: brokerAccountRequest.lastName,
      email: brokerAccountRequest.email,
      phone: brokerAccountRequest.phone,
      companyWebsite: brokerAccountRequest.companyWebsite ?? undefined,
      mcNumber: brokerAccountRequest.mcNumber ?? undefined,
      salesforceId: brokerAccountRequest.salesforceId ?? undefined,
      address: brokerAccountRequest.address ?? undefined,
      addressOne: brokerAccountRequest.addressOne ?? undefined,
      addressTwo: brokerAccountRequest.addressTwo ?? undefined,
      addressCity: brokerAccountRequest.addressCity ?? undefined,
      addressCountry: brokerAccountRequest.addressCountry ?? undefined,
      addressPostal: brokerAccountRequest.addressPostal ?? undefined,
      addressState: brokerAccountRequest.addressState ?? undefined,
      companyPhone: brokerAccountRequest.companyPhone ?? undefined,
      taxIdentificationNumber:
        brokerAccountRequest.taxIdentificationNumber ?? undefined,
    },
  });
  const [updateRequestLead, { isLoading }] = useUpdateRequestLeadMutation();
  const notificationBanner = useNotificationBanner();

  const [addressSelectedFromGoogle, setAddressSelectedFromGoogle] =
    useState<boolean>(
      brokerAccountRequest.address !== null &&
        brokerAccountRequest.address !== "",
    );

  const setAddressFromGooglePrediction = (
    prediction: google.maps.places.PlaceResult,
  ): void => {
    form.setValue("address", prediction.formatted_address ?? "");
    if (prediction.address_components) {
      const address = getAddressFieldsFromGooglePlacePrediction(
        prediction.address_components,
      );
      if (address) {
        setAddressSelectedFromGoogle(true);
        form.setValue("addressOne", address.address1);
        form.setValue("addressCity", address.city);
        form.setValue("addressState", address.state);
        form.setValue("addressCountry", address.country);
        form.setValue("addressPostal", address.postal);
      } else {
        alert("The selected address did not match expected format.");
      }
    } else {
      alert("The selected address did not match expected format.");
    }
  };

  async function onSubmit(
    data: IEditBrokerAccountRequestLeadInfo,
  ): Promise<void> {
    const response = await updateRequestLead({
      id: brokerAccountRequest.id,
      // want to make sure I'm passing null instead of empty strings
      /* eslint @typescript-eslint/prefer-nullish-coalescing: 0 */
      iEditBrokerAccountRequestLeadInfo: {
        brokerName: data.brokerName,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone: data.phone,
        companyWebsite: data.companyWebsite || null,
        mcNumber: data.mcNumber || null,
        salesforceId: data.salesforceId || null,
        address: data.address || null,
        addressOne: data.addressOne || null,
        addressTwo: data.addressTwo || null,
        addressCity: data.addressCity || null,
        addressCountry: data.addressCountry || null,
        addressPostal: data.addressPostal || null,
        addressState: data.addressState || null,
        companyPhone: data.companyPhone || null,
        taxIdentificationNumber: data.taxIdentificationNumber || null,
      },
    })
      .unwrap()
      .catch((error) => {
        notificationBanner({
          status: "error",
          description: error.data.message,
        });
      });
    if (!response) return;
    notificationBanner({
      status: "success",
      description: "Update success.",
    });
  }

  return (
    <>
      <VStack
        spacing="20px"
        maxW="640px"
        align="start"
        pb="40px"
        as="form"
        noValidate
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Heading size="md">Account administrator</Heading>
        <FormControl isRequired isInvalid={!!form.formState.errors.firstName}>
          <FormLabel requiredIndicator={<></>}>First name</FormLabel>
          <Input
            w="auto"
            {...form.register("firstName", {
              required: DEFAULT_MESSAGE_REQUIRED,
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="firstName" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!form.formState.errors?.lastName}>
          <FormLabel requiredIndicator={<></>}>Last name</FormLabel>
          <Input
            w="auto"
            {...form.register("lastName", {
              required: DEFAULT_MESSAGE_REQUIRED,
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="lastName" />
          </FormErrorMessage>
        </FormControl>
        <Box>
          <FormLabel>Email</FormLabel>
          <Text>{brokerAccountRequest.email}</Text>
        </Box>
        <FormControl isInvalid={!!form.formState.errors?.phone}>
          <FormLabel>Phone number</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.400" />
            </InputLeftElement>
            <PhoneInput
              name="phone"
              w="auto"
              placeholder="(___) - ___ - ____"
              control={form.control}
              rules={{
                validate: (value: string | undefined) => {
                  if (value) {
                    return validatePhoneNumber(`${value}`);
                  }
                },
                required: false,
              }}
              country="US"
              style={{ paddingLeft: "2.5rem" }}
              inputComponent={Input}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="phone" />
          </FormErrorMessage>
        </FormControl>
        <Divider />
        <Heading size="md">Company details</Heading>
        <FormControl isRequired isInvalid={!!form.formState.errors.brokerName}>
          <FormLabel requiredIndicator={<></>}>Company name</FormLabel>
          <Input
            w="auto"
            {...form.register("brokerName", {
              required: DEFAULT_MESSAGE_REQUIRED,
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="brokerName" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors?.companyWebsite}>
          <FormLabel optionalIndicator={<OptionalIndicator />}>
            Company website
          </FormLabel>
          <Input w="auto" {...form.register("companyWebsite")} />
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="companyWebsite"
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!form.formState.errors?.mcNumber}>
          <FormLabel requiredIndicator={<></>}>MC Number</FormLabel>
          <Input
            htmlSize={12}
            w="auto"
            {...form.register("mcNumber", {
              required: false,
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="mcNumber" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors?.salesforceId}>
          <FormLabel optionalIndicator={<OptionalIndicator />}>
            Salesforce ID
          </FormLabel>
          <Input w="auto" {...form.register("salesforceId")} />
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="salesforceId" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!form.formState.errors?.address}>
          <FormLabel requiredIndicator={<></>}>Company address</FormLabel>
          <Input
            w="auto"
            {...form.register("address", {
              required: false,
              validate: (value) => {
                return addressSelectedFromGoogle || !value
                  ? true
                  : "Must choose an address from the suggested reponses";
              },
            })}
            display="none"
          />
          <ReactGoogleAutocomplete
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onPlaceSelected={(place) => setAddressFromGooglePrediction(place)}
            options={{ types: ["address"] }}
            onChange={() => setAddressSelectedFromGoogle(false)}
            defaultValue={brokerAccountRequest.address ?? undefined}
            style={{
              borderRadius: "8px",
              padding: "0.5rem 1rem",
              border: "1px solid lightGray",
            }}
          />

          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="address" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={!!form.formState.errors?.companyPhone}>
          <FormLabel optionalIndicator={<OptionalIndicator />}>
            Phone number
          </FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.400" />
            </InputLeftElement>
            <PhoneInput
              name="companyPhone"
              w="auto"
              placeholder="(___) - ___ - ____"
              control={form.control}
              rules={{
                validate: (value: string | undefined) => {
                  if (value) {
                    return validatePhoneNumber(`${value}`);
                  }
                },
                required: false,
              }}
              country="US"
              style={{ paddingLeft: "2.5rem" }}
              inputComponent={Input}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="companyPhone" />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.taxIdentificationNumber}
        >
          <FormLabel requiredIndicator={<></>}>
            Tax Identification Number (TIN)
          </FormLabel>
          <Input
            w="auto"
            {...form.register("taxIdentificationNumber", {
              required: false,
              pattern: /^\d{9}$/,
            })}
          />
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              message={
                form.formState.errors?.taxIdentificationNumber?.type ===
                "pattern"
                  ? "Tax identification does not match expected pattern: 9 digit federal employer identification number (EIN)"
                  : form.formState.errors?.taxIdentificationNumber?.message
              }
              name="taxIdentificationNumber"
            />
          </FormErrorMessage>
        </FormControl>

        <Button layerStyle="yellow" isLoading={isLoading} type="submit">
          Save
        </Button>
      </VStack>
    </>
  );
}
