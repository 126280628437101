import { UnorderedList } from "@chakra-ui/layout";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { IconButton, ListItem } from "@chakra-ui/react";
import { DOTS, usePagination } from "./Pagination";
import "./Paginator.scss";

interface PaginatorProps {
  onPageChange: (newPage: number) => void;
  totalCount: number;
  currentPage: number;
  pageSize: number;
  siblingCount?: number;
}

export const Paginator = ({
  onPageChange,
  totalCount,
  currentPage,
  pageSize,
  siblingCount = 1,
}: PaginatorProps): JSX.Element => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange.length < 2) {
    return <></>;
  }

  const onNextPage = (): void => {
    onPageChange(currentPage + 1);
  };

  const onPreviousPage = (): void => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <UnorderedList className={"pagination-container"}>
      <ListItem className={"pagination-item"} onClick={onPreviousPage}>
        <IconButton
          h="40px"
          w="40px"
          icon={<ChevronLeftIcon />}
          aria-label={"previous page"}
          bgColor="transparent"
          onClick={onPreviousPage}
          isRound={true}
          disabled={currentPage === 0}
        />
      </ListItem>
      {paginationRange.map((pageNumber: string | number, index: number) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={`page selection list: ${index}`}
              className="pagination-item dots"
            >
              &#8230;
            </li>
          );
        }

        return (
          <ListItem
            key={`page selection list: ${index}`}
            backgroundColor={
              currentPage === Number(pageNumber) - 1 ? "black" : "transparent"
            }
            color={currentPage === Number(pageNumber) - 1 ? "white" : "black"}
            className={"pagination-item"}
            onClick={() => onPageChange(Number(pageNumber) - 1)}
          >
            {pageNumber}
          </ListItem>
        );
      })}
      <ListItem className={"pagination-item"} onClick={onNextPage}>
        <IconButton
          h="40px"
          w="40px"
          icon={<ChevronRightIcon />}
          aria-label={"next page"}
          bgColor="transparent"
          color="gray.600"
          onClick={onNextPage}
          isRound={true}
          borderRadius={60}
          px={4}
          disabled={currentPage === Number(lastPage) - 1}
        />
      </ListItem>
    </UnorderedList>
  );
};
