import { useState } from "react";
import { CloseIcon, EmailIcon } from "@chakra-ui/icons";
import { Text, HStack, Box } from "@chakra-ui/react";
import Header from "../../../components/Header";
import { toTitleCase } from "../../../reuse/Strings";
import BrokerDetailsBreadcrumbs from "./BrokerDetailsBreadcrumbs";
import { accountIcon } from "../../../resources/svg/CustomIcons";
import AdminBrokerAccountTabContainter from "../AdminBrokerAccountTabContainter";
import { useManageUserContext } from "../ManageUserContext";
import { BrokerStatus, BrokerUser } from "../../../types/Broker";
import ManageUserBtns from "../../../components/SharedTable/ManageUserBtns";
import SharedButton from "../../../components/SharedButton";
import {
  useRevokeBrokerMutation,
  useApproveUserMutation,
} from "../../../api/broker";
import useToastHook from "../../../components/useToastHook";
import { parseErrorResponse } from "../../../reuse/ErrorHandler";
import ReusableConfirmModal from "../../../components/ReusableConfirmModal";
import { appearanceApproveBtn, appearanceRevokeBtn } from "../constants";
import ReusableBadge from "../../../components/ReusableBadge";
import { getBadgeColorForBrokerStatus } from "../../../reuse/Styles";

export const AdminBrokerAccountPage = (): JSX.Element => {
  const toast = useToastHook();
  const context = useManageUserContext();
  const { selectedBroker } = context!;
  const [revokeBroker] = useRevokeBrokerMutation();
  const [approveUser] = useApproveUserMutation();
  const [approveOrRejectModal, setApproveOrRejectModal] =
    useState<boolean>(false);

  const AccountIcon = accountIcon("#7B7B7B");

  const confirmApproveOrRevoke = async (): Promise<void> => {
    const response =
      BrokerStatus.APPROVED === selectedBroker?.status
        ? await revokeBroker(selectedBroker!.id)
        : await approveUser({
            id: selectedBroker!.id,
            email: selectedBroker!.contactInformation.companyEmail,
          });
    if ("data" in response) {
      toast.success({ description: `Broker revoked successfully` });
      setTimeout(() => window.location.reload(), 500);
    } else {
      toast.error({
        description: parseErrorResponse(response.error),
      });
    }
  };

  const openApproveOrRejectModal = (): void => setApproveOrRejectModal(true);

  const renderCTAs = (
    selectedBroker: BrokerUser | undefined,
    openApproveOrRejectModal: () => void,
  ): JSX.Element => {
    if (!selectedBroker) return <></>;

    const { status } = selectedBroker;
    switch (status) {
      case BrokerStatus.PENDING:
        return (
          <ManageUserBtns
            id={selectedBroker.id}
            email={selectedBroker.contactInformation.email}
            userType={selectedBroker.userType}
          />
        );
      case BrokerStatus.APPROVED:
        return (
          <SharedButton
            title="Revoke Access"
            textTransform="none"
            textColor="red"
            border="1px solid red"
            fontSize="0.8vw"
            leftIcon={<CloseIcon fontSize="0.6vw" />}
            onClick={openApproveOrRejectModal}
          />
        );
      case BrokerStatus.REJECTED:
        return (
          <SharedButton
            title="Approve"
            textTransform="none"
            textColor="mvmntGreen"
            border="1px solid green"
            fontSize="0.8vw"
            onClick={openApproveOrRejectModal}
            size="small"
            leftIcon={<CloseIcon fontSize="10" />}
          />
        );
      default:
        return <></>;
    }
  };

  const approvedUser: boolean =
    BrokerStatus.APPROVED === selectedBroker?.status;

  return (
    <Box w="100%" pl="4rem" mt={8}>
      <BrokerDetailsBreadcrumbs />
      <HStack justifyContent="space-between" mr={8}>
        <Header
          marginY="20px"
          color="grey1"
          headingText={`${selectedBroker?.contactInformation.firstName} ${selectedBroker?.contactInformation.lastName}`}
        />
        {renderCTAs(selectedBroker, openApproveOrRejectModal)}
      </HStack>
      {selectedBroker && (
        <HStack w="100%">
          <Text color="grey3" mr="20px">
            <EmailIcon mr="5px" />
            {selectedBroker.contactInformation.email}
          </Text>
          <Text color="grey3" display={"flex"} mr="20px">
            <AccountIcon
              mr="5px"
              h="100%"
              justifySelf={"center"}
              alignSelf="center"
            />
            {toTitleCase(selectedBroker.userType)}
          </Text>
          <ReusableBadge<BrokerStatus>
            currentStatus={selectedBroker.status}
            badgeColor={getBadgeColorForBrokerStatus(selectedBroker.status)}
          />
        </HStack>
      )}
      <AdminBrokerAccountTabContainter />
      <ReusableConfirmModal
        isOpen={approveOrRejectModal}
        onClose={() => setApproveOrRejectModal(false)}
        onClickCTA={confirmApproveOrRevoke}
        title={approvedUser ? "Revoke Access" : "Approve User"}
        content={`Are you sure you want to ${
          approvedUser ? "revoke" : "approve this previously rejected account"
        } : ${selectedBroker?.contactInformation.companyName} ?`}
        cancelText="Cancel"
        confirmText={approvedUser ? "Revoke Access" : "Approve Access"}
        appearanceConfirmBtn={
          approvedUser ? appearanceRevokeBtn : appearanceApproveBtn
        }
      />
    </Box>
  );
};

export default AdminBrokerAccountPage;
