import {
  Box,
  Text,
  HStack,
  Heading,
  FormLabel,
  Divider,
} from "@chakra-ui/react";
import { IBrokerAccountRequestLead } from "../../../../../api-platform/brokerAccountRequestLeads";
import { getCurrentYear } from "../../../../../reuse/Dates";
import { parseNumberToZeroDecimalCurrency } from "../../../../../reuse/Money";

interface ViewAccountLeadFinancesProps {
  accountLead: IBrokerAccountRequestLead;
}

export function ViewAccountLeadFinances({
  accountLead,
}: ViewAccountLeadFinancesProps): JSX.Element {
  return (
    <>
      <Box>
        <FormLabel>Quickpay Limit</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(accountLead.creditLimit ?? 0),
          )}
        </Text>
      </Box>
      <Divider />
      <Heading size="sm">Total Revenue (Last 3 years)</Heading>
      <HStack spacing="96px">
        <Box>
          <FormLabel>{getCurrentYear() - 1}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(accountLead.yearOneRevenue ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 2}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(accountLead.yearTwoRevenue ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 3}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(accountLead.yearThreeRevenue ?? 0),
            )}
          </Text>
        </Box>
      </HStack>
      <Divider />
      <Heading size="sm">Cash flow (Last 3 years)</Heading>
      <Heading as="h5" size="xs">
        EBITDA (Last 3 years)
      </Heading>
      <HStack spacing="96px">
        <Box>
          <FormLabel>{getCurrentYear() - 1}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(accountLead.yearOneEbitda ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 2}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(accountLead.yearTwoEbitda ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 3}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(accountLead.yearThreeEbitda ?? 0),
            )}
          </Text>
        </Box>
      </HStack>
      <Box>
        <FormLabel>Last Full Year Net Income</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(accountLead.lastYearNetIncome ?? 0),
          )}
        </Text>
      </Box>
      <Box>
        <FormLabel>Last Full Year Interest Expense</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(accountLead.lastYearInterestExpense ?? 0),
          )}
        </Text>
      </Box>
      <Box>
        <FormLabel>Unrestricted Cash And Equivalents</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(accountLead.unrestrictedCash ?? 0),
          )}
        </Text>
      </Box>
      <Divider />
      <Heading size="sm">Financing</Heading>
      <Box>
        <FormLabel>Total Assets</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(accountLead.totalAssets ?? 0),
          )}
        </Text>
      </Box>
      <Box>
        <FormLabel>Total Debt</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(Number(accountLead.totalDebt ?? 0))}
        </Text>
      </Box>
      <Box>
        <FormLabel>Equity Capital</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(accountLead.equityCapital ?? 0),
          )}
        </Text>
      </Box>
      <Divider />
      <Box>
        <FormLabel>
          Felony charges against any officers in the last 12 months?
        </FormLabel>
        <Text>{accountLead.lastYearFelonyCharges ? "Yes" : "No"}</Text>
      </Box>
      <Box>
        <FormLabel>
          Has the company filed for bankruptcy in the last 12 months?
        </FormLabel>
        <Text>{accountLead.lastYearBankruptcy ? "Yes" : "No"}</Text>
      </Box>
      <Box>
        <FormLabel>Any unsatisfied judgements?</FormLabel>
        <Text>{accountLead.unsatisfiedJudgements ? "Yes" : "No"}</Text>
      </Box>
      <Box>
        <FormLabel>Any tax liens?</FormLabel>
        <Text>{accountLead.taxLiens ? "Yes" : "No"}</Text>
      </Box>
    </>
  );
}
