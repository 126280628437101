import { filenameToContentType } from "@aws-amplify/core";
import { SearchIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Divider,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Tooltip,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { IExternalLoad } from "../../api-platform/externalLoads";
import { DefaultModalProps } from "../../types/DefaultModal";
import { FileUploadButton } from "../../ui/components/FileUploadButton";
import { CloseIcon } from "../../ui/icons/CloseIcon";
import axios from "axios";
import { FileUploadProgress } from "../../ui/components/FileUploadProgress";
import { SuccessIcon, ErrorIcon } from "../../ui/icons";
import { QPUploadListLoad } from "./QPUploadListLoad";
import {
  ExtLoadDocTypeEnum,
  useGeneratePutUrlExtLoadDraftDocumentMutation,
  useGetExtLoadDraftDocumentsQuery,
  useUpdateExtLoadDocumentTypeMutation,
} from "../../api-platform/documents";
import { Document } from "./Document";
import Dropzone from "react-dropzone";
import { bgColors } from "../useNotificationBanner/useNotificationBanner";
import { IExtLoadDraft } from "../../api-platform/externalLoadsDraft";

const headerHeight = "70px";

interface ReusableUploadExternalLoadsModalProps extends DefaultModalProps {
  externalLoads: IExternalLoad[] | IExtLoadDraft[];
}

export const UploadQuickpayDocsModal = ({
  isOpen,
  onClose,
  externalLoads,
}: ReusableUploadExternalLoadsModalProps): JSX.Element => {
  const toast = useToast();
  const [selectedLoad, setSelectedLoad] = useState<
    IExternalLoad | IExtLoadDraft
  >(externalLoads[0]);
  const [uploadExtLoadDocument] =
    useGeneratePutUrlExtLoadDraftDocumentMutation();
  const { data: extLoadDocuments, isLoading } =
    useGetExtLoadDraftDocumentsQuery({
      externalLoadDraftId: selectedLoad.id,
    });
  const [updateDocumentType] = useUpdateExtLoadDocumentTypeMutation();

  const [uploadingFilesProgress, setUploadingFilesProgress] = useState<
    Array<number | undefined>
  >([]);

  // TODO: Thoughts on moving all file uploads to some wrapper component and only using the
  // beforeunload event listener there?
  const alertUser = (e: BeforeUnloadEvent): void => {
    e.preventDefault();
    // String does not get used, but value must be set
    e.returnValue = "Leaving now may cause file uploads to fail.";
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  async function uploadDocuments(files: File[] | null): Promise<void> {
    window.addEventListener("beforeunload", alertUser);
    if (!files) return;
    setUploadingFilesProgress(Array.from(Array(files.length)));
    const errors: string[] = [];
    await Promise.all(
      files.map(async (file, i) => {
        try {
          const uploadUrl = await uploadExtLoadDocument({
            extLoadDocumentUpload: {
              contentType: filenameToContentType(file.name),
              fileName: file.name,
            },
            id: selectedLoad.id,
          }).unwrap();
          await axios.put(uploadUrl, file, {
            onUploadProgress: (progressEvent) => {
              const progress =
                (progressEvent.loaded / progressEvent.total) * 100;
              setUploadingFilesProgress((current) =>
                current.map((fileProgress, index) => {
                  if (index === i) {
                    return progress;
                  }
                  return fileProgress;
                }),
              );
            },
          });
        } catch (err) {
          errors.push(`${file.name} failed to upload properly.`);
        }
      }),
    );
    if (errors.length) {
      toast({
        duration: null,
        isClosable: true,
        position: "top",
        containerStyle: {
          width: "100%",
          maxWidth: "none",
          margin: 0,
          textAlign: "center",
        },
        render: (props) => {
          return (
            <Box color="white" p={3} bg={bgColors.error}>
              <h4 style={{ marginBottom: "10px" }}>
                Some files failed to upload
              </h4>
              <VStack>
                {errors.map((e) => (
                  <p key={e}>{e}</p>
                ))}
              </VStack>
              <CloseIcon
                onClick={props.onClose} // eslint-disable-line react/prop-types
                position="absolute"
                right="20px"
                top={3}
                marginTop="3px"
                cursor="pointer"
              />
            </Box>
          );
        },
        status: "error",
      });
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="5xl" isCentered>
      <ModalOverlay backdropFilter="auto" backdropBlur="1px" />
      <ModalContent width="76%" height="2xl">
        <ModalHeader
          display="flex"
          justifyContent="space-between"
          justifySelf="flex-start"
          fontSize="20px"
          h={headerHeight}
          alignItems="center"
        >
          <Text>Attach Documents</Text>
          <IconButton
            icon={<CloseIcon />}
            bgColor="transparent"
            aria-label={""}
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody h={`calc(100% - ${headerHeight})`}>
          <HStack align={"-moz-initial"} justify={"center"} h="100%">
            <VStack width="360px" alignItems="left">
              <Text fontSize="16px" fontWeight="bold">
                Loads ({externalLoads.length})
              </Text>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  placeholder="Search loads (coming soon)"
                  type="Loads"
                  disabled
                />
              </InputGroup>
              <Box overflowY="auto">
                {externalLoads.map((externalLoad) => {
                  return (
                    <QPUploadListLoad
                      key={`upload files for load list: ${externalLoad.id}`}
                      externalLoad={externalLoad}
                      selectedLoad={selectedLoad}
                      setSelectedLoad={setSelectedLoad}
                    />
                  );
                })}
              </Box>
            </VStack>
            <Divider orientation="vertical" size="2xl" />
            <VStack width="600px" alignItems="left">
              <Text fontSize="16px" fontWeight="bold">
                Required for MVMNT QuickPay
              </Text>
              {extLoadDocuments?.some(
                (doc) => doc.documentType === "Invoice",
              ) ? (
                <HStack>
                  <SuccessIcon />
                  <Text color="green" fontSize="13px">
                    Carrier invoice uploaded
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <ErrorIcon />
                  <Text color="red" fontSize="13px">
                    Carrier invoice missing
                  </Text>
                </HStack>
              )}
              {extLoadDocuments?.some(
                (doc) => doc.documentType === "RateCon",
              ) ? (
                <HStack>
                  <SuccessIcon />
                  <Text color="green" fontSize="13px">
                    Rate confirmation uploaded
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <ErrorIcon />
                  <Text color="red" fontSize="13px">
                    Rate confirmation missing
                  </Text>
                </HStack>
              )}
              {extLoadDocuments?.some(
                (doc) => doc.documentType === "BillOfLading",
              ) ? (
                <HStack>
                  <SuccessIcon />
                  <Text color="green" fontSize="13px">
                    Signed Bill of Lading uploaded
                  </Text>
                </HStack>
              ) : (
                <HStack>
                  <ErrorIcon />
                  <Text color="red" fontSize="13px">
                    Signed Bill of Lading missing
                  </Text>
                </HStack>
              )}
              <br></br>
              {(Boolean(selectedLoad.signedBOL) ||
                Boolean(selectedLoad.PODRequired)) && (
                <Text fontSize="16px" fontWeight="bold">
                  Suggested based on your upload
                  <Tooltip
                    placement="top"
                    hasArrow
                    label="You indicated that these documents are required for customer invoicing. However, you can still request QuickPay."
                  >
                    <InfoIcon w="15px" h="15px" margin="0px 5px" />
                  </Tooltip>
                </Text>
              )}
              {Boolean(selectedLoad.PODRequired) && (
                <VStack alignItems="left">
                  <FormLabel marginRight={"0"} marginBottom={"0"}>
                    PROOF OF DELIVERY (POD)
                  </FormLabel>
                  <HStack>
                    <Checkbox
                      size="md"
                      backgroundColor={"realWhite"}
                      isChecked={extLoadDocuments?.some(
                        (doc) => doc.documentType === "ProofOfDelivery",
                      )}
                    />
                    <Text fontSize="15px" color="gray.500">
                      Document has been collected
                    </Text>
                  </HStack>
                </VStack>
              )}
              <Divider orientation="horizontal" />
              <Dropzone
                onDrop={async (files: File[] | null) => {
                  await uploadDocuments(files);
                  window.removeEventListener("beforeunload", alertUser);
                }}
                noClick={true}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    h="80px"
                    border="1px dashed gray"
                    display="grid"
                    placeContent="center"
                    borderRadius="5px"
                    minH="80px"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <HStack>
                      <Text fontSize="13px" fontStyle={"bold"}>
                        Drag your files here or
                      </Text>
                      <VStack>
                        <FileUploadButton
                          w="83px"
                          h="32px"
                          icon={<></>}
                          layerStyle="black"
                          textAlign={"center"}
                          accept={".jpg, .jpeg, .png, .pdf"}
                          multiple
                          onChange={async (files: File[] | null) => {
                            await uploadDocuments(files);
                            window.removeEventListener(
                              "beforeunload",
                              alertUser,
                            );
                          }}
                          render={(files, { clearFileInput }) => {
                            const elements = files.map((file, i) => (
                              <VStack
                                hidden={uploadingFilesProgress[i] === 100}
                                key={`file upload progress bar ${i}`}
                              >
                                <FileUploadProgress
                                  value={uploadingFilesProgress[i]}
                                  fileName={file.name}
                                  onCancel={clearFileInput}
                                  onDelete={clearFileInput}
                                  disabled={true}
                                />
                              </VStack>
                            ));
                            return <>{elements}</>;
                          }}
                        >
                          Browse
                        </FileUploadButton>
                      </VStack>
                    </HStack>
                  </Box>
                )}
              </Dropzone>
              {!isLoading && extLoadDocuments && extLoadDocuments.length > 0 && (
                <Text fontSize="16px" fontWeight="bold" pt={6}>
                  Documents Uploaded
                </Text>
              )}
              <Box overflowY="auto" pt={4} px={1}>
                {!isLoading &&
                  extLoadDocuments?.map((doc) => (
                    <Document
                      key={`ext load doc: ${doc.id}`}
                      doc={doc}
                      onChangeDocType={(event) =>
                        updateDocumentType({
                          id: doc.id,
                          body: {
                            documentType: event.currentTarget
                              .value as ExtLoadDocTypeEnum,
                          },
                        })
                      }
                    />
                  ))}
              </Box>
            </VStack>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadQuickpayDocsModal;
