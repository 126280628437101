import {
  DeleteExtLoadDocumentApiArg,
  DeleteExtLoadDocumentApiResponse,
  DeleteExtLoadDraftDocumentApiResponse,
  DeleteExtLoadDraftDocumentApiArg,
  mvmntApiPlatform,
} from "./generated";
export * from "./generated";

// This overrides an existing endpoint to allow it to invalidate a
// tag with an id that the endpoint doensn't otherwise have access to
const injectedApis = mvmntApiPlatform.injectEndpoints({
  endpoints: (build) => ({
    deleteExtLoadDocument: build.mutation<
      DeleteExtLoadDocumentApiResponse,
      DeleteExtLoadDocumentApiArg & { externalLoadId: string }
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_response, _errors, arg) => [
        { type: "ExtLoadDocuments", id: arg.externalLoadId },
      ],
    }),
    deleteExtLoadDraftDocument: build.mutation<
      DeleteExtLoadDraftDocumentApiResponse,
      DeleteExtLoadDraftDocumentApiArg & { externalLoadDraftId: string }
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load-draft/${queryArg.id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_response, _errors, arg) => [
        { type: "ExtLoadDocuments", id: arg.externalLoadDraftId },
      ],
    }),
  }),
  overrideExisting: true,
});

mvmntApiPlatform.enhanceEndpoints({
  endpoints: {
    getExtLoadDocuments: {
      providesTags: (_response, _errors, arg) => [
        { type: "ExtLoadDocuments", id: arg.externalLoadId },
      ],
    },
    getExtLoadDraftDocuments: {
      providesTags: (_response, _errors, arg) => [
        { type: "ExternalLoadsDraftDocuments", id: arg.externalLoadDraftId },
      ],
    },
    generatePutUrlExtLoadDocument: {
      invalidatesTags: (_response, _errors, arg) => [
        { type: "ExtLoadDocuments", id: arg.id },
      ],
    },
    generatePutUrlExtLoadDraftDocument: {
      invalidatesTags: (_response, _errors, arg) => [
        { type: "ExternalLoadsDraftDocuments", id: arg.id },
      ],
    },
    updateExtLoadDocumentType: {
      invalidatesTags: (response) => [
        { type: "ExtLoadDocuments", id: response?.externalLoadId },
        { type: "ExternalLoadsDraftDocuments", id: response?.externalDraftId },
        { type: "ExternalLoadsDraft", id: response?.externalDraftId },
      ],
    },
    deleteExtLoadDraftDocument: {
      invalidatesTags: ["ExternalLoadsDraftDocuments"],
    },
  },
});

export const {
  useDeleteExtLoadDocumentMutation,
  useDeleteExtLoadDraftDocumentMutation,
} = injectedApis;
