import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useGetCarriersForBrokerAccountQuery } from "../../../../api-platform/internal";
import { CarriersTable } from "./CarriersTable";

export const Carriers = (): JSX.Element => {
  const { brokerId } = useParams();

  const [currentPage, setCurrentPage] = useState<number>(0);

  const DEFAULT_PAGE_LIMIT = 25;
  const { data: brokerCarriers } = useGetCarriersForBrokerAccountQuery(
    brokerId
      ? { brokerId, page: currentPage, limit: DEFAULT_PAGE_LIMIT }
      : skipToken,
  );

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  return (
    <>
      {brokerCarriers && (
        <CarriersTable
          carriers={brokerCarriers}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      )}
    </>
  );
};
