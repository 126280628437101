import { EmailIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Divider,
  Button,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validateEmail } from "../../reuse/UserData";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { useUpdatePasswordMutation, UserUpdatePassword } from "../../api/auth";
import { routes } from "../../routes";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { useUsername } from "./components/Layout";
import { PasswordInput } from "./components/PasswordInput";
import { useEffect } from "react";

export function UpdatePassword(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();
  const { username } = useUsername();
  const navigate = useNavigate();
  const notificationBanner = useNotificationBanner();
  const form = useForm<UserUpdatePassword>({
    defaultValues: {
      username,
    },
  });

  async function onSubmit(data: UserUpdatePassword): Promise<void> {
    await updatePassword(data)
      .unwrap()
      .then(() => {
        notificationBanner({
          status: "success",
          description: "Password set successfully.",
        });
        navigate(routes.LOGIN);
      })
      .catch((error) => {
        notificationBanner({
          status: "error",
          description: error.message,
        });
      });
  }

  useEffect(() => {
    if (
      searchParams.get("user") !== null &&
      searchParams.get("temp") !== null
    ) {
      form.setValue("username", searchParams.get("user")!);
      form.setValue("tempPassword", searchParams.get("temp")!);
    }
  }, []);

  return (
    <FormProvider {...form}>
      <VStack
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Heading fontSize="24px">Choose a new password</Heading>
        <Text>
          Welcome to MVMNT! Since it&rsquo;s your first time logging in, please
          choose a new password.
        </Text>
        {!username && (
          <FormControl isRequired isInvalid={!!form.formState.errors?.username}>
            <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="gray.400" />
              </InputLeftElement>
              <Input
                {...form.register("username", {
                  validate: validateEmail,
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="username" />
            </FormErrorMessage>
          </FormControl>
        )}
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.tempPassword}
        >
          <FormLabel requiredIndicator={<></>}>Temporary password</FormLabel>
          <InputGroup>
            <Input
              type="password"
              {...form.register("tempPassword", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="tempPassword" />
          </FormErrorMessage>
        </FormControl>
        <PasswordInput />
        <Divider />
        <Button
          isLoading={isLoading}
          type="submit"
          size="lg"
          layerStyle="yellow"
        >
          Set new password
        </Button>
      </VStack>
    </FormProvider>
  );
}
