interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  country: string;
  postal: string;
}
export const getAddressFieldsFromGooglePlacePrediction = (
  addressComponents: google.maps.GeocoderAddressComponent[],
): IAddress | false => {
  const streetNumber = addressComponents.find((cmp) =>
    cmp.types.includes("street_number"),
  );
  const streetName = addressComponents.find((cmp) =>
    cmp.types.includes("route"),
  );
  const city = addressComponents.find((cmp) => cmp.types.includes("locality"));
  const state = addressComponents.find((cmp) =>
    cmp.types.includes("administrative_area_level_1"),
  );
  const country = addressComponents.find((cmp) =>
    cmp.types.includes("country"),
  );
  const postal = addressComponents.find((cmp) =>
    cmp.types.includes("postal_code"),
  );

  if (streetNumber && streetName && city && state && country && postal) {
    return {
      address1: `${streetNumber.short_name} ${streetName.short_name}`,
      city: city.short_name,
      state: state.short_name,
      country: country.short_name,
      postal: postal.short_name,
    };
  }
  return false;
};
