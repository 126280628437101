import { mvmntApiPlatform } from "./generated";
export * from "./generated";

mvmntApiPlatform.enhanceEndpoints({
  addTagTypes: ["QuickPays"],
  endpoints: {
    rejectQuickpays: {
      invalidatesTags: ["QuickPays"],
    },
    approveQuickpays: { invalidatesTags: ["QuickPays"] },
    getQuickPayCountsByStatus: {
      providesTags: ["QuickPays", "ExternalLoads", "ExternalLoadsDraft"],
    },
    getQuickPayRequestsView: {
      providesTags: ["QuickPays"],
    },
    submitQuickpayForAnExternalLoad: {
      invalidatesTags: ["QuickPays", "ExternalLoads"],
    },
    submitQuickpayNote: {
      invalidatesTags: ["QuickPays"],
    },
  },
});
