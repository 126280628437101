import { mvmntApiPlatform as api } from "./api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllBrokersByStatus: build.query<
      GetAllBrokersByStatusApiResponse,
      GetAllBrokersByStatusApiArg
    >({
      query: (queryArg) => ({ url: `/brokers/${queryArg.status}` }),
    }),
    getAllBrokerUsers: build.query<
      GetAllBrokerUsersApiResponse,
      GetAllBrokerUsersApiArg
    >({
      query: () => ({ url: `/brokers` }),
    }),
    testUserExists: build.query<
      TestUserExistsApiResponse,
      TestUserExistsApiArg
    >({
      query: () => ({ url: `/health/test-user-exists` }),
    }),
    fetchUser: build.query<FetchUserApiResponse, FetchUserApiArg>({
      query: () => ({ url: `/user` }),
    }),
    confirmUser: build.mutation<ConfirmUserApiResponse, ConfirmUserApiArg>({
      query: (queryArg) => ({
        url: `/user/confirmations`,
        method: "POST",
        body: queryArg.iConfirm,
      }),
    }),
    updateUserMeta: build.mutation<
      UpdateUserMetaApiResponse,
      UpdateUserMetaApiArg
    >({
      query: () => ({ url: `/user/meta`, method: "PUT" }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type GetAllBrokersByStatusApiResponse =
  /** status 200 Returned */ IBroker[];
export type GetAllBrokersByStatusApiArg = {
  status: BrokerStatus;
};
export type GetAllBrokerUsersApiResponse = /** status 200 Returned */ IBroker[];
export type GetAllBrokerUsersApiArg = void;
export type TestUserExistsApiResponse = unknown;
export type TestUserExistsApiArg = void;
export type FetchUserApiResponse = /** status 200 Returned */ {
  broker?: IBroker;
  user: IUser;
};
export type FetchUserApiArg = void;
export type ConfirmUserApiResponse = unknown;
export type ConfirmUserApiArg = {
  /** confirm interface */
  iConfirm: IConfirm;
};
export type UpdateUserMetaApiResponse = unknown;
export type UpdateUserMetaApiArg = void;
export type IBrokerAdminUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
};
export type IBroker = {
  id: string;
  name: string;
  address: string;
  addressCity: string;
  addressCountry: string;
  addressOne: string;
  addressPostal: string;
  addressState: string;
  addressTwo: string;
  adminUserId: string | null;
  counterpartyId: string | null;
  createDate: string;
  ledgerAccountId: string | null;
  mcNumber: string;
  phone: string;
  carrierQpPercentageFeeMax: number;
  carrierQpPercentageFeeMin: number;
  brokerQpPercentageFee: number;
  quickpayEnabled: boolean;
  salesforceId: string | null;
  updateDate: string;
  virtualAccountId: string | null;
  website: string | null;
  alloyApplicationToken: string | null;
  adminUser?: IBrokerAdminUser;
  svbSubAccount: string | null;
};
export type BrokerStatus = "PENDING" | "APPROVED" | "REJECTED" | "REVOKED";
export type UserType = "BROKER" | "ADMIN";
export type IUser = {
  id?: string;
  firstName: string;
  lastName: string;
  email: string;
  authToken: string;
  type: UserType;
};
export type IConfirm = {
  email: string;
};
export const {
  useGetAllBrokersByStatusQuery,
  useLazyGetAllBrokersByStatusQuery,
  useGetAllBrokerUsersQuery,
  useLazyGetAllBrokerUsersQuery,
  useTestUserExistsQuery,
  useLazyTestUserExistsQuery,
  useFetchUserQuery,
  useLazyFetchUserQuery,
  useConfirmUserMutation,
  useUpdateUserMetaMutation,
} = injectedRtkApi;
