import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  HStack,
  Divider,
  Textarea,
} from "@chakra-ui/react";
import { useState } from "react";
import mvmntTheme from "../../styles/theme";
import { DefaultModalProps } from "../../types/DefaultModal";
import SharedButton from "../SharedButton";

interface FormNoteModalProps extends DefaultModalProps {
  title: string;
  subtitle?: string;
  content?: string;
  onClickCTA: (n: string) => void;
  cancelText: string;
  confirmText: string;
  layerConfirmButton: string;
  loading?: boolean;
}

export const FormNoteModal = ({
  isOpen,
  onClose,
  onClickCTA,
  title,
  subtitle,
  content,
  cancelText,
  confirmText,
  layerConfirmButton,
  loading,
}: FormNoteModalProps): JSX.Element => {
  const [note, setNote] = useState<string>(content ?? "");

  const onSubmit = (): void => onClickCTA(note);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <Divider color={"#C0C0C0"} />
        <ModalBody>
          {subtitle && (
            <Text
              variant="Subtitle"
              fontSize="14px"
              mb="2rem"
              color={mvmntTheme.colors.gray90}
              fontWeight={500}
            >
              {subtitle}
            </Text>
          )}
          <Text
            variant="Title"
            fontSize="11px"
            mb="0.4rem"
            color={mvmntTheme.colors.gray60}
            fontWeight={600}
          >
            NOTES
          </Text>
          <Textarea
            border="1px solid red"
            w="100%"
            h="100px"
            placeholder="Add notes here..."
            resize="none"
            maxLength={250}
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
        </ModalBody>
        <Divider color={"#C0C0C0"} />
        <ModalFooter>
          <HStack py="0.5rem">
            <SharedButton
              title={cancelText}
              textTransform="none"
              textColor="#C0C0C0"
              border="1px solid gray"
              fontSize="15px"
              px="1.5rem"
              h="35px"
              isDisabled={loading}
              onClick={onClose}
            />
            <SharedButton
              title={confirmText}
              textTransform="none"
              fontSize="15px"
              px="1.5rem"
              h="35px"
              layerStyle={layerConfirmButton}
              isDisabled={loading}
              onClick={onSubmit}
            />
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FormNoteModal;
