import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

export function Header(props: {
  children?: ReactNode;
  heading: ReactNode;
  breadcrumb?: { to: string; title: ReactNode };
}): JSX.Element {
  return (
    <Box
      bgColor="grey6"
      padding="22px 40px 20px 40px"
      fontFamily="Montserrat Regular"
      fontSize="13px"
      borderRadius="4px"
    >
      <Box height="20px" mb="15px">
        {props.breadcrumb && (
          <Breadcrumb color="gray.500" fontWeight="bold">
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to="">
                Admin
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink
                as={Link}
                to={props.breadcrumb.to}
                color="gray.700"
              >
                {props.breadcrumb.title}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
      </Box>
      <Heading variant="montserrat" marginBottom="10px">
        {props.heading}
      </Heading>
      {props.children}
    </Box>
  );
}
