import { InfoIcon } from "@chakra-ui/icons";
import { HStack, VStack, Text, StackProps } from "@chakra-ui/layout";
import { useParams } from "react-router-dom";
import { useGetBrokerCreditInfoQuery } from "../../api-platform/internal";
import { GetPaymentsHistoryApiResponse } from "../../api-platform/quickpay";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface QPBalancesProps {
  data: GetPaymentsHistoryApiResponse | undefined;
  layerStyle?: "default" | "sidebar";
}

export const QPBalances = ({
  data,
  layerStyle = "default",
}: QPBalancesProps): JSX.Element => {
  const { brokerId } = useParams();

  const { data: brokerFinancials } = useGetBrokerCreditInfoQuery(
    brokerId ? { id: brokerId } : skipToken,
  );

  const getMainContainerStyles = (): StackProps =>
    layerStyle === "default"
      ? {
          spacing: 4,
        }
      : {
          spacing: 6,
          justifyContent: "space-between",
          mb: 8,
        };

  const getAmtToPayStyles = (): StackProps =>
    layerStyle === "default"
      ? {
          mr: 6,
          alignItems: "start",
        }
      : {
          p: "6px 85px 6px 10px",
          alignItems: "start",
          border: "1px solid lightgray",
          borderRadius: "4px",
        };

  const getAmtAvailableStyles = (): StackProps =>
    layerStyle === "default"
      ? {
          alignItems: "start",
        }
      : {
          p: "6px 60px 6px 10px",
          alignItems: "start",
          border: "1px solid lightgray",
          borderRadius: "4px",
        };

  return (
    <HStack {...getMainContainerStyles()}>
      <VStack {...getAmtToPayStyles()}>
        <Text fontSize="20px" fontWeight="bold">
          $
          {data?.reduce((accum, payment) => {
            accum += payment.direction === "debit" ? payment.amount : 0;
            return accum;
          }, 0)}
        </Text>
        <Text fontSize="11px" color="gray.700">
          Amount to be paid
        </Text>
      </VStack>
      <VStack {...getAmtAvailableStyles()}>
        <Text
          fontSize="20px"
          fontWeight="bold"
          color={layerStyle === "default" ? "successMedium" : ""}
        >
          $
          {data && data.length > 0 && data[0].available > 0
            ? data[0].available
            : brokerFinancials?.creditLimit}
        </Text>
        <HStack>
          <Text fontSize="11px" color="gray.700">
            Available for QuickPay
          </Text>
          <InfoIcon color="gray" boxSize={3} />
        </HStack>
      </VStack>
    </HStack>
  );
};
