import { VStack, HStack, Text } from "@chakra-ui/layout";
import { CloseIcon, CheckIcon } from "@chakra-ui/icons";
import { Box, Button, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { QuickPayTableContext } from "./QuickPayTableContext";
import { getAllIds, getAmountTotalSelected } from "./reuse";
import mvmntTheme from "../../styles/theme";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { FormNoteModal } from "../../components/FormNoteModal";
import {
  useRejectQuickpaysMutation,
  useApproveQuickpaysMutation,
} from "../../api-platform/quickpay";
import { bgColors } from "../../components/useNotificationBanner/useNotificationBanner";
import { parseNumberToTwoDecimalCurrency } from "../../reuse/Money";

interface HandleSelectionProps {
  title: string;
  isDisabled: boolean;
  onClick: () => void;
}

export const TableSelection = (): JSX.Element => {
  const context = useContext(QuickPayTableContext);
  const notificationBanner = useNotificationBanner();
  const toast = useToast();
  const { quickpays, selectedQuickPayIds, setSelectedQuickPayIds } = context;
  const [rejectQuickPay, { isLoading: isSubmiting }] =
    useRejectQuickpaysMutation();
  const [approveQuickpay, { isLoading: approvalLoading }] =
    useApproveQuickpaysMutation();

  const [modalRejection, setModalRejection] = useState<boolean>(false);

  const clearAll = (): void => setSelectedQuickPayIds([]);
  const selectAll = (): void =>
    setSelectedQuickPayIds(getAllIds(quickpays ?? []));
  const total = getAmountTotalSelected(quickpays ?? [], selectedQuickPayIds);

  const rejectSelected = async (note: string): Promise<void> => {
    const comment = note !== "" ? note : undefined;
    const response = await rejectQuickPay({
      body: {
        ids: selectedQuickPayIds,
        comment,
      },
    });
    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem requesting QuickPays. Please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Request success.</p>,
      });
      setSelectedQuickPayIds([]);
    }
    setModalRejection(false);
  };

  const approveSelected = async (): Promise<void> => {
    const response = await approveQuickpay({
      body: { ids: selectedQuickPayIds },
    });
    if ("data" in response) {
      if (response.data.failures.length) {
        toast({
          duration: null,
          isClosable: true,
          position: "top",
          containerStyle: {
            width: "100%",
            maxWidth: "none",
            margin: 0,
            textAlign: "center",
          },
          render: (props) => {
            return (
              <Box color="white" p={3} bg={bgColors.error}>
                <h4 style={{ marginBottom: "10px" }}>
                  Failed to approve all QuickPays
                </h4>
                <VStack>
                  {response.data.failures.map((e) => (
                    <p key={e}>{e}</p>
                  ))}
                </VStack>
                <CloseIcon
                  onClick={props.onClose} // eslint-disable-line react/prop-types
                  position="absolute"
                  right="20px"
                  top={3}
                  marginTop="3px"
                  cursor="pointer"
                />
              </Box>
            );
          },
          status: "error",
        });
      }
      if (response.data.successes.length) {
        notificationBanner({
          status: "success",
          description: (
            <p>{response.data.successes.length} QuickPay(s) approved.</p>
          ),
        });
      }
      if (response.data.failedEmails.length) {
        toast({
          duration: null,
          isClosable: true,
          position: "top",
          containerStyle: {
            width: "100%",
            maxWidth: "none",
            margin: 0,
            textAlign: "center",
          },
          render: (props) => {
            return (
              <Box color="white" p={3} bg={bgColors.error}>
                <h4 style={{ marginBottom: "10px" }}>
                  Some QuickPay(s) were successfully approved, but email
                  invoices failed to send.
                </h4>
                <VStack>
                  {response.data.failedEmails.map((e) => (
                    <p key={e}>{e}</p>
                  ))}
                </VStack>
                <CloseIcon
                  onClick={props.onClose} // eslint-disable-line react/prop-types
                  position="absolute"
                  right="20px"
                  top={3}
                  marginTop="3px"
                  cursor="pointer"
                />
              </Box>
            );
          },
          status: "error",
        });
      }
      setSelectedQuickPayIds([]);
    } else {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem approving QuickPays. Please try again.</p>
        ),
      });
    }
  };

  const HandleSelection = ({
    title,
    isDisabled,
    onClick,
  }: HandleSelectionProps): JSX.Element => (
    <Button
      color={mvmntTheme.colors.mvmntBlue}
      textDecoration="underline"
      padding="0 0.2rem"
      border="none"
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </Button>
  );

  return (
    <VStack
      color="white"
      height="100px"
      width="100%"
      display="grid"
      placeContent="center"
      padding="70px 0"
      position="fixed"
      bottom="0"
      overflowX="scroll"
    >
      <HStack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="960px"
        height="80px"
        background={mvmntTheme.colors.grey6}
        border={`1px solid ${mvmntTheme.colors.grey5}`}
        boxShadow="4px 4px 8px rgba(35, 35, 35, 0.1)"
        borderRadius="4px"
        px="1rem"
      >
        <HStack>
          <Text padding="0 1rem 0 0" color="black" fontWeight="900">
            {selectedQuickPayIds.length} Selected Rows
          </Text>
          <HandleSelection
            title="Clear"
            isDisabled={selectedQuickPayIds.length === 0}
            onClick={clearAll}
          />
          <HandleSelection
            title="Select all"
            isDisabled={quickpays?.length === selectedQuickPayIds.length}
            onClick={selectAll}
          />
        </HStack>
        <HStack>
          <HStack padding="0 1rem 0 0" color="black">
            <Text>Total:</Text>
            <Text fontWeight="900">
              {parseNumberToTwoDecimalCurrency(total)}
            </Text>
          </HStack>
          <Button
            color={mvmntTheme.colors.mvmntRed}
            borderColor={mvmntTheme.colors.mvmntRed}
            borderWidth="1.5px"
            isDisabled={selectedQuickPayIds.length === 0 || approvalLoading}
            onClick={() => setModalRejection(true)}
            leftIcon={<CloseIcon w={3} h={3} />}
          >
            Reject
          </Button>
          <Button
            color={mvmntTheme.colors.mvmntGreen}
            borderColor={mvmntTheme.colors.mvmntGreen}
            borderWidth="1.5px"
            isDisabled={selectedQuickPayIds.length === 0 || approvalLoading}
            onClick={approveSelected}
            leftIcon={<CheckIcon w={3} h={3} />}
          >
            Approve
          </Button>
          <FormNoteModal
            isOpen={modalRejection}
            onClose={() => setModalRejection(false)}
            onClickCTA={rejectSelected}
            loading={isSubmiting}
            title="Reject Quickpay request"
            subtitle="Are you sure you want to reject Quickpay request for LOAD IDs ?"
            cancelText="Close"
            confirmText="Reject"
            layerConfirmButton="red"
          />
        </HStack>
      </HStack>
    </VStack>
  );
};
