import { FormControl, FormLabel } from "@chakra-ui/react";
import { FileUploadButton } from "../../../ui/components/FileUploadButton";
import { FileUploadProgress } from "../../../ui/components/FileUploadProgress";
import { UploadIcon } from "../../../ui/icons";

interface RenderFileInputProps {
  title: string;
  progress: number;
  fileState: File | null;
  setFileState: React.Dispatch<React.SetStateAction<File | null>>;
  loading: boolean;
}

export const RenderFileInput = ({
  title,
  progress,
  fileState,
  setFileState,
  loading,
}: RenderFileInputProps): JSX.Element => {
  return (
    <FormControl>
      <FormLabel requiredIndicator={<></>}>{title}</FormLabel>
      {fileState ? (
        <FileUploadProgress
          value={progress}
          fileName={fileState.name}
          onCancel={() => {
            if (!loading) {
              setFileState(null);
            }
          }}
          disabled={false}
        />
      ) : (
        <FileUploadButton
          disabled={loading}
          layerStyle="black"
          onChange={setFileState}
          icon={<UploadIcon />}
        >
          Upload File
        </FileUploadButton>
      )}
    </FormControl>
  );
};
