import {
  Box,
  Divider,
  FormLabel,
  Heading,
  Text,
  VStack,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Spacer,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  useUpdateBrokerMutation,
  useUpdateBrokerAdminMutation,
  UpdateBrokerAdminParams,
  GetBrokerApiResponse,
  UpdateBrokerApiArg,
  IBrokerFinancials,
} from "../../../../../api-platform/internal";
import { useNotificationBanner } from "../../../../../components/useNotificationBanner";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_MESSAGE_REQUIRED,
} from "../../../../../reuse/Constants";
import { EditIcon, PhoneIcon } from "@chakra-ui/icons";
import { ErrorMessage } from "@hookform/error-message";
import { validatePhoneNumber } from "../../../../../reuse/UserData";
import { OptionalIndicator } from "../../../../../ui/components/OptionalIndicator";
import { formatPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { getAddressFieldsFromGooglePlacePrediction } from "../../../../../reuse/Addressess";
import { EditPaymentControls } from "../EditPaymentControls";

interface UpdateAccountProps {
  broker: GetBrokerApiResponse;
  financials: IBrokerFinancials | undefined;
}

export function UpdateAccount({
  broker,
  financials,
}: UpdateAccountProps): JSX.Element {
  const [updateBrokerAdmin, { isLoading: isUpdatingBrokerAdmin }] =
    useUpdateBrokerAdminMutation();
  const [updateBroker, { isLoading: isUpdatingBroker }] =
    useUpdateBrokerMutation();
  const [isEditBrokerAdminMode, setIsEditBrokerAdminMode] = useState(false);
  const [isEditBrokerMode, setIsEditBrokerMode] = useState(false);
  const [addressSelectedFromGoogle, setAddressSelectedFromGoogle] =
    useState<boolean>(true);

  const userForm = useForm<UpdateBrokerAdminParams>({
    defaultValues: {
      firstName: broker.adminUser?.firstName,
      lastName: broker.adminUser?.lastName,
      phone: broker.adminUser?.phone,
    },
  });
  const brokerForm = useForm<UpdateBrokerApiArg["body"]>({
    defaultValues: {
      name: broker.name,
      website: broker.website ?? "",
      mcNumber: broker.mcNumber,
      salesforceId: broker.salesforceId ?? "",
      address: broker.address,
      addressOne: broker.addressOne,
      addressCity: broker.addressCity,
      addressState: broker.addressState,
      addressCountry: broker.addressCountry,
      addressPostal: broker.addressPostal,
      phone: broker.phone,
      taxIdentificationNumber: financials?.taxIdentificationNumber ?? "",
    },
  });

  const notificationBanner = useNotificationBanner();

  const setAddressFromGooglePrediction = (
    prediction: google.maps.places.PlaceResult,
  ): void => {
    brokerForm.setValue("address", prediction.formatted_address ?? "");
    if (prediction.address_components) {
      const address = getAddressFieldsFromGooglePlacePrediction(
        prediction.address_components,
      );
      if (address) {
        setAddressSelectedFromGoogle(true);
        brokerForm.setValue("addressOne", address.address1);
        brokerForm.setValue("addressCity", address.city);
        brokerForm.setValue("addressState", address.state);
        brokerForm.setValue("addressCountry", address.country);
        brokerForm.setValue("addressPostal", address.postal);
      } else {
        alert("The selected address did not match expected format.");
      }
    } else {
      alert("The selected address did not match expected format.");
    }
  };

  async function handleUpdateBrokerAdminFormSubmit(
    data: UpdateBrokerAdminParams,
  ): Promise<void> {
    await updateBrokerAdmin({
      id: broker.id,
      updateBrokerAdminParams: {
        ...data,
        phone: data.phone ? data.phone : "",
      },
    })
      .unwrap()
      .catch((e) => {
        notificationBanner({
          status: "error",
          description: DEFAULT_ERROR_MESSAGE,
        });
        throw e;
      });
    setIsEditBrokerAdminMode(false);
    notificationBanner({
      status: "success",
      description: "Account administrator updated successfully.",
    });
  }

  async function handleUpdateBrokerFormSubmit(
    data: UpdateBrokerApiArg["body"],
  ): Promise<void> {
    await updateBroker({
      id: broker.id,
      body: {
        ...data,
        phone: data.phone ? data.phone : "",
      },
    })
      .unwrap()
      .catch((e) => {
        notificationBanner({
          status: "error",
          description: DEFAULT_ERROR_MESSAGE,
        });
        throw e;
      });
    setIsEditBrokerMode(false);
    notificationBanner({
      status: "success",
      description: "Company details updated successfully.",
    });
  }

  return (
    <HStack h="100%" alignItems="start">
      <VStack spacing="20px" w="50%" align="start" pb="40px">
        <HStack w="100%">
          <Heading size="md">Account administrator</Heading>
          <Spacer />
          <Button
            leftIcon={<EditIcon />}
            visibility={isEditBrokerAdminMode ? "hidden" : "visible"}
            onClick={() => setIsEditBrokerAdminMode(true)}
          >
            Edit
          </Button>
        </HStack>
        {isEditBrokerAdminMode ? (
          <>
            <FormControl
              isRequired
              isInvalid={!!userForm.formState.errors?.firstName}
            >
              <FormLabel requiredIndicator={<></>}>First name</FormLabel>
              <Input
                w="auto"
                {...userForm.register("firstName", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
              <FormErrorMessage>
                <ErrorMessage
                  errors={userForm.formState.errors}
                  name="firstName"
                />
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!userForm.formState.errors?.lastName}
            >
              <FormLabel requiredIndicator={<></>}>Last name</FormLabel>
              <Input
                w="auto"
                {...userForm.register("lastName", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
              <FormErrorMessage>
                <ErrorMessage
                  errors={userForm.formState.errors}
                  name="lastName"
                />
              </FormErrorMessage>
            </FormControl>
            <Box>
              <FormLabel>Email</FormLabel>
              <Text>{broker.adminUser?.email}</Text>
            </Box>
            <FormControl isInvalid={!!userForm.formState.errors?.phone}>
              <FormLabel optionalIndicator={<OptionalIndicator />}>
                Phone number
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="gray.400" />
                </InputLeftElement>
                <PhoneInput
                  name="phone"
                  w="auto"
                  placeholder="(___) - ___ - ____"
                  control={userForm.control}
                  rules={{
                    validate: (value: string | undefined) => {
                      if (value) {
                        return validatePhoneNumber(`${value}`);
                      }
                    },
                    required: false,
                  }}
                  country="US"
                  style={{ paddingLeft: "2.5rem" }}
                  inputComponent={Input}
                />
              </InputGroup>
              <FormErrorMessage>
                <ErrorMessage errors={userForm.formState.errors} name="phone" />
              </FormErrorMessage>
            </FormControl>
            <HStack>
              <Button
                layerStyle="yellow"
                onClick={() => {
                  void userForm.handleSubmit(
                    handleUpdateBrokerAdminFormSubmit,
                  )();
                }}
                isLoading={isUpdatingBrokerAdmin}
              >
                Save changes
              </Button>
              <Button
                onClick={() => {
                  userForm.reset();
                  setIsEditBrokerAdminMode(false);
                }}
              >
                Cancel
              </Button>
            </HStack>
          </>
        ) : (
          <VStack spacing="24px" align="start" mt="20px">
            <Box>
              <FormLabel>First name</FormLabel>
              <Text>{broker.adminUser?.firstName}</Text>
            </Box>
            <Box>
              <FormLabel>Last name</FormLabel>
              <Text>{broker.adminUser?.lastName}</Text>
            </Box>
            <Box>
              <FormLabel>Email</FormLabel>
              <Text>{broker.adminUser?.email}</Text>
            </Box>
            <Box>
              <FormLabel>Phone</FormLabel>
              <Text>
                {broker.adminUser?.phone ? (
                  formatPhoneNumber(broker.adminUser.phone)
                ) : (
                  <>&mdash;</>
                )}
              </Text>
            </Box>
          </VStack>
        )}
        <Divider />
        <HStack w="100%">
          <Heading size="md">Company details</Heading>
          <Spacer />
          <Button
            onClick={() => setIsEditBrokerMode(true)}
            leftIcon={<EditIcon />}
            visibility={isEditBrokerMode ? "hidden" : "visible"}
          >
            Edit
          </Button>
        </HStack>
        {isEditBrokerMode ? (
          <>
            <FormControl
              isRequired
              isInvalid={!!brokerForm.formState.errors?.name}
            >
              <FormLabel requiredIndicator={<></>}>Company name</FormLabel>
              <Input
                w="auto"
                {...brokerForm.register("name", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  name="name"
                />
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!brokerForm.formState.errors?.website}>
              <FormLabel optionalIndicator={<OptionalIndicator />}>
                Company website
              </FormLabel>
              <Input w="auto" {...brokerForm.register("website")} />
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  name="website"
                />
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!brokerForm.formState.errors?.mcNumber}
            >
              <FormLabel requiredIndicator={<></>}>MC Number</FormLabel>
              <Input
                htmlSize={12}
                w="auto"
                {...brokerForm.register("mcNumber", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  name="mcNumber"
                />
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!brokerForm.formState.errors?.salesforceId}
            >
              <FormLabel optionalIndicator={<OptionalIndicator />}>
                Salesforce ID
              </FormLabel>
              <Input w="auto" {...brokerForm.register("salesforceId")} />
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  name="salesforceId"
                />
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={!!brokerForm.formState.errors?.address}
            >
              <FormLabel requiredIndicator={<></>}>Company address</FormLabel>
              <Input
                w="auto"
                display="none"
                {...brokerForm.register("address", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                  validate: () =>
                    addressSelectedFromGoogle
                      ? true
                      : "Must choose an address from the suggested reponses",
                })}
              />
              <ReactGoogleAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                onPlaceSelected={(place) =>
                  setAddressFromGooglePrediction(place)
                }
                options={{ types: ["address"] }}
                onChange={() => setAddressSelectedFromGoogle(false)}
                defaultValue={broker.address}
                style={{
                  borderRadius: "8px",
                  padding: "0.5rem 1rem",
                  border: "1px solid lightGray",
                }}
              />
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  name="address"
                />
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!brokerForm.formState.errors?.phone}>
              <FormLabel optionalIndicator={<OptionalIndicator />}>
                Phone number
              </FormLabel>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <PhoneIcon color="gray.400" />
                </InputLeftElement>
                <PhoneInput
                  name="phone"
                  w="auto"
                  placeholder="(___) - ___ - ____"
                  control={brokerForm.control}
                  rules={{
                    validate: (value: string | undefined) => {
                      if (value) {
                        return validatePhoneNumber(`${value}`);
                      }
                    },
                    required: false,
                  }}
                  country="US"
                  style={{ paddingLeft: "2.5rem" }}
                  inputComponent={Input}
                />
              </InputGroup>
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  name="phone"
                />
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isRequired
              isInvalid={!!brokerForm.formState.errors?.taxIdentificationNumber}
            >
              <FormLabel requiredIndicator={<></>}>
                Tax Identification Number (TIN)
              </FormLabel>
              <Input
                w="auto"
                {...brokerForm.register("taxIdentificationNumber", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                  pattern: /^\d{9}$/,
                })}
              />
              <FormErrorMessage>
                <ErrorMessage
                  errors={brokerForm.formState.errors}
                  message={
                    brokerForm.formState.errors?.taxIdentificationNumber
                      ?.type === "pattern"
                      ? "Tax identification does not match expected pattern: 9 digit federal employer identification number (EIN)"
                      : brokerForm.formState.errors?.taxIdentificationNumber
                          ?.message
                  }
                  name="taxIdentificationNumber"
                />
              </FormErrorMessage>
            </FormControl>
            <HStack>
              <Button
                layerStyle="yellow"
                onClick={() => {
                  void brokerForm.handleSubmit(handleUpdateBrokerFormSubmit)();
                }}
                isLoading={isUpdatingBroker}
              >
                Save changes
              </Button>
              <Button
                onClick={() => {
                  brokerForm.reset();
                  setIsEditBrokerMode(false);
                }}
              >
                Cancel
              </Button>
            </HStack>
          </>
        ) : (
          <VStack spacing="24px" align="start" mt="20px">
            <Box>
              <FormLabel>Company name</FormLabel>
              <Text>{broker.name}</Text>
            </Box>
            <Box>
              <FormLabel>Company website</FormLabel>
              <Text>{broker.website ?? <>&mdash;</>}</Text>
            </Box>
            <Box>
              <FormLabel>MC Number</FormLabel>
              <Text>{broker.mcNumber}</Text>
            </Box>
            <Box>
              <FormLabel>Salesforce ID</FormLabel>
              <Text>{broker.salesforceId ?? <>&mdash;</>}</Text>
            </Box>
            <Box>
              <FormLabel>Company address</FormLabel>
              <Text>{broker.address}</Text>
            </Box>
            <Box>
              <FormLabel>Phone number</FormLabel>
              <Text>
                {broker.phone ? formatPhoneNumber(broker.phone) : <>&mdash;</>}
              </Text>
            </Box>

            <Box>
              <FormLabel>Tax Identification Number (TIN)</FormLabel>
              <Text>{financials?.taxIdentificationNumber}</Text>
            </Box>
          </VStack>
        )}
      </VStack>
      <Divider orientation="vertical" color="gray.200" />
      <VStack spacing="20px" w="50%" align="start" p="0px 20px">
        <EditPaymentControls broker={broker} />
      </VStack>
    </HStack>
  );
}
