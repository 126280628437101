import { VStack } from "@chakra-ui/react";
import TabsContainer from "../../components/TabsContainer";
import { BrokerStatus } from "../../types/Broker";
import { useManageUserContext } from "./ManageUserContext";
import AdminBrokerAccountForm from "./BrokerAccountSummary/AdminBrokerAccountForm";

export const AdminBrokerAccountTabContainter = (): JSX.Element => {
  const context = useManageUserContext();
  const { selectedBroker, setCurrentStatus } = context!;
  const handleClickTab = (title: string): void =>
    setCurrentStatus(title as BrokerStatus);

  return (
    <VStack mt={8}>
      {selectedBroker && (
        <TabsContainer
          containerBg="white"
          tabList={["Summary", "Comments"]}
          tabPanels={[
            <AdminBrokerAccountForm
              key="Summary Tab"
              broker={selectedBroker}
            />,
          ]}
          onClickTab={handleClickTab}
        />
      )}
    </VStack>
  );
};

export default AdminBrokerAccountTabContainter;
