import {
  IDocsUploadedToLoad,
  IExternalLoad,
} from "../../api-platform/externalLoads";

export const getAllIds = (quickpays: IExternalLoad[]): string[] => {
  return quickpays?.map((element) => element.id) ?? [];
};

export const getAmountTotalSelected = (loads: number[]): number => {
  const totalAmount = loads.reduce((acc, element) => acc + Number(element), 0);
  return totalAmount;
};

export const disabledQpRequest = (docTypesMissing: string[]): boolean =>
  docTypesMissing.includes("Carrier Invoice") ||
  docTypesMissing.includes("Rate Confirmation") ||
  docTypesMissing.includes("Bill Of Lading");

export const requiredUploadedDocsMissing = (
  uploadedDocs?: IDocsUploadedToLoad,
): boolean => {
  if (!uploadedDocs) {
    return true;
  } else
    return (
      uploadedDocs.invoiceId === null ||
      uploadedDocs.rateconId === null ||
      uploadedDocs.bolId === null
    );
};
