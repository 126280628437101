import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
import { baseUrl } from "./utils/baseUrl";

export const RolesCacheKey = "Roles" as const;
export const OrgAccountsKey = "OrgAccounts" as const;
export const OrgInvitesKey = "OrgInvites" as const;
export const DocumentsKey = "Documents" as const;
export const ShipmentDocumentsKey = "ShipmentDocuments" as const;
export const ExternalLoadsTag = "ExternalLoadsTag" as const;
export const QuickPaysTag = "QuickPaysTag" as const;
export const ExternalLoadCustomersTag = "ExternalLoadCustomersTag" as const;
export const ExternalLoadCarriersTag = "ExternalLoadCarriersTag" as const;
export const QuickPayBrokersTag = "QuickPayBrokersTag" as const;
export const QuickPayCarriersTag = "QuickPayCarriersTag" as const;
export const CarrierPayeeAccounts = "CarrierPayeeAccounts" as const;

export const mvmntApi = createApi({
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: [
    RolesCacheKey,
    CarrierPayeeAccounts,
    OrgAccountsKey,
    OrgInvitesKey,
    DocumentsKey,
    ShipmentDocumentsKey,
    ExternalLoadsTag,
    QuickPaysTag,
    ExternalLoadCustomersTag,
    ExternalLoadCarriersTag,
    QuickPayBrokersTag,
    QuickPayCarriersTag,
  ],
  endpoints: () => ({}),
});
