import { HStack, Stat, StatLabel, StatNumber } from "@chakra-ui/react";
import type { OrgAccount } from "../../../api/admin";

export function UsersHeader(props: { orgAccounts: OrgAccount[] }): JSX.Element {
  const stats = props.orgAccounts.reduce(
    (acc, orgAccount) => {
      if (!orgAccount.deactivatedDate) acc.active++;
      if (orgAccount.isAdmin) acc.admin++;
      return acc;
    },
    { total: props.orgAccounts?.length ?? 0, active: 0, admin: 0 },
  );

  return (
    <HStack spacing="20px">
      <Stat flex="initial">
        <StatLabel color="gray.600">Total Users</StatLabel>
        <StatNumber fontSize="18px" fontWeight="normal" textAlign="center">
          {stats.total}
        </StatNumber>
      </Stat>
      <Stat flex="initial">
        <StatLabel color="gray.600">Active Users</StatLabel>
        <StatNumber fontSize="18px" fontWeight="normal" textAlign="center">
          {stats.active}
        </StatNumber>
      </Stat>
      <Stat flex="initial">
        <StatLabel color="gray.600">Administrators</StatLabel>
        <StatNumber fontSize="18px" fontWeight="normal" textAlign="center">
          {stats.admin}
        </StatNumber>
      </Stat>
    </HStack>
  );
}
