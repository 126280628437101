import { createContext, useContext } from "react";
import {
  GetQuickPayRequestsViewApiResponse,
  QuickPaySortOptions,
} from "../../api-platform/quickpay";
import { QuickPaySortOptionsEnum } from "../../types/QuickPayRequest";

interface QuickPayTable {
  quickpays: GetQuickPayRequestsViewApiResponse;
  selectedQuickPayIds: string[];
  setSelectedQuickPayIds: React.Dispatch<React.SetStateAction<string[]>>;
  brokerNameFilter: string | null;
  setBrokerNameFilter: React.Dispatch<React.SetStateAction<string | null>>;
  carrierNameFilter: string | null;
  setCarrierNameFilter: React.Dispatch<React.SetStateAction<string | null>>;
  sortBy: QuickPaySortOptions;
  setSortBy: React.Dispatch<React.SetStateAction<QuickPaySortOptions>>;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  brokerNames: string[] | undefined;
  carrierNames: string[] | undefined;
}

const QuickPayTableContext = createContext<QuickPayTable>({
  quickpays: [],
  selectedQuickPayIds: [],
  setSelectedQuickPayIds: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  brokerNameFilter: null,
  setBrokerNameFilter: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  carrierNameFilter: null,
  setCarrierNameFilter: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  sortBy: QuickPaySortOptionsEnum.TimeRemaining,
  setSortBy: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  searchTerm: "",
  setSearchTerm: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  brokerNames: [],
  carrierNames: [],
});

const useQuickPayTableContext = (): QuickPayTable =>
  useContext(QuickPayTableContext);

export { QuickPayTableContext, useQuickPayTableContext };
