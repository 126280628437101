import { VStack } from "@chakra-ui/layout";
import { Skeleton } from "@chakra-ui/skeleton";
import { useMemo, useState } from "react";
import Header from "../../components/Header";
import TabsContainer from "../../components/TabsContainer";
import TabAdditionalContent from "./TabAdditionalContent";
import {
  BrokerStatus,
  BrokerStatusLabel,
  BrokerUser,
} from "../../types/Broker";
import { ManageUserContext } from "./ManageUserContext";
import { useGetAllBrokerUsersQuery } from "../../api/broker";
import AdminBrokerAccountPage from "./BrokerAccountSummary/AdminBrokerAccountPage";
import AdminManageBrokersTable from "./AdminManageBrokersTable";

export const ManageUsers = (): JSX.Element => {
  const { data, isLoading } = useGetAllBrokerUsersQuery();

  const [statusCellStyle, setStatusCellStyle] = useState<React.CSSProperties>({
    color: "darkergrey",
    backgroundColor: "lightgrey",
  });
  const [currentStatus, setCurrentStatus] = useState<BrokerStatus>(
    BrokerStatus.PENDING,
  );

  const handleClickTab = (title: string): void =>
    setCurrentStatus(title as BrokerStatus);

  const [selectedBroker, setSelectedBroker] = useState<
    BrokerUser | undefined
  >();

  const [term, setTerm] = useState("");

  const setStylingBasedOnStatus = (): void => {
    switch (currentStatus) {
      case BrokerStatus.PENDING:
        setStatusCellStyle({
          color: "darkergrey",
          backgroundColor: "lightgrey",
        });
        return;
      case BrokerStatus.APPROVED:
        setStatusCellStyle({
          color: "mvmntGreen",
          backgroundColor: "lightgreen",
        });
        return;
      default:
        setStatusCellStyle({
          color: "tomato",
          backgroundColor: "pink",
        });
    }
  };

  const PendingTable = useMemo(() => {
    const pendingBrokers =
      data?.filter((user) => user.status === BrokerStatus.PENDING) ?? [];
    return <AdminManageBrokersTable brokers={pendingBrokers} />;
  }, [data]);

  const ApprovedTable = useMemo(() => {
    const approvedBrokers =
      data?.filter((user) => user.status === BrokerStatus.APPROVED) ?? [];
    return <AdminManageBrokersTable brokers={approvedBrokers} />;
  }, [data]);

  const RejectedTable = useMemo(() => {
    const rejectedBrokers =
      data?.filter((user) => user.status === BrokerStatus.REJECTED) ?? [];
    return <AdminManageBrokersTable brokers={rejectedBrokers} />;
  }, [data]);

  const RevokedTable = useMemo(() => {
    const revokedBrokers =
      data?.filter((user) => user.status === BrokerStatus.REVOKED) ?? [];
    return <AdminManageBrokersTable brokers={revokedBrokers} />;
  }, [data]);

  const AllTable = useMemo(() => {
    const allBrokers = data ?? [];
    return <AdminManageBrokersTable brokers={allBrokers} />;
  }, [data]);

  const tabList = [...Object.values(BrokerStatusLabel), "All"];
  const tabPanels = [
    PendingTable,
    ApprovedTable,
    RejectedTable,
    RevokedTable,
    AllTable,
  ];

  return (
    <ManageUserContext.Provider
      value={{
        statusCellStyle,
        setStatusCellStyle,
        currentStatus,
        setCurrentStatus,
        setStylingBasedOnStatus,
        term,
        selectedBroker,
        setSelectedBroker,
      }}
    >
      <Skeleton isLoaded={!isLoading} bgColor="#F6F6F6" w="100%" h="100%">
        {selectedBroker ? (
          <AdminBrokerAccountPage />
        ) : (
          <>
            <VStack mt={8}>
              <VStack alignItems="start" w="100%" pl="4rem">
                <Header headingText="Manage Users" />
              </VStack>
              <TabsContainer
                containerBg="white"
                tabList={tabList}
                tabPanels={tabPanels}
                tabPanelHeader={
                  <TabAdditionalContent term={term} setTerm={setTerm} />
                }
                onClickTab={handleClickTab}
              />
            </VStack>
          </>
        )}
      </Skeleton>
    </ManageUserContext.Provider>
  );
};

export default ManageUsers;
