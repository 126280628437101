import { mvmntApiPlatform } from "./generated";
export * from "./generated";

mvmntApiPlatform.enhanceEndpoints({
  endpoints: {
    getExternalLoadBoardView: {
      providesTags: ["ExternalLoads"],
    },
    getBrokerExternalLoadCustomers: {
      providesTags: ["ExternalLoadCustomers"],
    },
    getBrokerLoadCarriers: {
      providesTags: ["ExternalLoadCarriers"],
    },
    addExternalLoadForQuickpay: {
      invalidatesTags: [
        "ExternalLoads",
        "ExternalLoadsDraft",
        "ExternalLoadCustomers",
        "ExternalLoadCarriers",
      ],
    },
  },
});
