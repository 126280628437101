import { mvmntApiPlatform as api } from "../api-platform/api";
import { UploadLoadsFromTemplateApiResponse } from "../api-platform/externalLoadsDraft/generated";

// . Note, this is a stop gap, the generated api changes the signature from File to Blob
// . I'm having issues fighting with it so this is the easiest solution for now
export const externalLoadsDraftApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadExternalTemplateLoadsNotGenerated: builder.mutation<
      UploadLoadsFromTemplateApiResponse,
      File
    >({
      query: (file) => {
        const body = new FormData();
        body.append("file", file);
        return {
          url: "/external-loads-draft/upload",
          method: "POST",
          body,
        };
      },
      invalidatesTags: [
        "ExternalLoadsDraft",
        "ExternalLoadCustomers",
        "ExternalLoadCarriers",
      ],
    }),
  }),
});

export const { useUploadExternalTemplateLoadsNotGeneratedMutation } =
  externalLoadsDraftApi;
