import { Icon, IconProps } from "@chakra-ui/react";

export const SuccessIcon = (props: IconProps): JSX.Element => (
  <Icon width="20px" height="20px" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M10 1C8.21997 1 6.47991 1.52784 4.99987 2.51677C3.51983 3.50571 2.36628 4.91131 1.68509 6.55585C1.0039 8.20038 0.82567 10.01 1.17294 11.7558C1.5202 13.5016 2.37737 15.1053 3.63604 16.364C4.89472 17.6226 6.49836 18.4798 8.24419 18.8271C9.99002 19.1743 11.7996 18.9961 13.4442 18.3149C15.0887 17.6337 16.4943 16.4802 17.4832 15.0001C18.4722 13.5201 19 11.78 19 10C19 7.61305 18.0518 5.32387 16.364 3.63604C14.6761 1.94821 12.387 1 10 1ZM15.1975 7.15L10.06 14.1175C10.0002 14.199 9.9246 14.2675 9.83766 14.319C9.75073 14.3705 9.65428 14.404 9.55411 14.4173C9.45395 14.4306 9.35212 14.4235 9.25475 14.3965C9.15739 14.3694 9.06648 14.323 8.9875 14.26L5.32 11.3275C5.24296 11.2656 5.17891 11.1891 5.13152 11.1024C5.08414 11.0156 5.05437 10.9204 5.04392 10.8221C5.03346 10.7239 5.04253 10.6245 5.07061 10.5297C5.09868 10.435 5.1452 10.3467 5.2075 10.27C5.33213 10.1188 5.51099 10.0223 5.70583 10.0013C5.90066 9.98027 6.09598 10.0363 6.25 10.1575L9.31 12.6025L13.99 6.25C14.1081 6.09186 14.2838 5.98664 14.4789 5.95715C14.6741 5.92767 14.873 5.9763 15.0325 6.0925C15.1135 6.15052 15.1822 6.22409 15.2344 6.30894C15.2867 6.39378 15.3215 6.48818 15.3369 6.58663C15.3522 6.68509 15.3478 6.78561 15.3239 6.88234C15.2999 6.97907 15.257 7.07006 15.1975 7.15Z"
      fill="#1BA774"
    />
  </Icon>
);
