import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

export function OptionalIndicator(props: {
  children?: ReactNode;
}): JSX.Element {
  return (
    <Box
      as="span"
      textTransform="none"
      fontSize="13px"
      fontFamily="Montserrat Regular"
    >
      {" "}
      {props.children ? props.children : "(Optional)"}
    </Box>
  );
}
