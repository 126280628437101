import { createContext, useContext } from "react";
import { ICarrierPayeeAccount } from "../../api-platform/carrierPayeeAccounts";

interface CarrierAccountsPage {
  carrierSelectedForDefaultLoad: ICarrierPayeeAccount | undefined;
  setCarrierSelectedForDefaultLoad: React.Dispatch<
    React.SetStateAction<ICarrierPayeeAccount | undefined>
  >;
}

const CarrierAccountsPageContext = createContext<CarrierAccountsPage>({
  carrierSelectedForDefaultLoad: undefined,
  setCarrierSelectedForDefaultLoad: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

const useCarrierAccountsPageContext = (): CarrierAccountsPage =>
  useContext(CarrierAccountsPageContext);

export { CarrierAccountsPageContext, useCarrierAccountsPageContext };
