import { ViewIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  InputGroup,
  Input,
  FormErrorMessage,
  InputRightElement,
  Box,
  Text,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import RegexPolicesList from "../../../components/RegexPolicesList";
import { DEFAULT_MESSAGE_REQUIRED } from "../../../reuse/Constants";
import { validatePassword } from "../../../reuse/UserData";
import { RegexPoliceProp } from "../../../types/Regex";

export function PasswordInput(): JSX.Element {
  const [show, setShow] = useState(false);
  const [regexList, setRegexList] = useState<[] | RegexPoliceProp[]>([]);
  const form = useFormContext();
  const newPassword = form.watch("newPassword");

  useEffect(() => {
    setRegexList(validatePassword(newPassword!));
  }, [newPassword]);

  return (
    <>
      <FormControl isRequired isInvalid={!!form.formState.errors?.newPassword}>
        <FormLabel requiredIndicator={<></>}>New password</FormLabel>
        <InputGroup>
          <InputRightElement cursor="pointer" onClick={() => setShow(!show)}>
            <ViewIcon color="gray.400" />
          </InputRightElement>
          <Input
            type={show ? "text" : "password"}
            placeholder="Min. 11 characters"
            {...form.register("newPassword", {
              required: DEFAULT_MESSAGE_REQUIRED,
              validate: () =>
                regexList.length === 4 ||
                "Please ensure the password requirements below are met.",
            })}
          />
        </InputGroup>
        <FormErrorMessage>
          <ErrorMessage errors={form.formState.errors} name="newPassword" />
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={!!form.formState.errors?.newPasswordConfirm}
      >
        <FormLabel requiredIndicator={<></>}>Confirm new password</FormLabel>
        <InputGroup>
          <Input
            type={show ? "text" : "password"}
            {...form.register("newPasswordConfirm", {
              validate: (value) =>
                value === form.getValues().newPassword ||
                "Please ensure the passwords match.",
            })}
          />
        </InputGroup>
        <FormErrorMessage>
          <ErrorMessage
            errors={form.formState.errors}
            name="newPasswordConfirm"
          />
        </FormErrorMessage>
      </FormControl>
      <Box>
        <Text fontWeight="bold" mb="5px">
          Password requirements:
        </Text>
        <RegexPolicesList
          regexList={regexList}
          columns={"1fr"}
          color="gray.900"
        />
      </Box>
    </>
  );
}
