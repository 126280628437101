import { Role } from "./admin";
import { mvmntApi, RolesCacheKey } from "./mvmnt.api";

export const rolesApi = mvmntApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoles: builder.query<Role[], void>({
      query: () => `roles`,
      providesTags: [RolesCacheKey],
    }),
  }),
});

export const { useGetAllRolesQuery } = rolesApi;
