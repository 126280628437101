import { IExternalLoadDocument } from "../api-platform/externalLoads/generated";
import { QuickPaySortOptionsEnum } from "../types/QuickPayRequest";

export const getShorthandForExtLoadDocType = (
  type: IExternalLoadDocument["documentType"],
): string => {
  switch (type) {
    case "BillOfLading":
      return "BOL";
    case "Invoice":
      return "CI";
    case "Other":
      return "OT";
    case "RateCon":
      return "RC";
    case "ProofOfDelivery":
      return "POD";
    default:
      return "OT";
  }
};

export const getLabelForQuickPaySortOption = (
  option: QuickPaySortOptionsEnum,
): string => {
  switch (option) {
    case QuickPaySortOptionsEnum.Brokerage:
      return "Brokerage";
    case QuickPaySortOptionsEnum.Carrier:
      return "Carrier Name";
    case QuickPaySortOptionsEnum.ElapsedTime:
      return "Elapsed Time";
    case QuickPaySortOptionsEnum.Amount:
      return "Amount";
    case QuickPaySortOptionsEnum.TimeRemaining:
      return "Time Remaining";
    default:
      return "Elapsed Time";
  }
};
