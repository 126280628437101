import Header from "../../components/Header";
import {
  ChevronLeftIcon,
  EmailIcon,
  PhoneIcon,
  QuestionIcon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Link,
  Divider,
  Button,
  Text,
  FormErrorMessage,
  Flex,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Select,
  RadioGroup,
  Radio,
  InputRightElement,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { routes } from "../../routes";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { useNavigate } from "react-router-dom";
import {
  validateCarrierPercent,
  validateEmail,
  validatePhoneNumber,
} from "../../reuse/UserData";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useDebounce } from "../../components/useDebounceHook";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { carrierIdTypes } from "./types";
import {
  OmitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId,
  useCreateCarrierPayeeAccountMutation,
} from "../../api-platform/carrierPayeeAccounts";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";

export const AddBrokerCarrierAccountPage = (): JSX.Element => {
  const data = useAppSelector(selectUserData);
  const { broker } = data;
  const [createCarrier, { isLoading }] = useCreateCarrierPayeeAccountMutation();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [selectedRadioValue, setSelectedRadioValue] = useState("");

  const handleRadioButtonChange = (event: string): void =>
    setSelectedRadioValue(event);

  const notificationBanner = useNotificationBanner();
  const form = useForm<
    OmitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId & {
      confirmAccountNumber: string;
    }
  >({ defaultValues: { brokerPaysFee: false } });
  const navigate = useNavigate();
  const addressInputValue = form.watch("corporateAddress");
  const accountNumber = form.watch("accountNumber");
  const achRoutingNumber = form.watch("achRoutingNumber");

  const debouncedAddressInput = useDebounce(addressInputValue ?? "", 500);

  const { placePredictions, getPlacePredictions } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  useEffect(() => {
    getPlacePredictions({
      input: debouncedAddressInput,
      types: ["street_address"],
    });
  }, [debouncedAddressInput]);

  useEffect(() => {
    if (placePredictions.length > 0 && !isMenuOpen) {
      setMenuOpen(true);
    } else if (!placePredictions.length) {
      setMenuOpen(false);
    }
  }, [placePredictions.length]);

  const setAddressFromGooglePrediction = (
    prediction: google.maps.places.AutocompletePrediction,
  ): void => {
    form.setValue("corporateAddress", prediction.description);
    setMenuOpen(false);
  };

  async function onSubmit(addMore: boolean): Promise<void> {
    const values = form.getValues();
    /* eslint @typescript-eslint/prefer-nullish-coalescing: 0 */
    // substitue emptys and undefineds for nulls
    // disabling the linter preference since I want empty strings -> null
    const payload: OmitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId =
      {
        accountNumber: values.accountNumber || null,
        achRoutingNumber: values.achRoutingNumber || null,
        corporateAddress: values.corporateAddress || null,
        carrierId: values.carrierId,
        typeId: values.typeId,
        name: values.name,
        phone: values.phone || null,
        primaryContactEmail: values.primaryContactEmail || null,
        primaryContactFirstName: values.primaryContactFirstName || null,
        primaryContactLastName: values.primaryContactLastName || null,
        primaryContactPhone: values.primaryContactPhone || null,
        brokerPaysFee: values.brokerPaysFee,
        carrierQpPercentageFee: values.carrierQpPercentageFee,
      };

    await createCarrier({
      omitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId:
        payload,
    })
      .unwrap()
      .then(() => {
        notificationBanner({
          status: "success",
          description: <p>Carrier added.</p>,
        });
        if (addMore) {
          form.reset();
        } else {
          navigate(routes.CARRIER_ACCOUNTS);
        }
      })
      .catch((error) => {
        if (error.status === 409) {
          form.setError("carrierId", { message: "carrierId must be unique" });
        }
        notificationBanner({
          status: "error",
          description: error.data.message,
        });
      });
  }

  const brokerPercentString = broker
    ? `(${broker.brokerQpPercentageFee}%)`
    : ``;

  const carrierMaxPercent = broker ? broker.carrierQpPercentageFeeMax : 11.0;
  const carrierMinPercent = broker ? broker.carrierQpPercentageFeeMin : 0.0;

  return (
    <VStack w="100%" alignItems="start">
      <Flex
        backgroundColor="grey6"
        w="100%"
        padding="1rem 4rem 1rem"
        flexDir={"column"}
        alignItems="start"
      >
        <Button
          onClick={() => navigate(routes.CARRIER_ACCOUNTS)}
          layerStyle="gray"
          leftIcon={<ChevronLeftIcon />}
          p={0}
          disabled={isLoading}
        >
          <Link>Back</Link>
        </Button>
        <Header as="h1" fontSize="32px" headingText="Add carrier" />
      </Flex>
      <VStack
        maxW="620px"
        padding="1rem 4rem 1rem"
        w="100%"
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        onSubmit={form.handleSubmit(async () => await onSubmit(false))}
      >
        <Heading as="h3" fontSize="20px" mb="16px">
          Company info
        </Heading>

        <FormControl isRequired isInvalid={!!form.formState.errors?.name}>
          <FormLabel requiredIndicator={<> (Required)</>}>
            Company Name
          </FormLabel>
          <InputGroup>
            <Input
              w="50%"
              {...form.register("name", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="name" />
          </FormErrorMessage>
        </FormControl>
        <HStack w="100%">
          <FormControl isRequired isInvalid={!!form.formState.errors?.typeId}>
            <FormLabel requiredIndicator={<></>}>Type ID (Required)</FormLabel>
            <Select
              backgroundColor="inherit"
              fontSize={15}
              fontWeight={500}
              textColor="gray.600"
              width="100%"
              variant="outline"
              placeholder="Select an option"
              {...form.register("typeId", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            >
              {carrierIdTypes.map((option) => (
                <option key={`Carrier Type ID select:${option}`} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="typeId" />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!form.formState.errors?.carrierId}
          >
            <FormLabel requiredIndicator={<></>}>
              Carrier ID (Required)
              <Tooltip label="This ID should be unique to this carrier. We recommentd using a MC or DOT number but it can be anything you choose.">
                <QuestionIcon w="15px" h="15px" margin="0px 5px" />
              </Tooltip>
            </FormLabel>
            <InputGroup>
              <Input
                w="100%"
                {...form.register("carrierId", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="carrierId" />
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl isInvalid={!!form.formState.errors?.phone}>
          <FormLabel requiredIndicator={<></>}>Phone number</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.400" />
            </InputLeftElement>
            <PhoneInput
              name="phone"
              w="50%"
              placeholder="(___) - ___ - ____"
              control={form.control}
              rules={{
                required: false,
                validate: (value: string) => {
                  return value ? validatePhoneNumber(`${value}`) : true;
                },
              }}
              country="US"
              style={{ paddingLeft: "2.5rem" }}
              inputComponent={Input}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="phone" />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.corporateAddress}
        >
          <FormLabel requiredIndicator={<></>}>Company Address</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="gray.300" />
            </InputLeftElement>
            <Input
              w="100%"
              placeholder="Street Address"
              fontSize="15px"
              {...form.register("corporateAddress", {
                required: false,
              })}
            />
            <Menu isOpen={isMenuOpen} flip={false} gutter={0}>
              <MenuButton
                aria-label="Autocomplete address options"
                visibility="hidden"
              />
              <MenuList maxW="25vw">
                {placePredictions.map((item) => (
                  <MenuItem
                    key={item.place_id}
                    onClick={() => setAddressFromGooglePrediction(item)}
                  >
                    {item.description}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="corporateAddress"
            />
          </FormErrorMessage>
        </FormControl>

        <Divider />

        <Heading as="h3" fontSize="20px" mb="16px">
          Payment details
        </Heading>
        <Text fontSize="15px">
          We need account information to know where to send a QuickPay. The
          account info below should be the same as where the carrier wants to be
          paid.
        </Text>

        <HStack w="100%">
          <FormControl
            isRequired
            isInvalid={!!form.formState.errors?.accountNumber}
          >
            <FormLabel requiredIndicator={<></>}>Account Number</FormLabel>
            <InputGroup w="100%">
              <Input
                w="100%"
                {...form.register("accountNumber", {
                  validate: (value) => {
                    if (achRoutingNumber !== "" && value === "") {
                      return "Must provide both account and routing number, or neither.";
                    } else {
                      return true;
                    }
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage
                errors={form.formState.errors}
                name="accountNumber"
              />
            </FormErrorMessage>
          </FormControl>

          <FormControl
            isRequired
            isInvalid={!!form.formState.errors?.confirmAccountNumber}
          >
            <FormLabel requiredIndicator={<></>}>
              Confirm Account Number
            </FormLabel>
            <InputGroup w="100%">
              <Input
                w="100%"
                {...form.register("confirmAccountNumber", {
                  required: accountNumber !== "",
                  validate: (value) => {
                    if (
                      value === accountNumber ||
                      (value === "" && accountNumber === "")
                    ) {
                      return true;
                    } else {
                      return "Confirmation account number must match account number.";
                    }
                  },
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage
                errors={form.formState.errors}
                name="confirmAccountNumber"
              />
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.achRoutingNumber}
        >
          <FormLabel requiredIndicator={<></>}>ACH Routing Number</FormLabel>
          <InputGroup>
            <Input
              w="50%"
              {...form.register("achRoutingNumber", {
                validate: (value) => {
                  if (accountNumber !== "" && value === "") {
                    return "Must provide both account and routing number, or neither.";
                  } else if (value === "" || value?.length === 9) {
                    return true;
                  } else {
                    return "Routing number must be 9 digits.";
                  }
                },
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="achRoutingNumber"
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={
            !!form.formState.errors?.brokerPaysFee ||
            !!form.formState.errors?.carrierQpPercentageFee
          }
        >
          <FormLabel requiredIndicator={<></>}>
            Who is responsible for covering the MVMNT fee?
          </FormLabel>
          <RadioGroup
            onChange={handleRadioButtonChange}
            value={selectedRadioValue}
          >
            <VStack alignItems="start">
              <Radio
                disabled={isLoading}
                {...form.register("brokerPaysFee", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
                value={"true"}
              >
                My brokerage will cover the fee {brokerPercentString}
              </Radio>
              <Radio
                disabled={isLoading}
                {...form.register("brokerPaysFee", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
                value={"false"}
              >
                The carrier will cover the fee
              </Radio>
            </VStack>
          </RadioGroup>
          {selectedRadioValue === "false" && (
            <VStack alignItems="flex-start" marginLeft="6" marginTop="3">
              <FormLabel requiredIndicator={<></>} marginBottom="0">
                Rate
              </FormLabel>
              <InputGroup w="103px">
                <Input
                  type="number"
                  placeholder="3.0"
                  step={0.01}
                  {...form.register("carrierQpPercentageFee", {
                    required: DEFAULT_MESSAGE_REQUIRED,
                    validate: (value) =>
                      validateCarrierPercent(
                        carrierMaxPercent,
                        carrierMinPercent,
                        value!,
                      ),
                    valueAsNumber: true,
                  })}
                />
                <InputRightElement>%</InputRightElement>
              </InputGroup>
            </VStack>
          )}
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="brokerPaysFee" />
            <ErrorMessage
              errors={form.formState.errors}
              name="carrierQpPercentageFee"
            />
          </FormErrorMessage>
        </FormControl>

        <Divider />

        <Heading as="h3" fontSize="20px" mb="16px">
          Primary Contact
        </Heading>
        <Text fontSize="15px">
          Providing a primary contact allows MVMNT to send communications, such
          as MVMNT Pay confirmation emails, directly to the carrier. If no email
          address is provided, the carrier will not receive communications from
          MVMNT.
        </Text>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.primaryContactFirstName}
        >
          <FormLabel requiredIndicator={<></>}>First Name</FormLabel>
          <InputGroup>
            <Input
              w="60%"
              {...form.register("primaryContactFirstName", {
                required: false,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactFirstName"
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.primaryContactLastName}
        >
          <FormLabel requiredIndicator={<></>}>Last Name</FormLabel>
          <InputGroup>
            <Input
              w="60%"
              {...form.register("primaryContactLastName", {
                required: false,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactLastName"
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.primaryContactEmail}
        >
          <FormLabel requiredIndicator={<></>}>Email</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="gray.400" />
            </InputLeftElement>
            <Input
              w="60%"
              {...form.register("primaryContactEmail", {
                validate: (value) => {
                  if (value !== null) {
                    return validateEmail(value);
                  }
                  return true;
                },
                required: false,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactEmail"
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors?.primaryContactPhone}>
          <FormLabel requiredIndicator={<></>}>Phone number</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.400" />
            </InputLeftElement>
            <PhoneInput
              name="primaryContactPhone"
              w="60%"
              placeholder="(___) - ___ - ____"
              control={form.control}
              rules={{
                required: false,
                validate: (value: string) => {
                  return value ? validatePhoneNumber(`${value}`) : true;
                },
              }}
              country="US"
              style={{ paddingLeft: "2.5rem" }}
              inputComponent={Input}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactPhone"
            />
          </FormErrorMessage>
        </FormControl>

        <Divider />

        <Flex>
          <Button
            mr="10px"
            layerStyle="yellow"
            type="submit"
            disabled={isLoading}
          >
            Add Carrier
          </Button>
          <Button
            disabled={isLoading}
            layerStyle="black"
            onClick={async () =>
              await form.handleSubmit(async () => await onSubmit(true))()
            }
          >
            Save and add another
          </Button>
          <Button
            ml="auto"
            layerStyle="gray"
            onClick={() => navigate(routes.CARRIER_ACCOUNTS)}
            disabled={isLoading}
          >
            Cancel
          </Button>
        </Flex>
      </VStack>
    </VStack>
  );
};

export default AddBrokerCarrierAccountPage;
