import { Icon, IconProps } from "@chakra-ui/react";

export const PersonIcon = (props: IconProps): JSX.Element => (
  <Icon width="11px" height="12px" viewBox="0 0 11 12" fill="none" {...props}>
    <path
      d="M3 3.33333C3 3.95217 3.24583 4.54566 3.68342 4.98325C4.121 5.42083 4.71449 5.66667 5.33333 5.66667C5.95217 5.66667 6.54566 5.42083 6.98325 4.98325C7.42083 4.54566 7.66667 3.95217 7.66667 3.33333C7.66667 2.71449 7.42083 2.121 6.98325 1.68342C6.54566 1.24583 5.95217 1 5.33333 1C4.71449 1 4.121 1.24583 3.68342 1.68342C3.24583 2.121 3 2.71449 3 3.33333V3.33333Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 11C1 9.85075 1.45655 8.74855 2.2692 7.93589C3.08186 7.12323 4.18406 6.66669 5.33333 6.66669C6.4826 6.66669 7.58481 7.12323 8.39746 7.93589C9.21012 8.74855 9.66667 9.85075 9.66667 11"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
