import { Icon, IconProps } from "@chakra-ui/react";

export const EmailIcon = (props: IconProps): JSX.Element => (
  <Icon width="12px" height="10px" viewBox="0 0 12 10" fill="none" {...props}>
    <path
      d="M1 1.42999H11V8.57285H1V1.42999Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.8385 1.69189L6.96043 4.67475C6.68505 4.88659 6.34737 5.00145 5.99995 5.00145C5.65252 5.00145 5.31484 4.88659 5.03947 4.67475L1.16138 1.69189"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
