import {
  Box,
  Text,
  HStack,
  Heading,
  FormLabel,
  Divider,
} from "@chakra-ui/react";
import {
  IBroker,
  IBrokerFinancials,
} from "../../../../../api-platform/internal";
import { getCurrentYear } from "../../../../../reuse/Dates";
import { parseNumberToZeroDecimalCurrency } from "../../../../../reuse/Money";

interface ViewFinancesProps {
  financials: IBrokerFinancials | undefined;
  broker: IBroker;
}

export function ViewFinances({
  financials,
  broker,
}: ViewFinancesProps): JSX.Element {
  return (
    <>
      <Box>
        <FormLabel>SVB Sub-Account</FormLabel>
        <Text>{broker.svbSubAccount}</Text>
      </Box>
      <Box>
        <FormLabel>Quickpay Limit</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(financials?.creditLimit ?? 0),
          )}
        </Text>
      </Box>
      <Divider />
      <Heading size="sm">Total Revenue (Last 3 years)</Heading>
      <HStack spacing="96px">
        <Box>
          <FormLabel>{getCurrentYear() - 1}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(financials?.yearOneRevenue ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 2}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(financials?.yearTwoRevenue ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 3}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(financials?.yearThreeRevenue ?? 0),
            )}
          </Text>
        </Box>
      </HStack>
      <Divider />
      <Heading size="sm">Cash flow (Last 3 years)</Heading>
      <Heading as="h5" size="xs">
        EBITDA (Last 3 years)
      </Heading>
      <HStack spacing="96px">
        <Box>
          <FormLabel>{getCurrentYear() - 1}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(financials?.yearOneEbitda ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 2}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(financials?.yearTwoEbitda ?? 0),
            )}
          </Text>
        </Box>
        <Box>
          <FormLabel>{getCurrentYear() - 3}</FormLabel>
          <Text>
            {parseNumberToZeroDecimalCurrency(
              Number(financials?.yearThreeEbitda ?? 0),
            )}
          </Text>
        </Box>
      </HStack>
      <Box>
        <FormLabel>Last Full Year Net Income</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(financials?.lastYearNetIncome ?? 0),
          )}
        </Text>
      </Box>
      <Box>
        <FormLabel>Last Full Year Interest Expense</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(financials?.lastYearInterestExpense ?? 0),
          )}
        </Text>
      </Box>
      <Box>
        <FormLabel>Unrestricted Cash And Equivalents</FormLabel>
        <Text>
          {parseNumberToZeroDecimalCurrency(
            Number(financials?.unrestrictedCash ?? 0),
          )}
        </Text>
      </Box>
      <Divider />
      <Heading size="sm">Financing</Heading>
      <Box>
        <FormLabel>Total Assets</FormLabel>
        <Text>
          {financials?.totalAssets
            ? parseNumberToZeroDecimalCurrency(Number(financials.totalAssets))
            : ""}
        </Text>
      </Box>
      <Box>
        <FormLabel>Total Debt</FormLabel>
        <Text>
          {financials?.totalDebt
            ? parseNumberToZeroDecimalCurrency(Number(financials.totalDebt))
            : ""}
        </Text>
      </Box>
      <Box>
        <FormLabel>Equity Capital</FormLabel>
        <Text>
          {financials?.equityCapital
            ? parseNumberToZeroDecimalCurrency(Number(financials.equityCapital))
            : ""}
        </Text>
      </Box>
      <Divider />
      <Box>
        <FormLabel>
          Felony charges against any officers in the last 12 months?
        </FormLabel>
        <Text>{financials?.lastYearFelonyCharges ? "Yes" : "No"}</Text>
      </Box>
      <Box>
        <FormLabel>
          Has the company filed for bankruptcy in the last 12 months?
        </FormLabel>
        <Text>{financials?.lastYearBankruptcy ? "Yes" : "No"}</Text>
      </Box>
      <Box>
        <FormLabel>Any unsatisfied judgements?</FormLabel>
        <Text>{financials?.unsatisfiedJudgements ? "Yes" : "No"}</Text>
      </Box>
      <Box>
        <FormLabel>Any tax liens?</FormLabel>
        <Text>{financials?.taxLiens ? "Yes" : "No"}</Text>
      </Box>
      <Divider />
    </>
  );
}
