import { DeleteIcon } from "@chakra-ui/icons";
import { HStack, Radio, Spinner, Text } from "@chakra-ui/react";
import { IModernTreasuryAccountInfo } from "../../api-platform/carrierPayeeAccounts";

interface ExternalAccountItemProps {
  account: IModernTreasuryAccountInfo;
  checked: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  onClickUpdatePrimaryExternalAccount: () => void;
  onClickSelectAccountToDelete: () => void;
}

export const ExternalAccountItem = ({
  account,
  checked,
  isUpdating,
  isDeleting,
  onClickUpdatePrimaryExternalAccount,
  onClickSelectAccountToDelete,
}: ExternalAccountItemProps): JSX.Element => (
  <HStack
    maxW="480px"
    justifyContent="space-between"
    border={checked ? "1px solid #0A81FF" : undefined}
    background="#F7FBFF"
    p="1.01rem"
    borderRadius="4px"
  >
    <HStack>
      {isUpdating && checked ? (
        <Spinner size="sm" color="blue.500" />
      ) : (
        <Radio
          pointerEvents={
            isUpdating || isDeleting || checked ? "none" : undefined
          }
          isChecked={checked}
          onClick={() =>
            !checked &&
            !isUpdating &&
            !isDeleting &&
            onClickUpdatePrimaryExternalAccount()
          }
        />
      )}
      <Text>Account number ending in {account.digits}</Text>
    </HStack>
    {!checked && (
      <DeleteIcon
        cursor="pointer"
        pointerEvents={isUpdating || isDeleting ? "none" : undefined}
        onClick={onClickSelectAccountToDelete}
      />
    )}
  </HStack>
);
