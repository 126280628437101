import { HStack } from "@chakra-ui/layout";
import { Checkbox, Select } from "@chakra-ui/react";
import { useContext } from "react";
import { QuickPayTableContext } from "./QuickPayTableContext";
import { getAllIds } from "./reuse";
import { QuickPayStatusEnum } from "../../types/QuickPayRequest";
import "../../styles/TableFilters.scss";

interface TableFilterProps {
  status: QuickPayStatusEnum;
}

export const TableFilters = ({ status }: TableFilterProps): JSX.Element => {
  const context = useContext(QuickPayTableContext);
  const {
    quickpays,
    selectedQuickPayIds,
    setSelectedQuickPayIds,
    brokerNameFilter,
    setBrokerNameFilter,
    brokerNames,
    carrierNameFilter,
    setCarrierNameFilter,
    carrierNames,
  } = context;

  const allSelected =
    quickpays?.length === selectedQuickPayIds.length &&
    selectedQuickPayIds.length !== 0;

  const onCheckBoxChange = (): void =>
    setSelectedQuickPayIds(allSelected ? [] : getAllIds(quickpays));

  return (
    <HStack display="flex" width="100%" m="0.8rem 0">
      {status === QuickPayStatusEnum.Pending && (
        <Checkbox
          isChecked={allSelected}
          isDisabled={quickpays?.length === 0}
          onChange={onCheckBoxChange}
        />
      )}
      <Select
        backgroundColor="inherit"
        fontSize={13}
        fontWeight={500}
        textColor="gray.500"
        width="auto"
        value={brokerNameFilter ?? ""}
        placeholder="Brokerage"
        onChange={(e) => {
          setBrokerNameFilter(e.target.value);
        }}
        variant="outline"
      >
        {brokerNames?.map((option) => (
          <option key={`broker name select:${option}`} value={option}>
            {option}
          </option>
        ))}
      </Select>

      <Select
        backgroundColor="inherit"
        fontSize={13}
        fontWeight={500}
        textColor="gray.500"
        width="auto"
        value={carrierNameFilter ?? ""}
        placeholder="Carrier"
        onChange={(e) => {
          setCarrierNameFilter(e.target.value);
        }}
        variant="outline"
      >
        {carrierNames?.map((option, idx) => (
          <option key={`carrier name select:${option} - ${idx}`} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </HStack>
  );
};
