import { EmailIcon, ViewIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  VStack,
  Link,
  Divider,
  Button,
  Text,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validateEmail } from "../../reuse/UserData";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { useLoginUserMutation } from "../../api/auth";
import { useSetUserMetaMutation } from "../../api/user";
import { routes } from "../../routes";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { useUsername } from "./components/Layout";
import { useState } from "react";

interface LoginForm {
  username: string;
  password: string;
}

export function Login(): JSX.Element {
  const [loginUser, { isLoading: isLoggingIn }] = useLoginUserMutation();
  const [setUserMeta] = useSetUserMetaMutation();
  const navigate = useNavigate();
  const notificationBanner = useNotificationBanner();
  const form = useForm<LoginForm>();
  const [show, setShow] = useState(false);
  const { setUsername } = useUsername();
  async function onSubmit(data: LoginForm): Promise<void> {
    await loginUser(data)
      .unwrap()
      .then((response) => {
        if (response === "NEW_PASSWORD_REQUIRED") {
          setUsername(data.username);
          navigate(routes.UPDATE_PASSWORD);
          return;
        }
        void setUserMeta();
        navigate(routes.QUICKPAY);
      })
      .catch((error) => {
        notificationBanner({
          status: "error",
          description: error.message,
          duration: 5000,
        });
      });
  }

  return (
    <>
      <VStack
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Heading fontSize="24px" mb="16px">
          Login to MVMNT
        </Heading>
        <FormControl isRequired isInvalid={!!form.formState.errors?.username}>
          <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="gray.400" />
            </InputLeftElement>
            <Input
              {...form.register("username", {
                validate: validateEmail,
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="username" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!form.formState.errors?.password}>
          <FormLabel requiredIndicator={<></>}>Password</FormLabel>
          <InputGroup>
            <InputRightElement cursor="pointer" onClick={() => setShow(!show)}>
              <ViewIcon color="gray.400" />
            </InputRightElement>
            <Input
              type={show ? "text" : "password"}
              placeholder="Min. 11 characters"
              {...form.register("password", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <Flex>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="password" />
            </FormErrorMessage>
            <Link
              mt="10px"
              marginLeft="auto"
              as={RouterLink}
              color="blue.500"
              to={routes.FORGOT_PASSWORD}
              fontSize="13px"
              textDecoration="underline"
            >
              Forgot password?
            </Link>
          </Flex>
        </FormControl>
        <Divider />
        <Button
          isLoading={isLoggingIn}
          type="submit"
          size="lg"
          layerStyle="yellow"
        >
          Login
        </Button>
        <Text fontSize="13px" textAlign="center">
          Don&rsquo;t have an account?{" "}
          <Link
            as={RouterLink}
            color="blue.500"
            to={routes.REQUEST_ACCOUNT}
            textDecoration="underline"
          >
            Request a MVMNT account
          </Link>
        </Text>
      </VStack>
    </>
  );
}
