import { createApi } from "@reduxjs/toolkit/query/react";
import { setUserData, UserReducerState } from "../app/userSlice";
import { User } from "../types/User";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";

export interface ICreateInvitedUser {
  email: string;
  firstName: string;
  lastName: string;
  authToken: string;
}

export const userApi = createApi({
  reducerPath: "userApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}/user`),
  tagTypes: ["User"],
  endpoints: (builder) => ({
    getUser: builder.query<UserReducerState, void>({
      query: () => "/",
      onQueryStarted: async (_arg, api): Promise<void> => {
        try {
          const { dispatch, queryFulfilled } = api;
          const { data } = await queryFulfilled;
          dispatch(setUserData(data));
        } catch (err) {
          alert("Failed to set user data!");
        }
      },
    }),
    setUserMeta: builder.mutation<void, void>({
      query: () => ({
        url: `/meta/`,
        method: "PUT",
      }),
    }),
    createInvitedUser: builder.mutation<User, ICreateInvitedUser>({
      query: (invitedUser) => ({
        url: `/invited-user`,
        method: "POST",
        body: invitedUser,
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useSetUserMetaMutation,
  useCreateInvitedUserMutation,
} = userApi;
