import Header from "../../../components/Header";
import { ChevronLeftIcon, InfoIcon, QuestionIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  VStack,
  Link,
  Divider,
  Button,
  FormErrorMessage,
  Flex,
  Tooltip,
  HStack,
  Select,
  RadioGroup,
  Stack,
  Radio,
  Text,
  Checkbox,
  Spacer,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_MESSAGE_REQUIRED,
} from "../../../reuse/Constants";
import { useNotificationBanner } from "../../../components/useNotificationBanner";
import {
  IAddExternalLoadForQuickpayRequest,
  useAddExternalLoadForQuickpayMutation,
} from "../../../api-platform/externalLoads";
import { Countries, states } from "../../../resources/regions";
import CarrierDetailsBox from "./CarrierDetailsBox";
import { validateUSDollarAmount } from "../../../reuse/UserData";
import { useEffect, useState } from "react";
import { useSubmitQuickpayForAnExternalLoadMutation } from "../../../api-platform/quickpay";
import {
  ExtLoadDocTypeEnum,
  useGeneratePutUrlExtLoadDraftDocumentMutation,
  useGetExtLoadDraftDocumentsQuery,
} from "../../../api-platform/documents";
import { filenameToContentType } from "@aws-amplify/core";
import axios from "axios";
import AppRoutes from "../../../routes";
import { FileUploadProgress } from "../../../ui/components/FileUploadProgress";
import { FileUploadButton } from "../../../ui/components/FileUploadButton";
import { UploadIcon } from "../../../ui/icons";
import {
  ICarrierPayeeAccount,
  useGetCarriersForBrokerQuery,
  useGetExternalAccountsQuery,
} from "../../../api-platform/carrierPayeeAccounts";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import {
  IExtLoadDraftCreate,
  useUpsertExtLoadDraftMutation,
  useGetExtLoadDraftQuery,
  useDeleteExtLoadDraftsMutation,
} from "../../../api-platform/externalLoadsDraft";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCarrierAccountsPageContext } from "../CarrierAccountsPageContext";
import { UploadDoc } from "./UploadDoc/UploadDoc";
import { UploadDocUploaded } from "./UploadDoc/UploadDocUploaded";
import { appearanceApproveBtn } from "../../ManageUsers/constants";
import ReusableConfirmModal from "../../../components/ReusableConfirmModal";

const stateOptions = states[Countries.US];
const DEFAULT_LOAD_PROGRESS = 0.1;

export const AddExternalLoadPage = (): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const notificationBanner = useNotificationBanner();

  const [getPutDraftUrl] = useGeneratePutUrlExtLoadDraftDocumentMutation();

  const [shouldNavigateBcSearchParam, setShouldNavigateBcSearchParam] =
    useState<boolean>(false);

  const { carrierSelectedForDefaultLoad, setCarrierSelectedForDefaultLoad } =
    useCarrierAccountsPageContext();

  const [deleteLoads] = useDeleteExtLoadDraftsMutation();

  const { data: brokerCarriers } = useGetCarriersForBrokerQuery({
    sortOption: "createDate",
    sortDirection: "desc",
  });

  const { data: accounts } = useGetExternalAccountsQuery(
    carrierSelectedForDefaultLoad?.id
      ? {
          id: carrierSelectedForDefaultLoad?.id,
        }
      : skipToken,
  );

  const { data: extLoadDraft } = useGetExtLoadDraftQuery(
    searchParams.get("extLoadDraftId") === null
      ? skipToken
      : {
          extLoadDraftId: searchParams.get("extLoadDraftId")!,
        },
  );

  const { data: extLoadDraftDocs, isLoading } =
    useGetExtLoadDraftDocumentsQuery(
      !extLoadDraft ? skipToken : { externalLoadDraftId: extLoadDraft.id },
    );

  const [createExtLoad] = useAddExternalLoadForQuickpayMutation();
  const [submitQuickPay] = useSubmitQuickpayForAnExternalLoadMutation();
  const [upsertExtLoadDraft] = useUpsertExtLoadDraftMutation();

  const [uploadOtherProgress, setUploadOtherProgress] = useState<
    Array<{
      name: string;
      progress: number;
    }>
  >([]);

  const [otherFilesToUpload, setOtherFilesToUpload] = useState<File[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [uploadInvoiceProgress, setUploadInvoiceProgress] = useState<number>(
    DEFAULT_LOAD_PROGRESS,
  );
  const [carrierInvoiceFileToUpload, setCarrierInvoiceFileToUpload] =
    useState<File | null>(null);

  const [uploadRateConProgress, setUploadRateConProgress] = useState<number>(
    DEFAULT_LOAD_PROGRESS,
  );
  const [rateConToUpload, setRateConToUpload] = useState<File | null>(null);

  const [uploadBOLProgress, setUploadBOLProgress] = useState<number>(
    DEFAULT_LOAD_PROGRESS,
  );
  const [bolToUpload, setBOLToUpload] = useState<File | null>(null);

  const [brokerCarriersList, setBrokerCarriersList] = useState<
    ICarrierPayeeAccount[] | undefined
  >();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
    useState<boolean>(false);

  const form = useForm<IAddExternalLoadForQuickpayRequest>();

  const carrierWithoutPaymentsMethodsAvailables =
    accounts?.length === 0 ||
    !carrierSelectedForDefaultLoad?.primaryExternalAccountId;

  const alertUser = (e: BeforeUnloadEvent): void => {
    e.preventDefault();
    // String does not get used, but value must be set
    e.returnValue = "Leaving now may cause file uploads to fail.";
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const onCreateDraft = async (): Promise<void> => {
    const formValues: IExtLoadDraftCreate =
      form.getValues() as unknown as IExtLoadDraftCreate;

    const result = await upsertExtLoadDraft(
      searchParams.get("extLoadDraftId") === null
        ? {
            body: {
              data: {
                ...formValues,
                carrierName: carrierSelectedForDefaultLoad?.name ?? "",
                brokerId: carrierSelectedForDefaultLoad?.brokerId ?? "",
                carrierInvoiceAmount: Number(formValues.carrierInvoiceAmount),
                carrierPayeeAccountId: carrierSelectedForDefaultLoad?.id ?? "",
                signedBOL: null,
              },
            },
          }
        : {
            body: {
              id: searchParams.get("extLoadDraftId")!,
              data: {
                ...formValues,
                carrierName: carrierSelectedForDefaultLoad?.name ?? "",
                brokerId: carrierSelectedForDefaultLoad?.brokerId ?? "",
                carrierInvoiceAmount: Number(formValues.carrierInvoiceAmount),
                carrierPayeeAccountId: carrierSelectedForDefaultLoad?.id ?? "",
                signedBOL: null,
              },
            },
          },
    );

    if ("error" in result)
      notificationBanner({
        status: "error",
        description:
          "There was an error creating the draft, please try again later",
        duration: null,
        isClosable: true,
      });
    else {
      const uploadResponse = await uploadDocuments(result.data.id);
      if (uploadResponse.errors.length) {
        // TODO: display individual file upload errors?
        notificationBanner({
          status: "error",
          description:
            "One or more files failed to upload, but your load draft was created successfully, please use the QuickPay page to handle the rest of your request",
          duration: null,
          isClosable: true,
        });
      }

      notificationBanner({
        status: "success",
        description:
          searchParams.get("extLoadDraftId") !== null ? (
            <p>External Load draft saved</p>
          ) : (
            <p>External Load draft created</p>
          ),
      });
      if (location.pathname === AppRoutes.DRAFT) {
        navigate(-1);
      } else {
        if (searchParams.get("extLoadDraftId") === null) {
          setCarrierSelectedForDefaultLoad(undefined);
        } else {
          setShouldNavigateBcSearchParam(true);
        }
      }
    }
  };

  const submitForQP = async (extLoadId: string): Promise<void> => {
    const qpResponse = await submitQuickPay({
      body: {
        ids: [extLoadId],
      },
    });

    if ("error" in qpResponse) {
      notificationBanner({
        status: "error",
        description: (
          <p>
            Load created and files uploaded, but failed to submit QuickPay.
            Please handle the rest of your request from the QuickPay page
          </p>
        ),
        duration: null,
        isClosable: true,
      });
      if (searchParams.get("extLoadDraftId") === null) {
        setCarrierSelectedForDefaultLoad(undefined);
      } else {
        setShouldNavigateBcSearchParam(true);
      }
    } else {
      notificationBanner({
        status: "success",
        description: <p>QuickPay submission success</p>,
      });

      if (location.pathname === AppRoutes.DRAFT) {
        navigate(-1);
      } else {
        if (searchParams.get("extLoadDraftId") === null) {
          setCarrierSelectedForDefaultLoad(undefined);
        } else {
          setShouldNavigateBcSearchParam(true);
        }
      }
    }
  };

  const createAndSubmitLoad = async (
    values: IAddExternalLoadForQuickpayRequest,
    draftId?: string,
  ): Promise<void> => {
    await createExtLoad({
      carrierPayeeId: carrierSelectedForDefaultLoad!.id,
      iAddExternalLoadForQuickpayRequest: {
        ...values,
        draftId: draftId ?? searchParams.get("extLoadDraftId") ?? undefined,
        signedBOL: false,
      },
    })
      .unwrap()
      .then(async (response) => {
        await submitForQP(response.id);

        notificationBanner({
          status: "success",
          description: (
            <p>Your MVMNT Pay request was submitted successfully.</p>
          ),
          duration: null,
          isClosable: true,
        });
      })
      .catch((error) => {
        notificationBanner({
          status: "error",
          description: error.data.message,
          duration: null,
          isClosable: true,
        });
      });
  };

  interface ResponseError {
    data: {
      message: string;
    };
  }

  async function onSubmit(): Promise<void> {
    window.addEventListener("beforeunload", alertUser);
    if (carrierWithoutPaymentsMethodsAvailables) {
      notificationBanner({
        status: "error",
        description:
          "Carrier doesn't have any payment method yet. Create one please.",
        duration: null,
        isClosable: true,
      });
    } else {
      setLoading(true);
      const values = form.getValues();

      const docTypes: ExtLoadDocTypeEnum[] =
        extLoadDraftDocs?.map((doc) => doc.documentType) ?? [];

      const rateConDoc = docTypes.includes("RateCon");
      const invoiceDoc = docTypes.includes("Invoice");
      const bolDoc = docTypes.includes("BillOfLading");

      if (
        (!rateConToUpload && !rateConDoc) ||
        (!carrierInvoiceFileToUpload && !invoiceDoc) ||
        (!bolToUpload && !bolDoc)
      ) {
        notificationBanner({
          status: "error",
          description:
            "Please attach a valid carrier invoice, rate confirmation and BOL.",
          duration: null,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (carrierSelectedForDefaultLoad) {
        try {
          const updateResponse = await upsertExtLoadDraft(
            searchParams.get("extLoadDraftId") === null
              ? {
                  body: {
                    data: {
                      ...values,
                      carrierName: carrierSelectedForDefaultLoad?.name ?? "",
                      brokerId: carrierSelectedForDefaultLoad?.brokerId ?? "",
                      carrierInvoiceAmount: Number(values.carrierInvoiceAmount),
                      carrierPayeeAccountId:
                        carrierSelectedForDefaultLoad?.id ?? "",
                      signedBOL: null,
                    },
                  },
                }
              : {
                  body: {
                    id: searchParams.get("extLoadDraftId")!,
                    data: {
                      ...values,
                      carrierName: carrierSelectedForDefaultLoad?.name ?? "",
                      brokerId: carrierSelectedForDefaultLoad?.brokerId ?? "",
                      carrierInvoiceAmount: Number(values.carrierInvoiceAmount),
                      carrierPayeeAccountId:
                        carrierSelectedForDefaultLoad?.id ?? "",
                      signedBOL: null,
                    },
                  },
                },
          ).unwrap();

          const uploadResponse = await uploadDocuments(updateResponse!.id);

          if (uploadResponse.errors.length) {
            // TODO: display individual file upload errors?
            notificationBanner({
              status: "error",
              description:
                "One or more files failed to upload, but your load was updated successfully, please use the QuickPay page to handle the rest of your request",
              duration: null,
              isClosable: true,
            });

            if (location.pathname === AppRoutes.DRAFT) {
              navigate(-1);
            } else {
              if (searchParams.get("extLoadDraftId") === null) {
                setCarrierSelectedForDefaultLoad(undefined);
              } else {
                setShouldNavigateBcSearchParam(true);
              }
            }
          } else {
            await createAndSubmitLoad(values, updateResponse.id);
          }
        } catch (error: unknown) {
          if ((error as ResponseError).data.message) {
            notificationBanner({
              status: "error",
              description: (error as ResponseError).data.message,
              duration: null,
              isClosable: true,
            });
          } else {
            notificationBanner({
              status: "error",
              description: DEFAULT_ERROR_MESSAGE,
            });
          }
        }
      }
    }
    setLoading(false);
  }

  async function uploadDocuments(
    loadId: string,
  ): Promise<{ errors: string[] }> {
    const errors: string[] = [];
    // Upload Invoice
    if (carrierInvoiceFileToUpload) {
      const invoiceError = await uploadFile(
        carrierInvoiceFileToUpload,
        "Invoice",
        loadId,
      );
      if (invoiceError) {
        errors.push(invoiceError);
      }
    } else if (
      !extLoadDraftDocs?.some((doc) => doc.documentType === "Invoice")
    ) {
      errors.push("Carrier Invoice file not found");
    }

    // Upload RateCon
    if (rateConToUpload) {
      const rateConError = await uploadFile(rateConToUpload, "RateCon", loadId);
      if (rateConError) {
        errors.push(rateConError);
      }
    } else if (
      !extLoadDraftDocs?.some((doc) => doc.documentType === "RateCon")
    ) {
      errors.push("Rate Confirmation file not found");
    }
    // Upload BOL
    if (bolToUpload) {
      const bolError = await uploadFile(bolToUpload, "BillOfLading", loadId);
      if (bolError) {
        errors.push(bolError);
      }
    } else if (
      !extLoadDraftDocs?.some((doc) => doc.documentType === "BillOfLading")
    ) {
      errors.push("BOL file not found");
    }
    // Upload Others
    const uploadPromises = otherFilesToUpload.map(async (file, i) => {
      try {
        const uploadUrl = await getPutDraftUrl({
          extLoadDocumentUpload: {
            contentType: filenameToContentType(file.name),
            fileName: file.name,
          },
          id: loadId,
        }).unwrap();

        await axios.put(uploadUrl, file, {
          onUploadProgress: (progressEvent) => {
            setUploadOtherProgress((current) =>
              current.map((fileProgress) => {
                if (fileProgress.name === file.name) {
                  return {
                    name: file.name,
                    progress:
                      (progressEvent.loaded / progressEvent.total) * 100,
                  };
                } else {
                  return fileProgress;
                }
              }),
            );
          },
        });
      } catch (_err) {
        errors.push(`${file.name} failed to upload`);
      }
    });
    await Promise.all(uploadPromises);
    return { errors };
  }

  const uploadFile = async (
    file: File,
    type: ExtLoadDocTypeEnum,
    loadId: string,
  ): Promise<string | void> => {
    try {
      const uploadUrl = await getPutDraftUrl({
        extLoadDocumentUpload: {
          contentType: filenameToContentType(file.name),
          fileName: file.name,
          type,
        },
        id: loadId,
      }).unwrap();
      await axios.put(uploadUrl, file, {
        onUploadProgress: (progressEvent) => {
          if (type === "Invoice") {
            setUploadInvoiceProgress(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
          } else if (type === "RateCon") {
            setUploadRateConProgress(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
          } else if (type === "BillOfLading") {
            setUploadBOLProgress(
              (progressEvent.loaded / progressEvent.total) * 100,
            );
          }
        },
      });
    } catch (_err) {
      return `${file.name} failed to upload`;
    }
  };

  const addOtherFile = (file: File): void => {
    setUploadOtherProgress([
      ...uploadOtherProgress,
      { name: file.name, progress: DEFAULT_LOAD_PROGRESS },
    ]);
    setOtherFilesToUpload([...otherFilesToUpload, file]);
  };

  const removeOtherFile = (name: string): void => {
    setUploadOtherProgress(
      uploadOtherProgress.filter((item) => item.name !== name),
    );
    setOtherFilesToUpload(
      otherFilesToUpload.filter((item) => item.name !== name),
    );
  };

  const updateCarrierSelectedForDefaultLoad = (
    brokerCarriers: ICarrierPayeeAccount[],
  ): void => {
    const selectedCarrier = brokerCarriers.find(
      ({ id }) => id === carrierSelectedForDefaultLoad?.id,
    );
    setCarrierSelectedForDefaultLoad(selectedCarrier);
  };

  useEffect(() => {
    if (brokerCarriers && brokerCarriers.length !== 0) {
      setBrokerCarriersList(brokerCarriers as ICarrierPayeeAccount[]);
      updateCarrierSelectedForDefaultLoad(brokerCarriers);
    }
  }, [brokerCarriers]);

  useEffect(() => {
    if (extLoadDraft) {
      form.setValue("PODRequired", extLoadDraft.PODRequired!);
      form.setValue("brokerShipmentId", extLoadDraft.brokerShipmentId!);
      form.setValue("carrierInvoiceAmount", extLoadDraft.carrierInvoiceAmount!);
      form.setValue("carrierInvoiceId", extLoadDraft.carrierInvoiceId!);
      form.setValue(
        "carrierInvoiceMatchRateCon",
        extLoadDraft.carrierInvoiceMatchRateCon!,
      );
      form.setValue("customerName", extLoadDraft.customerName!);
      form.setValue(
        "customerReferenceNumber",
        extLoadDraft.customerReferenceNumber!,
      );
      form.setValue(
        "customerReferenceType",
        extLoadDraft.customerReferenceType!,
      );
      form.setValue("destinationCity", extLoadDraft.destinationCity!);
      form.setValue("destinationState", extLoadDraft.destinationState!);
      form.setValue("originCity", extLoadDraft.originCity!);
      form.setValue("originState", extLoadDraft.originState!);
    }
  }, [extLoadDraft]);

  useEffect(() => {
    if (extLoadDraft) {
      setCarrierSelectedForDefaultLoad(
        brokerCarriersList?.find(
          (carrier) => carrier.id === extLoadDraft?.carrierPayeeAccountId,
        ),
      );
    }
  }, [extLoadDraft, brokerCarriersList]);

  useEffect(() => {
    if (shouldNavigateBcSearchParam) {
      navigate(-1);
    }
    setShouldNavigateBcSearchParam(false);
  }, [shouldNavigateBcSearchParam]);

  const LoadDetails = (): JSX.Element => {
    return (
      <>
        <Heading as="h3" fontSize="20px">
          Who’s getting paid?
        </Heading>

        <FormControl>
          <FormLabel requiredIndicator={<></>}>Carrier (Required)</FormLabel>
          <Select
            disabled={loading}
            w="50%"
            value={carrierSelectedForDefaultLoad?.id}
            onChange={(event) => {
              setCarrierSelectedForDefaultLoad(
                brokerCarriersList?.find((c) => c.id === event.target.value),
              );
            }}
          >
            <option value="">Select carrier</option>
            {brokerCarriersList?.map((carrier) => (
              <option
                key={`add single qp form carrier option:${carrier.id}`}
                value={carrier.id}
              >
                {carrier.name}
              </option>
            ))}
          </Select>
        </FormControl>

        {carrierSelectedForDefaultLoad && (
          <FormControl
            isInvalid={!carrierSelectedForDefaultLoad?.primaryExternalAccountId}
          >
            <FormErrorMessage>
              This carrier has no payment details.
            </FormErrorMessage>
          </FormControl>
        )}

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.carrierInvoiceId}
        >
          <FormLabel requiredIndicator={<></>}>
            Carrier Invoice ID (Required)
          </FormLabel>
          <InputGroup>
            <Input
              disabled={loading}
              w="50%"
              {...form.register("carrierInvoiceId", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="carrierInvoiceId"
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.carrierInvoiceAmount}
        >
          <FormLabel requiredIndicator={<></>}>
            Carrier Invoice Amount (Required)
          </FormLabel>
          <InputGroup>
            <Input
              disabled={loading}
              type="number"
              w="50%"
              {...form.register("carrierInvoiceAmount", {
                required: DEFAULT_MESSAGE_REQUIRED,
                validate: (value) => validateUSDollarAmount(String(value)),
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="carrierInvoiceAmount"
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.carrierInvoiceMatchRateCon}
        >
          <VStack alignItems="left">
            <FormLabel requiredIndicator={<></>} whiteSpace="nowrap">
              Is the carrier invoice for the same amount as agreed upon in the
              ratecon? (Required)
              <Tooltip
                placement="bottom-end"
                hasArrow
                label="MVMNT only pays carriers for standard loads where the invoice amount is the same as the rate agreed upon in the Rate Confirmation document. Invoices with discrepancies due to unexpected additional charges, such as detention, are not accepted at this time."
              >
                <InfoIcon w="15px" h="15px" margin="0px 5px" />
              </Tooltip>
            </FormLabel>
            <HStack>
              <Checkbox
                disabled={loading}
                {...form.register("carrierInvoiceMatchRateCon", {
                  required:
                    "To request a quickpay, the carrier invoice and ratecon amounts must match.",
                })}
                size="md"
                backgroundColor={"realWhite"}
              />
              <Text fontSize="15px" color="gray.500">
                Yes, I confirm that the amounts are the same
              </Text>
            </HStack>
          </VStack>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="carrierInvoiceMatchRateCon"
            />
          </FormErrorMessage>
        </FormControl>
      </>
    );
  };

  const CustomerDetails = (): JSX.Element => {
    return (
      <>
        <Heading as="h3" fontSize="20px" mb="16px">
          Tell us a bit more about this load
        </Heading>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.customerName}
        >
          <FormLabel requiredIndicator={<></>}>
            Customer Name (Required)
          </FormLabel>
          <InputGroup>
            <Input
              disabled={loading}
              w="50%"
              {...form.register("customerName", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="customerName" />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.brokerShipmentId}
        >
          <FormLabel requiredIndicator={<></>}>
            Shipment or Load ID (Required)
          </FormLabel>
          <InputGroup>
            <Input
              disabled={loading}
              w="50%"
              {...form.register("brokerShipmentId", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="brokerShipmentId"
            />
          </FormErrorMessage>
        </FormControl>

        <HStack w="100%">
          <FormControl isInvalid={!!form.formState.errors?.originCity}>
            <FormLabel requiredIndicator={<></>}>Origin City</FormLabel>
            <InputGroup>
              <Input
                disabled={loading}
                w="100%"
                {...form.register("originCity", {
                  required: false,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="originCity" />
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!form.formState.errors?.originState}>
            <FormLabel requiredIndicator={<></>}>Origin State</FormLabel>
            <Select
              disabled={loading}
              {...form.register("originState", {
                required: false,
              })}
            >
              <option value="">Select an option</option>
              {stateOptions.map((state) => (
                <option
                  key={`state origin option extLoad add${state.name}`}
                  value={state.abbreviation}
                >
                  {state.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="originState" />
            </FormErrorMessage>
          </FormControl>
        </HStack>
        <HStack w="100%">
          <FormControl isInvalid={!!form.formState.errors?.destinationCity}>
            <FormLabel requiredIndicator={<></>}>Destination City</FormLabel>
            <InputGroup>
              <Input
                disabled={loading}
                w="100%"
                {...form.register("destinationCity", {
                  required: false,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage
                errors={form.formState.errors}
                name="destinationCity"
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!form.formState.errors?.destinationState}>
            <FormLabel requiredIndicator={<></>}>Destination State</FormLabel>
            <Select
              disabled={loading}
              {...form.register("destinationState", {
                required: false,
              })}
            >
              <option value="">Select an option</option>
              {stateOptions.map((state) => (
                <option
                  key={`state destination option extLoad add${state.name}`}
                  value={state.abbreviation}
                >
                  {state.name}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <ErrorMessage
                errors={form.formState.errors}
                name="destinationState"
              />
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <HStack w="100%">
          <FormControl
            isRequired
            isInvalid={!!form.formState.errors?.customerReferenceType}
          >
            <FormLabel requiredIndicator={<></>}>
              Customer Reference Type
            </FormLabel>
            <Select
              {...form.register("customerReferenceType")}
              disabled={loading}
            >
              <option value="">Select an option</option>
              <option value="Bill of Lading">Bill of Lading</option>
              <option value="Purchase Order">Purchase Order</option>
              <option value="Pickup #">Pickup #</option>
              <option value="Item # ID">Item # ID</option>
              <option value="Delivery #">Delivery #</option>
              <option value="Master BOL #">Master BOL #</option>
              <option value="Load ID">Load ID</option>
              <option value="GL Code">GL Code</option>
              <option value="Shipment ID">Shipment ID</option>
            </Select>
            <FormErrorMessage>
              <ErrorMessage
                errors={form.formState.errors}
                name="customerReferenceType"
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!form.formState.errors?.customerReferenceNumber}
          >
            <FormLabel requiredIndicator={<></>}>
              Reference Number{" "}
              <Tooltip label="number the customer associates to the load">
                <QuestionIcon w="15px" h="15px" margin="0px 5px" />
              </Tooltip>
            </FormLabel>
            <InputGroup>
              <Input
                disabled={loading}
                {...form.register("customerReferenceNumber", {
                  required: false,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage
                errors={form.formState.errors}
                name="customerReferenceNumber"
              />
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl isRequired>
          <FormLabel requiredIndicator={<></>}>
            Is proof of delivery required?
          </FormLabel>
          <RadioGroup
            value={
              String(form.watch("PODRequired")) === "true" ? "true" : "false"
            }
          >
            <Stack direction="row">
              <Radio
                disabled={loading}
                {...form.register("PODRequired")}
                value={"true"}
              >
                Yes
              </Radio>
              <Radio
                disabled={loading}
                {...form.register("PODRequired")}
                value={"false"}
              >
                No
              </Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
      </>
    );
  };

  const UploadDocuments = (): JSX.Element => {
    return (
      <>
        <Heading as="h3" fontSize="20px" mb="16px">
          Upload documents
        </Heading>

        <UploadDoc
          isLoading={isLoading}
          extLoadDraftDoc={
            extLoadDraftDocs?.filter((doc) => doc.documentType === "Invoice")[0]
          }
          title="Carrier Invoice (Required)"
          progress={uploadInvoiceProgress}
          fileState={carrierInvoiceFileToUpload}
          setFileState={setCarrierInvoiceFileToUpload}
          loading={loading}
        />
        <UploadDoc
          isLoading={isLoading}
          extLoadDraftDoc={
            extLoadDraftDocs?.filter((doc) => doc.documentType === "RateCon")[0]
          }
          title="Rate Confirmation (Required)"
          progress={uploadRateConProgress}
          fileState={rateConToUpload}
          setFileState={setRateConToUpload}
          loading={loading}
        />
        <UploadDoc
          isLoading={isLoading}
          extLoadDraftDoc={
            extLoadDraftDocs?.filter(
              (doc) => doc.documentType === "BillOfLading",
            )[0]
          }
          title="Bill of Lading (Required)"
          progress={uploadBOLProgress}
          fileState={bolToUpload}
          setFileState={setBOLToUpload}
          loading={loading}
        />

        {extLoadDraftDocs
          ?.filter((doc) => doc.documentType === "Other")
          .map((docOther, i) => (
            <UploadDocUploaded
              extLoadDraftDoc={docOther}
              key={`other docs uploaded:${docOther.documentName} ${i}`}
            />
          ))}

        {otherFilesToUpload?.map((file, i) => (
          <FileUploadProgress
            key={`other docs upload progress:${file.name} ${i}`}
            value={uploadOtherProgress[i]?.progress}
            fileName={file.name}
            onCancel={() => {
              if (!loading) {
                removeOtherFile(file.name);
              }
            }}
          />
        ))}
        <FormControl>
          <FormLabel requiredIndicator={<></>}>
            Additional Documents (Optional)
          </FormLabel>
          <FileUploadButton
            disabled={loading}
            // This clears the file input each time a file is added
            // Not sure if I actually want this here, but the only reason I can think
            // that this may be useful is if someone adds a file
            // removes it, and then tries to add the file again
            render={(file, { clearFileInput }) => {
              if (file) clearFileInput();
              return <></>;
            }}
            layerStyle="black"
            onChange={(file) => {
              if (file) addOtherFile(file);
            }}
            icon={<UploadIcon />}
          >
            Upload File
          </FileUploadButton>
        </FormControl>
      </>
    );
  };

  const deleteLoadAfterConfirmation = async (): Promise<void> => {
    await deleteLoads({
      body: { ids: [searchParams.get("extLoadDraftId")!] },
    }).then(() => navigate(-1));
  };

  return (
    <VStack w="100%" alignItems="start">
      <Flex
        backgroundColor="grey6"
        w="100%"
        padding="1rem 4rem 1rem"
        flexDir={"column"}
        alignItems="start"
      >
        <Button
          disabled={loading}
          onClick={() => {
            if (location.pathname === AppRoutes.DRAFT) {
              navigate(-1);
            } else {
              setCarrierSelectedForDefaultLoad(undefined);
            }
          }}
          layerStyle="gray"
          leftIcon={<ChevronLeftIcon />}
          p={0}
        >
          <Link>Back</Link>
        </Button>
        <Header as="h1" fontSize="32px" headingText="Pay your carrier" />
        <Text color="gray.600" fontSize="15px" lineHeight="20px" mt={2}>
          The form below can be used to create a MVMNT Pay request. It can also
          be used to create a draft payment to send later. Fields marked
          required must be completed before submitting your request.
        </Text>
      </Flex>
      <HStack w="100%" alignItems="start">
        <VStack
          maxW="625px"
          padding="1rem 4rem 1rem"
          w="100%"
          as="form"
          noValidate
          align="content-start"
          spacing="24px"
          onSubmit={form.handleSubmit(async () => {
            await onSubmit();
            window.removeEventListener("beforeunload", alertUser);
          })}
        >
          <LoadDetails />

          <Divider />

          <CustomerDetails />

          <Divider />

          <UploadDocuments />

          <Divider />

          <Flex>
            <Button
              disabled={loading}
              color="mvmntRed"
              outlineColor="mvmntRed"
              variant="ghost"
              ml="auto"
              onClick={async () => {
                searchParams.get("extLoadDraftId") === null
                  ? navigate(-1)
                  : setShowConfirmDeleteModal(true);
              }}
            >
              Delete
            </Button>
            <Spacer />
            <Button mr="10px" layerStyle="gray" onClick={onCreateDraft}>
              Save for later
            </Button>
            <Button
              mr="10px"
              layerStyle="yellow"
              type="submit"
              disabled={
                loading ||
                !carrierSelectedForDefaultLoad?.primaryExternalAccountId
              }
            >
              Send payment
            </Button>
          </Flex>
        </VStack>
        {carrierSelectedForDefaultLoad && (
          <CarrierDetailsBox
            noPaymentsMethodsAvailables={
              carrierWithoutPaymentsMethodsAvailables
            }
            carrierSelectedForDefaultLoad={carrierSelectedForDefaultLoad}
          />
        )}
      </HStack>
      {showConfirmDeleteModal && (
        <ReusableConfirmModal
          isOpen={showConfirmDeleteModal}
          onClose={() => {
            setShowConfirmDeleteModal(false);
          }}
          onClickCTA={deleteLoadAfterConfirmation}
          title={"Delete Draft"}
          content={"Are you sure you want to delete this draft?"}
          cancelText="Cancel"
          confirmText="Confirm"
          appearanceConfirmBtn={appearanceApproveBtn}
        />
      )}
    </VStack>
  );
};

export default AddExternalLoadPage;
