import {
  Container,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  forwardRef,
  Box,
  BoxProps,
} from "@chakra-ui/react";
import { Header } from "./components/Header";
import {
  OrgAccountStatus,
  useGetOrgAccountsQuery,
  useGetOrgQuery,
} from "../../api/admin";
import { DataTableSkeleton } from "../../ui/components/DataTable";
import { UsersHeader } from "./components/UsersHeader";
import { UsersTable } from "./components/UsersTable";
import { AddIcon } from "@chakra-ui/icons";
import { MenuIcon } from "../../ui/icons";
import { useEffect, useState } from "react";
import InviteUserModal from "./components/Modals/InviteUserModal";
import { useGetUnacceptedOrgInvitesQuery } from "../../api/org";
import { skipToken } from "@reduxjs/toolkit/dist/query";

const MenuIconButton = forwardRef<BoxProps, "div">((props, ref) => (
  <Box as="span" cursor="pointer" ref={ref} {...props}>
    <MenuIcon />
  </Box>
));

export function Users(): JSX.Element {
  const [showUserInviteModal, setShowUserInviteModal] =
    useState<boolean>(false);
  const { data: org } = useGetOrgQuery();
  const { data: orgAccounts, isLoading: loadingOrgAccounts } =
    useGetOrgAccountsQuery();
  const { data: orgInvites, isLoading: loadingInvites } =
    useGetUnacceptedOrgInvitesQuery(org?.id ?? skipToken);

  useEffect(() => {
    if (showUserInviteModal && !org) {
      alert("Org and Broker Not set, cannot invite new user");
      setShowUserInviteModal(false);
    }
  }, [showUserInviteModal]);

  return (
    <>
      {showUserInviteModal && org && (
        <InviteUserModal
          orgId={org.id}
          brokerId={org.broker.id}
          isOpen
          onClose={() => setShowUserInviteModal(false)}
        />
      )}
      <Header breadcrumb={{ to: "", title: "Manage users" }} heading="Users">
        <UsersHeader orgAccounts={orgAccounts ?? []} />
      </Header>
      <Container maxW="none" padding="0px 40px">
        {!loadingOrgAccounts && !loadingInvites ? (
          <UsersTable
            orgAccounts={orgAccounts ?? []}
            orgInvites={orgInvites ?? []}
            menuCell={(cell) => {
              const status = cell.row.original.status;
              return (
                <Menu>
                  <MenuButton as={MenuIconButton} />
                  <MenuList>
                    {status === OrgAccountStatus.ACTIVE && (
                      <>
                        <MenuItem>Change role</MenuItem>
                        <MenuItem>Deactivate</MenuItem>
                      </>
                    )}
                    {status === OrgAccountStatus.DELIVERED && (
                      <>
                        <MenuItem>Change role</MenuItem>
                        <MenuItem>Resend invite</MenuItem>
                        <MenuItem>Revoke invite</MenuItem>
                      </>
                    )}
                    {status === OrgAccountStatus.INACTIVE && (
                      <>
                        <MenuItem>Reactivate</MenuItem>
                      </>
                    )}
                  </MenuList>
                </Menu>
              );
            }}
            afterFilters={
              <Button
                onClick={() => setShowUserInviteModal(true)}
                leftIcon={<AddIcon />}
                layerStyle="yellow"
              >
                Invite
              </Button>
            }
          />
        ) : (
          <DataTableSkeleton />
        )}
      </Container>
    </>
  );
}
