import { mvmntApiPlatform } from "./generated";
export * from "./generated";

mvmntApiPlatform.enhanceEndpoints({
  endpoints: {
    getExtLoadDraft: {
      providesTags: (_response, _errors, arg) => [
        { type: "ExternalLoadsDraft", id: arg.extLoadDraftId },
      ],
    },
    upsertExtLoadDraft: {
      invalidatesTags: (_response, _errors, arg) => [
        { type: "ExternalLoadsDraft", id: arg.body.id },
        { type: "ExternalLoadsDraftDocuments", id: arg.body.id },
        "ExternalLoadsDraft",
      ],
    },
    getAllExtLoadDraftsView: {
      providesTags: ["ExternalLoadsDraft"],
    },
    getDraftBrokerExternalLoadCustomers: {
      providesTags: ["ExternalLoadsDraft"],
    },
    getDraftBrokerExternalLoadOrigins: {
      providesTags: ["ExternalLoadsDraft"],
    },
    deleteExtLoadDrafts: {
      invalidatesTags: ["ExternalLoadsDraft"],
    },
    uploadLoadsFromTemplate: {
      invalidatesTags: [
        "ExternalLoads",
        "ExternalLoadCustomers",
        "ExternalLoadCarriers",
      ],
    },
    duplicateExternalLoadOrDraft: {
      invalidatesTags: ["ExternalLoadsDraft"],
    },
    submitDraftForQuickpay: {
      invalidatesTags: ["ExternalLoadsDraft"],
    },
  },
});
