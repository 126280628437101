import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createBrokerAccountRequestLead: build.mutation<
      CreateBrokerAccountRequestLeadApiResponse,
      CreateBrokerAccountRequestLeadApiArg
    >({
      query: (queryArg) => ({
        url: `/broker-account-requests`,
        method: "POST",
        body: queryArg.iCreateBrokerAccountRequestLead,
      }),
    }),
    getBrokerAccountRequestLead: build.query<
      GetBrokerAccountRequestLeadApiResponse,
      GetBrokerAccountRequestLeadApiArg
    >({
      query: (queryArg) => ({ url: `/broker-account-requests/${queryArg.id}` }),
    }),
    updateRequestLead: build.mutation<
      UpdateRequestLeadApiResponse,
      UpdateRequestLeadApiArg
    >({
      query: (queryArg) => ({
        url: `/broker-account-requests/${queryArg.id}/info`,
        method: "PUT",
        body: queryArg.iEditBrokerAccountRequestLeadInfo,
      }),
    }),
    updateRequestLeadFinancials: build.mutation<
      UpdateRequestLeadFinancialsApiResponse,
      UpdateRequestLeadFinancialsApiArg
    >({
      query: (queryArg) => ({
        url: `/broker-account-requests/${queryArg.id}/financials`,
        method: "PUT",
        body: queryArg.iEditBrokerAccountRequestLeadFinancials,
      }),
    }),
    approveBrokerAccountRequest: build.mutation<
      ApproveBrokerAccountRequestApiResponse,
      ApproveBrokerAccountRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/broker-account-requests/${queryArg.id}/approve`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type CreateBrokerAccountRequestLeadApiResponse = unknown;
export type CreateBrokerAccountRequestLeadApiArg = {
  iCreateBrokerAccountRequestLead: ICreateBrokerAccountRequestLead;
};
export type GetBrokerAccountRequestLeadApiResponse =
  /** status 200 Returned */ IBrokerAccountRequestLead;
export type GetBrokerAccountRequestLeadApiArg = {
  id: string;
};
export type UpdateRequestLeadApiResponse =
  /** status 200 Updated */ IBrokerAccountRequestLead;
export type UpdateRequestLeadApiArg = {
  id: string;
  iEditBrokerAccountRequestLeadInfo: IEditBrokerAccountRequestLeadInfo;
};
export type UpdateRequestLeadFinancialsApiResponse =
  /** status 200 Updated */ IBrokerAccountRequestLead;
export type UpdateRequestLeadFinancialsApiArg = {
  id: string;
  iEditBrokerAccountRequestLeadFinancials: IEditBrokerAccountRequestLeadFinancials;
};
export type ApproveBrokerAccountRequestApiResponse =
  /** status 201 Created */ IApproveRequestLeadResponse;
export type ApproveBrokerAccountRequestApiArg = {
  id: string;
};
export type ICreateBrokerAccountRequestLead = {
  brokerName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};
export type IBrokerAccountRequestLead = {
  address: string | null;
  addressCity: string | null;
  addressCountry: string | null;
  addressOne: string | null;
  addressPostal: string | null;
  addressState: string | null;
  addressTwo: string | null;
  approvalDate: string | null;
  brokerName: string;
  companyPhone: string | null;
  companyWebsite: string | null;
  createDate: string;
  creditLimit: number | null;
  email: string;
  equityCapital: number | null;
  firstName: string;
  id: string;
  lastName: string;
  lastYearBankruptcy: boolean | null;
  lastYearFelonyCharges: boolean | null;
  lastYearInterestExpense: number | null;
  lastYearNetIncome: number | null;
  mcNumber: string | null;
  monthlyIncome: number | null;
  monthlyRevenue: number | null;
  phone: string;
  rejectionDate: string | null;
  salesforceId: string | null;
  taxIdentificationNumber: string | null;
  taxLiens: boolean | null;
  totalAssets: number | null;
  totalDebt: number | null;
  unrestrictedCash: number | null;
  unsatisfiedJudgements: boolean | null;
  updateDate: string;
  yearOneEbitda: number | null;
  yearOneRevenue: number | null;
  yearThreeEbitda: number | null;
  yearThreeRevenue: number | null;
  yearTwoEbitda: number | null;
  yearTwoRevenue: number | null;
  readyForApproval: boolean;
};
export type IEditBrokerAccountRequestLeadInfo = {
  brokerName: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyWebsite: string | null;
  mcNumber: string | null;
  salesforceId: string | null;
  address: string | null;
  addressOne: string | null;
  addressTwo: string | null;
  addressCity: string | null;
  addressCountry: string | null;
  addressPostal: string | null;
  addressState: string | null;
  companyPhone: string | null;
  taxIdentificationNumber: string | null;
};
export type IEditBrokerAccountRequestLeadFinancials = {
  equityCapital: number | null;
  lastYearBankruptcy: boolean | null;
  lastYearFelonyCharges: boolean | null;
  lastYearInterestExpense: number | null;
  lastYearNetIncome: number | null;
  monthlyIncome: number | null;
  monthlyRevenue: number | null;
  taxLiens: boolean | null;
  totalAssets: number | null;
  totalDebt: number | null;
  unrestrictedCash: number | null;
  unsatisfiedJudgements: boolean | null;
  yearOneEbitda: number | null;
  yearOneRevenue: number | null;
  yearThreeEbitda: number | null;
  yearThreeRevenue: number | null;
  yearTwoEbitda: number | null;
  yearTwoRevenue: number | null;
  creditLimit: number | null;
};
export type IApproveRequestLeadResponse = {
  brokerId: string;
  userAuthTokenSet: boolean;
};
export const {
  useCreateBrokerAccountRequestLeadMutation,
  useGetBrokerAccountRequestLeadQuery,
  useLazyGetBrokerAccountRequestLeadQuery,
  useUpdateRequestLeadMutation,
  useUpdateRequestLeadFinancialsMutation,
  useApproveBrokerAccountRequestMutation,
} = injectedRtkApi;
