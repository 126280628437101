import { VStack, Flex, Box } from "@chakra-ui/layout";
import Header from "../../components/Header";
import { Button, Link, Image, Text } from "@chakra-ui/react";
import EmptyStateGraphic from "../../resources/png/EmptyStateGraphic.png";
import AppRoutes from "../../routes";
import { Link as RouterLink } from "react-router-dom";
import BrokerCarrierAccountTable from "./BrokerCarrierAccountTable";
import { useState } from "react";
import { CarrierAccountsPageContext } from "./CarrierAccountsPageContext";
import {
  ICarrierPayeeAccount,
  useGetCarriersForBrokerQuery,
} from "../../api-platform/carrierPayeeAccounts";
import AddExternalLoadPage from "./AddExternalLoadPage/AddExternalLoadPage";

export const BrokerCarrierAccountsPage = (): JSX.Element => {
  const { data, isLoading } = useGetCarriersForBrokerQuery({});
  const [carrierSelectedForDefaultLoad, setCarrierSelectedForDefaultLoad] =
    useState<ICarrierPayeeAccount | undefined>();

  const getAddCarrierButton = (): JSX.Element => {
    return (
      <Link as={RouterLink} to={AppRoutes.CARRIER_ACCOUNTS_ADD}>
        <Button layerStyle="yellow" mr="5px">
          + Add Carrier
        </Button>
      </Link>
    );
  };

  return (
    <CarrierAccountsPageContext.Provider
      value={{
        carrierSelectedForDefaultLoad,
        setCarrierSelectedForDefaultLoad,
      }}
    >
      <VStack w="100%">
        {carrierSelectedForDefaultLoad ? (
          <AddExternalLoadPage />
        ) : (
          <>
            <Flex
              backgroundColor="grey6"
              w="100%"
              padding="1rem 4rem 1rem"
              justifyContent="space-between"
            >
              <Header as="h1" fontSize="32px" headingText="Carriers" />
              <Flex>{getAddCarrierButton()}</Flex>
            </Flex>
            {!isLoading && (
              <>
                {data?.length ? (
                  <Box w="100%" p="1rem">
                    <BrokerCarrierAccountTable />
                  </Box>
                ) : (
                  <>
                    <Text
                      as="h3"
                      color="gray.900"
                      fontSize="20px"
                      fontWeight="600"
                    >
                      You haven&apos;t added any carriers yet
                    </Text>
                    {getAddCarrierButton()}
                    <Image src={EmptyStateGraphic} alt="No Carriers" />
                  </>
                )}
              </>
            )}
          </>
        )}
      </VStack>
    </CarrierAccountsPageContext.Provider>
  );
};

export default BrokerCarrierAccountsPage;
