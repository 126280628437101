import { mvmntApi, OrgAccountsKey, OrgInvitesKey } from "./mvmnt.api";

export enum OrgType {
  BROKER = "BROKER",
}

export enum OrgStatus {
  ACTIVE = "ACTIVE",
  HOLD = "HOLD",
}

export interface Broker {
  id: string;
  orgId: string;
  name: string;
}

export interface UserOrg {
  id: string;
  createDate: string;
  updateDate: string;
  type: OrgType;
  name: string;
  status: OrgStatus;
  broker: Broker;
}

export enum OrgAccountStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  DELIVERED = "DELIVERED",
  HOLD = "HOLD",
}

export interface Role {
  id: string;
  name: string;
}
export interface OrgAccount {
  id: string;
  orgId: string;
  status: OrgAccountStatus;
  isAdmin: boolean;
  lastLoginDate: string;
  deactivatedDate: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  orgAccountRoles: Array<{
    id: string;
    role: Role;
    brokerId: string;
  }>;
}

export interface ICreateOrgInvite {
  emails: string[];
  roleId: string;
  orgId: string;
  brokerId: string;
}

export const administrationApi = mvmntApi.injectEndpoints({
  endpoints: (builder) => ({
    inviteUsersToOrg: builder.mutation<void, ICreateOrgInvite>({
      query: (body) => ({
        url: "/admin/org/invite",
        method: "POST",
        body,
      }),
      invalidatesTags: [OrgAccountsKey, OrgInvitesKey],
    }),
    getOrg: builder.query<UserOrg, void>({
      query: () => ({
        url: `/admin/org`,
        method: "GET",
      }),
    }),
    getOrgAccounts: builder.query<OrgAccount[], void>({
      query: () => ({
        url: `/admin/org/accounts`,
        method: "GET",
      }),
      providesTags: [OrgAccountsKey],
    }),
  }),
});

export const {
  useGetOrgQuery,
  useGetOrgAccountsQuery,
  useInviteUsersToOrgMutation,
} = administrationApi;
