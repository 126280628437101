import { Text } from "@chakra-ui/react";
import {
  GetPaymentsHistoryApiResponse,
  PaymentsTableData,
} from "../../api-platform/quickpay";

import { getHistoricalDateText } from "../../reuse/Dates";
import { DataTable, useDataTable } from "../../ui/components/DataTable";

interface PaymentsTableProps {
  payments: GetPaymentsHistoryApiResponse | undefined;
}

export const PaymentsTable = ({
  payments,
}: PaymentsTableProps): JSX.Element => {
  const table = useDataTable<PaymentsTableData>({
    data: payments ?? [],
    columns: [
      {
        header: "DATE",
        accessorKey: "date",
        cell: (cellInfo) => {
          return (
            <Text>
              {getHistoricalDateText(cellInfo.row.original.paymentDate)}
            </Text>
          );
        },
      },
      {
        header: "ACTIVITY",
        accessorKey: "activity",
        cell: (cellInfo) => {
          return <Text>{cellInfo.row.original.activity}</Text>;
        },
      },
      {
        header: "AMOUNT",
        accessorKey: "amount",
        cell: (cellInfo) => {
          return (
            <Text
              color={
                cellInfo.row.original.direction === "credit"
                  ? "successMedium"
                  : ""
              }
              fontWeight="bold"
            >
              {cellInfo.row.original.direction === "debit"
                ? "$" + cellInfo.row.original.amount.toString()
                : "+$" + cellInfo.row.original.amount.toString()}
            </Text>
          );
        },
      },
      {
        header: "AVAILABLE",
        accessorKey: "available",
        cell: (cellInfo) => {
          return (
            <Text fontWeight="bold">
              {"$" + cellInfo.row.original.available.toString()}
            </Text>
          );
        },
      },
    ],
  });

  return <DataTable table={table} />;
};
