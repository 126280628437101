import { mvmntApiPlatform } from "./generated";
export * from "./generated";

mvmntApiPlatform.enhanceEndpoints({
  addTagTypes: [
    "CarrierPayeeAccounts",
    "CarrierPayeePublicInfo",
    "CarrierPayeeAccount",
    "CarrierPaymentDetails",
  ],
  endpoints: {
    getCarriersForBroker: {
      providesTags: ["CarrierPayeeAccounts"],
    },
    createCarrierPayeeAccount: {
      invalidatesTags: ["CarrierPayeeAccounts"],
    },
    createCarriersAccountStatus: {
      invalidatesTags: ["CarrierPayeeAccounts"],
    },
    getPublicCarrierPayeeAccountById: {
      providesTags: ["CarrierPayeePublicInfo"],
    },
    signAgreement: {
      invalidatesTags: ["CarrierPayeePublicInfo"],
    },
    getCarrierPayeeAccountById: {
      providesTags: (_response, _errors, arg) => [
        { type: "CarrierPayeeAccount", id: arg.id },
      ],
    },
    updateCarrierPayeeAccount: {
      invalidatesTags: (_response, _errors, arg) => [
        { type: "CarrierPayeeAccount", id: arg.id },
        "CarrierPayeeAccounts",
      ],
    },
    getExternalAccounts: {
      providesTags: ["CarrierPaymentDetails"],
    },
    createExternalAccount: {
      invalidatesTags: [
        "CarrierPaymentDetails",
        "CarrierPayeeAccount",
        "CarrierPayeeAccounts",
      ],
    },
    updateCarrierPrimaryExternalAccount: {
      invalidatesTags: ["CarrierPaymentDetails", "CarrierPayeeAccount"],
    },
    deleteCarrierPrimaryExternalAccount: {
      invalidatesTags: ["CarrierPaymentDetails", "CarrierPayeeAccount"],
    },
  },
});
