import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Text,
  CloseButton,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Link,
  HStack,
} from "@chakra-ui/react";
import { CsvError } from "../../api-platform/externalLoadsDraft";
import { errorIcon } from "../../resources/svg/CustomIcons";
import { DefaultModalProps } from "../../types/DefaultModal";

interface CSVErrorsModalProps extends DefaultModalProps {
  errors: CsvError[];
  templateHref: string | undefined;
}

export const CSVErrorsModal = ({
  onClose,
  errors,
  templateHref,
}: CSVErrorsModalProps): JSX.Element => {
  const ErrorIcon = errorIcon("none");

  const renderCSVError = (error: CsvError, index: number): JSX.Element => {
    return (
      <Tr key={`error-row-${index}`}>
        <Td>{error.row}</Td>
        <Td>{error.invoice_amount}</Td>
        <Td>{error.message}</Td>
      </Tr>
    );
  };

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="2xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" justifyContent="space-between">
          Upload Failed <CloseButton onClick={onClose} justifySelf="end" />
        </ModalHeader>
        <ModalBody p={6}>
          <HStack px={3} pb={2}>
            <ErrorIcon w={"20px"} h={"20px"}></ErrorIcon>
            <Text>
              {"We couldn't upload your file because it has the following"}{" "}
              <b>{errors.length} error(s):</b>
            </Text>
          </HStack>
          <TableContainer whiteSpace={"normal"}>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Row</Th>
                  <Th>Value</Th>
                  <Th>Error</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {errors.map((err, index) => renderCSVError(err, index))}
              </Tbody>
            </Table>
          </TableContainer>
          <Text px={3} pt={2}>
            {"Make sure you’re using"}{" "}
            <Link color="blue" href={templateHref}>
              our template
            </Link>{" "}
            as a guide!
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="flex-end" borderTop={"none"} pt={0}>
          <Button layerStyle="gray" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CSVErrorsModal;
