import { Icon, IconProps } from "@chakra-ui/react";

export const RequestMoneyIcon = (props: IconProps): JSX.Element => (
  <Icon
    width={props.w ?? "38"}
    height={props.h ?? "38"}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M0.625 11.875V19.375M0.625 18.125H14.375C14.375 17.462 14.1116 16.8261 13.6428 16.3573C13.1739 15.8884 12.538 15.625 11.875 15.625H8.75M8.75 15.625C8.75 14.962 8.48661 14.3261 8.01777 13.8573C7.54893 13.3884 6.91304 13.125 6.25 13.125H0.625M8.75 15.625H5.625M16.8667 15.0067C17.7749 14.3301 18.4815 13.4188 18.9105 12.3706C19.3395 11.3225 19.4746 10.1772 19.3013 9.05802C19.128 7.93882 18.6529 6.88805 17.927 6.01871C17.2011 5.14938 16.252 4.49439 15.1817 4.12418L16.7258 1.48252C16.7767 1.39571 16.8036 1.2969 16.8036 1.19627C16.8036 1.09564 16.7767 0.996823 16.7258 0.910018C16.673 0.822392 16.5983 0.750031 16.509 0.700058C16.4197 0.650085 16.319 0.624223 16.2167 0.625018H10.0333C9.9313 0.624513 9.83089 0.650512 9.74193 0.700471C9.65297 0.75043 9.57851 0.822636 9.52583 0.910018C9.47492 0.996823 9.44809 1.09564 9.44809 1.19627C9.44809 1.2969 9.47492 1.39571 9.52583 1.48252L11.07 4.12418C9.84729 4.54688 8.78647 5.3397 8.03476 6.39261C7.28304 7.44552 6.87771 8.70631 6.875 10M11.4167 11.5367C11.5971 11.7737 11.8321 11.9638 12.1016 12.0907C12.3712 12.2176 12.6673 12.2777 12.965 12.2659C13.9142 12.2659 14.6842 11.6884 14.6842 10.9767C14.6842 10.265 13.9142 9.68835 12.965 9.68835C12.0158 9.68835 11.25 9.11085 11.25 8.39835C11.25 7.68585 12.02 7.10918 12.9683 7.10918M12.9683 7.10918C13.266 7.09712 13.5623 7.15711 13.8318 7.28406C14.1014 7.411 14.3363 7.60116 14.5167 7.83835M12.9683 7.10918V6.25002M12.9683 12.2659V13.125"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
