import { SearchIcon } from "@chakra-ui/icons";
import {
  HStack,
  Text,
  Select,
  InputLeftElement,
  InputGroup,
  Input,
} from "@chakra-ui/react";
import { useContext } from "react";
import { CarrierTableContext } from "./CarrierTableContext";

interface BrokerCarrierAccountSubheadProps {
  totalDisplayedCarriers: number | undefined;
}

export const BrokerCarrierAccountSubhead = ({
  totalDisplayedCarriers,
}: BrokerCarrierAccountSubheadProps): JSX.Element => {
  const context = useContext(CarrierTableContext);
  const { sortOption, setSortOption, searchTerm, setSearchTerm } = context;

  return (
    <HStack
      w="100%"
      h="60px"
      backgroundColor="grey6"
      padding="1rem 1rem"
      justify="space-between"
      minW="740px"
    >
      <div className="subHeadLeft">
        <div className="totalDisplayedCarriersContainer">
          <Text color="gray70">
            Showing {totalDisplayedCarriers} carrier
            {totalDisplayedCarriers !== 1 && "s"}
          </Text>
        </div>
        <div className="sortContainer">
          <Text color="gray70" fontSize={15} mr={4}>
            {" "}
            Sort By:{" "}
          </Text>
          <Select
            w="inherit"
            variant="unstyled"
            value={sortOption}
            onChange={(e) => {
              if (e.target.value) {
                setSortOption("createDate");
              }
            }}
          >
            <option value={"createDate"}>Date added</option>
          </Select>
        </div>
      </div>

      <div className="searchContainer">
        <InputGroup w="100%" borderRadius="8px">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.400" />
          </InputLeftElement>
          <Input
            placeholder="Search carrier name, MC number or contact"
            borderRadius="4px"
            borderColor="grey5"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target?.value);
            }}
          />
        </InputGroup>
      </div>
    </HStack>
  );
};

export default BrokerCarrierAccountSubhead;
