import { createContext, useContext } from "react";
import { URLSearchParamsInit } from "react-router-dom";

interface IBrokerQPPageContext {
  searchParams: URLSearchParams;
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?:
      | {
          replace?: boolean | undefined;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          state?: any;
        }
      | undefined,
  ) => void;
}

const BrokerQPPageContext = createContext<IBrokerQPPageContext>({
  searchParams: new URLSearchParams(),
  setSearchParams: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

const useBrokerQPPageContext = (): IBrokerQPPageContext =>
  useContext(BrokerQPPageContext);

export { BrokerQPPageContext, useBrokerQPPageContext };
