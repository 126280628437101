import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IBroker } from "../api-platform/internal";
import { User, UserType } from "../types/User";
import { RootState } from "./store";

export interface UserReducerState {
  user: User;
  broker?: IBroker;
}

const user: User = {
  id: "",
  firstName: "",
  lastName: "",
  email: "",
  type: UserType.BROKER,
  authToken: "",
};

const initialState: UserReducerState = {
  user,
};

export const userSlice = createSlice({
  name: "userReducer",
  initialState,
  reducers: {
    setUserData: (
      state,
      { payload: data }: PayloadAction<UserReducerState>,
    ) => ({
      ...state,
      user: data.user,
      broker: data.broker,
    }),
  },
});
export const selectUserData = (state: RootState): UserReducerState => {
  return {
    user: state.userReducer.user,
    broker: state.userReducer.broker,
  };
};

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
