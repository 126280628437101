import { Text, HStack } from "@chakra-ui/react";
import { useManageUserContext } from "../ManageUserContext";

export const BrokerDetailsBreadcrumbs = (): JSX.Element => {
  const context = useManageUserContext();
  const { selectedBroker, setSelectedBroker } = context!;
  return (
    <HStack>
      <Text
        cursor="pointer"
        as="u"
        color={"grey3"}
        onClick={() => setSelectedBroker(undefined)}
      >
        Manage Users
      </Text>
      <Text color={"grey5"}> / </Text>
      <Text color={"grey2"}>
        {selectedBroker?.contactInformation.firstName}{" "}
        {selectedBroker?.contactInformation.lastName}
      </Text>
    </HStack>
  );
};

export default BrokerDetailsBreadcrumbs;
