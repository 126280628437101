import { Routes, Route } from "react-router-dom";
import { Admin } from "./Admin";
import { Users } from "./Users";
import { Layout } from "./components/Layout";

export const ADMIN_ROUTES = {
  INDEX: "",
  USERS: "users",
} as const;

export function AdminRoute(): JSX.Element {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route path={ADMIN_ROUTES.INDEX} element={<Admin />} />
          <Route path={ADMIN_ROUTES.USERS} element={<Users />} />
        </Route>
      </Routes>
    </>
  );
}
