import { VStack, Text, Heading } from "@chakra-ui/layout";
import { useCarrierTableContext } from "./CarrierTableContext";

export const NoResults = (): JSX.Element => {
  const context = useCarrierTableContext();
  const { searchTerm } = context!;

  return (
    <VStack mt={16}>
      <Heading as="h3" fontSize="xl">
        {`No results found ${searchTerm === "" ? "" : `for '${searchTerm}'`} `}
      </Heading>
      <Text fontSize="xs" fontWeight={500} color="gray.700">
        Please try searching with another term
      </Text>
    </VStack>
  );
};
