import { IconButton, Tooltip } from "@chakra-ui/react";
import { CommentIcon } from "../../ui/icons";

interface CommentTooltipProps {
  comment: string;
}

export const CommentTooltip = ({
  comment,
}: CommentTooltipProps): JSX.Element => (
  <Tooltip label={comment}>
    <IconButton icon={<CommentIcon />} bgColor="white" aria-label={""} />
  </Tooltip>
);

export default CommentTooltip;
