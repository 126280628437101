import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createCarrierPayeeAccount: build.mutation<
      CreateCarrierPayeeAccountApiResponse,
      CreateCarrierPayeeAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account`,
        method: "POST",
        body: queryArg.omitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId,
      }),
    }),
    getCarriersForBroker: build.query<
      GetCarriersForBrokerApiResponse,
      GetCarriersForBrokerApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account`,
        params: {
          sortOption: queryArg.sortOption,
          sortDirection: queryArg.sortDirection,
          searchTerm: queryArg.searchTerm,
        },
      }),
    }),
    getPublicCarrierPayeeAccountById: build.query<
      GetPublicCarrierPayeeAccountByIdApiResponse,
      GetPublicCarrierPayeeAccountByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/public`,
      }),
    }),
    getCarrierPayeeAccountById: build.query<
      GetCarrierPayeeAccountByIdApiResponse,
      GetCarrierPayeeAccountByIdApiArg
    >({
      query: (queryArg) => ({ url: `/carrier-payee-account/${queryArg.id}` }),
    }),
    createCarriersAccountStatus: build.mutation<
      CreateCarriersAccountStatusApiResponse,
      CreateCarriersAccountStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getExternalAccounts: build.query<
      GetExternalAccountsApiResponse,
      GetExternalAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/external-accounts`,
      }),
    }),
    createExternalAccount: build.mutation<
      CreateExternalAccountApiResponse,
      CreateExternalAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/external-accounts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    updateCarrierPrimaryExternalAccount: build.mutation<
      UpdateCarrierPrimaryExternalAccountApiResponse,
      UpdateCarrierPrimaryExternalAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/external-accounts`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteCarrierPrimaryExternalAccount: build.mutation<
      DeleteCarrierPrimaryExternalAccountApiResponse,
      DeleteCarrierPrimaryExternalAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/external-accounts`,
        method: "DELETE",
        body: queryArg.body,
      }),
    }),
    signAgreement: build.mutation<
      SignAgreementApiResponse,
      SignAgreementApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/carrier-agreement`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    updateCarrierPayeeAccount: build.mutation<
      UpdateCarrierPayeeAccountApiResponse,
      UpdateCarrierPayeeAccountApiArg
    >({
      query: (queryArg) => ({
        url: `/carrier-payee-account/${queryArg.id}/information`,
        method: "PUT",
        body: queryArg.iUpdateCarrierPayeeAccount,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type CreateCarrierPayeeAccountApiResponse =
  /** status 201 Created */ ICarrierPayeeAccount;
export type CreateCarrierPayeeAccountApiArg = {
  omitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId: OmitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId;
};
export type GetCarriersForBrokerApiResponse =
  /** status 200 Returned */ ICarrierPayeeAccount[];
export type GetCarriersForBrokerApiArg = {
  sortOption?: "createDate";
  sortDirection?: "asc" | "desc";
  searchTerm?: string;
};
export type GetPublicCarrierPayeeAccountByIdApiResponse =
  /** status 200 Returned */ ICarrierPayeePublicInfo;
export type GetPublicCarrierPayeeAccountByIdApiArg = {
  id: string;
};
export type GetCarrierPayeeAccountByIdApiResponse =
  /** status 200 Returned */ ICarrierPayeeAccount;
export type GetCarrierPayeeAccountByIdApiArg = {
  id: string;
};
export type CreateCarriersAccountStatusApiResponse =
  /** status 201 Returned */ {
    status: "ACTIVE" | "INACTIVE" | "PENDING";
  };
export type CreateCarriersAccountStatusApiArg = {
  id: string;
  body: {
    status: CarrierAccountStatusEnum;
  };
};
export type GetExternalAccountsApiResponse =
  /** status 200 Returned */ IModernTreasuryAccountInfo[];
export type GetExternalAccountsApiArg = {
  id: string;
};
export type CreateExternalAccountApiResponse = /** status 201 Returned */ {
  externalAccountId: string;
};
export type CreateExternalAccountApiArg = {
  id: string;
  body: {
    achRoutingNumber: string;
    accountNumber: string;
  };
};
export type UpdateCarrierPrimaryExternalAccountApiResponse = unknown;
export type UpdateCarrierPrimaryExternalAccountApiArg = {
  id: string;
  body: {
    primaryExternalAccountId: string;
  };
};
export type DeleteCarrierPrimaryExternalAccountApiResponse = unknown;
export type DeleteCarrierPrimaryExternalAccountApiArg = {
  id: string;
  body: {
    carrierExternalAccountId: string;
  };
};
export type SignAgreementApiResponse = unknown;
export type SignAgreementApiArg = {
  id: string;
  body: {
    fullName: string;
  };
};
export type UpdateCarrierPayeeAccountApiResponse =
  /** status 201 Returned */ ICarrierPayeeAccount;
export type UpdateCarrierPayeeAccountApiArg = {
  id: string;
  iUpdateCarrierPayeeAccount: IUpdateCarrierPayeeAccount;
};
export type CarrierTypeIdEnum = "DOT number" | "Motor Carrier number" | "Other";
export type CarrierAccountStatusEnum = "ACTIVE" | "INACTIVE" | "PENDING";
export type ICarrierPayeeAccount = {
  id: string;
  brokerId: string;
  createDate: string;
  updateDate: string;
  corporateAddress: string | null;
  carrierId: string;
  typeId: CarrierTypeIdEnum;
  name: string;
  phone: string | null;
  primaryContactEmail: string | null;
  primaryContactFirstName: string | null;
  primaryContactLastName: string | null;
  primaryContactPhone: string | null;
  counterpartyId: string | null;
  ledgerAccountId: string | null;
  agreementSignDate: string | null;
  agreementSigner: string | null;
  accountStatus: CarrierAccountStatusEnum | null;
  lastPaidDate: string | null;
  primaryExternalAccountId: string | null;
  brokerPaysFee: boolean;
  carrierQpPercentageFee: string | null;
  brokerQpPercentageFee: string | null;
  brokerCarrierQpMaxFee: string | null;
  brokerCarrierQpMinFee: string | null;
};
export type PickICreateCarrierPayeeAccountExcludeKeyofICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId =
  {
    name: string;
    carrierId: string;
    typeId: CarrierTypeIdEnum;
    accountNumber: string | null;
    achRoutingNumber: string | null;
    corporateAddress: string | null;
    phone: string | null;
    primaryContactEmail: string | null;
    primaryContactFirstName: string | null;
    primaryContactLastName: string | null;
    primaryContactPhone: string | null;
    brokerPaysFee: boolean;
    carrierQpPercentageFee?: number;
  };
export type OmitICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId =
  PickICreateCarrierPayeeAccountExcludeKeyofICreateCarrierPayeeAccountBrokerIdOrCounterpartyIdOrLedgerAccountId;
export type ICarrierPayeePublicInfo = {
  name: string;
  agreementSignDate: string | null;
  agreementSigner: string | null;
  brokerName: string | null;
  brokerQPPercentageFee: number | null;
};
export type IModernTreasuryAccountInfo = {
  mtId?: string;
  id?: string;
  digits: string;
};
export type IUpdateCarrierPayeeAccount = {
  name?: string;
  carrierId?: string;
  typeId?: CarrierTypeIdEnum;
  corporateAddress?: string | null;
  phone?: string | null;
  primaryContactEmail?: string | null;
  primaryContactFirstName?: string | null;
  primaryContactLastName?: string | null;
  primaryContactPhone?: string | null;
  brokerPaysFee?: boolean;
  carrierQpPercentageFee?: number;
};
export const {
  useCreateCarrierPayeeAccountMutation,
  useGetCarriersForBrokerQuery,
  useLazyGetCarriersForBrokerQuery,
  useGetPublicCarrierPayeeAccountByIdQuery,
  useLazyGetPublicCarrierPayeeAccountByIdQuery,
  useGetCarrierPayeeAccountByIdQuery,
  useLazyGetCarrierPayeeAccountByIdQuery,
  useCreateCarriersAccountStatusMutation,
  useGetExternalAccountsQuery,
  useLazyGetExternalAccountsQuery,
  useCreateExternalAccountMutation,
  useUpdateCarrierPrimaryExternalAccountMutation,
  useDeleteCarrierPrimaryExternalAccountMutation,
  useSignAgreementMutation,
  useUpdateCarrierPayeeAccountMutation,
} = injectedRtkApi;
