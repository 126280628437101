import {
  Button,
  FormControl,
  Input,
  VStack,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ErrorMessage from "../../components/ErrorMessage";
import SignInPageHOC from "../../components/SignInPageHOC/SignInPageHOC";
import AppRoutes from "../../routes";
import { validatePassword } from "../../reuse/UserData";
import { RegexPoliceProp } from "../../types/Regex";
import RegexPolicesList from "../../components/RegexPolicesList";
import { borderStyling } from "../../reuse/Forms";
import { FormError } from "../../types/FormError";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useSignUpUserNoPhoneMutation } from "../../api/auth";
import { useCreateInvitedUserMutation } from "../../api/user";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { useGetOrgInviteEmailQuery } from "../../api/org";

interface IRegisterInvitedUserForm {
  password: string;
  firstName: string;
  lastName: string;
}

const SignUpByInvitation = (): JSX.Element => {
  const {
    register,
    watch,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<IRegisterInvitedUserForm>();
  const { inviteId } = useParams();
  const { data: invitedEmail } = useGetOrgInviteEmailQuery(
    inviteId ?? skipToken,
  );
  const [signUp] = useSignUpUserNoPhoneMutation();
  const [createInvitedUser] = useCreateInvitedUserMutation();

  const navigate = useNavigate();
  const [regexList, setRegexList] = useState<RegexPoliceProp[]>([]);

  const notificationBanner = useNotificationBanner();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "password") {
        const regexList = validatePassword(value.password!);
        setRegexList(regexList);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleSignUpByInvitation = async (
    signUpData: IRegisterInvitedUserForm,
  ): Promise<void> => {
    if (!invitedEmail) {
      alert("could not find email");
      return;
    }

    const { password } = signUpData;
    const signUpResponse = await signUp({
      email: invitedEmail,
      password,
    });
    if ("data" in signUpResponse) {
      const createUserResponse = await createInvitedUser({
        email: invitedEmail,
        firstName: signUpData.firstName,
        lastName: signUpData.lastName,
        authToken: signUpResponse.data,
      });
      if ("data" in createUserResponse) {
        notificationBanner({
          status: "success",
          description: <p>Sign up successful.</p>,
        });
        navigate(AppRoutes.LOGIN);
      } else {
        notificationBanner({
          status: "error",
          description: <p>Failed to create user.</p>,
        });
      }
    } else {
      notificationBanner({
        status: "error",
        description: <p>Failed to sign up user, please try again.</p>,
      });
    }
  };

  const onSubmit = handleSubmit(handleSignUpByInvitation);

  return (
    <form onSubmit={onSubmit}>
      <VStack spacing="1rem" w="403px" bg="white" borderRadius="5px" p="30px">
        <FormControl isDisabled={false}>
          <FormLabel requiredIndicator={<></>}>First Name</FormLabel>
          <Input
            placeholder="First Name"
            {...register("firstName", {
              required: {
                value: true,
                message: "You must enter your first name",
              },
            })}
            style={{ borderColor: borderStyling(errors?.firstName) }}
          />
        </FormControl>

        <FormControl isDisabled={false}>
          <FormLabel requiredIndicator={<></>}>Last Name</FormLabel>
          <Input
            placeholder="Last Name"
            {...register("lastName", {
              required: {
                value: true,
                message: "You must enter your last name",
              },
            })}
            style={{ borderColor: borderStyling(errors?.lastName) }}
          />
        </FormControl>

        <FormControl isDisabled={false}>
          <FormLabel requiredIndicator={<></>}>Create Password</FormLabel>
          <Input
            type="password"
            placeholder="Password"
            {...register("password", {
              required: {
                value: true,
                message: "You must enter your password",
              },
            })}
            style={{ borderColor: borderStyling(errors?.password) }}
          />
        </FormControl>

        <RegexPolicesList
          regexList={regexList}
          columns={"1fr"}
          color="black"
          alignSelf="start"
        />

        <Text textAlign="center" size="xSmall" color="#7d7d7d" marginBottom="4">
          By clicking Agree & Join, you agree to the MVMNT User Agreement,
          Privacy Policy, and Cookie Policy.
        </Text>

        <Button
          isDisabled={false}
          layerStyle="yellow"
          w="50%"
          onClick={onSubmit}
        >
          Submit
        </Button>

        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
      </VStack>
    </form>
  );
};

export default SignInPageHOC(SignUpByInvitation);
