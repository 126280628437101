import {
  FormLabel,
  Heading,
  Text,
  VStack,
  Button,
  HStack,
  FormControl,
  FormErrorMessage,
  Input,
  Spacer,
  Switch,
  InputGroup,
  InputRightElement,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNotificationBanner } from "../../../../components/useNotificationBanner";
import { DEFAULT_MESSAGE_REQUIRED } from "../../../../reuse/Constants";
import { ErrorMessage } from "@hookform/error-message";
import { IBroker } from "../../../../api-platform/generated";
import {
  IUpdateBrokerPaymentControls,
  useUpdateBrokerPaymentControlsMutation,
  useUpdateBrokerQuickPayEnableMutation,
} from "../../../../api-platform/internal";
import { EditIcon } from "@chakra-ui/icons";
import {
  validateMaximumPercent,
  validateMinimumPercent,
} from "../../../../reuse/UserData";

export function EditPaymentControls({
  broker,
}: {
  broker: IBroker;
}): JSX.Element {
  const notificationBanner = useNotificationBanner();
  const [updatePaymentControls, { isLoading: updatingControls }] =
    useUpdateBrokerPaymentControlsMutation();
  const [toggleBrokerQuickPayEnable, { isLoading: updatingEnabled }] =
    useUpdateBrokerQuickPayEnableMutation();
  const [editMode, setEditMode] = useState<boolean>(false);

  const controlsForm = useForm<IUpdateBrokerPaymentControls>({
    defaultValues: {
      carrierQpPercentageFeeMax: broker.carrierQpPercentageFeeMax,
      carrierQpPercentageFeeMin: broker.carrierQpPercentageFeeMin,
      brokerQpPercentageFee: broker.brokerQpPercentageFee,
    },
  });

  async function onSubmit(data: IUpdateBrokerPaymentControls): Promise<void> {
    await updatePaymentControls({
      id: broker.id,
      iUpdateBrokerPaymentControls: data,
    })
      .unwrap()
      .then(() => {
        notificationBanner({
          status: "success",
          description: <p>Changes saved</p>,
        });
        setEditMode(false);
      })
      .catch((_error) => {
        notificationBanner({
          status: "error",
          description: "Failed to update QuickPay Percents.",
        });
      });
  }

  const onToggleQPEnabled = async (): Promise<void> => {
    const response = toggleBrokerQuickPayEnable({
      id: broker.id,
    });
    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: (
          <p>There was a problem making your change, please try again.</p>
        ),
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Updated.</p>,
      });
    }
  };

  return (
    <VStack spacing="20px" align="start" pb="40px" w="100%">
      <VStack w="100%">
        <HStack w="100%">
          <Heading
            h="40px"
            display="flex"
            alignItems="center"
            size="md"
            w="100%"
            justifyContent="start"
          >
            MVMNT Pay controls
          </Heading>
          <Spacer />
          <Button
            leftIcon={<EditIcon />}
            visibility={editMode ? "hidden" : "visible"}
            onClick={() => setEditMode(true)}
          >
            Edit
          </Button>
        </HStack>
        <HStack w="100%">
          <Switch
            disabled={updatingControls || updatingEnabled}
            colorScheme="teal"
            size="lg"
            isChecked={broker.quickpayEnabled}
            onChange={onToggleQPEnabled}
          />
          <VStack alignItems="start" spacing={0}>
            <FormLabel m={0} color={"gray.700"} requiredIndicator={<></>}>
              {broker.quickpayEnabled ? "MVMNT Pay On" : "MVMNT Pay off"}
            </FormLabel>
            <FormLabel m={0} color={"gray.500"} requiredIndicator={<></>}>
              {broker.quickpayEnabled
                ? "The broker can request MVMNT Pays"
                : "The broker cannot request MVMNT Pays"}
            </FormLabel>
          </VStack>
        </HStack>
      </VStack>
      <HStack
        as="form"
        onSubmit={controlsForm.handleSubmit(onSubmit)}
        w="100%"
        alignItems="start"
      >
        <VStack w="50%" alignItems="start">
          {editMode ? (
            <>
              <FormControl
                isRequired
                isInvalid={
                  !!controlsForm.formState.errors?.brokerQpPercentageFee
                }
              >
                <HStack w="100%" justifyContent="space-between">
                  <FormLabel requiredIndicator={<></>} w="30%">
                    Brokerage fee
                  </FormLabel>
                  <InputGroup w="103px">
                    <Input
                      type="number"
                      step={0.01}
                      {...controlsForm.register("brokerQpPercentageFee", {
                        required: DEFAULT_MESSAGE_REQUIRED,
                        min: 0,
                        max: 10,
                        valueAsNumber: true,
                      })}
                    />
                    <InputRightElement>%</InputRightElement>
                  </InputGroup>
                </HStack>
                <FormErrorMessage>
                  <ErrorMessage
                    errors={controlsForm.formState.errors}
                    name="brokerQpPercentageFee"
                  />
                </FormErrorMessage>
              </FormControl>
              <HStack w="100%" justifyContent="space-between">
                <FormLabel w="30%" requiredIndicator={<></>}>
                  Carrier Fee Range
                </FormLabel>
                <HStack>
                  <FormControl
                    isRequired
                    isInvalid={
                      !!controlsForm.formState.errors?.carrierQpPercentageFeeMin
                    }
                  >
                    <InputGroup w="103px">
                      <Input
                        type="number"
                        step={0.01}
                        {...controlsForm.register("carrierQpPercentageFeeMin", {
                          required: DEFAULT_MESSAGE_REQUIRED,
                          validate: (value) => validateMinimumPercent(value),
                          valueAsNumber: true,
                        })}
                      />
                      <InputRightElement>%</InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={controlsForm.formState.errors}
                        name="carrierQpPercentageFeeMin"
                      />
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={
                      !!controlsForm.formState.errors?.carrierQpPercentageFeeMax
                    }
                  >
                    <InputGroup w="103px">
                      <Input
                        type="number"
                        step={0.01}
                        {...controlsForm.register("carrierQpPercentageFeeMax", {
                          required: DEFAULT_MESSAGE_REQUIRED,
                          validate: (value) => validateMaximumPercent(value),
                          valueAsNumber: true,
                        })}
                      />
                      <InputRightElement>%</InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>
                      <ErrorMessage
                        errors={controlsForm.formState.errors}
                        name="carrierQpPercentageFeeMax"
                      />
                    </FormErrorMessage>
                  </FormControl>
                </HStack>
              </HStack>
              <HStack marginTop="5">
                <Button
                  type="submit"
                  layerStyle="yellow"
                  disabled={updatingControls || updatingEnabled}
                >
                  {updatingControls ? <Spinner /> : "Save changes"}
                </Button>
                <Button
                  onClick={() => {
                    setEditMode(false);
                    controlsForm.reset({
                      carrierQpPercentageFeeMax:
                        broker.carrierQpPercentageFeeMax,
                      carrierQpPercentageFeeMin:
                        broker.carrierQpPercentageFeeMin,
                      brokerQpPercentageFee: broker.brokerQpPercentageFee,
                    });
                  }}
                >
                  Cancel
                </Button>
              </HStack>
            </>
          ) : (
            <>
              <HStack w="100%" justifyContent="start">
                <FormLabel requiredIndicator={<></>} w="30%" marginBottom="0">
                  Brokerage fee
                </FormLabel>
                <Text>{broker.brokerQpPercentageFee}%</Text>
              </HStack>
              <HStack w="100%" justifyContent="start">
                <FormLabel requiredIndicator={<></>} w="30%" marginBottom="0">
                  Carrier Fee Range
                </FormLabel>
                <Text>{broker.carrierQpPercentageFeeMin}%</Text>
                <Text>-</Text>
                <Text>{broker.carrierQpPercentageFeeMax}%</Text>
              </HStack>
            </>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
}
