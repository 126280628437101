import { Icon, IconProps } from "@chakra-ui/react";

export const DollarCoinIcon = (props: IconProps): JSX.Element => (
  <Icon
    width={props.w ?? "38"}
    height={props.h ?? "38"}
    viewBox="0 0 38 38"
    fill="none"
    {...props}
  >
    <path
      d="M15.4 23.3714C15.4 24.1343 15.6262 24.88 16.05 25.5143C16.4739 26.1486 17.0763 26.643 17.7811 26.935C18.4859 27.2269 19.2614 27.3033 20.0096 27.1545C20.7578 27.0056 21.4451 26.6383 21.9846 26.0988C22.524 25.5594 22.8913 24.8721 23.0402 24.1239C23.189 23.3757 23.1126 22.6002 22.8207 21.8954C22.5287 21.1906 22.0344 20.5882 21.4001 20.1643C20.7658 19.7405 20.02 19.5143 19.2571 19.5143C18.4943 19.5143 17.7485 19.2881 17.1142 18.8642C16.4799 18.4404 15.9855 17.838 15.6936 17.1332C15.4017 16.4284 15.3253 15.6529 15.4741 14.9047C15.6229 14.1564 15.9903 13.4692 16.5297 12.9297C17.0692 12.3903 17.7564 12.0229 18.5047 11.8741C19.2529 11.7253 20.0284 11.8017 20.7332 12.0936C21.438 12.3855 22.0404 12.8799 22.4642 13.5142C22.8881 14.1485 23.1143 14.8943 23.1143 15.6571M19.2571 9.22857V11.8M19.2571 27.2286V29.8M1 19C1 23.7739 2.89642 28.3523 6.27208 31.7279C9.64773 35.1036 14.2261 37 19 37C23.7739 37 28.3523 35.1036 31.7279 31.7279C35.1036 28.3523 37 23.7739 37 19C37 14.2261 35.1036 9.64773 31.7279 6.27208C28.3523 2.89642 23.7739 1 19 1C14.2261 1 9.64773 2.89642 6.27208 6.27208C2.89642 9.64773 1 14.2261 1 19Z"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
