import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Link,
  Divider,
  Button,
  Text,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { routes } from "../../routes";
import { useForm } from "react-hook-form";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_MESSAGE_REQUIRED,
} from "../../reuse/Constants";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { ErrorMessage } from "@hookform/error-message";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {
  ICreateBrokerAccountRequestLead,
  useCreateBrokerAccountRequestLeadMutation,
} from "../../api-platform/brokerAccountRequestLeads";

export function RequestAccount(): JSX.Element {
  const ref = useRef<ReCAPTCHA | null>(null);
  const [validCaptcha, setValidCaptcha] = useState<boolean | undefined>();
  const [validUser, setValidUser] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const form = useForm<ICreateBrokerAccountRequestLead>();
  const notificationBanner = useNotificationBanner();
  const [createBrokerAccountRequestLead, { isLoading }] =
    useCreateBrokerAccountRequestLeadMutation();

  const onChangeCaptcha = (): void => {
    if (ref.current?.getValue()) setValidCaptcha(true);
  };

  if (validUser && submitted) return <SuccessState />;

  async function onSubmit(
    data: ICreateBrokerAccountRequestLead,
  ): Promise<void> {
    if (ref.current?.getValue()) {
      await createBrokerAccountRequestLead({
        iCreateBrokerAccountRequestLead: {
          ...data,
          email: data.email.toLowerCase(),
        },
      })
        .unwrap()
        .then(() => {
          notificationBanner({
            status: "success",
            description: "Account request received.",
          });
          setSubmitted(true);
          setValidUser(true);
          setValidCaptcha(true);
        })
        .catch((err) => {
          notificationBanner({
            status: "error",
            description: err.data?.message ?? DEFAULT_ERROR_MESSAGE,
          });
        });
    } else {
      setValidUser(false);
      setValidCaptcha(false);
    }
  }

  return (
    <VStack
      align="content-start"
      spacing="24px"
      as="form"
      noValidate
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Heading fontSize="24px" mb="16px">
        Request a MVMNT account
      </Heading>
      <FormControl isRequired isInvalid={!!form.formState.errors?.brokerName}>
        <FormLabel requiredIndicator={<></>}>Broker name</FormLabel>
        <Input
          type="text"
          {...form.register("brokerName", {
            required: DEFAULT_MESSAGE_REQUIRED,
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={form.formState.errors} name="brokerName" />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!form.formState.errors?.firstName}>
        <FormLabel requiredIndicator={<></>}>First name</FormLabel>
        <Input
          type="text"
          {...form.register("firstName", {
            required: DEFAULT_MESSAGE_REQUIRED,
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={form.formState.errors} name="firstName" />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!form.formState.errors?.lastName}>
        <FormLabel requiredIndicator={<></>}>Last name</FormLabel>
        <Input
          type="text"
          {...form.register("lastName", {
            required: DEFAULT_MESSAGE_REQUIRED,
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={form.formState.errors} name="lastName" />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!form.formState.errors?.email}>
        <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <EmailIcon color="gray.400" />
          </InputLeftElement>
          <Input
            {...form.register("email", {
              validate: validateEmail,
              required: DEFAULT_MESSAGE_REQUIRED,
            })}
          />
        </InputGroup>
        <FormErrorMessage>
          <ErrorMessage errors={form.formState.errors} name="email" />
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!form.formState.errors?.phone}>
        <FormLabel requiredIndicator={<></>}>Phone number</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <PhoneIcon color="gray.400" />
          </InputLeftElement>
          <PhoneInput
            name="phone"
            placeholder="(___) - ___ - ____"
            control={form.control}
            rules={{
              required: DEFAULT_MESSAGE_REQUIRED,
              validate: (value: string) => validatePhoneNumber(`${value}`),
            }}
            country="US"
            style={{ paddingLeft: "2.5rem" }}
            inputComponent={Input}
          />
        </InputGroup>
        <FormErrorMessage>
          <ErrorMessage errors={form.formState.errors} name="phone" />
        </FormErrorMessage>
      </FormControl>
      <VStack>
        <ReCAPTCHA
          ref={ref}
          sitekey={process.env.REACT_APP_RECAPTCHA_ACCESS_KEY ?? ""}
          onChange={onChangeCaptcha}
        />
        {validCaptcha === false && <Text>Please complete captcha check</Text>}
      </VStack>
      <Divider />
      <Button size="lg" layerStyle="yellow" type="submit" isLoading={isLoading}>
        Request to join MVMNT
      </Button>
      <Text fontSize="13px" textAlign="center">
        Already have an account?{" "}
        <Link
          as={RouterLink}
          color="blue.500"
          to={routes.LOGIN}
          textDecoration="underline"
        >
          Login
        </Link>
      </Text>
    </VStack>
  );
}

function SuccessState(): JSX.Element {
  return (
    <VStack align="content-start" spacing="24px" maxW="370px">
      <Heading fontSize="24px" mb="16px">
        You&rsquo;re one step closer to joining the MVMNT!
      </Heading>
      <Text>
        Look out for an email from a MVMNT team member to complete your
        onboarding.
      </Text>
      <Text>
        For any further questions, email us at{" "}
        <Link
          color="blue.500"
          href="mailto:support@mvmnt.io"
          textDecoration="underline"
        >
          support@mvmnt.io
        </Link>
      </Text>
      <Divider />
      <Link as={RouterLink} to={routes.LOGIN} _hover={{ textDecor: "none" }}>
        <Button size="lg" layerStyle="yellow">
          Return to MVMNT home
        </Button>
      </Link>
    </VStack>
  );
}
