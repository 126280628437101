import { mvmntApiPlatform } from "./generated";
export * from "./generated";

mvmntApiPlatform.enhanceEndpoints({
  addTagTypes: ["BrokerAccountRequestLead"],
  endpoints: {
    getBrokerAccountRequestLead: {
      providesTags: (_response, _errors, arg) => [
        { type: "BrokerAccountRequestLead", id: arg.id },
      ],
    },
    updateRequestLead: {
      invalidatesTags: (_response, _errors, arg) => [
        { type: "BrokerAccountRequestLead", id: arg.id },
      ],
    },
    updateRequestLeadFinancials: {
      invalidatesTags: (_response, _errors, arg) => [
        { type: "BrokerAccountRequestLead", id: arg.id },
      ],
    },
    approveBrokerAccountRequest: {
      invalidatesTags: ["BrokerAccountSummary", "BrokerAccounts"],
    },
  },
});
