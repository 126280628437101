import { createApi } from "@reduxjs/toolkit/query/react";
import { BrokerAccountRequestData, BrokerUser } from "../types/Broker";
import { baseUrl } from "./utils/baseUrl";
import { createBaseQueryWithReauth } from "./utils/baseQueryReauth";
interface ApproveUserParams {
  id: string;
  email: string;
}

interface Address {
  addressCity: string;
  addressState: string;
  addressCountry: string;
  date: string;
  receivingName?: string;
}
export interface BrokerInvoice {
  id: string;
  dueDate: string | null;
  load: {
    id: string;
    mvmntLoadId: number;
    loadStops: {
      origin: Address;
      destination: Address;
    };
  };
  payee: string;
  directPaymentRequest: {
    id: string;
    amount: number;
  } | null;
}

export const brokerApi = createApi({
  reducerPath: "brokerApiReducer",
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  tagTypes: ["Broker", "ManageUsers"],
  endpoints: (builder) => ({
    getBroker: builder.query<BrokerUser, void>({
      query: () => "/user/brokers",
      providesTags: ["Broker"],
    }),
    getBrokerByCompany: builder.query<Partial<BrokerUser>, string>({
      query: (companyName) => `/brokers/contact?companyName=${companyName}`,
    }),
    getBrokerById: builder.query<Partial<BrokerUser>, string>({
      query: (mvmntIdNumber) =>
        `/brokers/contact?mvmntIdNumber=${mvmntIdNumber}`,
    }),
    sendBrokerAccountForm: builder.mutation<
      BrokerUser,
      BrokerAccountRequestData
    >({
      query: (body) => ({
        url: "/brokers",
        method: "POST",
        body,
      }),
    }),
    getAllBrokerUsers: builder.query<BrokerUser[], void>({
      query: (body) => ({
        url: "/brokers",
        method: "GET",
      }),
      providesTags: ["ManageUsers"],
    }),
    rejectBroker: builder.mutation<void, string>({
      query: (body) => ({
        url: `/brokers/${body}/reject`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["ManageUsers"],
    }),
    approveUser: builder.mutation<void, ApproveUserParams>({
      query: (body) => ({
        url: `/brokers/${body.id}/approve`,
        method: "PUT",
        body: { email: body.email },
      }),
      invalidatesTags: ["ManageUsers"],
    }),
    revokeBroker: builder.mutation<void, string>({
      query: (id) => ({
        url: `/brokers/${id}/revoke`,
        method: "PUT",
      }),
      invalidatesTags: ["ManageUsers"],
    }),
    getBrokerInvoices: builder.query<BrokerInvoice[], string>({
      query: (id: string) => ({
        url: `/brokers/${id}/invoices`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetBrokerQuery,
  useSendBrokerAccountFormMutation,
  useLazyGetBrokerByCompanyQuery,
  useLazyGetBrokerByIdQuery,
  useGetAllBrokerUsersQuery,
  useGetBrokerByIdQuery,
  useRejectBrokerMutation,
  useApproveUserMutation,
  useRevokeBrokerMutation,
  useGetBrokerInvoicesQuery,
} = brokerApi;

export const { useQueryState } = brokerApi.endpoints.getBroker;

export const { invalidateTags } = brokerApi.util;
