import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllExtLoadDraftsView: build.query<
      GetAllExtLoadDraftsViewApiResponse,
      GetAllExtLoadDraftsViewApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          origin: queryArg.origin,
          carrier: queryArg.carrier,
          customer: queryArg.customer,
          lastStatusChangeDate: queryArg.lastStatusChangeDate,
          missingDocuments: queryArg.missingDocuments,
          search: queryArg.search,
        },
      }),
    }),
    upsertExtLoadDraft: build.mutation<
      UpsertExtLoadDraftApiResponse,
      UpsertExtLoadDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    duplicateExternalLoadOrDraft: build.mutation<
      DuplicateExternalLoadOrDraftApiResponse,
      DuplicateExternalLoadOrDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft/duplicate`,
        method: "POST",
        body: queryArg.iDuplicateExternalLoad,
      }),
    }),
    getDraftBrokerExternalLoadCustomers: build.query<
      GetDraftBrokerExternalLoadCustomersApiResponse,
      GetDraftBrokerExternalLoadCustomersApiArg
    >({
      query: () => ({ url: `/external-loads-draft/customer-names` }),
    }),
    getDraftBrokerExternalLoadOrigins: build.query<
      GetDraftBrokerExternalLoadOriginsApiResponse,
      GetDraftBrokerExternalLoadOriginsApiArg
    >({
      query: () => ({ url: `/external-loads-draft/origins` }),
    }),
    uploadLoadsFromTemplate: build.mutation<
      UploadLoadsFromTemplateApiResponse,
      UploadLoadsFromTemplateApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft/upload`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteExtLoadDrafts: build.mutation<
      DeleteExtLoadDraftsApiResponse,
      DeleteExtLoadDraftsApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft/delete-drafts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    submitDraftForQuickpay: build.mutation<
      SubmitDraftForQuickpayApiResponse,
      SubmitDraftForQuickpayApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft/submit`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getExtLoadDraft: build.query<
      GetExtLoadDraftApiResponse,
      GetExtLoadDraftApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads-draft/${queryArg.extLoadDraftId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type GetAllExtLoadDraftsViewApiResponse = /** status 200 Returned */ {
  results: IExtLoadDraft[];
  totalSize: number;
  limit: number;
  page: number;
};
export type GetAllExtLoadDraftsViewApiArg = {
  page?: number;
  limit?: number;
  origin?: string;
  carrier?: string;
  customer?: string;
  lastStatusChangeDate?: string;
  missingDocuments?: string;
  search?: string;
};
export type UpsertExtLoadDraftApiResponse =
  /** status 201 Draft Created */ IExtLoadDraft;
export type UpsertExtLoadDraftApiArg = {
  body: {
    data: IExtLoadDraftCreate;
    id?: string;
  };
};
export type DuplicateExternalLoadOrDraftApiResponse =
  /** status 201 Draft Created */ IExtLoadDraft;
export type DuplicateExternalLoadOrDraftApiArg = {
  iDuplicateExternalLoad: IDuplicateExternalLoad;
};
export type GetDraftBrokerExternalLoadCustomersApiResponse =
  /** status 200 Returned */ (string | null)[];
export type GetDraftBrokerExternalLoadCustomersApiArg = void;
export type GetDraftBrokerExternalLoadOriginsApiResponse =
  /** status 200 Returned */ string[];
export type GetDraftBrokerExternalLoadOriginsApiArg = void;
export type UploadLoadsFromTemplateApiResponse =
  /** status 201 Created */ IExternalLoadsDraftUpload;
export type UploadLoadsFromTemplateApiArg = {
  body: {
    file: Blob;
  };
};
export type DeleteExtLoadDraftsApiResponse = unknown;
export type DeleteExtLoadDraftsApiArg = {
  body: {
    ids: string[];
  };
};
export type SubmitDraftForQuickpayApiResponse = unknown;
export type SubmitDraftForQuickpayApiArg = {
  body: {
    ids: string[];
  };
};
export type GetExtLoadDraftApiResponse =
  /** status 200 Returned */ IExtLoadDraft;
export type GetExtLoadDraftApiArg = {
  extLoadDraftId: string;
};
export type IDocsUploadedToLoad = {
  bolId: string | null;
  podId: string | null;
  rateconId: string | null;
  invoiceId: string | null;
};
export type IExtLoadDraft = {
  id: string;
  createDate: string;
  updateDate: string;
  brokerShipmentId: string | null;
  carrierPayeeAccountId: string;
  carrierInvoiceId: string | null;
  carrierInvoiceAmount: number | null;
  customerName: string | null;
  customerReferenceType: string | null;
  customerReferenceNumber: string | null;
  amountToPayCarrier: number | null;
  carrierInvoiceMatchRateCon: boolean | null;
  PODRequired: boolean | null;
  signedBOL: boolean | null;
  originCity: string | null;
  originState: string | null;
  destinationCity: string | null;
  destinationState: string | null;
  lastStatusChangeDate?: string | null;
  carrierPayeeAccount?: {
    name: string;
  };
  uploadedDocs?: IDocsUploadedToLoad;
};
export type IExtLoadDraftCreate = {
  brokerId: string;
  brokerShipmentId: string | null;
  carrierName: string;
  carrierInvoiceId: string | null;
  carrierInvoiceAmount: number | null;
  customerName: string | null;
  customerReferenceType: string | null;
  customerReferenceNumber: string | null;
  carrierInvoiceMatchRateCon: boolean | null;
  PODRequired: boolean | null;
  signedBOL: boolean | null;
  originCity: string | null;
  originState: string | null;
  destinationCity: string | null;
  destinationState: string | null;
  carrierPayeeAccountId: string;
};
export type IDuplicateExternalLoad = {
  id: string;
  type: "draft" | "load";
};
export type CsvError = {
  row: number;
  invoice_amount: string;
  message: string;
};
export type IExternalLoadsDraftUpload = {
  externalLoads: IExtLoadDraft[];
  errors: CsvError[];
};
export const {
  useGetAllExtLoadDraftsViewQuery,
  useLazyGetAllExtLoadDraftsViewQuery,
  useUpsertExtLoadDraftMutation,
  useDuplicateExternalLoadOrDraftMutation,
  useGetDraftBrokerExternalLoadCustomersQuery,
  useLazyGetDraftBrokerExternalLoadCustomersQuery,
  useGetDraftBrokerExternalLoadOriginsQuery,
  useLazyGetDraftBrokerExternalLoadOriginsQuery,
  useUploadLoadsFromTemplateMutation,
  useDeleteExtLoadDraftsMutation,
  useSubmitDraftForQuickpayMutation,
  useGetExtLoadDraftQuery,
  useLazyGetExtLoadDraftQuery,
} = injectedRtkApi;
