import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Divider,
  Button,
  FormErrorMessage,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { validateEmail, validatePhoneNumber } from "../../reuse/UserData";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {
  IUpdateCarrierPayeeAccount,
  useUpdateCarrierPayeeAccountMutation,
} from "../../api-platform/carrierPayeeAccounts";
import { useCarrierPayeeAccountPageContext } from "./CarrierPayeeAccountPageContext";
import { useEffect } from "react";

export const CarrierPrimaryContactForm = (): JSX.Element => {
  const { carrier } = useCarrierPayeeAccountPageContext();
  const [updateCarrier, { isLoading }] = useUpdateCarrierPayeeAccountMutation();

  const notificationBanner = useNotificationBanner();
  const form = useForm<Partial<IUpdateCarrierPayeeAccount>>();

  useEffect(() => {
    if (carrier) {
      form.reset({
        primaryContactEmail: carrier?.primaryContactEmail,
        primaryContactFirstName: carrier?.primaryContactFirstName,
        primaryContactLastName: carrier?.primaryContactLastName,
        primaryContactPhone: carrier?.primaryContactPhone,
      });
    }
  }, [carrier]);

  async function onSubmit(): Promise<void> {
    const values = form.getValues();
    if (carrier) {
      const response = updateCarrier({
        id: carrier.id,
        iUpdateCarrierPayeeAccount: {
          primaryContactEmail: values.primaryContactEmail,
          primaryContactFirstName: values.primaryContactFirstName,
          primaryContactLastName: values.primaryContactLastName,
          primaryContactPhone: values.primaryContactPhone,
        },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: <p>Failed to save your changes, please try again.</p>,
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Changes have been saved successfully.</p>,
        });
      }
    }
  }

  return (
    <VStack w="100%" alignItems="start">
      <VStack
        maxW="640px"
        w="100%"
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        onSubmit={form.handleSubmit(async () => await onSubmit())}
      >
        <Heading as="h3" fontSize="20px">
          Primary contact
        </Heading>
        <Text fontSize="15px">
          Providing a primary contact allows MVMNT to send communications, such
          as MVMNT Pay confirmation emails, directly to the carrier. If no email
          address is provided, the carrier will not receive communications from
          MVMNT.
        </Text>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.primaryContactFirstName}
        >
          <FormLabel requiredIndicator={<></>}>First Name</FormLabel>
          <InputGroup>
            <Input
              w="50%"
              {...form.register("primaryContactFirstName", {
                required: false,
              })}
              onBlur={form.handleSubmit(async () => {
                if (
                  form.getValues("primaryContactFirstName") !==
                  carrier?.primaryContactFirstName
                ) {
                  await onSubmit();
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactFirstName"
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.primaryContactLastName}
        >
          <FormLabel requiredIndicator={<></>}>Last Name</FormLabel>
          <InputGroup>
            <Input
              w="50%"
              {...form.register("primaryContactLastName", {
                required: false,
              })}
              onBlur={form.handleSubmit(async () => {
                if (
                  form.getValues("primaryContactLastName") !==
                  carrier?.primaryContactLastName
                ) {
                  await onSubmit();
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactLastName"
            />
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.primaryContactEmail}
        >
          <FormLabel requiredIndicator={<></>}>Email</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <EmailIcon color="gray.400" />
            </InputLeftElement>
            <Input
              w="50%"
              {...form.register("primaryContactEmail", {
                validate: (value) => {
                  if (value) {
                    return validateEmail(value);
                  }
                  return true;
                },
                required: false,
              })}
              onBlur={form.handleSubmit(async () => {
                if (
                  form.getValues("primaryContactEmail") !==
                  carrier?.primaryContactEmail
                ) {
                  await onSubmit();
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactEmail"
            />
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!form.formState.errors?.primaryContactPhone}>
          <FormLabel requiredIndicator={<></>}>Phone number</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.400" />
            </InputLeftElement>
            <PhoneInput
              name="primaryContactPhone"
              w="50%"
              placeholder="(___) - ___ - ____"
              control={form.control}
              rules={{
                required: false,
                validate: (value: string) => validatePhoneNumber(`${value}`),
              }}
              country="US"
              style={{ paddingLeft: "2.5rem" }}
              inputComponent={Input}
              onChange={form.handleSubmit(async () => {
                if (
                  form.getValues("primaryContactPhone") !==
                    carrier?.primaryContactPhone &&
                  !(
                    typeof validatePhoneNumber(
                      form.getValues("primaryContactPhone")?.toString(),
                    ) === "string"
                  )
                ) {
                  await onSubmit();
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="primaryContactPhone"
            />
          </FormErrorMessage>
        </FormControl>

        <Divider />

        <Button
          layerStyle="yellow"
          type="submit"
          disabled={isLoading}
          w="150px"
        >
          {isLoading ? <Spinner /> : "Save changes"}
        </Button>
      </VStack>
    </VStack>
  );
};

export default CarrierPrimaryContactForm;
