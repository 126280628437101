import { Icon, IconProps } from "@chakra-ui/react";

export const PeopleIcon = (props: IconProps): JSX.Element => (
  <Icon width="32px" height="25px" viewBox="0 0 32 25" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1226 0.9245C8.48152 0.9245 6.90764 1.57642 5.74722 2.73685C5.38593 3.09813 5.07394 3.49949 4.81557 3.93047C4.79731 3.95755 4.78088 3.9855 4.76629 4.01416C4.22629 4.94782 3.93486 6.01501 3.93486 7.11226C3.93486 8.75335 4.58679 10.3272 5.74722 11.4877C6.90764 12.6481 8.48152 13.3 10.1226 13.3C11.7637 13.3 13.3376 12.6481 14.498 11.4877C15.6585 10.3272 16.3104 8.75335 16.3104 7.11226C16.3104 6.83564 16.2919 6.56094 16.2555 6.28981C16.2544 6.28042 16.2531 6.27103 16.2517 6.26165C16.0675 4.9348 15.4561 3.69491 14.498 2.73685C13.3376 1.57642 11.7637 0.9245 10.1226 0.9245ZM14.5418 5.88001C14.3315 5.12606 13.9299 4.43146 13.3667 3.86822C12.5063 3.00785 11.3394 2.5245 10.1226 2.5245C8.90587 2.5245 7.73896 3.00785 6.87859 3.86822C6.7515 3.99531 6.63264 4.12908 6.52235 4.26872C7.59579 5.21699 8.90327 5.86851 10.3149 6.15225C11.727 6.43606 13.185 6.34019 14.5418 5.88001ZM5.75308 5.71415C5.60977 6.16202 5.53486 6.63328 5.53486 7.11226C5.53486 8.329 6.01821 9.49592 6.87859 10.3563C7.73896 11.2167 8.90587 11.7 10.1226 11.7C11.3394 11.7 12.5063 11.2167 13.3667 10.3563C14.1327 9.59027 14.5998 8.58124 14.6931 7.5104C13.1747 7.96019 11.5635 8.03521 9.99965 7.72088C8.4362 7.40663 6.97957 6.71524 5.75308 5.71415Z"
      fill="currentColor"
    />
    <path
      d="M10.1227 15.2592C7.99661 15.2592 5.95763 16.1038 4.45428 17.6071C2.95092 19.1105 2.10635 21.1495 2.10635 23.2755C2.10635 23.7173 1.74818 24.0755 1.30635 24.0755C0.86452 24.0755 0.506348 23.7173 0.506348 23.2755C0.506348 20.7251 1.51949 18.2792 3.3229 16.4757C5.12632 14.6723 7.57227 13.6592 10.1227 13.6592C12.6731 13.6592 15.119 14.6723 16.9224 16.4757C18.7259 18.2792 19.739 20.7251 19.739 23.2755C19.739 23.7173 19.3808 24.0755 18.939 24.0755C18.4972 24.0755 18.139 23.7173 18.139 23.2755C18.139 21.1495 17.2944 19.1105 15.7911 17.6071C14.2877 16.1038 12.2487 15.2592 10.1227 15.2592Z"
      fill="currentColor"
    />
    <path
      d="M23.6618 15.4621C22.4884 15.1931 21.2694 15.1915 20.0953 15.4576C19.6644 15.5552 19.236 15.285 19.1383 14.8541C19.0407 14.4232 19.3109 13.9947 19.7418 13.8971C21.15 13.5781 22.6119 13.5799 24.0193 13.9026C25.4267 14.2252 26.7435 14.8603 27.8721 15.7609C29.0008 16.6615 29.9123 17.8044 30.5393 19.1051C31.1663 20.4057 31.4926 21.8308 31.4941 23.2747C31.4945 23.7165 31.1367 24.0751 30.6949 24.0755C30.2531 24.076 29.8945 23.7182 29.8941 23.2764C29.8928 22.0725 29.6207 20.8843 29.098 19.7998C28.5752 18.7154 27.8152 17.7624 26.8742 17.0115C25.9332 16.2606 24.8353 15.7311 23.6618 15.4621Z"
      fill="currentColor"
    />
    <path
      d="M21.5766 2.5343C22.3606 2.4831 23.1446 2.6341 23.8535 2.97283C24.5624 3.31156 25.1724 3.82667 25.6252 4.46879C26.0779 5.11092 26.3581 5.85854 26.439 6.64004C26.4405 6.65525 26.442 6.67046 26.4435 6.68568C25.8177 6.60077 25.2065 6.42294 24.6305 6.15678C23.8077 5.7766 23.0756 5.22498 22.4833 4.53887C22.3317 4.36319 22.1112 4.26202 21.8791 4.26163C21.647 4.26125 21.4262 4.36167 21.2739 4.53685C20.5663 5.35107 19.6661 5.97518 18.6554 6.35227C18.2415 6.50672 18.0311 6.9675 18.1855 7.38145C18.34 7.7954 18.8008 8.00577 19.2147 7.85133C20.203 7.48259 21.1066 6.92453 21.8763 6.21134C22.491 6.78321 23.1938 7.2555 23.9593 7.60922C24.7058 7.95414 25.4994 8.18053 26.3117 8.28202C26.2534 8.50322 26.1784 8.72049 26.087 8.93192C25.7754 9.65315 25.2838 10.2823 24.6593 10.759C24.0348 11.2357 23.2983 11.5441 22.5205 11.6545C21.7426 11.7649 20.9494 11.6738 20.2169 11.3897C19.805 11.23 19.3415 11.4344 19.1818 11.8464C19.022 12.2583 19.2265 12.7217 19.6384 12.8815C20.6264 13.2646 21.6962 13.3876 22.7454 13.2386C23.7945 13.0897 24.7879 12.6738 25.6302 12.0308C26.0425 11.716 26.4119 11.352 26.7309 10.9483C26.9394 11.7526 27.2659 12.5263 27.7032 13.2432C27.9333 13.6204 28.4256 13.7396 28.8028 13.5095C29.18 13.2794 29.2992 12.7871 29.0691 12.4099C28.3669 11.2589 28.0169 9.92748 28.0624 8.57993C28.0629 8.56716 28.063 8.55438 28.0628 8.54161L28.0486 7.5385C28.073 7.18553 28.0672 6.82984 28.0305 6.47533C27.9214 5.42129 27.5434 4.41293 26.9328 3.54685C26.3222 2.68078 25.4995 1.98603 24.5433 1.52917C23.5872 1.07231 22.5298 0.868649 21.4724 0.937701C20.4149 1.00675 19.393 1.34621 18.5044 1.92354C18.1339 2.16425 18.0287 2.65974 18.2694 3.03023C18.5101 3.40073 19.0056 3.50594 19.3761 3.26522C20.0349 2.83718 20.7926 2.5855 21.5766 2.5343Z"
      fill="currentColor"
    />
  </Icon>
);
