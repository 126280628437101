import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateExternalLoadTemplateGetUrl: build.query<
      GenerateExternalLoadTemplateGetUrlApiResponse,
      GenerateExternalLoadTemplateGetUrlApiArg
    >({
      query: () => ({ url: `/documents/external-load-template` }),
    }),
    generateGetCarrierQpAgreement: build.query<
      GenerateGetCarrierQpAgreementApiResponse,
      GenerateGetCarrierQpAgreementApiArg
    >({
      query: () => ({ url: `/documents/carrier-qp-agreement` }),
    }),
    generatePutUrlExtLoadDocument: build.mutation<
      GeneratePutUrlExtLoadDocumentApiResponse,
      GeneratePutUrlExtLoadDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load/${queryArg.id}`,
        method: "POST",
        body: queryArg.extLoadDocumentUpload,
      }),
    }),
    updateExtLoadDocumentType: build.mutation<
      UpdateExtLoadDocumentTypeApiResponse,
      UpdateExtLoadDocumentTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load/${queryArg.id}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteExtLoadDocument: build.mutation<
      DeleteExtLoadDocumentApiResponse,
      DeleteExtLoadDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    generatePutUrlExtLoadDraftDocument: build.mutation<
      GeneratePutUrlExtLoadDraftDocumentApiResponse,
      GeneratePutUrlExtLoadDraftDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load-draft/${queryArg.id}`,
        method: "POST",
        body: queryArg.extLoadDocumentUpload,
      }),
    }),
    deleteExtLoadDraftDocument: build.mutation<
      DeleteExtLoadDraftDocumentApiResponse,
      DeleteExtLoadDraftDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load-draft/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getExtLoadDocuments: build.query<
      GetExtLoadDocumentsApiResponse,
      GetExtLoadDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load/${queryArg.externalLoadId}`,
      }),
    }),
    getExtLoadDraftDocuments: build.query<
      GetExtLoadDraftDocumentsApiResponse,
      GetExtLoadDraftDocumentsApiArg
    >({
      query: (queryArg) => ({
        url: `/documents/ext-load-draft/${queryArg.externalLoadDraftId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type GenerateExternalLoadTemplateGetUrlApiResponse =
  /** status 200 Returned */ string;
export type GenerateExternalLoadTemplateGetUrlApiArg = void;
export type GenerateGetCarrierQpAgreementApiResponse =
  /** status 200 Returned */ string;
export type GenerateGetCarrierQpAgreementApiArg = void;
export type GeneratePutUrlExtLoadDocumentApiResponse =
  /** status 201 Posted */ string;
export type GeneratePutUrlExtLoadDocumentApiArg = {
  id: string;
  /** External Load Document Upload file */
  extLoadDocumentUpload: ExtLoadDocumentUpload;
};
export type UpdateExtLoadDocumentTypeApiResponse = /** status 200 Returned */ {
  updateDate: string;
  id: string;
  documentType:
    | "Other"
    | "BillOfLading"
    | "Invoice"
    | "ProofOfDelivery"
    | "RateCon";
  documentName: string;
  deletedAt: string;
  createDate: string;
  externalDraftId: string;
  externalLoadId: string;
};
export type UpdateExtLoadDocumentTypeApiArg = {
  /** External Load Document Id */
  id: string;
  body: {
    documentType: ExtLoadDocTypeEnum;
  };
};
export type DeleteExtLoadDocumentApiResponse = unknown;
export type DeleteExtLoadDocumentApiArg = {
  /** External Load Document Id */
  id: string;
};
export type GeneratePutUrlExtLoadDraftDocumentApiResponse =
  /** status 201 Posted */ string;
export type GeneratePutUrlExtLoadDraftDocumentApiArg = {
  id: string;
  /** External Load Document Upload file */
  extLoadDocumentUpload: ExtLoadDocumentUpload;
};
export type DeleteExtLoadDraftDocumentApiResponse = unknown;
export type DeleteExtLoadDraftDocumentApiArg = {
  /** External Load Document Id */
  id: string;
};
export type GetExtLoadDocumentsApiResponse = /** status 200 Returned */ {
  updateDate: string;
  documentType:
    | "Other"
    | "BillOfLading"
    | "Invoice"
    | "ProofOfDelivery"
    | "RateCon";
  documentName: string;
  deletedAt: string;
  createDate: string;
  externalLoadId: string;
  id: string;
}[];
export type GetExtLoadDocumentsApiArg = {
  externalLoadId: string;
};
export type GetExtLoadDraftDocumentsApiResponse = /** status 200 Returned */ {
  updateDate: string;
  documentType:
    | "Other"
    | "BillOfLading"
    | "Invoice"
    | "ProofOfDelivery"
    | "RateCon";
  documentName: string;
  deletedAt: string;
  createDate: string;
  externalDraftId: string;
  id: string;
}[];
export type GetExtLoadDraftDocumentsApiArg = {
  externalLoadDraftId: string;
};
export type ExtLoadDocTypeEnum =
  | "BillOfLading"
  | "Invoice"
  | "Other"
  | "ProofOfDelivery"
  | "RateCon";
export type ExtLoadDocumentUpload = {
  fileName: string;
  contentType: string;
  customLabel?: string;
  type?: ExtLoadDocTypeEnum;
};
export const {
  useGenerateExternalLoadTemplateGetUrlQuery,
  useLazyGenerateExternalLoadTemplateGetUrlQuery,
  useGenerateGetCarrierQpAgreementQuery,
  useLazyGenerateGetCarrierQpAgreementQuery,
  useGeneratePutUrlExtLoadDocumentMutation,
  useUpdateExtLoadDocumentTypeMutation,
  useDeleteExtLoadDocumentMutation,
  useGeneratePutUrlExtLoadDraftDocumentMutation,
  useDeleteExtLoadDraftDocumentMutation,
  useGetExtLoadDocumentsQuery,
  useLazyGetExtLoadDocumentsQuery,
  useGetExtLoadDraftDocumentsQuery,
  useLazyGetExtLoadDraftDocumentsQuery,
} = injectedRtkApi;
