import { Icon, IconProps } from "@chakra-ui/react";

export const QuickPayIcon = (props: IconProps): JSX.Element => (
  <Icon width="40px" height="38px" viewBox="0 0 40 38" fill="none" {...props}>
    <path
      d="M1.25 36.25H28.75C28.75 34.9239 28.2232 33.6521 27.2855 32.7145C26.3479 31.7768 25.0761 31.25 23.75 31.25H17.5C17.5 29.9239 16.9732 28.6521 16.0355 27.7145C15.0979 26.7768 13.8261 26.25 12.5 26.25H1.25"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.7333 30.0133C35.5498 28.6602 36.963 26.8375 37.821 24.7412C38.679 22.6449 38.9492 20.3544 38.6026 18.116C38.256 15.8776 37.3057 13.7761 35.854 12.0374C34.4023 10.2987 32.504 8.98874 30.3633 8.24834L33.4517 2.96501C33.5535 2.79139 33.6072 2.59377 33.6072 2.39251C33.6072 2.19124 33.5535 1.99362 33.4517 1.82001C33.346 1.64475 33.1966 1.50003 33.018 1.40009C32.8395 1.30014 32.638 1.24842 32.4333 1.25001H20.0667C19.8626 1.24899 19.6618 1.30099 19.4839 1.40091C19.3059 1.50083 19.157 1.64524 19.0517 1.82001C18.9498 1.99362 18.8962 2.19124 18.8962 2.39251C18.8962 2.59377 18.9498 2.79139 19.0517 2.96501L22.14 8.24834C19.6946 9.09374 17.5729 10.6794 16.0695 12.7852C14.5661 14.891 13.7554 17.4126 13.75 20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.8333 23.0733C23.1943 23.5474 23.6642 23.9275 24.2033 24.1814C24.7423 24.4352 25.3346 24.5554 25.93 24.5317C27.8283 24.5317 29.3683 23.3767 29.3683 21.9533C29.3683 20.53 27.8283 19.3767 25.93 19.3767C24.0317 19.3767 22.5 18.2217 22.5 16.7967C22.5 15.3717 24.04 14.2183 25.9367 14.2183C26.5321 14.1942 27.1246 14.3142 27.6637 14.5681C28.2028 14.822 28.6727 15.2023 29.0333 15.6767"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
