import { CarrierTypeIdEnum } from "../../api-platform/carrierPayeeAccounts";

export interface ISelected {
  id: string;
  name?: string;
  content?: string;
}

export interface Option {
  title: string;
  icon?: string;
  disabled?: boolean;
  onClick: () => void;
}

export enum QPOperation {
  REQUEST = "REQUEST",
  DELETE = "DELETE",
}

export const carrierIdTypes: CarrierTypeIdEnum[] = [
  "Other",
  "DOT number",
  "Motor Carrier number",
];

export type AbbreviatedCarrierTypeID = {
  [key in CarrierTypeIdEnum]: string;
};
