import { createContext, useContext } from "react";
import {
  ICarrierPayeeAccount,
  IModernTreasuryAccountInfo,
} from "../../api-platform/carrierPayeeAccounts";

interface CarrierPayeeAccountPage {
  carrier: ICarrierPayeeAccount | undefined;
  setCarrier: React.Dispatch<
    React.SetStateAction<ICarrierPayeeAccount | undefined>
  >;
  externalAccounts: IModernTreasuryAccountInfo[];
  setExternalAccounts: React.Dispatch<
    React.SetStateAction<IModernTreasuryAccountInfo[]>
  >;
}

const CarrierPayeeAccountPageContext = createContext<CarrierPayeeAccountPage>({
  carrier: undefined,
  setCarrier: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  externalAccounts: [],
  setExternalAccounts: (): void => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

const useCarrierPayeeAccountPageContext = (): CarrierPayeeAccountPage =>
  useContext(CarrierPayeeAccountPageContext);

export { CarrierPayeeAccountPageContext, useCarrierPayeeAccountPageContext };
