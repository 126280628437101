import { PhoneIcon, QuestionIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Divider,
  Button,
  FormErrorMessage,
  Tooltip,
  Spinner,
  HStack,
  Select,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { validatePhoneNumber } from "../../reuse/UserData";
import { useEffect, useRef } from "react";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import {
  IUpdateCarrierPayeeAccount,
  useUpdateCarrierPayeeAccountMutation,
} from "../../api-platform/carrierPayeeAccounts";
import { useCarrierPayeeAccountPageContext } from "./CarrierPayeeAccountPageContext";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { carrierIdTypes } from "../BrokerCarrierAccounts/types";

export const CarrierCompanyDetailsForm = (): JSX.Element => {
  const { carrier } = useCarrierPayeeAccountPageContext();
  const [updateCarrier, { isLoading }] = useUpdateCarrierPayeeAccountMutation();
  const autoCompleteRef = useRef<HTMLInputElement>(null);

  const notificationBanner = useNotificationBanner();
  const form = useForm<Partial<IUpdateCarrierPayeeAccount>>();
  const addressValue = form.watch("corporateAddress");

  useEffect(() => {
    if (carrier) {
      form.reset({
        name: carrier?.name,
        carrierId: carrier?.carrierId,
        typeId: carrier?.typeId,
        corporateAddress: carrier?.corporateAddress,
        phone: carrier?.phone,
      });
    }
  }, [carrier]);

  async function onSubmit(): Promise<void> {
    const values = form.getValues();
    if (carrier) {
      const response = updateCarrier({
        id: carrier.id,
        iUpdateCarrierPayeeAccount: {
          name: values.name,
          carrierId: values.carrierId,
          typeId: values.typeId,
          corporateAddress: values.corporateAddress,
          phone: values.phone,
        },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: <p>Failed to save your changes, please try again.</p>,
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Changes have been saved successfully.</p>,
        });
      }
    }
  }

  const setAddressFromGooglePrediction = (
    prediction: google.maps.places.PlaceResult,
  ): void => {
    form.setValue("corporateAddress", prediction.formatted_address ?? "");
    void form.handleSubmit(async () => await onSubmit())();
  };

  return (
    <VStack w="100%" alignItems="start">
      <VStack
        maxW="640px"
        w="100%"
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        onSubmit={form.handleSubmit(async () => await onSubmit())}
      >
        <Heading as="h3" fontSize="20px" mb="16px">
          Company details
        </Heading>

        <FormControl isRequired isInvalid={!!form.formState.errors?.name}>
          <FormLabel requiredIndicator={<> (Required)</>}>
            Company Name
          </FormLabel>
          <InputGroup>
            <Input
              w="50%"
              {...form.register("name", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
              onBlur={form.handleSubmit(async () => {
                if (form.getValues("name") !== carrier?.name) {
                  await onSubmit();
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="name" />
          </FormErrorMessage>
        </FormControl>
        <HStack>
          <FormControl isRequired isInvalid={!!form.formState.errors?.typeId}>
            <FormLabel requiredIndicator={<></>}>Type ID (Required)</FormLabel>
            <Select
              backgroundColor="inherit"
              fontSize={15}
              fontWeight={500}
              textColor="gray.600"
              width="100%"
              variant="outline"
              placeholder="Select an option"
              {...form.register("typeId", {
                required: DEFAULT_MESSAGE_REQUIRED,
              })}
            >
              {carrierIdTypes.map((option) => (
                <option key={`Carrier Type ID select:${option}`} value={option}>
                  {option}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="typeId" />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!form.formState.errors?.carrierId}
          >
            <FormLabel requiredIndicator={<></>}>
              Carrier ID (Required)
              <Tooltip label="This ID should be unique to this carrier. We recommentd using a MC or DOT number but it can be anything you choose.">
                <QuestionIcon w="15px" h="15px" margin="0px 5px" />
              </Tooltip>
            </FormLabel>
            <InputGroup>
              <Input
                w="100%"
                {...form.register("carrierId", {
                  required: DEFAULT_MESSAGE_REQUIRED,
                })}
                onBlur={form.handleSubmit(async () => {
                  if (form.getValues("carrierId") !== carrier?.carrierId) {
                    await onSubmit();
                  }
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage errors={form.formState.errors} name="carrierId" />
            </FormErrorMessage>
          </FormControl>
        </HStack>

        <FormControl isInvalid={!!form.formState.errors?.phone}>
          <FormLabel requiredIndicator={<></>}>Phone number</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <PhoneIcon color="gray.400" />
            </InputLeftElement>
            <PhoneInput
              name="phone"
              w="40%"
              placeholder="(___) - ___ - ____"
              control={form.control}
              rules={{
                required: false,
                validate: (value: string) => {
                  return value ? validatePhoneNumber(`${value}`) : true;
                },
              }}
              country="US"
              style={{ paddingLeft: "2.5rem" }}
              inputComponent={Input}
              onBlur={form.handleSubmit(async () => {
                if (form.getValues("phone") !== carrier?.phone) {
                  await onSubmit();
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="phone" />
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isRequired
          isInvalid={!!form.formState.errors.corporateAddress}
        >
          <FormLabel requiredIndicator={<></>}>Company address</FormLabel>
          <Input
            w="100%"
            {...form.register("corporateAddress", {
              required: false,
            })}
            display="none"
          />
          {carrier && (
            <ReactGoogleAutocomplete
              ref={autoCompleteRef}
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              onPlaceSelected={(place) => setAddressFromGooglePrediction(place)}
              options={{ types: ["address"] }}
              defaultValue={addressValue ?? ""}
              style={{
                width: "100%",
                borderRadius: "8px",
                padding: "0.5rem 1rem",
                border: "1px solid lightGray",
              }}
              onChange={(event) =>
                form.setValue("corporateAddress", event.currentTarget.value)
              }
            />
          )}

          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors.corporateAddress}
              name="address"
            />
          </FormErrorMessage>
        </FormControl>

        <Divider />

        <Button
          layerStyle="yellow"
          type="submit"
          disabled={isLoading}
          w="150px"
        >
          {isLoading ? <Spinner /> : "Save changes"}
        </Button>
      </VStack>
    </VStack>
  );
};

export default CarrierCompanyDetailsForm;
