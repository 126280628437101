import { Routes, Route } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Add } from "./Accounts/Add";
import { Accounts } from "./Accounts/Accounts";
import { AccountLayout } from "./components/AccountLayout";
import { AccountInfo } from "./Accounts/Account/AccountInfo/AccountInfo";
import { Finances } from "./Accounts/Account/Finances/Finances";
import { Carriers } from "./Accounts/Account/Carriers";

export const INTERNAL_ROUTES = {
  ACCOUNTS: "accounts",
  ACCOUNT_INFO: "accounts/:brokerId",
  ACCOUNT_FINANCES: "accounts/:brokerId/finances",
  ACCOUNT_CARRIERS: "accounts/:brokerId/carriers",
  ACCOUNTS_ADD: "accounts/add",
} as const;

export function InternalRoute(): JSX.Element {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path={INTERNAL_ROUTES.ACCOUNTS} element={<Accounts />} />
        <Route path={INTERNAL_ROUTES.ACCOUNTS_ADD} element={<Add />} />
        <Route element={<AccountLayout />} path={INTERNAL_ROUTES.ACCOUNT_INFO}>
          <Route index element={<AccountInfo />} />
          <Route path="finances" element={<Finances />} />
          <Route path="carriers" element={<Carriers />} />
        </Route>
      </Route>
    </Routes>
  );
}
