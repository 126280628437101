import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExternalLoadBoardView: build.query<
      GetExternalLoadBoardViewApiResponse,
      GetExternalLoadBoardViewApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads`,
        params: {
          page: queryArg.page,
          limit: queryArg.limit,
          status: queryArg.status,
          origin: queryArg.origin,
          lastStatusChangeDate: queryArg.lastStatusChangeDate,
          carrier: queryArg.carrier,
          customer: queryArg.customer,
          missingDocuments: queryArg.missingDocuments,
          paymentOrderStatus: queryArg.paymentOrderStatus,
          search: queryArg.search,
        },
      }),
    }),
    getBrokerExternalLoadOrigins: build.query<
      GetBrokerExternalLoadOriginsApiResponse,
      GetBrokerExternalLoadOriginsApiArg
    >({
      query: () => ({ url: `/external-loads/origins` }),
    }),
    getBrokerExternalLoadCustomers: build.query<
      GetBrokerExternalLoadCustomersApiResponse,
      GetBrokerExternalLoadCustomersApiArg
    >({
      query: () => ({ url: `/external-loads/customer-names` }),
    }),
    getBrokerLoadCarriers: build.query<
      GetBrokerLoadCarriersApiResponse,
      GetBrokerLoadCarriersApiArg
    >({
      query: () => ({ url: `/external-loads/carrier-names` }),
    }),
    getDocumentsForExtLoad: build.query<
      GetDocumentsForExtLoadApiResponse,
      GetDocumentsForExtLoadApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads/${queryArg.extLoadId}/documents`,
      }),
    }),
    addExternalLoadForQuickpay: build.mutation<
      AddExternalLoadForQuickpayApiResponse,
      AddExternalLoadForQuickpayApiArg
    >({
      query: (queryArg) => ({
        url: `/external-loads/carrier-payee-account/${queryArg.carrierPayeeId}`,
        method: "POST",
        body: queryArg.iAddExternalLoadForQuickpayRequest,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type GetExternalLoadBoardViewApiResponse = /** status 200 Returned */ {
  results: IExternalLoad[];
  totalSize: number;
  limit: number;
  page: number;
};
export type GetExternalLoadBoardViewApiArg = {
  page?: number;
  limit?: number;
  status: QuickPayStatusEnum;
  origin?: string;
  lastStatusChangeDate?: string;
  carrier?: string;
  customer?: string;
  missingDocuments?: string;
  paymentOrderStatus?: string;
  search?: string;
};
export type GetBrokerExternalLoadOriginsApiResponse =
  /** status 200 Returned */ string[];
export type GetBrokerExternalLoadOriginsApiArg = void;
export type GetBrokerExternalLoadCustomersApiResponse =
  /** status 200 Returned */ string[];
export type GetBrokerExternalLoadCustomersApiArg = void;
export type GetBrokerLoadCarriersApiResponse =
  /** status 200 Returned */ string[];
export type GetBrokerLoadCarriersApiArg = void;
export type GetDocumentsForExtLoadApiResponse =
  /** status 200 Returned */ IExternalLoadDocument[];
export type GetDocumentsForExtLoadApiArg = {
  extLoadId: string;
};
export type AddExternalLoadForQuickpayApiResponse =
  /** status 201 Created */ IExternalLoad;
export type AddExternalLoadForQuickpayApiArg = {
  carrierPayeeId: string;
  iAddExternalLoadForQuickpayRequest: IAddExternalLoadForQuickpayRequest;
};
export type IExternalLoadCarrier = {
  id?: string;
  name: string;
  ledgerAccountId: string | null;
  carrierExternalAccountId?: string;
};
export type PaymentOrderStatusEnum = "FAILED" | "PENDING" | "POSTED";
export type IExtLoadQuickPayItem = {
  id: string;
  paymentOrderStatus: PaymentOrderStatusEnum | null;
};
export type IDocsUploadedToLoad = {
  bolId: string | null;
  podId: string | null;
  rateconId: string | null;
  invoiceId: string | null;
};
export type IExternalLoad = {
  id: string;
  createDate: string;
  updateDate: string;
  brokerShipmentId: string;
  carrierPayeeAccountId: string;
  carrierInvoiceId: string;
  carrierInvoiceAmount: number;
  customerName: string;
  customerReferenceType: string;
  customerReferenceNumber: string;
  amountToPayCarrier: number;
  amountBrokerPays: number;
  carrierInvoiceMatchRateCon: boolean;
  PODRequired: boolean | null;
  signedBOL: boolean | null;
  originCity: string;
  originState: string;
  origin: string;
  destinationCity: string;
  destinationState: string;
  destination: string;
  carrierPayeeAccount?: IExternalLoadCarrier;
  extLoadQuickPayItem?: IExtLoadQuickPayItem;
  uploadedDocs?: IDocsUploadedToLoad;
  paymentOrderStatus?: PaymentOrderStatusEnum | null;
  lastStatusChangeDate?: string;
  brokerPaidFee: boolean | null;
  percentFeeUsed: number | null;
};
export type QuickPayStatusEnum =
  | "NOTREQUESTED"
  | "PENDING"
  | "APPROVED"
  | "REJECTED"
  | "DELETED";
export type IExternalLoadDocument = {
  id: string;
  createDate: string;
  updateDate: string;
  documentName: string;
  loadId: string | null;
  draftId: string | null;
  customLabel: string;
  documentType:
    | "BillOfLading"
    | "Invoice"
    | "Other"
    | "ProofOfDelivery"
    | "RateCon";
  getUrl: string;
  externalLoadId: string | null;
};
export type IAddExternalLoadForQuickpayRequest = {
  brokerShipmentId: string;
  carrierInvoiceId: string;
  carrierInvoiceAmount: number;
  customerName: string;
  customerReferenceType: string;
  customerReferenceNumber: string;
  carrierInvoiceMatchRateCon: boolean;
  PODRequired: boolean;
  signedBOL: boolean;
  originCity: string;
  originState: string;
  destinationCity: string;
  destinationState: string;
  draftId?: string;
};
export const {
  useGetExternalLoadBoardViewQuery,
  useLazyGetExternalLoadBoardViewQuery,
  useGetBrokerExternalLoadOriginsQuery,
  useLazyGetBrokerExternalLoadOriginsQuery,
  useGetBrokerExternalLoadCustomersQuery,
  useLazyGetBrokerExternalLoadCustomersQuery,
  useGetBrokerLoadCarriersQuery,
  useLazyGetBrokerLoadCarriersQuery,
  useGetDocumentsForExtLoadQuery,
  useLazyGetDocumentsForExtLoadQuery,
  useAddExternalLoadForQuickpayMutation,
} = injectedRtkApi;
