export const getBaseUrl = (nodeEnvironment: string): string => {
  let baseUrl: string;
  switch (nodeEnvironment) {
    case "dev":
    case "stage":
    case "demo":
      baseUrl = `https://api.mvmnt-api-platform-${nodeEnvironment}.mvmnt.solutions`;
      break;
    case "prod":
      baseUrl = `https://api.mvmnt-api-platform.mvmnt.solutions`;
      break;
    case "qpprod":
      baseUrl = `https://api.mvmnt-api-platform-qpprod.mvmnt.solutions`;
      break;
    default:
      baseUrl = "http://localhost:5000";
      break;
  }
  return baseUrl;
};

export const baseUrl = getBaseUrl(process.env.REACT_APP_NODE_ENV ?? "local");
