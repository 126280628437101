import { VStack, HStack, Text } from "@chakra-ui/layout";
import { Button, useToast } from "@chakra-ui/react";
import { useContext } from "react";
import { ExtLoadTableContext } from "./ExtLoadTableContext";
import { getAmountTotalSelected, requiredUploadedDocsMissing } from "./reuse";
import mvmntTheme from "../../styles/theme";
import UploadQuickpayDocsModal from "../../components/UploadQuickpayDocsModal";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import ReusableConfirmModal from "../../components/ReusableConfirmModal";
import { appearanceApproveBtn } from "../ManageUsers/constants";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { parseNumberToTwoDecimalCurrency } from "../../reuse/Money";
import { QPOperation } from "../BrokerCarrierAccounts/types";
import {
  useDeleteExtLoadDraftsMutation,
  useSubmitDraftForQuickpayMutation,
} from "../../api-platform/externalLoadsDraft";

interface HandleSelectionProps {
  title: string;
  isDisabled: boolean;
  onClick: () => void;
}

interface TableSelectionProps {
  isOpenFlagModal: boolean;
  setIsOpenFlagModal: React.Dispatch<React.SetStateAction<boolean>>;
  qpOperation: QPOperation | undefined;
  setQpOperation: React.Dispatch<React.SetStateAction<QPOperation | undefined>>;
  refetch?: () => void;
}

export const TableSelection = ({
  isOpenFlagModal,
  setIsOpenFlagModal,
  qpOperation,
  setQpOperation,
  refetch,
}: TableSelectionProps): JSX.Element => {
  const toast = useToast();
  const notificationBanner = useNotificationBanner();
  const data = useAppSelector(selectUserData);

  const context = useContext(ExtLoadTableContext);
  const { quickpays, selectedExtLoads, setSelectedExtLoads } = context;

  const [submitExtLoads, { isLoading: isLoadingSubmit }] =
    useSubmitDraftForQuickpayMutation();

  const [deleteLoads, { isLoading: isLoadingDelete }] =
    useDeleteExtLoadDraftsMutation();

  const clearAll = (): void => setSelectedExtLoads([]);
  const selectAll = (): void => setSelectedExtLoads(quickpays ?? []);

  const total = getAmountTotalSelected(
    selectedExtLoads.map((extLoad) =>
      extLoad.amountToPayCarrier ? +extLoad.amountToPayCarrier : +0,
    ),
  );

  const confirmProcess = async (): Promise<void> => {
    const ids = selectedExtLoads.map((extLoad) => extLoad.id);
    const args = { body: { ids } };
    const response =
      qpOperation === QPOperation.REQUEST
        ? await submitExtLoads(args)
        : await deleteLoads(args);

    if ("error" in response) {
      if (
        "status" in response.error &&
        response.error.status === 422 &&
        qpOperation === QPOperation.REQUEST
      ) {
        const readableError = parseErrorResponse(response.error);
        const errors = readableError.split(",");
        notificationBanner({
          title: (
            <h4 style={{ marginBottom: "10px" }}>
              There was a problem requesting QuickPays. Please try again.
            </h4>
          ),
          status: "error",
          description: (
            <VStack>
              {errors.map((e) => (
                <p key={e}>{e}</p>
              ))}
            </VStack>
          ),
        });
      } else {
        notificationBanner({
          status: "error",
          description: (
            <p>{`There was a problem ${
              qpOperation === QPOperation.REQUEST ? "requesting" : "removing"
            } QuickPays. Please try again.`}</p>
          ),
        });
      }
    } else {
      notificationBanner({
        status: "success",
        description: <p>Request success.</p>,
      });
      setQpOperation(undefined);
      setSelectedExtLoads([]);
      if (refetch) {
        refetch();
      }
    }
  };

  const HandleSelection = ({
    title,
    isDisabled,
    onClick,
  }: HandleSelectionProps): JSX.Element => (
    <Button
      color={mvmntTheme.colors.mvmntBlue}
      textDecoration="underline"
      padding="0 0.2rem"
      border="none"
      isDisabled={isDisabled}
      onClick={onClick}
    >
      {title}
    </Button>
  );

  return (
    <VStack
      color="white"
      height="100px"
      width="100%"
      display="grid"
      placeContent="center"
      padding="70px 0"
      position="fixed"
      bottom="0"
      overflowX="scroll"
    >
      <HStack
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        width="960px"
        height="80px"
        background={mvmntTheme.colors.grey6}
        border={`1px solid ${mvmntTheme.colors.grey5}`}
        boxShadow="4px 4px 8px rgba(35, 35, 35, 0.1)"
        borderRadius="4px"
        px="1rem"
      >
        <HStack>
          <Text padding="0 1rem 0 0" color="black" fontWeight="900">
            {selectedExtLoads.length} Selected Rows
          </Text>
          <HandleSelection
            title="Clear"
            isDisabled={selectedExtLoads.length === 0}
            onClick={clearAll}
          />
          <HandleSelection
            title="Select all"
            isDisabled={quickpays?.length === selectedExtLoads.length}
            onClick={selectAll}
          />
        </HStack>
        <HStack>
          <HStack padding="0 1rem 0 0" color="black">
            <Text>Total:</Text>
            <Text fontWeight="900">
              {parseNumberToTwoDecimalCurrency(total)}
            </Text>
          </HStack>
          <Button
            size="sm"
            color="mvmntRed"
            variant="link"
            onClick={() => setQpOperation(QPOperation.DELETE)}
          >
            Delete
          </Button>
          <Button
            layerStyle="black"
            onClick={async () => {
              setIsOpenFlagModal(true);
            }}
            isDisabled={selectedExtLoads.length === 0}
          >
            Attach Documents
          </Button>
          <Button
            layerStyle="yellow"
            isDisabled={
              selectedExtLoads.length === 0 ||
              selectedExtLoads.filter((extLoad) => {
                return requiredUploadedDocsMissing(extLoad.uploadedDocs);
              }).length > 0
            }
            onClick={() => {
              if (!data.broker?.quickpayEnabled) {
                toast({
                  title: "Account QuickPay temporarily on hold.",
                  description: "Please email support@mvmnt.io for help.",
                  status: "error",
                  position: "top",
                  duration: 9000,
                  isClosable: true,
                });
              } else {
                setQpOperation(QPOperation.REQUEST);
              }
            }}
          >
            Send payment
          </Button>
        </HStack>
      </HStack>
      <UploadQuickpayDocsModal
        isOpen={isOpenFlagModal}
        onClose={() => {
          setSelectedExtLoads([]);
          setIsOpenFlagModal(false);
          // TODO: this happens whether or not a file is uploaded,
          // Need to fix this to be more efficient
          if (refetch) {
            refetch();
          }
        }}
        externalLoads={selectedExtLoads}
      />
      {qpOperation && (
        <ReusableConfirmModal
          isOpen={!!qpOperation}
          onClose={() => {
            setQpOperation(undefined);
            setSelectedExtLoads([]);
          }}
          onClickCTA={confirmProcess}
          title={`${
            qpOperation === QPOperation.REQUEST ? "Request" : "Delete"
          } Draft`}
          content={`Are you sure you want to ${
            qpOperation === QPOperation.REQUEST ? "request" : "delete"
          } selected Draft IDs ?`}
          cancelText="Cancel"
          confirmText="Confirm"
          loading={isLoadingSubmit || isLoadingDelete}
          appearanceConfirmBtn={appearanceApproveBtn}
        />
      )}
    </VStack>
  );
};
