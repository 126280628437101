import { VStack, Image, Text, HStack } from "@chakra-ui/react";
import { ExternalLoadQPStatus } from "../../types/ExternalLoad";
import mvmntTheme from "../../styles/theme";
import { BrokerQPPageContext } from "./BrokerQuickpayPageContext";
import { useContext } from "react";

interface EmptyProps {
  source: string;
  onClickReference?: () => void;
  onClickDownloadTemplate?: () => void;
}

export const Empty = ({
  source,
  onClickReference,
  onClickDownloadTemplate,
}: EmptyProps): JSX.Element => {
  const pageContext = useContext(BrokerQPPageContext);
  const { searchParams } = pageContext;
  const title =
    searchParams.get("status") === ExternalLoadQPStatus.Draft
      ? "You haven't added any loads yet..."
      : `You don't have any ${searchParams
          .get("status")
          ?.toLowerCase()} loads yet...`;

  return (
    <VStack w="100%" py="3rem">
      <Image src={source} alt="" />
      <Text pt="1rem" fontWeight={600} fontSize={20}>
        {title}
      </Text>
      {searchParams.get("status") === ExternalLoadQPStatus.Draft && (
        <VStack>
          <HStack fontSize={13}>
            <Text>Get started by</Text>
            <Text
              color={mvmntTheme.colors.mvmntBlue}
              textDecoration="underline"
              cursor="pointer"
              onClick={onClickReference}
            >
              uploading loads
            </Text>
          </HStack>
          <HStack fontSize={13}>
            <Text>You can download</Text>
            <Text
              color={mvmntTheme.colors.mvmntBlue}
              textDecoration="underline"
              cursor="pointer"
              onClick={onClickDownloadTemplate}
            >
              Template loads
            </Text>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
};
