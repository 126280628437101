import { EditIcon, PhoneIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
  SkeletonText,
  Button,
  Spacer,
  FormLabel,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useParams } from "react-router-dom";
import {
  IUpsertBrokerBillingContactInfo,
  useGetBrokerBillingContactQuery,
  useUpsertBrokerBillingContactMutation,
} from "../../../../../api-platform/internal";
import { useNotificationBanner } from "../../../../../components/useNotificationBanner";
import { DEFAULT_ERROR_MESSAGE } from "../../../../../reuse/Constants";
import {
  validateEmail,
  validatePhoneNumber,
} from "../../../../../reuse/UserData";

export function BillingContact(): JSX.Element {
  const { brokerId } = useParams();
  const [isEditMode, setIsEditMode] = useState(false);
  const [upsertContactInfo, { isLoading: upsertLoading }] =
    useUpsertBrokerBillingContactMutation();

  const { data: contact, isLoading } = useGetBrokerBillingContactQuery(
    brokerId ? { brokerId } : skipToken,
  );

  const creditInfoForm = useForm<IUpsertBrokerBillingContactInfo>();

  useEffect(() => {
    creditInfoForm.reset({
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      phone: contact?.phone,
      email: contact?.email ?? "",
    });
  }, [isLoading]);

  const notificationBanner = useNotificationBanner();

  async function handleUpsertBrokerCreditInfoFormSubmit(
    data: IUpsertBrokerBillingContactInfo,
  ): Promise<void> {
    if (brokerId) {
      await upsertContactInfo({
        brokerId,
        iUpsertBrokerBillingContactInfo: data,
      })
        .unwrap()
        .catch((e) => {
          notificationBanner({
            status: "error",
            description: DEFAULT_ERROR_MESSAGE,
          });
          throw e;
        });
      setIsEditMode(false);
      notificationBanner({
        status: "success",
        description: "Billing Contact Info updated successfully.",
      });
    }
  }

  if (isLoading) {
    return (
      <SkeletonText maxW="400px" mt="8px" noOfLines={20} spacing="4" mb="8px" />
    );
  }
  return (
    <VStack spacing="20px" w="100%" maxW="640px" align="start">
      <VStack w="100%" alignItems="start">
        <Divider />
        <HStack w="100%">
          <Heading size="md">Billing contact</Heading>
          <Spacer />
          <Button
            leftIcon={<EditIcon />}
            visibility={isEditMode ? "hidden" : "visible"}
            onClick={() => setIsEditMode(true)}
          >
            <Text pt="0.1rem">Edit</Text>
          </Button>
        </HStack>
      </VStack>
      {isEditMode ? (
        <>
          <FormControl
            isRequired
            isInvalid={!!creditInfoForm.formState.errors?.firstName}
          >
            <FormLabel requiredIndicator={<></>}>First Name</FormLabel>
            <Input
              w="auto"
              {...creditInfoForm.register("firstName", {
                required: false,
              })}
            />
            <FormErrorMessage>
              <ErrorMessage
                errors={creditInfoForm.formState.errors}
                name="firstName"
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!creditInfoForm.formState.errors?.lastName}
          >
            <FormLabel requiredIndicator={<></>}>Last Name</FormLabel>
            <Input
              w="auto"
              {...creditInfoForm.register("lastName", {
                required: false,
              })}
            />
            <FormErrorMessage>
              <ErrorMessage
                errors={creditInfoForm.formState.errors}
                name="lastName"
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            isInvalid={!!creditInfoForm.formState.errors?.email}
          >
            <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <EmailIcon color="gray.400" />
              </InputLeftElement>
              <Input
                {...creditInfoForm.register("email", {
                  validate: () =>
                    validateEmail(creditInfoForm.getValues().email ?? ""),
                  required: false,
                })}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage
                errors={creditInfoForm.formState.errors}
                name="email"
              />
            </FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!creditInfoForm.formState.errors?.phone}>
            <FormLabel>Phone number</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <PhoneIcon color="gray.400" />
              </InputLeftElement>
              <PhoneInput
                name="phone"
                w="auto"
                placeholder="(___) - ___ - ____"
                control={creditInfoForm.control}
                rules={{
                  validate: (value: string | undefined) => {
                    if (value) {
                      validatePhoneNumber(`${value}`);
                    }
                  },
                  required: false,
                }}
                country="US"
                style={{ paddingLeft: "2.5rem" }}
                inputComponent={Input}
              />
            </InputGroup>
            <FormErrorMessage>
              <ErrorMessage
                errors={creditInfoForm.formState.errors}
                name="phone"
              />
            </FormErrorMessage>
          </FormControl>
          <HStack>
            <Button
              layerStyle="yellow"
              onClick={() => {
                void creditInfoForm.handleSubmit(
                  handleUpsertBrokerCreditInfoFormSubmit,
                )();
              }}
              isLoading={upsertLoading}
            >
              Save changes
            </Button>
            <Button
              onClick={() => {
                creditInfoForm.reset();
                setIsEditMode(false);
              }}
            >
              Cancel
            </Button>
          </HStack>
        </>
      ) : (
        <VStack spacing="24px" align="start" mt="20px">
          <Box>
            <FormLabel>First Name</FormLabel>
            <Text>{contact?.firstName}</Text>
          </Box>
          <Box>
            <FormLabel>Last Name</FormLabel>
            <Text>{contact?.lastName}</Text>
          </Box>
          <Box>
            <FormLabel>Email</FormLabel>
            <Text>{contact?.email}</Text>
          </Box>
          <Box>
            <FormLabel>Phone Number</FormLabel>
            <Text>{contact?.phone}</Text>
          </Box>
        </VStack>
      )}
    </VStack>
  );
}
