import { Icon, IconProps } from "@chakra-ui/react";

export const ErrorIcon = (props: IconProps): JSX.Element => (
  <Icon width="18px" height="18px" viewBox="0 0 18 18" fill="none" {...props}>
    <path
      d="M9.00018 0.00168586C6.59129 0.0392716 4.29374 1.0224 2.60328 2.73893C1.76186 3.58023 1.09796 4.58194 0.650953 5.68464C0.203945 6.78735 -0.0170662 7.9686 0.00102763 9.15832C3.95253e-05 10.3203 0.22831 11.4711 0.672765 12.5448C1.11722 13.6184 1.76912 14.5939 2.59114 15.4152C3.41316 16.2365 4.38913 16.8876 5.46316 17.3311C6.5372 17.7747 7.68818 18.002 8.85019 18H9.00018C11.4075 17.9782 13.7077 17.0015 15.3951 15.2845C17.0826 13.5675 18.0193 11.2507 17.9993 8.84335C18.0015 7.6683 17.7691 6.50463 17.3157 5.42058C16.8623 4.33654 16.1969 3.35395 15.3587 2.53042C14.5205 1.7069 13.5264 1.05903 12.4345 0.624796C11.3426 0.190566 10.175 -0.0212782 9.00018 0.00168586ZM7.87528 12.4055C7.86915 12.2547 7.89383 12.1043 7.9478 11.9634C8.00177 11.8225 8.08391 11.6941 8.18919 11.586C8.29447 11.4779 8.42069 11.3924 8.56013 11.3347C8.69957 11.2771 8.8493 11.2485 9.00018 11.2506C9.29555 11.2523 9.57888 11.3679 9.79121 11.5732C10.0035 11.7786 10.1285 12.0579 10.1401 12.353C10.1461 12.5015 10.1221 12.6497 10.0694 12.7887C10.0168 12.9277 9.93651 13.0546 9.83354 13.1618C9.73056 13.269 9.60697 13.3542 9.4702 13.4124C9.33343 13.4706 9.18631 13.5005 9.03767 13.5004C8.73967 13.5046 8.45171 13.3927 8.23476 13.1883C8.01781 12.984 7.88889 12.7032 7.87528 12.4055ZM8.25025 9.4058V4.90622C8.25025 4.70733 8.32926 4.51658 8.4699 4.37594C8.61054 4.2353 8.80128 4.15629 9.00018 4.15629C9.19907 4.15629 9.38982 4.2353 9.53046 4.37594C9.6711 4.51658 9.75011 4.70733 9.75011 4.90622V9.4058C9.75011 9.60469 9.6711 9.79544 9.53046 9.93608C9.38982 10.0767 9.19907 10.1557 9.00018 10.1557C8.80128 10.1557 8.61054 10.0767 8.4699 9.93608C8.32926 9.79544 8.25025 9.60469 8.25025 9.4058Z"
      fill="#ED3054"
    />
  </Icon>
);
