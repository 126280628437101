import { Skeleton } from "@chakra-ui/react";
import { Routes, Route } from "react-router";
import AppRoutes from "../../routes";
import ComingSoon from "../../components/ComingSoon";
import ManageUsers from "../ManageUsers";
import QuickPayRequest from "../QuickPayRequest";
import { InternalRoute } from "../Internal";

export const AdminShell = (): JSX.Element => {
  return (
    <Skeleton isLoaded w="100%" h="100%" d="flex" flexDir="column">
      <Routes>
        <Route path={AppRoutes.MANAGE_USERS} element={<ManageUsers />} />
        <Route path={AppRoutes.QUICKPAY} element={<QuickPayRequest />} />
        <Route path={AppRoutes.INTERNAL} element={<InternalRoute />} />
        <Route path="/*" element={<ComingSoon />} />
      </Routes>
    </Skeleton>
  );
};

export default AdminShell;
