import { Text, VStack } from "@chakra-ui/layout";
import {
  DataTable,
  useDataTable,
} from "../../ui/components/DataTable/DataTable";
import { useState } from "react";
import { useDebounce } from "../../components/useDebounceHook";
import { CarrierTableContext } from "./CarrierTableContext";
import BrokerCarrierAccountSubhead from "./BrokerCarrierAccountSubhead";
import { parseStopDateToLocal } from "../../reuse/Dates";
import { Button, Link, HStack, Tooltip } from "@chakra-ui/react";
import { useCarrierAccountsPageContext } from "./CarrierAccountsPageContext";
import { selectUserData } from "../../app/userSlice";
import { useAppSelector } from "../../app/hooks";
import { NoResults } from "./NoResults";
import {
  ICarrierPayeeAccount,
  useGetCarriersForBrokerQuery,
} from "../../api-platform/carrierPayeeAccounts";
import routes from "../../routes";
import { useNavigate } from "react-router-dom";
import { abbreviatedCarrierTypeID } from "../../reuse/Constants";
import { DollarCoinIcon, RequestMoneyIcon } from "../../ui/icons";
import ChevronIcon from "../../components/ChevronIcon";

export const BrokerCarrierAccountTable = (): JSX.Element => {
  const navigate = useNavigate();
  const userData = useAppSelector(selectUserData);
  const { broker } = userData;
  const [sortOption, setSortOption] = useState<"createDate">("createDate");
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { setCarrierSelectedForDefaultLoad } = useCarrierAccountsPageContext();

  const { data } = useGetCarriersForBrokerQuery({
    sortOption,
    sortDirection,
    searchTerm: debouncedSearchTerm,
  });

  const table = useDataTable<ICarrierPayeeAccount>({
    data: data ?? [],
    columns: [
      {
        header: " ",
        accessorKey: `broker pays fee column`,
        cell: (cell) => {
          const { brokerPaysFee } = cell.row.original;
          return (
            <Tooltip
              hasArrow
              placement="right-end"
              label={`MVMNT fee paid by ${
                brokerPaysFee ? broker?.name : "carrier"
              }`}
            >
              <span>
                {brokerPaysFee ? (
                  <RequestMoneyIcon color="gray.500" w="18px" />
                ) : (
                  <DollarCoinIcon color="gray.500" w="18px" />
                )}
              </span>
            </Tooltip>
          );
        },
      },
      {
        header: "Company Name",
        accessorKey: "name",
        cell: (cellInfo) => {
          return (
            <Link
              color="blue.500"
              onClick={() =>
                navigate(
                  routes.CARRIER_ACCOUNT.replace(
                    ":carrierId",
                    cellInfo.row.original.id,
                  ),
                )
              }
            >
              {cellInfo.row.original.name}
            </Link>
          );
        },
      },
      {
        header: "Carrier ID",
        accessorKey: "carrierId",
        cell: (cellInfo) => {
          return (
            <VStack alignItems="baseline">
              <Text>{cellInfo.row.original.carrierId}</Text>
              <Text textColor="gray60" fontSize={11}>
                {abbreviatedCarrierTypeID[cellInfo.row.original.typeId]}
              </Text>
            </VStack>
          );
        },
      },
      {
        header: "Primary Contact",
        cell: (cellInfo) => {
          return <Text>{cellInfo.row.original.primaryContactEmail ?? ""}</Text>;
        },
      },
      {
        header: "Last Paid Date",
        accessorKey: "lastPaidDate",
        cell: (cellInfo) => {
          const lastPaidDate = cellInfo.row.original.lastPaidDate;
          return (
            <Text>
              {lastPaidDate
                ? parseStopDateToLocal(new Date(lastPaidDate))
                : "-"}
            </Text>
          );
        },
      },
      {
        header: "Date Added",
        accessorKey: "createDate",
        cell: (cellInfo) => {
          return (
            <Text>
              {parseStopDateToLocal(
                new Date(String(cellInfo.row.original.createDate)),
              )}
            </Text>
          );
        },
      },
      {
        header: " ",
        // Turns out if more than one column have no accessor key, the html keys overlap with each other
        accessorKey: `send qp button row`,
        cell: (cellInfo) => {
          return (
            <HStack justifyContent="flex-end">
              <Button
                onClick={() => {
                  setCarrierSelectedForDefaultLoad(cellInfo.row.original);
                }}
                disabled={!broker?.quickpayEnabled}
                layerStyle="black"
                leftIcon={<ChevronIcon h={3} />}
              >
                Pay
              </Button>
            </HStack>
          );
        },
      },
    ],
  });

  return (
    <CarrierTableContext.Provider
      value={{
        sortOption,
        setSortOption,
        sortDirection,
        setSortDirection,
        searchTerm,
        setSearchTerm,
      }}
    >
      <BrokerCarrierAccountSubhead totalDisplayedCarriers={data?.length ?? 0} />
      {data && data.length > 0 ? <DataTable table={table} /> : <NoResults />}
    </CarrierTableContext.Provider>
  );
};

export default BrokerCarrierAccountTable;
