import { useToast } from "@chakra-ui/react";

const DURATION_DEFAULT = 3000;

interface ToastFunction {
  description: string;
  title?: string;
  duration?: number | null;
  isClosable?: boolean;
}

const useToastHook = (): {
  error: ({ description, title, duration, isClosable }: ToastFunction) => void;

  warning: ({
    description,
    title,
    duration,
    isClosable,
  }: ToastFunction) => void;

  success: ({
    description,
    title,
    duration,
    isClosable,
  }: ToastFunction) => void;
} => {
  const toast = useToast();

  const error = ({
    description,
    duration = null,
    title = "Error",
    isClosable = true,
  }: ToastFunction): void => {
    toast({
      status: "error",
      description,
      title,
      duration,
      isClosable,
    });
  };

  const warning = ({
    description,
    duration = DURATION_DEFAULT,
    title = "Warning",
    isClosable,
  }: ToastFunction): void => {
    toast({
      status: "warning",
      description,
      title,
      duration,
      isClosable,
    });
  };

  const success = ({
    description,
    duration = DURATION_DEFAULT,
    title = "Success",
    isClosable,
  }: ToastFunction): void => {
    toast({
      status: "success",
      description,
      title,
      duration,
      isClosable,
    });
  };

  return { error, warning, success };
};

export default useToastHook;
