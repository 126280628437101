import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Flex,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { createColumnHelper, SortingState } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import {
  BrokerAccountCarriers,
  GetCarriersForBrokerAccountApiResponse,
} from "../../../../api-platform/internal";
import { Paginator } from "../../../../components/Paginator/Paginator";
import { abbreviatedCarrierTypeID } from "../../../../reuse/Constants";
import { parseStopDateToLocal } from "../../../../reuse/Dates";
import { DataTable, useDataTable } from "../../../../ui/components/DataTable";
interface CarriersTableProps {
  carriers: GetCarriersForBrokerAccountApiResponse;
  setCurrentPage: (newPage: number) => void;
  currentPage: number;
}

enum COLUMN_IDS {
  carrierName = "carrierName",
  accountLastFourDigits = "accountLastFourDigits",
  routing = "routing",
  bank = "bank",
  lastPaidDate = "lastPaidDate",
  dateAdded = "dateAdded",
}

const columnHelper = createColumnHelper<BrokerAccountCarriers>();

export const CarriersTable = ({
  carriers,
  setCurrentPage,
  currentPage,
}: CarriersTableProps): JSX.Element => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([
    { id: "dateAdded", desc: false },
  ]);

  const table = useDataTable({
    data: carriers.results ?? [],
    state: {
      globalFilter,
      sorting,
    },
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    columns: useMemo(
      () => [
        columnHelper.accessor("company.carrierName", {
          header: "Company Name",
          id: COLUMN_IDS.carrierName,
          sortingFn: "textCaseSensitive",
          cell: (cell) => {
            return (
              <VStack alignItems="baseline">
                <Text fontWeight={600}>
                  {cell.row.original.company.carrierName}
                </Text>
                <Text textColor="gray60" fontSize={11}>
                  {abbreviatedCarrierTypeID[cell.row.original.company.typeId]}:{" "}
                  {cell.row.original.company.carrierId}
                </Text>
              </VStack>
            );
          },
        }),
        columnHelper.accessor("corporateAddress", {
          header: "Address",
          sortingFn: "textCaseSensitive",
          cell: (cell) => {
            return (
              <Text whiteSpace="pre-wrap" width="60%" noOfLines={2}>
                {cell.row.original.corporateAddress}
              </Text>
            );
          },
        }),
        columnHelper.accessor("phone", {
          header: "Phone",
          sortingFn: "textCaseSensitive",
          cell: (cell) => {
            return <Text>{cell.row.original.phone}</Text>;
          },
        }),
        columnHelper.accessor("contact.primaryContactFirstName", {
          header: "Contact",
          sortingFn: "textCaseSensitive",
          cell: (cell) => {
            return (
              <VStack alignItems="baseline">
                <Text fontWeight={600}>
                  {cell.row.original.contact.primaryContactFirstName}{" "}
                  {cell.row.original.contact.primaryContactLastName}
                </Text>
                <Text textColor="gray60" fontSize={11}>
                  {cell.row.original.contact.primaryContactEmail}
                </Text>
                <Text textColor="gray60" fontSize={11}>
                  {cell.row.original.contact.primaryContactPhone}
                </Text>
              </VStack>
            );
          },
        }),
        columnHelper.accessor("paymentAccount.bank", {
          header: "Payment Account",
          sortingFn: "textCaseSensitive",
          cell: (cell) => {
            const routing = cell.row.original.paymentAccount.routing;
            return (
              <VStack alignItems="baseline">
                <Text fontWeight={600}>
                  {cell.row.original.paymentAccount.bank}
                </Text>
                {routing && (
                  <Text textColor="gray60" fontSize={11}>
                    {routing} | ...
                    {cell.row.original.paymentAccount.accountLastFourDigits}
                  </Text>
                )}
              </VStack>
            );
          },
        }),
        columnHelper.accessor("lastPaidDate", {
          header: "Date Last Paid",
          id: COLUMN_IDS.lastPaidDate,
          sortingFn: "datetime",
          cell: (cell) => {
            const lastPaidDate = cell.row.original.lastPaidDate;
            return (
              <Text>
                {lastPaidDate
                  ? parseStopDateToLocal(new Date(lastPaidDate))
                  : "-"}
              </Text>
            );
          },
        }),
        columnHelper.accessor("dateAdded", {
          header: "Date Added",
          id: COLUMN_IDS.dateAdded,
          sortingFn: "datetime",
          cell: (cell) => {
            const dateAdded = cell.row.original.dateAdded;
            return (
              <Text>
                {dateAdded ? parseStopDateToLocal(new Date(dateAdded)) : "-"}
              </Text>
            );
          },
        }),
      ],
      [],
    ),
  });

  return (
    <>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        marginTop="20px"
        marginBottom="20px"
        padding="8px 8px 8px 20px"
        fontFamily="Montserrat Regular"
        fontSize="13px"
      >
        <HStack>
          <Text fontWeight="bold" fontSize="20px">
            Carrier List
          </Text>
        </HStack>
        <InputGroup marginLeft="auto" width="auto">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray30" />
          </InputLeftElement>
          {table.getState().globalFilter && (
            <InputRightElement
              cursor="pointer"
              onClick={() => table.resetGlobalFilter()}
            >
              <CloseIcon color="gray30" />
            </InputRightElement>
          )}
          <Input
            onChange={(event) => setGlobalFilter(event.target.value)}
            value={table.getState().globalFilter || ""}
            placeholder="Search"
            width="480px"
            borderRadius="4px"
            borderColor="grey5"
            bgColor="#fff"
          />
        </InputGroup>
      </Flex>
      <DataTable table={table} />
      {carriers.results && (
        <Paginator
          onPageChange={setCurrentPage}
          totalCount={carriers.totalSize}
          currentPage={currentPage}
          pageSize={carriers.limit}
        />
      )}
    </>
  );
};
