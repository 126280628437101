import { Box, Heading, HStack, Tab, TabList, Tabs } from "@chakra-ui/react";
import { Header } from "../components/Header";
import {
  GetBrokerAccountsApiArg,
  useGetBrokerAccountsQuery,
} from "../../../api-platform/internal";
import { AccountsTable } from "../components/AccountsTable";
import { DataTableSkeleton } from "../../../ui/components/DataTable";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const statuses: Array<GetBrokerAccountsApiArg["status"]> = [
  "PENDING",
  "APPROVED",
  "REJECTED",
];

export function Accounts(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(
    searchParams.get("status")
      ? statuses.findIndex((s) => s === searchParams.get("status") ?? 0)
      : 0,
  );

  useEffect(() => {
    if (searchParams.get("status") === null) {
      searchParams.set("status", "PENDING");
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  const { data, isLoading } = useGetBrokerAccountsQuery({
    status:
      (searchParams.get("status") as GetBrokerAccountsApiArg["status"]) ??
      undefined,
    search: searchParams.get("search") ?? undefined,
    ordering:
      (searchParams.get("ordering") as GetBrokerAccountsApiArg["ordering"]) ??
      undefined,
  });

  function onTabChange(i: number): void {
    const status = statuses[i];
    setTabIndex(i);
    // blow away all other params on tab navigation
    if (status) setSearchParams({ status });
    else {
      searchParams.delete("status");
      setSearchParams(searchParams);
    }
  }
  return (
    <>
      <Header>
        <Heading>Accounts</Heading>
        <Tabs defaultIndex={tabIndex} onChange={onTabChange}>
          <HStack as={TabList} border="none" spacing="20px" mt="15px">
            {["Pending", "Approved", "Rejected", "All"].map((tab, i) => (
              <Tab
                key={tab}
                fontFamily="Montserrat Regular"
                fontSize="13px"
                mb="0"
                padding="5px 0"
                _active={{ bg: "none", borderColor: "transparent" }}
                _focus={{ boxShadow: "none" }}
                _selected={{
                  borderColor: "blue.500",
                  fontFamily: "Montserrat Bold",
                }}
              >
                {tab}
              </Tab>
            ))}
          </HStack>
        </Tabs>
      </Header>
      <Box padding="20px 40px">
        {isLoading || !data ? (
          <DataTableSkeleton />
        ) : (
          <AccountsTable accounts={data ?? []} />
        )}
      </Box>
    </>
  );
}
