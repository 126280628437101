import { useEffect, useRef, useState } from "react";
import { Button, ButtonProps, Input } from "@chakra-ui/react";

interface FileInputProps<T>
  extends Partial<Pick<HTMLInputElement, "accept" | "multiple">> {
  onClickReference?: boolean;
  setOnClickReference?: React.Dispatch<React.SetStateAction<boolean>>;
  value?: T;
  onChange?: (files: T) => void;
  render?: (
    file: Exclude<T, null>,
    {
      clearFileInput,
    }: {
      clearFileInput: () => void;
    },
  ) => JSX.Element;
  hideOnSelected?: boolean;
  icon?: JSX.Element;
}
type IFileUploadButton = Omit<ButtonProps, "onChange" | "value"> &
  (
    | ({ multiple: true } & FileInputProps<File[] | null>)
    | ({ multiple?: false } & FileInputProps<File | null>)
  );

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function getFiles(filelist: FileList | null | undefined) {
  return {
    singleFile: filelist?.[0] ?? null,
    multipleFiles: [...(filelist ?? [])],
  };
}

export function FileUploadButton({
  onClickReference,
  setOnClickReference,
  multiple,
  accept,
  value,
  onChange,
  render,
  hideOnSelected,
  icon,
  ...buttonProps
}: IFileUploadButton): JSX.Element {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileList, setFileList] = useState<FileList | null>();

  function clearFilelist(fileEl: typeof fileInputRef): void {
    if (fileEl.current) fileEl.current.value = "";
    setFileList(null);
  }

  useEffect(() => {
    if (!value) clearFilelist(fileInputRef);
  }, [value]);

  useEffect(() => {
    if (onClickReference && setOnClickReference) {
      fileInputRef.current?.click();
      setOnClickReference(false);
    }
  }, [onClickReference]);

  return (
    <>
      <Input
        type="file"
        multiple={multiple}
        accept={accept}
        ref={fileInputRef}
        hidden
        onChange={(event) => {
          const { singleFile, multipleFiles } = getFiles(event.target.files);
          setFileList(event.target.files);
          multiple ? onChange?.(multipleFiles) : onChange?.(singleFile);
        }}
      />
      {hideOnSelected && fileList?.length ? null : (
        <Button
          leftIcon={icon}
          {...buttonProps}
          onClick={(value) => {
            buttonProps.onClick?.(value);
            fileInputRef.current?.click();
          }}
        >
          {buttonProps.children}
        </Button>
      )}
      {(() => {
        const files = getFiles(fileList);
        const clearFileInput = (): void => clearFilelist(fileInputRef);
        if (!files.singleFile || !files.multipleFiles.length) return;
        return (
          <>
            {multiple
              ? render?.(files.multipleFiles, {
                  clearFileInput,
                })
              : render?.(files.singleFile, {
                  clearFileInput,
                })}
          </>
        );
      })()}
    </>
  );
}
