import {
  FormLabel,
  Heading,
  VStack,
  Text,
  Alert,
  AlertIcon,
  HStack,
} from "@chakra-ui/react";
import { useState } from "react";
import {
  ICarrierPayeeAccount,
  useCreateExternalAccountMutation,
} from "../../../api-platform/carrierPayeeAccounts";
import { useNotificationBanner } from "../../../components/useNotificationBanner";
import { AddPaymentModal } from "../../CarrierPayeeAccount/AddPaymentModal";

interface CarrierDetailsBoxProps {
  noPaymentsMethodsAvailables: boolean;
  carrierSelectedForDefaultLoad: ICarrierPayeeAccount;
}

export const CarrierDetailsBox = ({
  noPaymentsMethodsAvailables,
  carrierSelectedForDefaultLoad,
}: CarrierDetailsBoxProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();

  const [addModalPayment, setAddModalPayment] = useState<boolean>(false);

  const [createExternalAccount, { isLoading: isCreating }] =
    useCreateExternalAccountMutation();

  async function onCreateExternalAccount(
    accountNumber: string,
    achRoutingNumber: string,
  ): Promise<void> {
    const response = await createExternalAccount({
      id: carrierSelectedForDefaultLoad.id,
      body: {
        accountNumber,
        achRoutingNumber,
      },
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: <p>Failed to save your changes, please try again.</p>,
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Changes have been saved successfully.</p>,
      });
    }
    setAddModalPayment(false);
  }

  return (
    <VStack
      w="420px"
      bg="gray.100"
      border="1px solid"
      borderRadius="4px"
      borderColor="gray.200"
      alignItems="start"
      p="15px"
    >
      <Heading as="h3" fontSize="20px" mb="16px">
        Carrier details
      </Heading>

      <VStack spacing={0} alignItems="start">
        <FormLabel requiredIndicator={<></>}>Carrier Name</FormLabel>
        <Text w="100%">{carrierSelectedForDefaultLoad?.name}</Text>
      </VStack>
      <VStack spacing={0} alignItems="start">
        <FormLabel requiredIndicator={<></>}>Carrier ID</FormLabel>
        <Text w="100%">{carrierSelectedForDefaultLoad?.carrierId}</Text>
      </VStack>
      <VStack spacing={0} alignItems="start">
        <Text w="100%" fontSize={12}>{`The ${
          carrierSelectedForDefaultLoad?.brokerPaysFee ? "brokerage" : "carrier"
        } will pay a ${
          carrierSelectedForDefaultLoad?.brokerPaysFee
            ? carrierSelectedForDefaultLoad?.brokerQpPercentageFee
            : carrierSelectedForDefaultLoad?.carrierQpPercentageFee
        }% fee on this load`}</Text>
      </VStack>
      {noPaymentsMethodsAvailables && (
        <VStack spacing={0}>
          <Alert
            status="error"
            bg="white"
            variant="left-accent"
            borderRadius="5px"
          >
            <HStack alignItems="flex-start">
              <AlertIcon />
              <VStack alignItems="flex-start" fontSize="13px">
                <Text w="100%">
                  This carrier has no payment details. You must set up a payment
                  method to pay this carrier.
                </Text>
                <Text
                  textDecoration="underline"
                  fontWeight="900"
                  cursor="pointer"
                  onClick={() => setAddModalPayment(true)}
                >
                  Add payment details
                </Text>
              </VStack>
            </HStack>
          </Alert>
        </VStack>
      )}
      <AddPaymentModal
        isOpen={addModalPayment}
        loading={isCreating}
        title="Add another payment method"
        cancelText="Close"
        confirmText="Add payment method"
        layerConfirmButton="yellow"
        onClose={() => setAddModalPayment(false)}
        onClickCTA={onCreateExternalAccount}
      />
    </VStack>
  );
};

export default CarrierDetailsBox;
