import { Skeleton } from "@chakra-ui/react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppRoutes from "../../routes";
import ComingSoon from "../../components/ComingSoon";
import { AdminRoute } from "../Admin";
import BrokerQuickpayPage from "../BrokerQuickpay/BrokerQuickpayPage";
import BrokerCarrierAccountsPage from "../BrokerCarrierAccounts/BrokerCarrierAccountsPage";
import AddBrokerCarrierAccountPage from "../BrokerCarrierAccounts/AddBrokerCarrierAccountPage";
import CarrierPayeeAccountPage from "../CarrierPayeeAccount/CarrierPayeeAccountPage";
import { DraftUpdateFormWrapper } from "../BrokerQuickpay/DraftUpdateFormWrapper";

export const BrokerShell = (): JSX.Element => {
  return (
    <Skeleton isLoaded={true} w="100%" h="100%" d="flex" flexDir="column">
      <Routes>
        <Route path={AppRoutes.QUICKPAY} element={<BrokerQuickpayPage />} />
        <Route
          path={AppRoutes.CARRIER_ACCOUNTS}
          element={<BrokerCarrierAccountsPage />}
        />
        <Route
          path={AppRoutes.CARRIER_ACCOUNT}
          element={<CarrierPayeeAccountPage />}
        />
        <Route
          path={AppRoutes.CARRIER_ACCOUNTS_ADD}
          element={<AddBrokerCarrierAccountPage />}
        />
        <Route
          path={AppRoutes.BASE}
          element={<Navigate to={AppRoutes.QUICKPAY} replace />}
        />
        <Route path="/*" element={<ComingSoon />} />
        <Route path={AppRoutes.ADMIN} element={<AdminRoute />} />
        <Route path={AppRoutes.DRAFT} element={<DraftUpdateFormWrapper />} />
      </Routes>
    </Skeleton>
  );
};

export default BrokerShell;
