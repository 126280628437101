import {
  HStack,
  Heading,
  FormLabel,
  Divider,
  FormControl,
  FormErrorMessage,
  Input,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { FormState, UseFormWatch, UseFormRegister } from "react-hook-form";
import { IUpsertBrokerFinancials } from "../../../../../api-platform/internal";
import { DEFAULT_MESSAGE_REQUIRED } from "../../../../../reuse/Constants";
import { parseCurrencyToNumber } from "../../../../../reuse/Money";
import CurrencyInput from "react-currency-input-field";

interface EditFinancesProps {
  register: UseFormRegister<IUpsertBrokerFinancials>;
  formState: FormState<IUpsertBrokerFinancials>;
  watch: UseFormWatch<IUpsertBrokerFinancials>;
  requestLeadMode: boolean;
}

export function EditFinances({
  register,
  formState,
  watch, // Watch is being used here to get the values of the masked input fields if exists on edit
  requestLeadMode,
}: EditFinancesProps): JSX.Element {
  return (
    <>
      <FormControl isInvalid={!!formState.errors?.creditLimit}>
        <FormLabel>Quickpay Limit</FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("creditLimit") ?? undefined}
          {...register("creditLimit", {
            required: false,
            setValueAs: (value) => {
              if (value && value !== "$") {
                return parseCurrencyToNumber(String(value));
              } else {
                return null;
              }
            },
          })}
        />
      </FormControl>
      <Divider />
      <Heading size="sm">Total Revenue (Last 3 years)</Heading>
      <HStack>
        <FormControl isRequired isInvalid={!!formState.errors?.yearOneRevenue}>
          <FormLabel requiredIndicator={<></>}>Last Year</FormLabel>
          <Input
            w="auto"
            as={CurrencyInput}
            prefix="$"
            value={watch("yearOneRevenue") ?? undefined}
            {...register("yearOneRevenue", {
              required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              setValueAs: (value) => parseCurrencyToNumber(String(value)),
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={formState.errors} name="yearOneRevenue" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!formState.errors?.yearTwoRevenue}>
          <FormLabel requiredIndicator={<></>}>2 Years Ago</FormLabel>
          <Input
            w="auto"
            as={CurrencyInput}
            prefix="$"
            value={watch("yearTwoRevenue") ?? undefined}
            {...register("yearTwoRevenue", {
              required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              setValueAs: (value) => {
                return parseCurrencyToNumber(String(value));
              },
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={formState.errors} name="yearTwoRevenue" />
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          isInvalid={!!formState.errors?.yearThreeRevenue}
        >
          <FormLabel requiredIndicator={<></>}>3 Years Ago</FormLabel>
          <Input
            w="auto"
            as={CurrencyInput}
            prefix="$"
            value={watch("yearThreeRevenue") ?? undefined}
            {...register("yearThreeRevenue", {
              required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              setValueAs: (value) => parseCurrencyToNumber(String(value)),
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={formState.errors} name="yearThreeRevenue" />
          </FormErrorMessage>
        </FormControl>
      </HStack>
      <Divider />
      <Heading size="sm">Cash flow (Last 3 years)</Heading>
      <Heading as="h5" size="xs">
        EBITDA (Last 3 years)
      </Heading>
      <HStack>
        <FormControl isRequired isInvalid={!!formState.errors?.yearOneEbitda}>
          <FormLabel requiredIndicator={<></>}>Last Year</FormLabel>
          <Input
            w="auto"
            as={CurrencyInput}
            prefix="$"
            value={watch("yearOneEbitda") ?? undefined}
            {...register("yearOneEbitda", {
              required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              setValueAs: (value) => parseCurrencyToNumber(String(value)),
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={formState.errors} name="yearOneEbitda" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!formState.errors?.yearTwoEbitda}>
          <FormLabel requiredIndicator={<></>}>2 Years Ago</FormLabel>
          <Input
            w="auto"
            as={CurrencyInput}
            prefix="$"
            value={watch("yearTwoEbitda") ?? undefined}
            {...register("yearTwoEbitda", {
              required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              setValueAs: (value) => parseCurrencyToNumber(String(value)),
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={formState.errors} name="yearTwoEbitda" />
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!formState.errors?.yearThreeEbitda}>
          <FormLabel requiredIndicator={<></>}>3 Years Ago</FormLabel>
          <Input
            w="auto"
            as={CurrencyInput}
            prefix="$"
            value={watch("yearThreeEbitda") ?? undefined}
            {...register("yearThreeEbitda", {
              required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              setValueAs: (value) => parseCurrencyToNumber(String(value)),
            })}
          />
          <FormErrorMessage>
            <ErrorMessage errors={formState.errors} name="yearThreeEbitda" />
          </FormErrorMessage>
        </FormControl>
      </HStack>
      <FormControl isRequired isInvalid={!!formState.errors?.lastYearNetIncome}>
        <FormLabel requiredIndicator={<></>}>
          Last Full Year Net Income
        </FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("lastYearNetIncome") ?? undefined}
          {...register("lastYearNetIncome", {
            required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
            setValueAs: (value) => parseCurrencyToNumber(String(value)),
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="lastYearNetIncome" />
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={!!formState.errors?.lastYearInterestExpense}
      >
        <FormLabel requiredIndicator={<></>}>
          Last Full Year Interest Expense
        </FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("lastYearInterestExpense") ?? undefined}
          {...register("lastYearInterestExpense", {
            required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
            setValueAs: (value) => parseCurrencyToNumber(String(value)),
          })}
        />
        <FormErrorMessage>
          <ErrorMessage
            errors={formState.errors}
            name="lastYearInterestExpense"
          />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!formState.errors?.unrestrictedCash}>
        <FormLabel requiredIndicator={<></>}>
          Unrestricted Cash and Equivalents
        </FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("unrestrictedCash") ?? undefined}
          {...register("unrestrictedCash", {
            required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
            setValueAs: (value) => parseCurrencyToNumber(String(value)),
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="unrestrictedCash" />
        </FormErrorMessage>
      </FormControl>
      <Divider />
      <Heading size="sm">Financing</Heading>
      <FormControl isRequired isInvalid={!!formState.errors?.totalAssets}>
        <FormLabel requiredIndicator={<></>}>Total Assets</FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("totalAssets") ?? undefined}
          {...register("totalAssets", {
            required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
            setValueAs: (value) => parseCurrencyToNumber(String(value)),
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="totalAssets" />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!formState.errors?.totalDebt}>
        <FormLabel requiredIndicator={<></>}>Total Debt</FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("totalDebt") ?? undefined}
          {...register("totalDebt", {
            required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
            setValueAs: (value) => parseCurrencyToNumber(String(value)),
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="totalDebt" />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!formState.errors?.equityCapital}>
        <FormLabel requiredIndicator={<></>}>Equity Capital</FormLabel>
        <Input
          w="auto"
          as={CurrencyInput}
          prefix="$"
          value={watch("equityCapital") ?? undefined}
          {...register("equityCapital", {
            required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
            setValueAs: (value) => parseCurrencyToNumber(String(value)),
          })}
        />
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="equityCapital" />
        </FormErrorMessage>
      </FormControl>
      <Divider />
      <FormControl
        isRequired
        isInvalid={!!formState.errors?.lastYearFelonyCharges}
      >
        <FormLabel requiredIndicator={<></>}>
          Felony charges against any officers in the last 12 months?
        </FormLabel>
        <RadioGroup
          value={
            String(watch("lastYearFelonyCharges")) === "True" ? "True" : "False"
          }
        >
          <Stack direction="row">
            <Radio
              {...register("lastYearFelonyCharges", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"True"}
            >
              Yes
            </Radio>
            <Radio
              {...register("lastYearFelonyCharges", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"False"}
            >
              No
            </Radio>
          </Stack>
        </RadioGroup>
        <FormErrorMessage>
          <ErrorMessage
            errors={formState.errors}
            name="lastYearFelonyCharges"
          />
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={!!formState.errors?.lastYearBankruptcy}
      >
        <FormLabel requiredIndicator={<></>}>
          Has the company filed for bankruptcy in the last 12 months?
        </FormLabel>
        <RadioGroup
          value={
            String(watch("lastYearBankruptcy")) === "True" ? "True" : "False"
          }
        >
          <Stack direction="row">
            <Radio
              {...register("lastYearBankruptcy", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"True"}
            >
              Yes
            </Radio>
            <Radio
              {...register("lastYearBankruptcy", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"False"}
            >
              No
            </Radio>
          </Stack>
        </RadioGroup>
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="lastYearBankruptcy" />
        </FormErrorMessage>
      </FormControl>
      <FormControl
        isRequired
        isInvalid={!!formState.errors?.unsatisfiedJudgements}
      >
        <FormLabel requiredIndicator={<></>}>
          Any unsatisified judgements?
        </FormLabel>
        <RadioGroup
          value={
            String(watch("unsatisfiedJudgements")) === "True" ? "True" : "False"
          }
        >
          <Stack direction="row">
            <Radio
              {...register("unsatisfiedJudgements", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"True"}
            >
              Yes
            </Radio>
            <Radio
              {...register("unsatisfiedJudgements", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"False"}
            >
              No
            </Radio>
          </Stack>
        </RadioGroup>
        <FormErrorMessage>
          <ErrorMessage
            errors={formState.errors}
            name="unsatisfiedJudgements"
          />
        </FormErrorMessage>
      </FormControl>
      <FormControl isRequired isInvalid={!!formState.errors?.taxLiens}>
        <FormLabel requiredIndicator={<></>}>Any Tax Liens?</FormLabel>
        <RadioGroup
          value={String(watch("taxLiens")) === "True" ? "True" : "False"}
        >
          <Stack direction="row">
            <Radio
              {...register("taxLiens", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"True"}
            >
              Yes
            </Radio>
            <Radio
              {...register("taxLiens", {
                required: requestLeadMode ? false : DEFAULT_MESSAGE_REQUIRED,
              })}
              value={"False"}
            >
              No
            </Radio>
          </Stack>
        </RadioGroup>
        <FormErrorMessage>
          <ErrorMessage errors={formState.errors} name="taxLiens" />
        </FormErrorMessage>
      </FormControl>
      <Divider />
    </>
  );
}
