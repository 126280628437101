import { AbbreviatedCarrierTypeID } from "../pages/BrokerCarrierAccounts/types";

export const DEFAULT_MESSAGE_REQUIRED = "This field is required.";
export const DEFAULT_ERROR_MESSAGE =
  "There was a problem submitting your request. Please try again.";
export const QUICKPAY_REQUEST_DEADLINE = 72; // hours

export const abbreviatedCarrierTypeID: AbbreviatedCarrierTypeID = {
  Other: "Other",
  "Motor Carrier number": "MC",
  "DOT number": "DOT",
};
