import { ExtLoadDocTypeEnum } from "../../../../api-platform/documents";
import { RenderFileInput } from "../RenderFileInput";
import { UploadDocUploaded } from "./UploadDocUploaded";

export interface IDraftDoc {
  updateDate?: string;
  documentType: ExtLoadDocTypeEnum;
  documentName: string;
  deletedAt?: string;
  createDate?: string;
  externalDraftId: string;
  id: string;
}

interface UploadDocProps {
  isLoading: boolean;
  extLoadDraftDoc: IDraftDoc | undefined;
  title: string;
  progress: number;
  fileState: File | null;
  setFileState: React.Dispatch<React.SetStateAction<File | null>>;
  loading: boolean;
}

export const UploadDoc = ({
  extLoadDraftDoc,
  isLoading,
  title,
  progress,
  fileState,
  setFileState,
  loading,
}: UploadDocProps): JSX.Element => {
  return (
    <>
      {!isLoading && extLoadDraftDoc ? (
        <UploadDocUploaded extLoadDraftDoc={extLoadDraftDoc} />
      ) : (
        <RenderFileInput
          title={title}
          progress={progress}
          fileState={fileState}
          setFileState={setFileState}
          loading={loading}
        />
      )}
    </>
  );
};
