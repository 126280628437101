import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

export function Header(props: { children: ReactNode }): JSX.Element {
  return (
    <Box
      bgColor="grey6"
      padding="22px 40px 0px 40px"
      fontFamily="Montserrat Regular"
      fontSize="13px"
      borderRadius="4px"
    >
      {props.children}
    </Box>
  );
}
