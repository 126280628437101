import { mvmntApiPlatform as api } from "../api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaymentsHistory: build.query<
      GetPaymentsHistoryApiResponse,
      GetPaymentsHistoryApiArg
    >({
      query: () => ({ url: `/quickpay/payments-history` }),
    }),
    submitQuickpayForAnExternalLoad: build.mutation<
      SubmitQuickpayForAnExternalLoadApiResponse,
      SubmitQuickpayForAnExternalLoadApiArg
    >({
      query: (queryArg) => ({
        url: `/quickpay`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getQuickPayRequestsView: build.query<
      GetQuickPayRequestsViewApiResponse,
      GetQuickPayRequestsViewApiArg
    >({
      query: (queryArg) => ({
        url: `/quickpay`,
        params: {
          status: queryArg.status,
          brokerage: queryArg.brokerage,
          carrier: queryArg.carrier,
          sortOption: queryArg.sortOption,
          sortDirection: queryArg.sortDirection,
          searchTerm: queryArg.searchTerm,
        },
      }),
    }),
    rejectQuickpays: build.mutation<
      RejectQuickpaysApiResponse,
      RejectQuickpaysApiArg
    >({
      query: (queryArg) => ({
        url: `/quickpay/reject`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    approveQuickpays: build.mutation<
      ApproveQuickpaysApiResponse,
      ApproveQuickpaysApiArg
    >({
      query: (queryArg) => ({
        url: `/quickpay/approve`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getQuickPayCountsByStatus: build.query<
      GetQuickPayCountsByStatusApiResponse,
      GetQuickPayCountsByStatusApiArg
    >({
      query: () => ({ url: `/quickpay/count-by-status` }),
    }),
    getQuickPayBrokers: build.query<
      GetQuickPayBrokersApiResponse,
      GetQuickPayBrokersApiArg
    >({
      query: () => ({ url: `/quickpay/broker-names` }),
    }),
    getQuickPayCarriers: build.query<
      GetQuickPayCarriersApiResponse,
      GetQuickPayCarriersApiArg
    >({
      query: () => ({ url: `/quickpay/carrier-names` }),
    }),
    submitQuickpayNote: build.mutation<
      SubmitQuickpayNoteApiResponse,
      SubmitQuickpayNoteApiArg
    >({
      query: (queryArg) => ({
        url: `/quickpay/${queryArg.id}`,
        method: "PUT",
        body: queryArg.iqpNote,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as mvmntApiPlatform };
export type GetPaymentsHistoryApiResponse =
  /** status 200 Returned */ PaymentsTableData[];
export type GetPaymentsHistoryApiArg = void;
export type SubmitQuickpayForAnExternalLoadApiResponse =
  /** status 200 Updated */ {
    statuses: QuickpayStatusEnum[];
  };
export type SubmitQuickpayForAnExternalLoadApiArg = {
  body: {
    ids: string[];
  };
};
export type GetQuickPayRequestsViewApiResponse =
  /** status 200 Returned */ IQuickPayView[];
export type GetQuickPayRequestsViewApiArg = {
  status: QuickPayStatusEnum;
  brokerage?: string;
  carrier?: string;
  sortOption?: QuickPaySortOptions;
  sortDirection?: "ASC" | "DESC";
  searchTerm?: string;
};
export type RejectQuickpaysApiResponse = /** status 200 Updated */ {
  failedEmails: string[];
};
export type RejectQuickpaysApiArg = {
  body: {
    comment?: string;
    ids: string[];
  };
};
export type ApproveQuickpaysApiResponse = /** status 200 Updated */ {
  failedEmails: string[];
  successes: string[];
  failures: string[];
};
export type ApproveQuickpaysApiArg = {
  body: {
    ids: string[];
  };
};
export type GetQuickPayCountsByStatusApiResponse =
  /** status 200 Returned */ IQuickPayCountsByQpStatus;
export type GetQuickPayCountsByStatusApiArg = void;
export type GetQuickPayBrokersApiResponse = /** status 200 Returned */ string[];
export type GetQuickPayBrokersApiArg = void;
export type GetQuickPayCarriersApiResponse =
  /** status 200 Returned */ string[];
export type GetQuickPayCarriersApiArg = void;
export type SubmitQuickpayNoteApiResponse = unknown;
export type SubmitQuickpayNoteApiArg = {
  id: string;
  iqpNote: IqpNote;
};
export type PaymentsTableData = {
  paymentDate: string;
  activity: string;
  amount: number;
  available: number;
  direction: "credit" | "debit";
};
export type QuickpayStatusEnum =
  | "APPROVED"
  | "DELETED"
  | "NOTREQUESTED"
  | "PENDING"
  | "REJECTED";
export type PaymentOrderStatusEnum = "FAILED" | "PENDING" | "POSTED";
export type IQuickPayView = {
  id: string;
  brokerage: string;
  carrier: string;
  amountToPayCarrier: number;
  amountBrokerPays: number;
  invoice: number;
  paymentOrderStatus: PaymentOrderStatusEnum | null;
  elapsedTime: number;
  timeRemaining: number;
  decidingUser?: string;
  decisionDate?: string;
  rejectNote?: string;
  brokerShipmentId?: string;
  carrierInvoiceId?: string;
  createDate: string;
  extLoadId: string;
  brokerPaidFee: boolean | null;
  percentFeeUsed: number | null;
};
export type QuickPayStatusEnum =
  | "NOTREQUESTED"
  | "PENDING"
  | "APPROVED"
  | "REJECTED"
  | "DELETED";
export type QuickPaySortOptions =
  | "brokerage"
  | "carrier"
  | "elapsedTime"
  | "amount"
  | "timeRemaining";
export type IQuickPayCountsByQpStatus = {
  APPROVED: number;
  REJECTED: number;
  PENDING: number;
  NOTREQUESTED: number;
  DRAFT: number;
};
export type IQuickPayNoteOption = "Create" | "Update";
export type IqpNote = {
  note: string;
  option: IQuickPayNoteOption;
};
export const {
  useGetPaymentsHistoryQuery,
  useLazyGetPaymentsHistoryQuery,
  useSubmitQuickpayForAnExternalLoadMutation,
  useGetQuickPayRequestsViewQuery,
  useLazyGetQuickPayRequestsViewQuery,
  useRejectQuickpaysMutation,
  useApproveQuickpaysMutation,
  useGetQuickPayCountsByStatusQuery,
  useLazyGetQuickPayCountsByStatusQuery,
  useGetQuickPayBrokersQuery,
  useLazyGetQuickPayBrokersQuery,
  useGetQuickPayCarriersQuery,
  useLazyGetQuickPayCarriersQuery,
  useSubmitQuickpayNoteMutation,
} = injectedRtkApi;
