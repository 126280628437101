import {
  Button,
  FormControl,
  HStack,
  Input,
  Skeleton,
  VStack,
  Text,
} from "@chakra-ui/react";
import PhoneInput from "react-phone-number-input/react-hook-form-input";
import { useForm, UseFormRegister, Control, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useSendBrokerAccountFormMutation } from "../../api/broker";
import ErrorMessage from "../../components/ErrorMessage";
import SignInPageHOC from "../../components/SignInPageHOC";
import { parseSigninErrorResponse } from "../../reuse/ErrorHandler";
import { borderStyling } from "../../reuse/Forms";
import { validatePhoneNumber } from "../../reuse/UserData";
import AppRoutes from "../../routes";
import { FormError } from "../../types/FormError";
import { BrokerAccountRequestData, Broker } from "../../types/Broker";
import { UserType } from "../../types/User";
import useToastHook from "../../components/useToastHook";

interface AccountRequestProps {
  userType: Broker;
}

const getUserTypeText = (userType: UserType): string => {
  switch (userType) {
    case UserType.BROKER:
      return "Broker";
    case UserType.ADMIN:
      return "ADMIN";
  }
};

const BrokerFormHeader = ({ userType }: AccountRequestProps): JSX.Element => (
  <VStack spacing="2vh" mt="2vh" color="white">
    <Text fontSize="1.5rem">{getUserTypeText(userType)} Sign Up</Text>
    <Text textAlign="center" maxW="50vw">
      Please enter the following information about your organization and Account
      Administrator (the primary MVMNT user in your organization.) A MVMNT team
      member will reach out to you to complete your account creation. For any
      questions, email us at support@mvmnt.io.
    </Text>
  </VStack>
);

const BrokerFormPrimaryAdmin = (
  errors: FormError,
  register: UseFormRegister<BrokerAccountRequestData>,
  userType: UserType,
): JSX.Element => {
  return (
    <>
      <Text alignSelf="start" color="white">
        {getUserTypeText(userType)} Account Administrator
      </Text>

      <HStack alignSelf="start" w="100%">
        <FormControl>
          <Input
            placeholder="First Name"
            {...register("adminFirst", {
              required: {
                value: true,
                message: "You must enter the first name",
              },
            })}
            style={{ borderColor: borderStyling(errors?.adminFirst) }}
          />
        </FormControl>

        <FormControl>
          <Input
            placeholder="Last Name"
            {...register("adminLast", {
              required: {
                value: true,
                message: "You must enter the last name",
              },
            })}
            style={{ borderColor: borderStyling(errors?.adminLast) }}
          />
        </FormControl>
      </HStack>

      <HStack w="100%">
        <FormControl>
          <Input
            placeholder="Email Address"
            {...register("adminEmail", {
              required: {
                value: true,
                message: "You must enter the email address",
              },
            })}
            style={{ borderColor: borderStyling(errors?.adminEmail) }}
          />
        </FormControl>
      </HStack>
    </>
  );
};

const BrokerFormCompanyInfo = (
  errors: FormError,
  control: Control<BrokerAccountRequestData>,
  register: UseFormRegister<BrokerAccountRequestData>,
): JSX.Element => {
  return (
    <>
      <Text alignSelf="start" mt="8vh" color="white">
        Company Information
      </Text>

      <VStack w="100%">
        <HStack alignSelf="start" w="100%">
          <FormControl>
            <Input
              defaultValue=""
              placeholder="Company Name"
              {...register("companyName")}
            />
          </FormControl>

          <FormControl>
            <Input
              defaultValue=""
              placeholder="Email Address"
              {...register("companyEmail")}
            />
          </FormControl>
        </HStack>

        <FormControl>
          <Input
            placeholder="Website"
            {...register("companyWebsite", {
              required: {
                value: true,
                message: "You must enter the website",
              },
            })}
            style={{ borderColor: borderStyling(errors?.companyWebsite) }}
          />
        </FormControl>

        <FormControl>
          <Controller
            control={control}
            name="companyPhone"
            rules={{
              required: {
                value: true,
                message: "You must enter the company phone number",
              },
              validate: (value) => validatePhoneNumber(value),
            }}
            render={({
              field: { name, value, onChange },
              fieldState: { error },
            }) => (
              <PhoneInput
                placeholder="Phone Number"
                control={control}
                name={name}
                value={value}
                onChange={onChange}
                country="US"
                style={{ borderColor: borderStyling(error) }}
              />
            )}
          />
        </FormControl>

        <HStack alignSelf="start" w="100%">
          <FormControl>
            <Input
              defaultValue=""
              placeholder="DUNS Code"
              {...register("dunsCode")}
            />
          </FormControl>

          <FormControl>
            <Input
              defaultValue=""
              placeholder="NAICS Code"
              {...register("naicsCode")}
            />
          </FormControl>
        </HStack>
      </VStack>
    </>
  );
};

const BrokerFormInsuranceCoverage = (
  errors: FormError,
  register: UseFormRegister<BrokerAccountRequestData>,
): JSX.Element => {
  return (
    <>
      <Text alignSelf="start" color="white">
        Insurance Coverage Requirements
      </Text>
      <HStack alignSelf="start" w="100%">
        <FormControl>
          <Input
            placeholder="Auto Liability"
            {...register("insurance.autoLiability")}
          />
        </FormControl>

        <FormControl>
          <Input
            placeholder="General Liability"
            {...register("insurance.generalLiability")}
          />
        </FormControl>
      </HStack>

      <HStack w="100%">
        <FormControl>
          <Input
            placeholder="Cargo Insurance"
            {...register("insurance.cargoInsurance")}
          />
        </FormControl>
      </HStack>
    </>
  );
};

const BrokerFormAccountsPayable = (
  errors: FormError,
  control: Control<BrokerAccountRequestData>,
  register: UseFormRegister<BrokerAccountRequestData>,
): JSX.Element => {
  return (
    <>
      <Text alignSelf="start" mt="8vh" color="white">
        Accounts Payable
      </Text>

      <VStack w="100%">
        <FormControl>
          <Input
            placeholder="Company Name"
            {...register("accountsReceivable.companyName")}
          />
        </FormControl>

        <FormControl>
          <Input
            defaultValue=""
            placeholder="Address"
            {...register("accountsReceivable.address1")}
          />
        </FormControl>
        <HStack alignSelf="start" w="100%">
          <FormControl>
            <Input
              defaultValue=""
              placeholder="Contact Name"
              {...register("accountsReceivable.contactName")}
            />
          </FormControl>

          <FormControl>
            <Controller
              control={control}
              name="accountsReceivable.phoneNumber"
              rules={{
                validate: (value) => validatePhoneNumber(value),
              }}
              render={({
                field: { name, value, onChange },
                fieldState: { error },
              }) => (
                <PhoneInput
                  placeholder="Phone Number"
                  control={control}
                  name={name}
                  value={value}
                  onChange={onChange}
                  country="US"
                  style={{
                    borderColor: borderStyling(error),
                  }}
                />
              )}
            />
          </FormControl>
        </HStack>

        <FormControl>
          <Input
            defaultValue=""
            placeholder="Email"
            {...register("accountsReceivable.email")}
          />
        </FormControl>
      </VStack>
    </>
  );
};

const AccountRequest = ({ userType }: AccountRequestProps): JSX.Element => {
  const {
    control,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm<BrokerAccountRequestData>();
  const navigate = useNavigate();
  const [sendBrokerAccountForm, { isLoading: isSendingForm }] =
    useSendBrokerAccountFormMutation();
  const toast = useToastHook();

  const handleBrokerFormSubmit = async (
    brokerAccountData: BrokerAccountRequestData,
  ): Promise<void> => {
    const apiResponse = await sendBrokerAccountForm({
      ...brokerAccountData,
      userType,
    });

    if ("data" in apiResponse) {
      toast.success({
        description:
          "Form submitted successfully! Once your request is approved a confirmation email will be sent",
      });
      navigate(AppRoutes.LOGIN);
    } else {
      toast.error({
        description: `${parseSigninErrorResponse(apiResponse.error)}`,
      });
    }
  };

  const onSubmit = handleSubmit(handleBrokerFormSubmit);

  const onClickBack = (): void => {
    navigate(AppRoutes.LOGIN);
  };

  return (
    <Skeleton isLoaded={!isSendingForm} w="100%" mt="4vh">
      <form onSubmit={onSubmit}>
        <HStack spacing="24px" mb="2%">
          <VStack spacing="24px">
            {BrokerFormPrimaryAdmin(errors as FormError, register, userType)}
            {BrokerFormCompanyInfo(errors as FormError, control, register)}
          </VStack>
          <VStack spacing="24px">
            {BrokerFormInsuranceCoverage(errors as FormError, register)}
            {BrokerFormAccountsPayable(errors as FormError, control, register)}
          </VStack>
        </HStack>
        <ErrorMessage errors={errors as FormError} clearErrors={clearErrors} />
        <VStack spacing="1rem">
          <Button layerStyle="red" w="25%" mt="2%" onClick={onSubmit}>
            Submit
          </Button>
          <Button layerStyle="yellow" w="25%" onClick={onClickBack}>
            Back
          </Button>
        </VStack>
      </form>
    </Skeleton>
  );
};

export default SignInPageHOC(AccountRequest, BrokerFormHeader);
