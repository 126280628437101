import {
  Modal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Button,
  ModalBody,
  Text,
  CloseButton,
  InputGroup,
  InputRightAddon,
  Input,
  Select,
  HStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { useInviteUsersToOrgMutation } from "../../../../api/admin";
import { useGetAllRolesQuery } from "../../../../api/roles";
import { useNotificationBanner } from "../../../../components/useNotificationBanner";
import { DefaultModalProps } from "../../../../types/DefaultModal";

interface InviteUserModalProps extends DefaultModalProps {
  orgId: string;
  brokerId: string;
}

export const InviteUserModal = ({
  isOpen,
  onClose,
  orgId,
  brokerId,
}: InviteUserModalProps): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const { data: roles } = useGetAllRolesQuery();
  const [emails, setEmails] = useState<string>("");
  const [emailsValid, setEmailsValid] = useState<boolean>(false);
  const [selectedRoleId, setSelectedRoleId] = useState<string>("");
  const [invite, { isLoading }] = useInviteUsersToOrgMutation();

  const sendInvite = async (): Promise<void> => {
    if (!emailsValid) {
      notificationBanner({
        status: "error",
        description: <p>One or more of the entered emails are invalid</p>,
      });
      return;
    }
    if (!selectedRoleId) {
      notificationBanner({
        status: "error",
        description: <p>You must select a role</p>,
      });
      return;
    }

    const response = await invite({
      emails: emails.split(",").map((email) => email.trim()),
      brokerId,
      orgId,
      roleId: selectedRoleId,
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: <p>Could not send the invites, please try again.</p>,
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Invites sent.</p>,
      });
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textStyle="uppercase"
          display="flex"
          justifyContent="space-between"
        >
          Invite team members
          <CloseButton onClick={onClose} justifySelf="end" />
        </ModalHeader>
        <ModalBody>
          <Text fontSize={18} mb={"10px"}>
            Invite by email
          </Text>
          <HStack>
            <InputGroup>
              <Input
                placeholder="Type email, comma separated"
                value={emails}
                required
                type="email"
                multiple
                onChange={(e) => {
                  setEmailsValid(e.target.validity.valid);
                  setEmails(e.target.value);
                }}
              />
              <InputRightAddon background={"transparent"}>
                <Select
                  value={selectedRoleId}
                  onChange={(event) => setSelectedRoleId(event.target.value)}
                  variant="unstyled"
                  bgColor="none"
                  fontSize="13px"
                  textAlign="left"
                  fontFamily="Montserrat Bold"
                  width="fit-content"
                >
                  <option value="">Select Role</option>
                  {roles?.map((role) => (
                    <option
                      value={role.id}
                      key={`invite role select:${role.id}`}
                    >
                      {role.name}
                    </option>
                  ))}
                </Select>
              </InputRightAddon>
            </InputGroup>
            <Button
              fontSize="15px"
              justifySelf="end"
              layerStyle="blackAndWhite"
              borderRadius="22px"
              onClick={sendInvite}
              disabled={isLoading || !emailsValid || !selectedRoleId}
            >
              Send invite
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InviteUserModal;
