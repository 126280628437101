import { BadgeColors } from "../components/ReusableBadge/ReusableBadge";
import { QuickPayInvoiceStatus } from "../types/QuickPayRequest";
import { BrokerStatus } from "../types/Broker";

export const getBadgeColorForQPInvoiceStatus = (
  status: QuickPayInvoiceStatus,
): BadgeColors =>
  status === QuickPayInvoiceStatus.SHIPPER_PAID
    ? BadgeColors.Green
    : status === QuickPayInvoiceStatus.DELIVERED
    ? BadgeColors.Grey
    : status === QuickPayInvoiceStatus.NOT_PAID
    ? BadgeColors.Blue
    : BadgeColors.Red;

export const getBadgeColorForBrokerStatus = (
  status: BrokerStatus,
): BadgeColors =>
  status === BrokerStatus.APPROVED
    ? BadgeColors.Green
    : status === BrokerStatus.PENDING
    ? BadgeColors.Grey
    : BadgeColors.Red;
