import { SmallAddIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
  IModernTreasuryAccountInfo,
  IUpdateCarrierPayeeAccount,
  useCreateExternalAccountMutation,
  useDeleteCarrierPrimaryExternalAccountMutation,
  useUpdateCarrierPayeeAccountMutation,
  useUpdateCarrierPrimaryExternalAccountMutation,
} from "../../api-platform/carrierPayeeAccounts";
import { useAppSelector } from "../../app/hooks";
import { selectUserData } from "../../app/userSlice";
import ReusableConfirmModal from "../../components/ReusableConfirmModal";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { validateCarrierPercent } from "../../reuse/UserData";
import { AddPaymentModal } from "./AddPaymentModal";
import { useCarrierPayeeAccountPageContext } from "./CarrierPayeeAccountPageContext";
import { ExternalAccountItem } from "./ExternalAccountItem";

const appearanceConfirmBtn = {
  textColor: "mvmntRed",
  border: "1px solid red",
};

export const CarrierPaymentDetails = (): JSX.Element => {
  const notificationBanner = useNotificationBanner();
  const { carrierId } = useParams();
  const { carrier, externalAccounts } = useCarrierPayeeAccountPageContext();
  const data = useAppSelector(selectUserData);
  const { broker } = data;
  const form = useForm<Partial<IUpdateCarrierPayeeAccount>>();

  useEffect(() => {
    if (carrier?.carrierQpPercentageFee) {
      form.reset({
        carrierQpPercentageFee: Number(carrier.carrierQpPercentageFee),
      });
    }
  }, [carrier]);

  const brokerPercentString = broker
    ? `(${broker.brokerQpPercentageFee}%)`
    : ``;
  const carrierPercentString = carrier?.carrierQpPercentageFee
    ? `(${carrier.carrierQpPercentageFee}%)`
    : ``;

  const carrierMaxPercent = broker ? broker.carrierQpPercentageFeeMax : 11.0;
  const carrierMinPercent = broker ? broker.carrierQpPercentageFeeMin : 0.0;

  const [createExternalAccount, { isLoading: isCreating }] =
    useCreateExternalAccountMutation();
  const [updateCarrier, { isLoading }] = useUpdateCarrierPayeeAccountMutation();

  const [updateCarrierPrimaryExternalAccount, { isLoading: isUpdating }] =
    useUpdateCarrierPrimaryExternalAccountMutation();

  const [deleteCarrierPrimaryExternalAccount, { isLoading: isDeleting }] =
    useDeleteCarrierPrimaryExternalAccountMutation();

  const [addModalPayment, setAddModalPayment] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState<
    IModernTreasuryAccountInfo | undefined
  >();

  const onSetBrokeragePays = async (brokerPaysFee: boolean): Promise<void> => {
    if (carrier) {
      const response = updateCarrier({
        id: carrier.id,
        iUpdateCarrierPayeeAccount: {
          brokerPaysFee,
        },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: <p>Failed to save your changes, please try again.</p>,
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Changes have been saved successfully.</p>,
        });
      }
    }
  };

  async function onSubmit(): Promise<void> {
    const values = form.getValues();
    if (carrier) {
      const response = updateCarrier({
        id: carrier.id,
        iUpdateCarrierPayeeAccount: {
          carrierQpPercentageFee: values.carrierQpPercentageFee,
        },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: <p>Failed to save your changes, please try again.</p>,
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Changes have been saved successfully.</p>,
        });
      }
    }
  }

  async function onCreateExternalAccount(
    accountNumber: string,
    achRoutingNumber: string,
  ): Promise<void> {
    const response = await createExternalAccount({
      id: carrierId!,
      body: {
        accountNumber,
        achRoutingNumber,
      },
    });

    if ("error" in response) {
      notificationBanner({
        status: "error",
        description: <p>Failed to save your changes, please try again.</p>,
      });
    } else {
      notificationBanner({
        status: "success",
        description: <p>Changes have been saved successfully.</p>,
      });
    }
    setAddModalPayment(false);
  }

  const onUpdateCarrierPrimaryExternalAccount = async (
    account: IModernTreasuryAccountInfo,
  ): Promise<void> => {
    if (carrier) {
      const response = await updateCarrierPrimaryExternalAccount({
        id: carrier.id,
        body: {
          primaryExternalAccountId: account.id!,
        },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: (
            <p>
              There was a problem updating carrier primary external account.
              Please try again.
            </p>
          ),
        });
      } else {
        notificationBanner({
          status: "success",
          description: (
            <p>Successfully updated carrier primary external account.</p>
          ),
        });
      }
    }
  };

  const onDeleteCarrierPrimaryExternalAccount = async (): Promise<void> => {
    if (carrier && selectedAccount) {
      const response = await deleteCarrierPrimaryExternalAccount({
        id: carrier.id,
        body: {
          carrierExternalAccountId: selectedAccount.id!,
        },
      });

      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: (
            <p>
              There was a problem removing carrier external account. Please try
              again.
            </p>
          ),
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Successfully deleted carrier external account.</p>,
        });
      }
    }
    setSelectedAccount(undefined);
  };

  return (
    <VStack w="100%" alignItems="start">
      <VStack
        maxW="640px"
        w="100%"
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
        mb="24px"
        onSubmit={form.handleSubmit(async () => await onSubmit())}
      >
        <Heading as="h3" fontSize="20px">
          Fee responsibility
        </Heading>
        <Text as="h5" fontSize="16px" color="gray.800">
          Select which party will be responsible for paying the MVMNT fee. This
          will be applied to all MVMNT Pay transactions for this carrier.
        </Text>
        <FormControl
          isRequired
          isInvalid={!!form.formState.errors?.carrierQpPercentageFee}
        >
          <RadioGroup value={String(carrier?.brokerPaysFee)}>
            <VStack alignItems="start">
              <Radio
                disabled={isLoading}
                value="true"
                onClick={async () => await onSetBrokeragePays(true)}
              >
                My brokerage will cover the fee {brokerPercentString}
              </Radio>
              <Radio
                disabled={isLoading}
                value="false"
                onClick={async () => await onSetBrokeragePays(false)}
              >
                The carrier will cover the fee {carrierPercentString}
              </Radio>
            </VStack>
          </RadioGroup>
          {String(carrier?.brokerPaysFee) === "false" && (
            <VStack alignItems="flex-start" marginLeft="6" marginTop="3">
              <FormLabel requiredIndicator={<></>} marginBottom="0">
                Rate
              </FormLabel>
              <InputGroup w="103px">
                <Input
                  type="number"
                  step={0.01}
                  {...form.register("carrierQpPercentageFee", {
                    required: DEFAULT_MESSAGE_REQUIRED,
                    validate: (value) =>
                      validateCarrierPercent(
                        carrierMaxPercent,
                        carrierMinPercent,
                        value!,
                      ),
                    valueAsNumber: true,
                  })}
                  onBlur={form.handleSubmit(async () => {
                    if (
                      form.getValues("carrierQpPercentageFee") !==
                      carrier?.carrierQpPercentageFee
                    ) {
                      await onSubmit();
                    }
                  })}
                />
                <InputRightElement>%</InputRightElement>
              </InputGroup>
              <Button
                layerStyle="yellow"
                type="submit"
                disabled={isLoading}
                w="150px"
                marginTop="2"
              >
                {isLoading ? <Spinner /> : "Save change"}
              </Button>
            </VStack>
          )}
          <FormErrorMessage>
            <ErrorMessage
              errors={form.formState.errors}
              name="carrierQpPercentageFee"
            />
          </FormErrorMessage>
        </FormControl>
      </VStack>
      <VStack
        maxW="640px"
        w="100%"
        as="form"
        noValidate
        align="content-start"
        spacing="24px"
      >
        <Heading as="h3" fontSize="20px">
          Payment details
        </Heading>
        <Text as="h5" fontSize="16px">
          To change a carrier`s payment account, delete the current account and
          a new payment method.
        </Text>
        {externalAccounts?.map((account) => (
          <ExternalAccountItem
            key={account.id}
            account={account}
            checked={carrier?.primaryExternalAccountId === account.id}
            isUpdating={isUpdating}
            isDeleting={isDeleting}
            onClickUpdatePrimaryExternalAccount={async () =>
              await onUpdateCarrierPrimaryExternalAccount(account)
            }
            onClickSelectAccountToDelete={() => setSelectedAccount(account)}
          />
        ))}
        <Button
          w="fit-content"
          layerStyle="black"
          mb="1rem"
          leftIcon={<SmallAddIcon />}
          onClick={() => setAddModalPayment(true)}
        >
          Add another
        </Button>
      </VStack>
      <AddPaymentModal
        isOpen={addModalPayment}
        loading={isCreating}
        title="Add another payment method"
        cancelText="Close"
        confirmText="Add payment method"
        layerConfirmButton="yellow"
        onClose={() => setAddModalPayment(false)}
        onClickCTA={onCreateExternalAccount}
      />
      <ReusableConfirmModal
        isOpen={!!selectedAccount}
        onClose={() => setSelectedAccount(undefined)}
        onClickCTA={onDeleteCarrierPrimaryExternalAccount}
        title="Remove payment account"
        content="Are you sure you want to remove this payment account?"
        cancelText="Close"
        confirmText="Remove account"
        loading={isDeleting}
        appearanceConfirmBtn={appearanceConfirmBtn}
      />
    </VStack>
  );
};
