import { useEffect, useMemo, useState } from "react";
import {
  ColumnFiltersState,
  createColumnHelper,
  SortingState,
} from "@tanstack/react-table";
import {
  DataTable,
  DataTableMultiselectFilter,
  useDataTable,
  searchParamsToColumnFilters,
} from "../../../ui/components/DataTable";
import { parseDateToShortDate } from "../../../reuse/Dates";
import {
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Select,
  Text,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  generatePath,
  useSearchParams,
} from "react-router-dom";
import type { GetBrokerAccountsApiResponse as Accounts } from "../../../api-platform/internal";
import { AccountStatusTag } from "./AccountStatusTag";
import routes from "../../../routes";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import { pluralize, toTitleCase } from "../../../reuse/Strings";

const columnHelper = createColumnHelper<Accounts["results"][0]>();

export function AccountsTable({
  accounts,
}: {
  accounts: Accounts;
}): JSX.Element {
  const [sortCount, setSortCount] = useState(0);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (sorting.length) {
      const { id, desc } = sorting[0];
      searchParams.set("ordering", `${desc ? "-" : ""}${id}`);
    } else if (sortCount) {
      searchParams.delete("ordering");
    }
    setSearchParams(searchParams);
  }, [sortCount]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  // set the column filters from the URL on load and when changing tabs
  useEffect(() => {
    setColumnFilters(
      searchParamsToColumnFilters(searchParams, ["search", "ordering"]),
    );
  }, [searchParams.get("status")]);

  // set sorting on load
  useEffect(() => {
    const ordering = searchParams.get("ordering");
    if (ordering) {
      const desc = ordering.startsWith("-");
      const id = desc ? ordering.substring(1) : ordering;
      setSorting([{ id, desc }]);
    }
  }, []);

  const table = useDataTable({
    data: accounts.results,
    manualSorting: true,
    state: {
      sorting,
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange(sort) {
      setSorting(sort);
      setSortCount(sortCount + 1);
    },
    columns: useMemo(
      () => [
        columnHelper.accessor("name", {
          header: "Company",
          cell(cell) {
            return (
              <Link
                as={RouterLink}
                to={generatePath(routes.INTERNAL_ACCOUNT_INFO, {
                  brokerId: cell.row.original.id,
                })}
                color="blue.500"
              >
                {cell.row.original.name}
              </Link>
            );
          },
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("admin", {
          header: "Admin",
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("createDate", {
          header: "Date created",
          cell: (cell) => parseDateToShortDate(new Date(cell.getValue())),
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("status", {
          header: "Account status",
          cell(cell) {
            return <AccountStatusTag status={cell.row.original.status} />;
          },
          filterFn: "arrIncludesSome",
        }),
        columnHelper.accessor("quickpayEnabled", {
          header: "QuickPay",
          filterFn(row, columnId, filterValue) {
            if (!filterValue.length) return true;
            return filterValue.includes(`${row.getValue(columnId)}`);
          },
          id: "quickpayEnabled",
          cell: (cell) => (cell.row.original.quickpayEnabled ? "On" : "Off"),
        }),
      ],
      [],
    ),
  });

  return (
    <>
      <Flex
        alignItems="center"
        flexWrap="wrap"
        bgColor="grey6"
        marginTop="20px"
        marginBottom="20px"
        padding="10px 10px 10px 20px"
        fontFamily="Montserrat Regular"
        fontSize="13px"
      >
        <HStack height="20px" spacing="13px">
          <Text>
            Showing{" "}
            {pluralize("account", table.getPrePaginationRowModel().rows.length)}
          </Text>
          <Divider orientation="vertical" color="gray30" />
          <Text>Sort by: </Text>
          <Select
            value={table.getState().sorting[0]?.id}
            onChange={(event) =>
              table.setSorting([{ id: event.currentTarget.value, desc: false }])
            }
            variant="unstyled"
            bgColor="none"
            fontSize="13px"
            textAlign="left"
            fontFamily="Montserrat Bold"
            width="fit-content"
          >
            {table
              .getAllColumns()
              .filter((col) => col.getCanSort())
              .map((col) => {
                return (
                  <option value={col.id} key={col.id}>
                    {col.columnDef.header}
                  </option>
                );
              })}
          </Select>
        </HStack>

        <InputGroup marginLeft="auto" width="auto">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray30" />
          </InputLeftElement>
          {searchParams.get("search") && (
            <InputRightElement
              cursor="pointer"
              onClick={() => {
                searchParams.delete("search");
                setSearchParams(searchParams);
              }}
            >
              <CloseIcon color="gray30" />
            </InputRightElement>
          )}
          <Input
            value={searchParams.get("search") ?? ""}
            onChange={({ target }) => {
              if (target.value) searchParams.set("search", target.value);
              else searchParams.delete("search");
              setSearchParams(searchParams);
            }}
            placeholder="Search by company, admin, or status"
            width="480px"
            borderRadius="4px"
            borderColor="grey5"
            bgColor="#fff"
          />
        </InputGroup>
      </Flex>
      <HStack marginBottom="10px">
        <DataTableMultiselectFilter
          table={table}
          columnId="name"
          syncSearchParams={true}
        />
        <DataTableMultiselectFilter
          table={table}
          columnId="admin"
          syncSearchParams={true}
        />
        <DataTableMultiselectFilter
          table={table}
          columnId="status"
          labelFormatter={(label) => toTitleCase(label)}
        />
        <DataTableMultiselectFilter
          table={table}
          columnId="quickpayEnabled"
          syncSearchParams={true}
          labelFormatter={(label) => (`${label}` === "true" ? "On" : "Off")}
        />
      </HStack>
      <DataTable table={table} />
    </>
  );
}
