import { createApi } from "@reduxjs/toolkit/query/react";
import { createBaseQueryWithReauth } from "../api/utils/baseQueryReauth";
import { baseUrl } from "../api/utils/baseUrl";

export const mvmntApiPlatform = createApi({
  baseQuery: createBaseQueryWithReauth(`${baseUrl}`),
  endpoints: () => ({}),
  reducerPath: "mvmntApiPlatform",
  tagTypes: [
    "ExternalLoads",
    "ExternalLoadCustomers",
    "ExternalLoadCarriers",
    "ExtLoadDocuments",
    "ExternalLoadsDraft",
    "ExternalLoadsDraftDocuments",
    "BrokerAccountSummary",
    "BrokerAccounts",
  ],
});
