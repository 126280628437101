import { INTERNAL_ROUTES } from "./pages/Internal";

export const routes = {
  BASE: "/",
  CARRIER_ACCOUNTS: "/carrier-accounts",
  CARRIER_ACCOUNT: "/carrier-accounts/:carrierId",
  CARRIER_AGREEMENTS: "/carrier-agreements/:carrierId",
  CARRIER_ACCOUNTS_ADD: "/carrier-accounts/add",
  LOGIN: "/login",
  REQUEST_ACCOUNT: "/request-account",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  ACCOUNT_DETAILS: "/account",
  MANAGE_USERS: "/manage-users",
  UPDATE_PASSWORD: "/update-password",
  INVITED_USER_SIGN_UP: "/register/invitation/:inviteId",
  QUICKPAY: "/quickpay",
  INVOICES: "/invoices",
  ADMIN: "/admin/*",
  INTERNAL: "/internal/*",
  INTERNAL_ACCOUNTS: `/internal/${INTERNAL_ROUTES.ACCOUNTS}`,
  INTERNAL_ACCOUNT_INFO: `/internal/${INTERNAL_ROUTES.ACCOUNT_INFO}`,
  INTERNAL_ACCOUNT_FINANCES: `/internal/${INTERNAL_ROUTES.ACCOUNT_FINANCES}`,
  INTERNAL_ACCOUNT_CARRIERS: `/internal/${INTERNAL_ROUTES.ACCOUNT_CARRIERS}`,
  INTERNAL_ACCOUNTS_ADD: `/internal/${INTERNAL_ROUTES.ACCOUNTS_ADD}`,
  DRAFT: "/load-draft",
} as const;

export default routes;
