import { Icon, IconProps } from "@chakra-ui/react";

export const MenuIcon = (props: IconProps): JSX.Element => (
  <Icon width="16px" height="4px" viewBox="0 0 16 4" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4ZM8 4C9.10457 4 10 3.10457 10 2C10 0.895431 9.10457 0 8 0C6.89543 0 6 0.895431 6 2C6 3.10457 6.89543 4 8 4ZM16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.895431 12.8954 0 14 0C15.1046 0 16 0.895431 16 2Z"
      fill="currentColor"
    />
  </Icon>
);
