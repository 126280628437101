import { Box, Checkbox, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useState } from "react";
import {
  QuickPayInvoiceStatus,
  QuickPaySortOptionsEnum,
  QuickPayStatusEnum,
} from "../../types/QuickPayRequest";
import { useDebounce } from "../../components/useDebounceHook";
import { DataTable, useDataTable } from "../../ui/components/DataTable";
import { parseNumberToTwoDecimalCurrency } from "../../reuse/Money";
import { QuickPayTableContext } from "./QuickPayTableContext";
import QuickPayTableSubhead from "./QuickPayTableSubhead";
import { TableFilters } from "./TableFilters";
import { TableSelection } from "./FooterSelection";
import ReusableBadge from "../../components/ReusableBadge";
import { BadgeColors } from "../../components/ReusableBadge/ReusableBadge";
import { errorAMTIcon } from "../../resources/svg/CustomIcons";
import {
  IQuickPayView,
  QuickPaySortOptions,
  useSubmitQuickpayNoteMutation,
  useGetQuickPayBrokersQuery,
  useGetQuickPayCarriersQuery,
  useGetQuickPayRequestsViewQuery,
} from "../../api-platform/quickpay";
import {
  getTimeSinceString,
  parseStopDateToLocal,
  parseTimeRemainingInHours,
} from "../../reuse/Dates";
import { downloadFiles, getDownloadNameForExtLoadDoc } from "../../reuse/Files";
import useToastHook from "../../components/useToastHook";
import { parseErrorResponse } from "../../reuse/ErrorHandler";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { PopoverOptions } from "../../components/PopoverOptions";
import { ISelected, Option } from "../BrokerCarrierAccounts/types";
import { FormNoteModal } from "../../components/FormNoteModal";
import { useNotificationBanner } from "../../components/useNotificationBanner";
import { CommentTooltip } from "../../components/CommentTooltip";
import {
  IExternalLoadDocument,
  useLazyGetDocumentsForExtLoadQuery,
} from "../../api-platform/externalLoads";
import { currentQuickPayStatus } from "../../reuse/QuickPay";

export interface QuickPayTableProps {
  status: QuickPayStatusEnum;
}

const showBrokerOwes: QuickPayStatusEnum[] = [QuickPayStatusEnum.Approved];
const showCarrierPayment: QuickPayStatusEnum[] = [QuickPayStatusEnum.Pending];

export const QuickPayTable = ({ status }: QuickPayTableProps): JSX.Element => {
  const toast = useToastHook();
  const notificationBanner = useNotificationBanner();
  const ErrorAMTIcon = errorAMTIcon();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [selected, setSelected] = useState<ISelected | undefined>();

  const { data: brokerNames } = useGetQuickPayBrokersQuery();
  const { data: carrierNames } = useGetQuickPayCarriersQuery();
  const [submitQuickPayNote, { isLoading }] = useSubmitQuickpayNoteMutation();

  const [brokerNameFilter, setBrokerNameFilter] = useState<string | null>(null);
  const [carrierNameFilter, setCarrierNameFilter] = useState<string | null>(
    null,
  );

  const [sortBy, setSortBy] = useState<QuickPaySortOptions>(
    QuickPaySortOptionsEnum.ElapsedTime,
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const { data } = useGetQuickPayRequestsViewQuery({
    status,
    sortOption: sortBy,
    sortDirection:
      sortBy === QuickPaySortOptionsEnum.TimeRemaining ? "DESC" : "ASC",
    searchTerm: searchTerm ? debouncedSearchTerm : undefined,
    brokerage: brokerNameFilter ?? undefined,
    carrier: carrierNameFilter ?? undefined,
  });

  const onCheckBoxChange = (qpId: string): void => {
    if (selectedIds.includes(qpId)) {
      setSelectedIds(selectedIds.filter((element) => element !== qpId));
    } else {
      setSelectedIds([...selectedIds, qpId]);
    }
  };

  const isHighAmount = (amount: number): boolean => amount > 4000;

  const [getExtLoadDocs] = useLazyGetDocumentsForExtLoadQuery();

  const downloadQPDocs = async (extLoadId: string): Promise<void> => {
    const response = await getExtLoadDocs({
      extLoadId,
    });
    if ("data" in response) {
      const data = response.data;
      if (!data || data.length === 0) {
        toast.warning({
          description: `There are no attached documents for the load: ${extLoadId}`,
        });
        return;
      }
      try {
        const filesData =
          response.data?.map((doc: IExternalLoadDocument) => ({
            documentName: getDownloadNameForExtLoadDoc(doc),
            url: doc.getUrl,
          })) ?? [];
        void downloadFiles(filesData, "external-load-documents.zip");
      } catch (error) {
        toast.error({
          description: `${parseErrorResponse(error as FetchBaseQueryError)}`,
        });
      }
    }
  };

  const confirmAddQuickPayNote = async (note: string): Promise<void> => {
    if (selected) {
      const response = await submitQuickPayNote({
        id: selected.id,
        iqpNote: {
          note,
          option: selected.content ? "Update" : "Create",
        },
      });
      if ("error" in response) {
        notificationBanner({
          status: "error",
          description: <p>There was a problem commenting. Please try again.</p>,
        });
      } else {
        notificationBanner({
          status: "success",
          description: <p>Your note has been added to {selected.id}.</p>,
        });
      }
      setSelected(undefined);
    }
  };

  const shouldShowOnStatus = (validStatuses: QuickPayStatusEnum[]): boolean => {
    return validStatuses.includes(status);
  };

  const table = useDataTable<IQuickPayView>({
    meta: {
      getRowAttributes: (row) => {
        const bgColor =
          row.original.amountToPayCarrier > 4000 ? "mvmntBgRed" : "";
        return {
          bg: bgColor,
        };
      },
    },
    state: {
      columnVisibility: {
        amountToPayCarrier: shouldShowOnStatus(showCarrierPayment),
        amountBrokerPays: shouldShowOnStatus(showBrokerOwes),
      },
    },
    data: data ?? [],
    columns: [
      {
        header: "",
        accessorKey: "id",
        cell: (cellInfo) => {
          return (
            status === QuickPayStatusEnum.Pending && (
              <Checkbox
                isChecked={selectedIds.includes(cellInfo.row.original.id)}
                onChange={() => onCheckBoxChange(cellInfo.row.original.id)}
              />
            )
          );
        },
      },
      {
        header: "Load Id",
        accessorKey: "brokerShipmentId",
        cell: (cellInfo) => {
          return <Text>{cellInfo.row.original.brokerShipmentId ?? ""}</Text>;
        },
      },
      {
        header: "BROKERAGE",
        accessorKey: "brokerage",
        cell: (cellInfo) => {
          return <Text>{cellInfo.row.original.brokerage}</Text>;
        },
      },
      {
        header: "Carrier",
        accessorKey: "carrier",
        cell: (cellInfo) => {
          return (
            <VStack alignItems="baseline">
              <Text>{cellInfo.row.original.carrier}</Text>
              <Text textColor="gray60" fontSize={11}>
                Invoice {cellInfo.row.original.carrierInvoiceId}
              </Text>
            </VStack>
          );
        },
      },
      {
        header: currentQuickPayStatus(status, QuickPayStatusEnum.Pending)
          ? "ELAPSED TIME"
          : currentQuickPayStatus(status, QuickPayStatusEnum.Approved)
          ? "APPROVED BY"
          : "REJECTED BY",
        accessorKey: currentQuickPayStatus(status, QuickPayStatusEnum.Pending)
          ? "elapsedTime"
          : "decidingUser",
        cell: (cellInfo) => {
          return (
            <Text>
              {status === QuickPayStatusEnum.Pending
                ? getTimeSinceString(new Date(cellInfo.row.original.createDate))
                : cellInfo.row.original.decidingUser}
            </Text>
          );
        },
      },
      {
        header: currentQuickPayStatus(status, QuickPayStatusEnum.Pending)
          ? "TIME REMAINING"
          : currentQuickPayStatus(status, QuickPayStatusEnum.Approved)
          ? "APPROVAL DATE"
          : "DATE REJECTED",
        accessorKey: currentQuickPayStatus(status, QuickPayStatusEnum.Pending)
          ? "timeRemaining"
          : "decisionDate",
        cell: (cellInfo) => {
          return (
            <HStack>
              <Text>
                {status === QuickPayStatusEnum.Pending
                  ? parseTimeRemainingInHours(
                      cellInfo.row.original.timeRemaining,
                    )
                  : cellInfo.row.original.decisionDate
                  ? parseStopDateToLocal(
                      new Date(cellInfo.row.original.decisionDate),
                    )
                  : ""}
              </Text>
              {status === QuickPayStatusEnum.Pending &&
                cellInfo.row.original.elapsedTime > 24 && (
                  <ReusableBadge<QuickPayInvoiceStatus>
                    currentStatus={QuickPayInvoiceStatus.OVERDUE}
                    badgeColor={BadgeColors.Red}
                  />
                )}
            </HStack>
          );
        },
      },
      {
        header: "INVOICE",
        accessorKey: "invoice",
        cell: (cellInfo) => {
          return (
            <HStack alignItems="baseline">
              <Box>
                {isHighAmount(cellInfo.row.original.invoice) && (
                  <ErrorAMTIcon w="20px" h="20px" />
                )}
              </Box>
              <Box>
                <Text
                  color={
                    isHighAmount(cellInfo.row.original.invoice)
                      ? "mvmntRed"
                      : ""
                  }
                >
                  {parseNumberToTwoDecimalCurrency(
                    cellInfo.row.original.invoice,
                  )}
                </Text>
              </Box>
            </HStack>
          );
        },
      },
      {
        header: "FEE",
        accessorKey: "percentFeeUsed",
        cell: (cellInfo) => {
          const {
            amountBrokerPays,
            amountToPayCarrier,
            percentFeeUsed,
            brokerage,
            invoice,
          } = cellInfo.row.original;
          const brokerPaidFee = Number(amountBrokerPays) > Number(invoice);
          return (
            <Tooltip
              hasArrow
              placement="right-end"
              label={
                brokerPaidFee
                  ? `MVMNT fee paid by ${brokerage}`
                  : "MVMNT fee paid by carrier"
              }
            >
              <Text>
                {parseNumberToTwoDecimalCurrency(
                  Math.abs(amountBrokerPays - amountToPayCarrier),
                )}{" "}
                {percentFeeUsed ? `(${percentFeeUsed}%)` : ""}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        header: "Broker Owes",
        accessorKey: "amountBrokerPays",
        cell: (cellInfo) => {
          return (
            <Text>
              {parseNumberToTwoDecimalCurrency(
                cellInfo.row.original.amountBrokerPays,
              )}
            </Text>
          );
        },
      },
      {
        header: "Carrier Payment",
        accessorKey: "amountToPayCarrier",
        cell: (cellInfo) => {
          return (
            <Text>
              {parseNumberToTwoDecimalCurrency(
                cellInfo.row.original.amountToPayCarrier,
              )}
            </Text>
          );
        },
      },
      {
        header: " ",
        cell: (cellInfo) => {
          const { id, extLoadId, rejectNote } = cellInfo.row.original;
          const popoverOptions: Option[] = [
            {
              title: "Download documents",
              onClick: async () => await downloadQPDocs(extLoadId),
            },
          ];
          if (status === QuickPayStatusEnum.Rejected) {
            popoverOptions.push({
              title: !rejectNote ? "Add note" : "Edit note",
              onClick: () => setSelected({ id, content: rejectNote }),
            });
          }
          return (
            <HStack>
              {status === QuickPayStatusEnum.Rejected && rejectNote && (
                <CommentTooltip comment={rejectNote} />
              )}
              <PopoverOptions options={popoverOptions} />
            </HStack>
          );
        },
      },
    ],
  });

  return (
    <QuickPayTableContext.Provider
      value={{
        quickpays: data ?? [],
        selectedQuickPayIds: selectedIds,
        setSelectedQuickPayIds: setSelectedIds,
        sortBy,
        setSortBy,
        searchTerm,
        setSearchTerm,
        brokerNameFilter,
        setBrokerNameFilter,
        carrierNameFilter,
        setCarrierNameFilter,
        brokerNames,
        carrierNames,
      }}
    >
      <Box height="calc(100vh - 300px)" overflow="auto" mb="30px">
        <TableFilters status={status} />
        <QuickPayTableSubhead totalDisplayedQuickPays={data?.length ?? 0} />
        <>
          <VStack w="100%">
            {data && <DataTable table={table} />}
            {status === QuickPayStatusEnum.Pending && data && (
              <TableSelection />
            )}
          </VStack>
        </>
        {selected && (
          <FormNoteModal
            isOpen={!!selected}
            onClose={() => setSelected(undefined)}
            onClickCTA={confirmAddQuickPayNote}
            loading={isLoading}
            title={selected.content ? "Edit note" : "Add a note"}
            content={selected.content}
            cancelText="Close"
            confirmText="Save"
            layerConfirmButton="yellow"
          />
        )}
      </Box>
    </QuickPayTableContext.Provider>
  );
};

export default QuickPayTable;
