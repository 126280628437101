import { CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  HStack,
  Text,
  InputLeftElement,
  InputGroup,
  Input,
  InputRightElement,
} from "@chakra-ui/react";
import { useContext } from "react";
import { BrokerQPPageContext } from "./BrokerQuickpayPageContext";

interface ExternalLoadTableSubheadProps {
  totalDisplayedLoads: number | undefined;
  totalPossibleLoads: number | undefined;
  page: number;
  offset: number;
}

export const ExternalLoadTableSubhead = ({
  totalDisplayedLoads,
  totalPossibleLoads,
  page,
  offset,
}: ExternalLoadTableSubheadProps): JSX.Element => {
  const pageContext = useContext(BrokerQPPageContext);
  const { searchParams, setSearchParams } = pageContext;
  const startingNumber = page * offset + 1;

  return (
    <HStack
      w="100%"
      h="60px"
      backgroundColor="grey6"
      padding="1rem 1rem"
      justify="space-between"
      minW="740px"
    >
      <HStack fontSize={13} color="gray80">
        <Text color="gray70">
          Showing {startingNumber}-
          {startingNumber + (totalDisplayedLoads ?? 0) - 1} of{" "}
          {totalPossibleLoads} load
          {totalDisplayedLoads !== 1 && "s"}
        </Text>
      </HStack>

      <div className="searchContainer">
        <InputGroup marginLeft="auto" width="auto">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray30" />
          </InputLeftElement>
          {searchParams.get("search") && (
            <InputRightElement
              cursor="pointer"
              onClick={() => {
                searchParams.delete("search");
                setSearchParams(searchParams);
              }}
            >
              <CloseIcon color="gray30" />
            </InputRightElement>
          )}
          <Input
            value={searchParams.get("search") ?? ""}
            onChange={({ target }) => {
              if (target.value) searchParams.set("search", target.value);
              else searchParams.delete("search");
              setSearchParams(searchParams);
            }}
            placeholder="Search for Load, invoice, customer or carrier"
            fontSize={13}
            width="480px"
            borderRadius="4px"
            borderColor="grey5"
            bgColor="#fff"
          />
        </InputGroup>
      </div>
    </HStack>
  );
};

export default ExternalLoadTableSubhead;
