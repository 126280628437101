const formatterZeroDecimals = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const formatterTwoDecimals = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const parseNumberToZeroDecimalCurrency = (value: number): string =>
  formatterZeroDecimals.format(value);

export const parseNumberToTwoDecimalCurrency = (value: number): string =>
  formatterTwoDecimals.format(value);

export const parseCurrencyToNumber = (money: string): string | undefined => {
  if (money === "") {
    return undefined;
  }
  const value = money.replace(/[^0-9]+/g, "");
  return value;
};
