import { EmailIcon } from "@chakra-ui/icons";
import {
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  VStack,
  Divider,
  Button,
  Text,
  FormErrorMessage,
  Link,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { validateEmail } from "../../reuse/UserData";
import { DEFAULT_MESSAGE_REQUIRED } from "../../reuse/Constants";
import { useForgotPasswordMutation } from "../../api/auth";
import { routes } from "../../routes";
import { useUsername } from "./components/Layout";
import { useNotificationBanner } from "../../components/useNotificationBanner";

interface IForgotPassword {
  username: string;
}

export function ForgotPassword(): JSX.Element {
  const form = useForm<IForgotPassword>();
  const notificationBanner = useNotificationBanner();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();
  const { setUsername } = useUsername();
  const navigate = useNavigate();

  async function onSubmit(data: IForgotPassword): Promise<void> {
    await forgotPassword(data.username)
      .unwrap()
      .then(() => {
        notificationBanner({
          status: "success",
          description: "Email successfully sent.",
        });
        setUsername(data.username);
        navigate(routes.RESET_PASSWORD);
      })
      .catch((e) => {
        notificationBanner({
          status: "error",
          description: e.message,
        });
      });
  }

  return (
    <VStack
      as="form"
      noValidate
      align="content-start"
      spacing="24px"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <Heading fontSize="24px">Forgot your password?</Heading>
      <Text>
        Enter your email address and we&rsquo;ll send you a code to reset your
        password.
      </Text>
      <FormControl isRequired isInvalid={!!form.formState.errors?.username}>
        <FormLabel requiredIndicator={<></>}>Email address</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none">
            <EmailIcon color="gray.400" />
          </InputLeftElement>
          <Input
            {...form.register("username", {
              validate: validateEmail,
              required: DEFAULT_MESSAGE_REQUIRED,
            })}
          />
        </InputGroup>
        <Flex>
          <FormErrorMessage>
            <ErrorMessage errors={form.formState.errors} name="username" />
          </FormErrorMessage>
          <Link
            mt="10px"
            marginLeft="auto"
            as={RouterLink}
            color="blue.500"
            to={routes.RESET_PASSWORD}
            fontSize="13px"
            textDecoration="underline"
            whiteSpace="nowrap"
          >
            Already have a code?
          </Link>
        </Flex>
      </FormControl>
      <Divider />
      <Button isLoading={isLoading} type="submit" size="lg" layerStyle="yellow">
        Send reset code
      </Button>
    </VStack>
  );
}
