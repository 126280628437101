import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { HStack, Link } from "@chakra-ui/layout";
import { Link as RouterLink } from "react-router-dom";
import SharedButton from "../../components/SharedButton";
import routes from "../../routes";

interface AdditionalContentProps {
  term: string;
  setTerm: (t: string) => void;
}

const TabAdditionalContent = ({
  term,
  setTerm,
}: AdditionalContentProps): JSX.Element => (
  <HStack justifyContent="space-between" m="1rem">
    <HStack w="50%">
      <Input
        w="80%"
        mr="1rem"
        placeholder="Search by name"
        value={term}
        onChange={(e) => setTerm(e.target.value)}
      />
      <CloseIcon
        mx="5rem"
        cursor="pointer"
        // hidden={term === ""}
        onClick={() => setTerm("")}
      />
    </HStack>
    <Link as={RouterLink} to={routes.INTERNAL_ACCOUNTS_ADD}>
      <SharedButton
        title="Add Account"
        leftIcon={<AddIcon />}
        layerStyle="yellow"
      />
    </Link>
  </HStack>
);

export default TabAdditionalContent;
