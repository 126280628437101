import { Invoice } from "../types/Invoice";
import { parseDateToLocal } from "./Dates";
import { QuickPayStatusEnum } from "../types/QuickPayRequest";
import { generateData } from "../pages/QuickPayRequest/reuse";
import axios from "axios";
import JSZip from "jszip";
import saveAs from "file-saver";
import invariant from "tiny-invariant";
import { GetQuickPayRequestsViewApiResponse } from "../api-platform/quickpay";
import { IDownloadFile } from "../types/Files";
import { IExternalLoadDocument } from "../api-platform/externalLoads/generated";
import { getShorthandForExtLoadDocType } from "./Enum";

export const OneMegaByte = 1048576;

export const createCsvFromObjectsArray = function (
  data: Array<Record<string, string | number | undefined>>,
): string {
  const dataWithHeader = [Object.keys(data[0] ?? []), ...data];
  const content = dataWithHeader
    .map((e) => Object.values(e).join(","))
    .join("\n");

  const csvData = new Blob([content], {
    type: "text/csv;charset=utf-8",
  });
  const csvUrl = URL.createObjectURL(csvData);
  return csvUrl;
};

export const exportFileResults = (csvUrl: string, fileName: string): void => {
  const link = document.createElement("a");
  link.href = csvUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const createInvoiceDataToExport = (
  invoices: Invoice[],
): Array<Record<string, string | number>> => {
  return invoices.map((invoice) => {
    const deliveryDate = parseDateToLocal(invoice.deliveryDate).split(",");
    const invoiceDate = parseDateToLocal(invoice.invoiceDate).split(",");
    const dueDate = parseDateToLocal(invoice.dueDate).split(",");

    return {
      [`Invoice ID`]: invoice.mvmntInvoiceId!,
      [`Customer`]: invoice.broker?.contactInformation.companyName,
      [`Delivery Date`]: `${deliveryDate[1]} ${deliveryDate[0]}`,
      [`Invoice Date`]: `${invoiceDate[1]} ${invoiceDate[0]}`,
      [`Due Date`]: `${dueDate[1]} ${dueDate[0]}`,
      [`Amount`]: invoice.invoiceAmount,
      [`Docs`]: invoice.documentName!,
    };
  });
};

export const createQuickPayRequestedToExport = (
  requests: GetQuickPayRequestsViewApiResponse,
): Array<Record<string, string | number>> => {
  if (!requests) return new Array<Record<string, string | number>>();

  return requests.map((request) => {
    const { elapsedTime, timeRemaining } = generateData(request);

    return {
      [`ID`]: request.id ?? "",
      [`Brokerage`]: request.brokerage,
      [`Carrier`]: request.carrier,
      [`Elapsed Time`]: `${elapsedTime} Hours`,
      [`Time Remaining`]: `${timeRemaining} Hours`,
      [`Amount`]: request.amountToPayCarrier,
    };
  });
};

export const createQuickPayDecisionToExport = (
  requests: GetQuickPayRequestsViewApiResponse,
  status: QuickPayStatusEnum,
): Array<Record<string, string | number>> => {
  return requests.map((request) => {
    const approved = status === QuickPayStatusEnum.Approved;
    const decisionDate = parseDateToLocal(
      new Date(request.decisionDate!),
    ).split(",");

    return {
      [`ID`]: request.id,
      [`Brokerage`]: request.brokerage,
      [`Carrier`]: request.carrier,
      [approved ? `Approved By` : `Rejected By`]: request.decidingUser ?? "",
      [approved
        ? `Approval Date`
        : `Rejected Date`]: `${decisionDate[1]} ${decisionDate[0]}`,
      [`Amount`]: request.amountToPayCarrier,
      [approved ? `Status` : `Reason`]: approved
        ? QuickPayStatusEnum.Approved
        : request.rejectNote ?? "",
    };
  });
};

export function getFileExtensionFromUrl(url: string): string {
  const { pathname } = new URL(url);
  const extension = pathname.split(".").pop();
  invariant(pathname.includes("."));
  invariant(extension);
  return extension;
}

export const getDownloadNameForExtLoadDoc = (
  doc: IExternalLoadDocument,
): string => {
  const name = doc.documentName.split("/").pop()?.split(".")[0];
  return doc.documentName.replace(
    /\/(?:.(?!\/))+$/,
    `/${getShorthandForExtLoadDocType(doc.documentType)}-${name}`,
  );
};

export async function downloadFiles(
  data: IDownloadFile[],
  zipFilename = "attachments.zip",
): Promise<void> {
  const zip = new JSZip();
  const zipFilePromises = data.map(async ({ documentName, url }) => {
    const name = documentName.split("/").pop()?.split(".")[0];
    const fileName = `${name}.${getFileExtensionFromUrl(url)}`;
    return await axios.get(url, { responseType: "blob" }).then((file) => ({
      file,
      fileName,
    }));
  });

  (await Promise.all(zipFilePromises)).forEach(({ file, fileName }) => {
    zip.file(fileName, file.data, { binary: true });
  });

  const zipFile = await zip.generateAsync({ type: "blob" });
  saveAs(zipFile, zipFilename);
}
