import { Icon, IconProps } from "@chakra-ui/react";

export const CommentIcon = (props: IconProps): JSX.Element => (
  <Icon width="26px" height="26px" viewBox="0 4 22 8" fill="none" {...props}>
    <path
      d="M11.0923 1.00734C9.59653 1.00518 8.12745 1.40335 6.83749 2.16052C5.54754 2.9177 4.48372 4.00629 3.75642 5.31333C3.02913 6.62036 2.66487 8.09822 2.70145 9.59353C2.73802 11.0888 3.1741 12.5471 3.96442 13.817L1.5 18.9929L6.67504 16.5285C7.77872 17.2147 9.02666 17.6352 10.3206 17.7569C11.6144 17.8787 12.9189 17.6983 14.1312 17.23C15.3435 16.7617 16.4305 16.0182 17.3065 15.0583C18.1826 14.0983 18.8237 12.948 19.1794 11.698C19.5352 10.4481 19.5958 9.13256 19.3565 7.85518C19.1172 6.57779 18.5846 5.37343 17.8005 4.33697C17.0165 3.30051 16.0025 2.46028 14.8384 1.88248C13.6743 1.30468 12.3919 1.0051 11.0923 1.00734V1.00734Z"
      stroke="#656565"
    />
  </Icon>
);
